<div class="btn-group btn-group-sm" style="background: rgb(255, 255, 255, 0.2)" role="group">
    <input id="measurementMode" type="checkbox" class="btn-check" name="btn-check" autocomplete="off"
        (click)="toggleMeasurementMode()" [checked]="showMeasurementUI">
    <label class="btn btn-outline-light text-dark" for="measurementMode" data-bs-toggle="tooltip"
        title="Toggle Measurement Mode"><i class="far fa-ruler"></i></label>

    <button *ngIf="showMeasurementUI" class="btn btn-light btn-outline-light text-dark fst-italic dropdown-toggle"
        type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
        {{ units }}
    </button>

    <ul *ngIf="showMeasurementUI" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li class="dropdown-item" (click)="convertUnit('ft')" title="feet">ft</li>
        <li class="dropdown-item" (click)="convertUnit('mi')" title="miles">mi</li>
        <div class="dropdown-divider"></div>
        <li class="dropdown-item" (click)="convertUnit('m')" title="meters">m</li>
        <li class="dropdown-item" (click)="convertUnit('km')" title="kilometers">km</li>
    </ul>

    <div *ngIf="showMeasurementUI">
        <button *ngIf="state == 'measureMode'" type="button" class="btn measure-btn" (click)="toggleMeasuring()">
            <i class="fas fa-plus" style="font-size: 20px;"></i>
        </button>
        <button *ngIf="state != 'measureMode'" type="button" class="btn cancel-btn" (click)="toggleMeasuring()">
            <i class="fas fa-xmark" style="font-size: 20px;"></i>
        </button>
    </div>
</div>