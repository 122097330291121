import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { Stripe } from 'stripe'

import { Component, OnInit } from '@angular/core'
import { StripeService } from '@services/stripe.service'
import { SubscriptionService } from '@services/subscription.service'

@Component({
  selector: 'billing-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.css']
})
export class EditSubscriptionComponent implements OnInit {
  public availableItems: Stripe.SubscriptionItem[] = []
  public items: Stripe.SubscriptionItem[] = []
  public selectedItem: Stripe.SubscriptionItem = null
  public confirmationMessage: string = ''
  public filterText: string = ''
  public sortField: string = 'name'
  public sortDirection: 'asc' | 'desc' = 'asc'
  public quantityError: string = ''
  public showDetails: boolean = true
  public isUpdating: boolean = false
  public invoice: any = null
  public paymentError: string = ''
  public paymentSetupLink: string = ''
  public prices: Stripe.Price[] = []
  public isUpdatingInterval: boolean = false
  public pendingIntervalChange: Stripe.Price.Recurring.Interval = null

  get interval() { return this._subscriptionService.interval }

  constructor(
    private _subscriptionService: SubscriptionService,
    private _stripeService: StripeService
  ) { }

  ngOnInit() {
    this.refreshSubscriptionData()
  }

  // Combine subscription items and prices so the merged list updates when either source changes
  refreshSubscriptionData() {
    combineLatest([
      this._subscriptionService.getSubscriptionItems(),
      this._stripeService.getAllPricesAndProducts(),
      this._subscriptionService.getSubscriptionDetails()
    ])
      .pipe(
        map(([items, prices]) => {
          this.items = items
          this.prices = prices
          return this.mergeItemsAndPrices()
        })
      )
      .subscribe(merged => {
        this.availableItems = merged
      })
  }

  // Recalculate merged items when sort criteria change
  sortBy(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
    }
    else {
      this.sortField = field
      this.sortDirection = 'asc'
    }
    this.availableItems = this.mergeItemsAndPrices()
  }

  // Call this from the filter input's (ngModelChange) event to update merged list
  updateMerged() {
    this.availableItems = this.mergeItemsAndPrices()
  }

  /**
   * Merges subscription items with active, editable prices matching the current interval.
   * For each eligible price not present in the subscription, creates a virtual item with quantity 0.
   */
  mergeItemsAndPrices(): Stripe.SubscriptionItem[] {
    let merged: Stripe.SubscriptionItem[] = this.items ? [...this.items] : []

    if (this.prices) {
      this.prices.forEach(price => {
        let product = typeof price.product === 'object' ? price.product as Stripe.Product : null
        if (!product) return
        if (!price.active || !product.active) return
        if (product.metadata && product.metadata.editable === 'false') return
        if (!price.recurring || price.recurring.interval !== this.interval) return

        const exists = merged.find(item => item.price && item.price.id === price.id)
        if (!exists) {
          const virtualItem: Stripe.SubscriptionItem = {
            id: 'virtual_' + price.id,
            object: 'subscription_item',
            price: price,
            quantity: 0,
          } as Stripe.SubscriptionItem
          merged.push(virtualItem)
        }
      })
    }

    let filtered = merged.filter(item =>
      item.price.product['name'].toLowerCase().includes(this.filterText.toLowerCase())
    )

    filtered = filtered.filter(item => this.isEditable(item))

    filtered.sort((a, b) => {
      let aVal, bVal
      switch (this.sortField) {
        case 'name':
          aVal = a.price.product['name'].toLowerCase()
          bVal = b.price.product['name'].toLowerCase()
          break
        case 'price':
          aVal = a.price.unit_amount
          bVal = b.price.unit_amount
          break
        case 'quantity':
          aVal = a.quantity
          bVal = b.quantity
          break
        default:
          aVal = ''
          bVal = ''
      }
      if (aVal < bVal) return this.sortDirection === 'asc' ? -1 : 1
      if (aVal > bVal) return this.sortDirection === 'asc' ? 1 : -1
      return 0
    })
    return filtered
  }

  isActive(item: Stripe.SubscriptionItem): boolean {
    const price = item?.price as Stripe.Price
    const product = price?.product as Stripe.Product
    return price.active && product.active
  }

  isEditable(item: Stripe.SubscriptionItem): boolean {
    if (item && item.price && item.price.product && item.price.product['metadata']) {
      const editableValue = item.price.product['metadata'].editable as string | boolean
      return !(editableValue === 'false' || editableValue === false)
    }
    return true
  }

  selectItem(item: Stripe.SubscriptionItem) {
    this.selectedItem = { ...item }
    this.quantityError = ''
    this.paymentError = ''
    this.invoice = null
  }

  cancelEdit() {
    this.selectedItem = null
    this.quantityError = ''
    this.paymentError = ''
  }

  toggleDetails() {
    this.showDetails = !this.showDetails
  }

  decrementQuantity() {
    if (!this.selectedItem) return
    const minQty = this.getMinQuantity(this.selectedItem)
    if (this.selectedItem.quantity > minQty) {
      this.selectedItem.quantity--
      this.validateQuantity()
    }
  }

  incrementQuantity() {
    if (!this.selectedItem) return
    const maxQty = this.getMaxQuantity(this.selectedItem)
    if (maxQty === null || this.selectedItem.quantity < maxQty) {
      this.selectedItem.quantity++
      this.validateQuantity()
    }
  }

  validateQuantity() {
    if (!this.selectedItem) return
    const minQty = this.getMinQuantity(this.selectedItem)
    const maxQty = this.getMaxQuantity(this.selectedItem)
    if (this.selectedItem.quantity < minQty) {
      this.quantityError = `Minimum quantity is ${minQty}.`
    } else if (maxQty !== null && this.selectedItem.quantity > maxQty) {
      this.quantityError = `Maximum quantity is ${maxQty}.`
    } else if (this.selectedItem.quantity < 0) {
      this.quantityError = 'Quantity cannot be negative.'
    } else {
      this.quantityError = ''
    }
  }

  getMinQuantity(item: Stripe.SubscriptionItem): number {
    return item.price.metadata && item.price.metadata.min_quantity ? Number(item.price.metadata.min_quantity) : 0
  }

  getMaxQuantity(item: Stripe.SubscriptionItem): number | null {
    return item.price.metadata && item.price.metadata.max_quantity ? Number(item.price.metadata.max_quantity) : null
  }

  submitEdit() {
    if (!this.selectedItem || this.quantityError) return
    this.isUpdating = true
    const action = this.selectedItem.id.startsWith('virtual_')
      ? this._subscriptionService.addItemToSubscription({ price: this.selectedItem.price.id, quantity: this.selectedItem.quantity })
      : this._subscriptionService.updateSubscriptionItem(this.selectedItem)

    action.subscribe(
      response => {
        if (this.selectedItem.id.startsWith('virtual_')) {
          this.items.push(response['item'])
        } else {
          this.items = this.items.map(item => item.id === this.selectedItem.id ? this.selectedItem : item)
        }

        this.refreshSubscriptionData()

        this.confirmationMessage = response.message
        if (response.invoice) this.invoice = response.invoice
        this.isUpdating = false
        this.selectedItem = null
      },
      error => {
        if (error['error'].includes("payment") || error['error'].includes("card")) {
          this._subscriptionService.createCheckoutSession({ cancel_route: 'billing', success_route: 'billing' }).subscribe(
            session => {
              this.paymentSetupLink = session.url
              this.paymentError = "Payment failed. Please update your payment method using the link below:"
              this.isUpdating = false
            },
            err => {
              this.paymentError = error.message || 'Update failed. Please try again or contact support.'
              this.isUpdating = false
            }
          )
        } else {
          this.isUpdating = false
        }
      }
    )
  }

  // Called when the user clicks on one of the interval buttons.
  initiateIntervalChange(interval: Stripe.Price.Recurring.Interval): void {
    if (this.isUpdatingInterval) {
      return // Prevent initiating while already updating.
    }
    // Set the pending interval so that the confirmation prompt is shown.
    this.pendingIntervalChange = interval
  }

  // Called when the user confirms the change.
  confirmIntervalChange(): void {
    if (!this.pendingIntervalChange) return

    this.isUpdatingInterval = true
    this._stripeService.updateSubscriptionInterval(this.pendingIntervalChange)
      .subscribe(
        response => {
          // On success, the subscription service might refresh the actual interval.
          // If needed, you can manually refresh data here:
          this.refreshSubscriptionData()

          // Clear pending changes and re-enable UI
          this.pendingIntervalChange = null
          this.isUpdatingInterval = false
          this.invoice = response.invoice
        },
        error => {
          console.error('Interval update failed:', error)
          // Optionally show a more detailed error message in the UI.
          this.pendingIntervalChange = null
          this.isUpdatingInterval = false
        }
      )
  }

  // Called if the user cancels the confirmation.
  cancelIntervalChange(): void {
    this.pendingIntervalChange = null
  }
}