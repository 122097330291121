import * as Mapbox from 'mapbox-gl'
import { EMPTY, forkJoin, merge, Observable, of } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js'

import { MapControls, OrbitControls } from '@classes/OrbitControls'

export type EasingFunction = (amount: number) => number;

export interface TransitionOptions {
  /** 
   * Length of ease time in Milliseconds 
   * @default 750
   */
  easeTime?: number

  /**
   * Ease movement & behavior 
   * @default TWEEN.Easing.Cubic.Out
   */
  
  easeType?: EasingFunction

  /** 
   * Whether or not the easing movement can be canceled by mouse events on the canvas or additional interruptions
   * @default true
   */
  interruptable?: boolean

  /** Additional events that can interrupt easing. Requires `interruptable` to be `true` */
  interruptions$?: Observable<any>[]

  /** Function to be called when the easing has completed */
  onComplete?: CallableFunction

  /** Function to be called when the easing is updating */
  onUpdate?: CallableFunction

  /** Function to be called if the easing is interrupted */
  onStop?: CallableFunction

  /** 
   * Whether or not to complete the transition if interrupted 
   * @default false
   */
  finishOnStop?: boolean
}

export function mapJumpTo(map: Mapbox.Map, options: Mapbox.CameraOptions) {
  map.jumpTo(options)
}

export function mapEaseTo(map: Mapbox.Map, options: Mapbox.EasingOptions = {}) {
  options.duration = options.duration ?? 2000

  map.easeTo(options)
}

export function fitMapToBounds(map: Mapbox.Map, bounds: Mapbox.LngLatBounds, options: Mapbox.EasingOptions = {}) {
  options.bearing = options.bearing ?? map.getBearing()
  options.padding = options.padding ?? { top: 20, bottom: 20, left: 20, right: 20 }
  options.maxZoom = options.maxZoom ?? 17
  options.duration = options.duration ?? 1000

  if (!bounds.isEmpty()) {
    map.fitBounds(bounds, options)
  }
}

export function fitMapToBox(map: Mapbox.Map, box: THREE.Box3, getLngLat: (point: THREE.Vector3) => Mapbox.LngLat, options: Mapbox.FitBoundsOptions = {}) {
  const size = box.getSize(new THREE.Vector3())
  const center = box.getCenter(new THREE.Vector3())

  const radius = size.x / 2, halfHeight = size.y / 2, halfDepth = size.z
  const neCorner = new THREE.Vector3(center.x + radius, center.y + halfHeight, center.z + halfDepth)
  const swCorner = new THREE.Vector3(center.x - radius, center.y - halfHeight, center.z - halfDepth)

  const point1 = getLngLat(neCorner)
  const point2 = getLngLat(swCorner)
  const bounds = new Mapbox.LngLatBounds()
    .extend(point1)
    .extend(point2)

  fitMapToBounds(map, bounds, options)
}

export function fitMapToModels(map: Mapbox.Map, models: THREE.Group[] | THREE.Object3D[], getLngLat: (point: THREE.Vector3) => Mapbox.LngLat, options: Mapbox.FitBoundsOptions = {}) {
  const box = new THREE.Box3()

  models.forEach(model => box.expandByObject(model))

  fitMapToBox(map, box, getLngLat, options)
}

export function ease(
  start: number,
  end: number,
  duration: number,
  updateFn: (num: number) => void,
  easingFn: (amount: number) => number = TWEEN.Easing.Cubic.Out
) {
  let startTime: number | null = null

  const step = (timestamp: number) => {
    if (!startTime) startTime = timestamp
    const timeElapsed = timestamp - startTime
    // Normalize timeElapsed to a value between 0 and 1
    const progress = Math.min(timeElapsed / duration, 1)
    // Apply the easing function to progress
    const easedProgress = easingFn(progress)
    // Calculate the current value based on eased progress
    const currentValue = start + (end - start) * easedProgress

    updateFn(currentValue)

    if (timeElapsed < duration) {
      requestAnimationFrame(step)
    } else { // Ensure the final value is set
      updateFn(end)
    }
  }

  requestAnimationFrame(step)
}

/** 
 * Gradually transitions a starting vector's values to the final vector's values 
 * @returns true if ease completed without interruption and false otherwise
 */
export function easeTo(start: THREE.Vector3, end: THREE.Vector3, options: TransitionOptions = {}) {
  const tween = new TWEEN.Tween(start)
  const easeTime = options?.easeTime ?? 750
  const easeType = options?.easeType ?? TWEEN.Easing.Cubic.Out
  const finishOnStop = options?.finishOnStop ?? false
  const interruptable = options?.interruptable ?? true
  const interruptions$ = options?.interruptions$ ?? [EMPTY]
  const onComplete = options?.onComplete
  const onUpdate = options?.onUpdate
  const onStop = options?.onStop

  const interruptionListener = merge(...interruptions$)
    .pipe(take(1), filter(() => interruptable))
    .subscribe(() => tween.stop())

  return new Promise<boolean>((resolve, reject) => {
    tween.to(end, easeTime)
      .easing(easeType)
      .onUpdate(() => {
        if (onUpdate) options.onUpdate()
      })
      .onComplete(() => {
        interruptionListener.unsubscribe()
        if (onComplete) onComplete()
        resolve(true)
      })
      .onStop(() => {
        interruptionListener.unsubscribe()
        if (finishOnStop) start.copy(end)
        if (onStop) onStop()
        resolve(false)
      })
      .start()
  })
}

/** Moves the OrbitControls target to the new position. */
export function focus(controls: OrbitControls, position: THREE.Vector3, ease = false, options: TransitionOptions = {}) {
  if (ease) {
    return easeTo(controls.target, position, options)
  } else {
    controls.target.copy(position)

    return of(true).toPromise()
  }
}

/** 
 * Moves the OrbitControls target to the box's center and 
 * moves the camera such that the entire box is in view. 
 */
export function focusOnBox(camera: THREE.PerspectiveCamera, controls: OrbitControls, box: THREE.Box3, ease = false, options: TransitionOptions = {}) {
  const fitRatio = 1.4 // How close the camera will be to the model
  const size = box.getSize(new THREE.Vector3())
  const modelCenter = box.getCenter(new THREE.Vector3())
  const maxSize = Math.max(size.x, size.y, size.z)
  const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360))
  const fitWidthDistance = fitHeightDistance / camera.aspect
  const distance = fitRatio * Math.max(fitHeightDistance, fitWidthDistance)
  const direction = controls.target
    .clone()
    .sub(camera.position)
    .normalize()
    .multiplyScalar(distance)

  if (direction.x == 0 && direction.y == 0 && direction.z == 0) {
    direction.set(0.001, 0.001, 0.001)
  }

  const cameraPosition = modelCenter.clone().sub(direction)

  if (ease) {
    return forkJoin([
      easeTo(camera.position, cameraPosition, options),
      easeTo(controls.target, modelCenter, options)
    ]).pipe(map(() => true)).toPromise()
  } else {
    camera.position.copy(cameraPosition)
    controls.target.copy(modelCenter)

    return of(true).toPromise()
  }
}

/** 
 * Moves the OrbitControls target to the model's center and 
 * moves the camera such that the entire model is in view. 
 */
export function focusOnModels(camera: THREE.PerspectiveCamera, controls: OrbitControls, models: THREE.Group[], ease = false, options: TransitionOptions = {}) {
  const box = new THREE.Box3()

  models.forEach(m => box.expandByObject(m))

  return focusOnBox(camera, controls, box, ease, options)
}

/** 
 * Moves the Camera around the OrbitControls such that 
 * it is oriented facing the new position 
 */
export function lookAt(camera: THREE.PerspectiveCamera, controls: OrbitControls, position: THREE.Vector3, ease = false, options: TransitionOptions = {}) {
  const target = controls.target.clone()
  const distance = target.distanceTo(camera.position)
  const direction = position
    .clone()
    .sub(target)
    .normalize()
    .multiplyScalar(distance)

  const newCameraPosition = target
    .clone()
    .sub(direction)

  if (ease) {
    return easeTo(camera.position, newCameraPosition, options)
  } else {
    camera.position.copy(newCameraPosition)

    return of(true).toPromise()
  }
}

/** Moves the camera such that the entire box is in view */
export function lookAtBox(camera: THREE.PerspectiveCamera, controls: OrbitControls, box: THREE.Box3, ease = false, options: TransitionOptions = {}) {
  const center = box.getCenter(new THREE.Vector3())

  return lookAt(camera, controls, center, ease, options)
}

/** Moves the camera such that all of the models are in view */
export function lookAtModels(camera: THREE.PerspectiveCamera, controls: OrbitControls, models: THREE.Group[], ease = false, options: TransitionOptions = {}) {
  const box = new THREE.Box3()

  models.forEach(model => box.expandByObject(model))

  return lookAtBox(camera, controls, box, ease, options)
}

/** 
 * Moves the OrbitControls target to the new position 
 * while keeping the camera y axis and angle the same.
 */
export function reposition(camera: THREE.PerspectiveCamera, controls: OrbitControls, target: THREE.Vector3, ease = false, options: TransitionOptions = {}) {
  const startingCameraPosition = camera.position.clone()
  const startingControlsPosition = controls.target.clone()
  const difference = startingCameraPosition.sub(startingControlsPosition)
  const newCameraPosition = target.clone().add(difference)

  const group = new THREE.Group()
  let controlsPoint = new THREE.Mesh(new THREE.SphereGeometry(), new THREE.MeshBasicMaterial())
  let cameraPoint = new THREE.Mesh(new THREE.SphereGeometry(), new THREE.MeshBasicMaterial())
  controlsPoint.position.copy(startingControlsPosition)
  cameraPoint.position.copy(camera.getWorldPosition(new THREE.Vector3()))

  const getMidpoint = (pointA: THREE.Vector3, pointB: THREE.Vector3) => {
    var dir = pointB.clone().sub(pointA);
    var len = dir.length();
    dir = dir.normalize().multiplyScalar(len / 2);
    return pointA.clone().add(dir);
  }

  const midpointStart = getMidpoint(controlsPoint.position, cameraPoint.position)
  group.position.copy(midpointStart)

  const midpointEnd = getMidpoint(target, newCameraPosition)

  group.attach(controlsPoint)
  group.attach(cameraPoint)

  const disposePoints = () => {
    controlsPoint.geometry.dispose()
    controlsPoint.material.dispose()
    controlsPoint = undefined

    cameraPoint.geometry.dispose()
    cameraPoint.material.dispose()
    cameraPoint = undefined
  }

  options.onUpdate = () => {
    camera.position.copy(cameraPoint.getWorldPosition(new THREE.Vector3()).clone())
    controls.target.copy(controlsPoint.getWorldPosition(new THREE.Vector3()).clone())
  }

  options.onStop = () => {
    camera.position.copy(newCameraPosition.clone())
    controls.target.copy(controlsPoint.getWorldPosition(new THREE.Vector3()).clone())

    disposePoints()
  }

  if (ease) {
    return easeTo(group.position, midpointEnd, options)

  } else {
    camera.position.copy(newCameraPosition)
    controls.target.copy(target)
    disposePoints()

    return Promise.resolve(true)
  }
}