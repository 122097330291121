<form class="container-fluid bg-light pt-2" [formGroup]="sceneForm" [style.margin-bottom]="'65px'"
    style="user-select: none;">
    <div class="mt-2 mb-3">
        <!-- Scene Name -->
        <label class="form-label">Scene name</label>
        <input class="form-control" formControlName="name" placeholder="Scene Name">
        <small *ngIf="sceneForm.get('name').status=='INVALID'" class="form-text text-danger">
            Scene name is required</small>
    </div>

    <div class="mb-3">
        <div class="d-flex">
            <!-- Container for the first button (conditionally rendered) -->
            <div class="flex-fill me-2">
                <button *ngIf="scene.type !== 'Map'" class="btn btn-sm btn-success w-100"
                    (click)="setStartingOrientation()">
                    Set Starting Orientation
                </button>
                <a *ngIf="scene.type === 'Map'" class="btn btn-sm btn-success w-100"
                    (click)="setMapsStartingOrientation()">
                    Set Starting Orientation
                </a>
            </div>
            <!-- Container for the second button -->
            <div class="flex-fill">
                <button class="btn btn-sm btn-success w-100" (click)="setSceneThumbnail()">
                    Set Thumbnail
                </button>
            </div>
        </div>
    </div>

    <ul *ngIf="scene.type == 'Map' || scene.type == '360 Image'" class="list-group mb-3">
        <!-- Terrain Property (Map scenes) -->
        <ng-container *ngIf="terrain && scene.type == 'Map'" [formGroup]="terrain">
            <li class="list-group-item d-flex align-items-center pe-1">
                <span class="text-nowrap text-truncate me-auto" style="min-width:0;">3D terrain</span>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" formControlName="value"
                        (change)="updateTerrain(terrain.value)" style="cursor: pointer;">
                </div>
            </li>
        </ng-container>
        <!-- Map scenes -->
        <ng-container *ngIf="scene.type == 'Map'">
            <li class="list-group-item d-flex align-items-center" [formGroup]="style">
                <label class="form-label me-2 mb-0">Style</label>
                <select class="form-select w-auto ms-auto" formControlName="value"
                    (change)="updateSceneProperty(style.value)" style="cursor: pointer">
                    <option *ngFor="let mapstyle of mapStyles" [value]="mapstyle.style">
                        {{ mapstyle.display }}
                    </option>
                </select>
            </li>
        </ng-container>

        <!-- Standard and 360 Scenes -->
        <ng-container *ngIf="scene.type == '360 Image'">
            <div class="mb-3">
                <!-- Scene File Property (360 Image scenes) -->
                <ng-container *ngIf="file" [formGroup]="file">
                    <label class="form-label">Background image</label>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span class="text-nowrap text-truncate me-auto" style="min-width:0;">
                            <i class="fas fa-file-image" style="width:20px;"></i>
                            <span class="ms-2">{{ fileReference.filename }}</span>
                        </span>
                        <div class="d-flex align-items-center">
                            <a class="btn text-secondary px-1" title="Download"
                                (click)="downloadBackgroundFile(fileReference)">
                                <i class="fas fa-file-download"></i>
                            </a>
                            <label for="background" class="btn text-secondary px-1 mb-0" title="Replace">
                                <i class="fas fa-file-upload"></i>
                            </label>
                            <input id="background" type="file" accept="image/*" class="d-none"
                                (change)="replaceBackgroundFile($event.target.files[0])">
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="form-label mb-0">Relative North</label>
                            <button class="btn btn-sm btn-success" (click)="setNorthAngle()">
                                Record North
                            </button>
                        </div>
                        <small class="text-muted d-block mt-1">
                            Used to orient 360 scenes with one another.
                        </small>
                    </li>
                </ng-container>
            </div>
        </ng-container>
    </ul>

    <button class="btn btn-secondary mb-3 w-100" type="button" (click)="toggleCollapse()">
        Advanced Settings
        <i [ngClass]="{'ms-2 fa': true, 'fa-caret-down': !showAdvanced, 'fa-caret-up': showAdvanced}"></i>
    </button>
    <div class="collapse" [class.collapse]="!showAdvanced" [class.show]="showAdvanced">
        <ul class="list-group mb-3">
            <!-- Scene of Interest -->
            <li class="list-group-item d-flex align-items-center pe-1">
                <span class="text-nowrap text-truncate me-auto" style="min-width:0;">Show in Viewer navigation
                    panel</span>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" formControlName="sceneOfInterest"
                        style="cursor: pointer;">
                </div>
            </li>
            <!-- Overlap Property (Map scenes) -->
            <ng-container *ngIf="scene.type == 'Map'" [formGroup]="overlap">
                <li class="list-group-item d-flex align-items-center pe-1">
                    <span class="text-nowrap text-truncate me-auto" style="min-width:0;">Hide markers on overlap</span>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" formControlName="value"
                            (change)="updateOverlapProperty(overlap.value)" style="cursor: pointer;">
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="timeOfDay && scene.type=='Map'" [formGroup]="timeOfDay">
                <li class="list-group-item d-flex align-items-center">
                    <div class="d-flex align-items-center w-100">
                        <label class="form-label me-2 mb-0">Time of Day</label>
                        <select class="form-select w-auto ms-auto" formControlName="value"
                            (change)="updateSunPosition(timeOfDay)" style="cursor: pointer">
                            <option *ngFor="let option of skyboxOptions" [value]="option.value">
                                {{ option.display }}
                            </option>
                        </select>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
</form>