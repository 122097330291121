import { Injectable } from '@angular/core'
import { MapSpace } from '@classes/MapSpace'
import { ModelSpace } from '@classes/ModelSpace'

import { EnvironmentManagerService } from './environment-manager.service'
import { ModelService } from './model.service'
import { SceneService } from './scene.service'

@Injectable({
  providedIn: 'root'
})
export class ThumbnailService {
  private get _sceneManager() { return this._envManager.sceneManager }
  private get _modelSpace() { return this._envManager.modelSpace }
  private get _selectedScene() { return this._sceneService.selectedScene }
  private get _selectedModel() { return this._modelService.selectedModel }

  constructor(
    private _modelService: ModelService,
    private _envManager: EnvironmentManagerService,
    private _sceneService: SceneService,
  ) { }

  updateSceneThumbnail() {
    const scene = this._selectedScene

    return this.getThumbnail(this._modelSpace).then(thumbnail => {
      scene.thumbnail = thumbnail
      scene.updateThumbnail = false

      return this._sceneService.updateScene(scene, false).toPromise()
    })
  }

  updateModelThumbnail(updateLocally = true) {
    const model = this._selectedModel

    return this.getThumbnail(this._modelSpace).then(thumbnail => {
      model.thumbnail = thumbnail

      return this._modelService.updateModel(model, { updateLocally, toast: false }).toPromise()
    })
  }

  private getThumbnail(space: ModelSpace | MapSpace) {
    const canvasHeight = space.canvas.height
    const canvasWidth = space.canvas.width

    space.renderer.render(this._sceneManager.renderedScene, space.camera)

    return this._getCanvasScreenshot(space)
      .then((screenShot: string) => this.cropScreenshot(screenShot, canvasWidth, canvasHeight))
  }

  private _getCanvasScreenshot(space: ModelSpace | MapSpace) {
    return new Promise<string>((resolve) => {
      if (space instanceof MapSpace) {
        space.map.once('render', () => resolve(space.canvas.toDataURL('image/png')))
      } else {
        resolve(space.canvas.toDataURL("image/png"))
      }
    })
  }

  cropScreenshot(screenShot: string, width: number, height: number) {
    const cropCanvas = document.createElement('canvas')
    const contex = cropCanvas.getContext("2d")
    const imageElement = document.createElement('img')
    const landscape: boolean = width > height

    imageElement.src = screenShot
    cropCanvas.width = 500
    cropCanvas.height = 500
    cropCanvas.append(imageElement)

    return new Promise<string>((resolve, reject) => {
      imageElement.onload = () => {
        const srcCanvasX = landscape ? (width - height) / 2 : 0
        const srcCanvasY = landscape ? 0 : (height - width) / 2
        const srcCanvasWidth = landscape ? height : width
        const srcCanvasHeight = landscape ? height : width
        const destCanvasX = 0
        const destCanvasY = 0
        const destCanvasWidth = 500
        const destCanvasHeight = 500

        contex.drawImage(imageElement,
          srcCanvasX, srcCanvasY,
          srcCanvasWidth, srcCanvasHeight,
          destCanvasX, destCanvasY,
          destCanvasWidth, destCanvasHeight)

        resolve(cropCanvas.toDataURL("image/png"))

        cropCanvas.remove()
        imageElement.remove()
      }
    })
  }
}
