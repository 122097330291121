import { Subscription } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { filter, map, startWith, switchMap } from 'rxjs/operators'

import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import { Project } from '@classes/Project'
import { AuthenticationService } from '@services/authentication.service'
import { DashboardService } from '@services/dashboard.service'
import { ModalService } from '@services/modal.service'
import { ProjectService, usersProjects$ } from '@services/project.service'
import { SubscriptionService } from '@services/subscription.service'
import { TooltipService } from '@services/tooltip.service'
import { SceneService } from '@services/scene.service'
import { Scene } from '@classes/Scene'
import { ThumbnailService } from '@services/thumbnail.service'

@Component({
  selector: 'shared-modal-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css'],

})
export class ProjectDetailsComponent implements OnDestroy, AfterViewInit {
  public cancelText = 'Close'
  get modal() { return this.dashboardService.detailsModal }
  set modal(modal) { this.dashboardService.detailsModal = modal }
  public title = 'Project Details'

  public onCancel: CallableFunction
  public link: string

  private _subscriptions: Subscription[] = []
  public currentUserPermission: string = ''
  public selectedProject$: Observable<Project> = this.dashboardService.selectedProjectForDetails$

  public showUpload = false
  private project: Project
  private scenes: Scene[]

  get atPublicProjectsLimit$() {
    return usersProjects$.pipe(
      startWith(this.projectService.usersProjects),
      map(ps => ps.filter(p => p.publicStatus).length >= this._subscriptionService.publicProjectLimit))
  }
  get hasFinancialAccess() { return this._authenticationService.hasFinancialAccess }
  get hasOrganization() { return this._authenticationService.hasOrganization }
  get inFreeTier() { return this._subscriptionService.inFreeTier }

  get firstSceneThumbnail() { return this.scenes.find(scene => scene.previousID == undefined).thumbnail }

  constructor(
    private _authenticationService: AuthenticationService,
    private _sceneService: SceneService,
    private _subscriptionService: SubscriptionService,
    private _thumbnailService: ThumbnailService,
    private _tooltipService: TooltipService,
    public dashboardService: DashboardService,
    public modalService: ModalService,
    public projectService: ProjectService,
  ) {
    this._subscriptions.push(
      this.dashboardService.selectedProjectForDetails$.pipe(
        filter(project => project !== undefined && project.permissions.length > 0),
        switchMap(project => {
          this.project = project
          this.currentUserPermission = this.dashboardService.getUserPermissionForProject(project)
          return this._sceneService.getScenes(project.id)
        })
      ).subscribe(scenes => {
        this.scenes = scenes
        if (!this.thumbSameAsScene(this.project)) this.showUpload = true
      }),
    )
  }

  _submit() {

  }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }
    if (this.dashboardService.selectedProjectForDetails.title.length == 0) return
    this.modal?.hide()
  }

  updateProject(updatedProject: Project) {
    if (updatedProject.title.length == 0) return
    this.projectService.updateProject(updatedProject).subscribe()
  }

  thumbSameAsScene(project: Project) {
    return project.thumbnail == this.firstSceneThumbnail
  }

  uploadToggle() {
    if (this.thumbSameAsScene(this.project)) {
      // Show file input for uploading
      this.project.thumbnail = undefined
    } else {
      // Set thumbnail to the first scene's thumbnail
      this.project.thumbnail = this.firstSceneThumbnail
      this.updateProject(this.project)
    }
  }

  convertImageToBase64(file: File): Promise<{ base64String: string; dimensions: { width: number; height: number } }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const img = new Image()
        img.onload = () => {
          const base64String = reader.result?.toString() || ''
          resolve({ base64String, dimensions: { width: img.width, height: img.height } })
        }
        img.onerror = (error) => reject(error)
  
        if (reader.result) {
          img.src = reader.result.toString() // Set the source of the image to the Base64 data URL
        } else {
          reject('File could not be read')
        }
      }
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file) // Read the file as a Base64-encoded data URL
    })
  }

  async addThumbnail(file: File) {
    const { base64String, dimensions } = await this.convertImageToBase64(file)
    this._thumbnailService.cropScreenshot(base64String, dimensions.width, dimensions.height).then(cropped => {
      this.project.thumbnail = cropped
      this.updateProject(this.project)
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => this._tooltipService.intializeTooltips())
  }

  ngOnDestroy() {
    this.modal.dispose()
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}