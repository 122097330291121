import { Component, OnInit } from '@angular/core'
import { FeatureService } from '@services/feature.service'
import { VecktaService } from '@services/veckta.service'

@Component({
  selector: 'viewer-veckta-view',
  templateUrl: './veckta-view.component.html',
  styleUrls: ['./veckta-view.component.css']
})
export class VecktaViewComponent implements OnInit {

  public projects = []
  openIndex: number | null = null

  constructor(
    public featureService: FeatureService,
    public vecktaService: VecktaService
  ) { }

  ngOnInit() {
    this.projects = this.vecktaService.projects
    this.vecktaService.vecktaProjectID$.subscribe(projectID => {
      const index = this.projects.findIndex(project => project.id == projectID)
      this.setIndex(index)
    })
  }

  setIndex(index: number): void {
    this.openIndex = index
  }
}