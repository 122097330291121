import { Modal } from 'bootstrap'
import { filter, switchMap, take, tap } from 'rxjs/operators'

import { Component, ViewChild } from '@angular/core'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Feature, FeatureTypes, isThreeFeature } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { Model } from '@classes/Model'
import { scenesLoaded$ } from '@classes/SceneManager'
import { CommandService } from '@services/command.service'
import { CopyService } from '@services/copy.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { BulkCreateOptions, FeatureService } from '@services/feature.service'
import { LoadingService } from '@services/loading.service'
import { models$ } from '@services/model.service'
import { SceneService } from '@services/scene.service'
import { SubscriptionService } from '@services/subscription.service'
import { CreateFeaturesFromGeojsonComponent } from '@shared/create-features-from-geojson/create-features-from-geojson.component'
import { CreateRasterLayersFromGeotiffsComponent } from '@shared/create-raster-layers-from-geotiffs/create-raster-layers-from-geotiffs.component'
import { EditCoordinateListComponent } from '@shared/edit-coordinate-list/edit-coordinate-list.component'

@Component({
  selector: 'shared-modal-create-feature',
  templateUrl: './create-feature.component.html',
  styleUrls: ['./create-feature.component.css'],
})
export class CreateFeatureComponent {
  @ViewChild(CreateFeaturesFromGeojsonComponent) geojsonComponent: CreateFeaturesFromGeojsonComponent
  @ViewChild(CreateRasterLayersFromGeotiffsComponent) rasterComponent: CreateRasterLayersFromGeotiffsComponent
  @ViewChild(EditCoordinateListComponent) coordinateListComponent: EditCoordinateListComponent

  public coordinates: [number, number][] = []
  public defaultMarkerImage: File = undefined
  public form: UntypedFormGroup
  public models$ = models$
  public multipleFeaturesFormArray: UntypedFormArray
  public selectedFiles: File[] = []

  public modal: Modal
  get isValid() {
    const { type } = this.form.value as { type: FeatureTypes & 'geojson' }

    if (type == '3D') {
      if (this.model.value)
        return true
    } else if (type == 'image') {
      if ((this.selectedFiles.length > 0) && this.multipleFeaturesFormArray.controls.every(control => control.valid))
        return true
    } else if (type == 'geojson') {
      if (this.geojsonComponent?.isValid)
        return true
    } else if (type == 'line' || type == 'polygon') {
      if (this.coordinateListComponent?.isValid)
        return true
    } else if (type == 'rasterImage') {
      return this.rasterComponent.files.valid
    } else {
      if (this.form.valid)
        return true
    }

    return false
  }

  get map() { return this.envManager.map }
  get selectedScene() { return this._sceneService.selectedScene }
  get selectedSceneID() { return this._sceneService.selectedSceneID }
  get orbitControls() { return this.envManager.modelSpace.orbitControls }

  get model() { return this.form.get('model') }
  get correctSeam() { return this.form.get('correctSeam') }
  get description() { return this.form.get('description') }
  get image() { return this.form.get('image') }
  get longitude() { return this.form.get('longitude') }
  get latitude() { return this.form.get('latitude') }
  get loadOnStart() { return this.form.get('loadOnStart') }
  get showOnTop() { return this.form.get('showOnTop') }
  get name() { return this.form.get('name') }
  get text() { return this.form.get('text') }
  get type() { return this.form.get('type') }
  get geojson() { return this.form.get('geojson') }

  get atFileLimit() { return this._subscriptionService.atFileLimit }
  get inFreeTier() { return this._subscriptionService.inFreeTier }
  get createAsGroup() { return this.form.get('createAsGroup') }
  get latLngRequired() { return this.type.value == 'marker' }
  get modelRequired() { return this.type.value == '3D' }
  get showNameAndDesc() { return !['geojson', 'image', 'rasterImage'].includes(this.type.value) }
  get textRequired() { return this.type.value == 'text' }
  get useMetadata() { return this.form.get('useMetadata') }

  constructor(
    private _commandService: CommandService,
    private _formBuilder: UntypedFormBuilder,
    private _loadingService: LoadingService,
    private _sceneService: SceneService,
    private _subscriptionService: SubscriptionService,
    public copyService: CopyService,
    public envManager: EnvironmentManagerService,
    public featureService: FeatureService,
  ) {
    this.form = this._formBuilder.group({
      name: ["New Feature", [Validators.required]],
      description: [""],
      type: ["3D"],
      model: [""],
      correctSeam: [false],
      loadOnStart: [true],
      showOnTop: [false],
      longitude: [this.map?.getCenter()?.lng],
      latitude: [this.map?.getCenter()?.lat],
      text: [""],
      image: [""],
      createAsGroup: [false],
      useMetadata: [false],
      geojson: [""]
    })
    this.multipleFeaturesFormArray = this._formBuilder.array([])

    this.copyService.getCoordinates().then(coordinates => this.coordinates = coordinates)
  }

  submit() {
    const { name, type, model } = this.form.value as { name: string, type: FeatureTypes & 'geojson', model: Model }

    if (!this.isValid) {
      this.showRequiredFields()
      return
    }

    const feature = new Feature(
      this.selectedSceneID, name, type, { ...this.form.value, modelID: model?.id, opacity: model?.opacity })

    if (isThreeFeature(feature)) {
      this._loadingService.setLoaded(false)
    }

    feature.position = this.getFocusedPosition()
    /** Set properties for Features */
    if (type == "image") {
      feature.properties.push(
        new FeatureProperty("boolean", "loadOnStart", "true"),
        new FeatureProperty("boolean", "showOnTop", "false"),
        new FeatureProperty("boolean", "trackCamera", "false"),
        new FeatureProperty("boolean", "scaleWithCamera", "false"))
    } else if (type == "line") {
      feature.properties.push(
        new FeatureProperty("integer", "width", "5"),
        new FeatureProperty("integer", "offset", "0"),
        new FeatureProperty("string", "color", "#ffffff"),
        new FeatureProperty("boolean", "dashed", "false"),
        new FeatureProperty("string", "coordinateString", this.coordinateListComponent.coordinatesInputToString()))
    } else if (type == "polygon") {
      feature.opacity = 0.5

      feature.properties.push(
        new FeatureProperty("string", "outlineColor", "#000000"),
        new FeatureProperty("boolean", "outlineVisibility", "true"),
        new FeatureProperty("string", "color", "#ffffff"),
        new FeatureProperty("string", "coordinateString", this.coordinateListComponent.coordinatesInputToString()))
    } else if (type == '3D') {
      feature.rotation = model.rotation
      feature.properties.push(
        new FeatureProperty("boolean", "loadOnStart", "true"),
        new FeatureProperty("boolean", "showOnTop", "false"),
        new FeatureProperty("integer", "renderSide", JSON.stringify(0)))
    } else if (type == 'cutOut') {
      feature.opacity = 0.5
      feature.properties.push(
        new FeatureProperty("boolean", "loadOnStart", "true"),
        new FeatureProperty("boolean", "showOnTop", "false"),
        new FeatureProperty("integer", "renderSide", JSON.stringify(0)))
    }

    if (type == 'marker') {
      const { longitude, latitude } = this.form.value
      const position = [+longitude, +latitude, 0] as [number, number, number]
      const markers = this.multipleFeaturesFormArray.controls.map(control =>
        new Feature(this.selectedSceneID, control.value.name, 'marker', {
          position: position,
          properties: [
            new FeatureProperty("integer", "size", "0.07"),
            new FeatureProperty("string", "backgroundColor", "#ffffff"),
            new FeatureProperty("string", "backgroundShape", "rounded"),
            new FeatureProperty("string", "color", "#000000"),
            new FeatureProperty("string", "displayType", "image"),
            new FeatureProperty("string", "icon", "fas fa-location-dot"),
            new FeatureProperty("string", "text", control.value.name),
          ]
        })
      )

      const embeddedImages = this.selectedFiles
      const groupFeatures = this.createAsGroup.value
      const markerImage = this.defaultMarkerImage
      const useGpsLocation = this.useMetadata.value

      if (markers.length == 0) {
        const properties = [
          new FeatureProperty("integer", "size", "0.07"),
          new FeatureProperty("string", "backgroundColor", "#ffffff"),
          new FeatureProperty("string", "backgroundShape", "rounded"),
          new FeatureProperty("string", "color", "#000000"),
          new FeatureProperty("string", "displayType", "image"),
          new FeatureProperty("string", "icon", "fas fa-location-dot"),
          new FeatureProperty("string", "text", "New Marker"),
        ]
        feature.properties = properties
        markers.push(feature)
      }

      this.featureService.createMarkers(markers, { markerImage, embeddedImages, groupFeatures, useGpsLocation }).subscribe(() => {
        this.envManager.sceneManager.transformControls.detach()
      })
    } else if (type == 'image') { /* Create multiple features when there are multiple files selected */
      const featuresToCreate = []
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const singleFeatureForm = this.multipleFeaturesFormArray.controls[i] as UntypedFormGroup

        const singleFeature = new Feature(this.selectedSceneID, singleFeatureForm.value.name, this.type.value, { ...feature })
        singleFeature.description = singleFeatureForm.value.description
        singleFeature.properties = feature.properties
        featuresToCreate.push(singleFeature)
      }

      const options: BulkCreateOptions = {
        files: this.selectedFiles,
        createGroup: this.createAsGroup.value,
        useMetadata: this.useMetadata.value
      }

      this._loadingService.await(
        this.featureService.createImageFeatures(featuresToCreate, options).pipe(
          switchMap(features => scenesLoaded$.pipe(
            filter(scene => scene.loaded),
            take(1),
            tap(() => {
              this.envManager.sceneManager.transformControls.detach()
              if (features.length == 1) {
                this.featureService.selectedFeature = features[0]
                const model = this.envManager.sceneManager.getFeatureGroup(features[0].id)
                this.envManager.sceneManager.transformControls.attach(model)
              }
            })
          ))
        )
      )
    } else if (type == 'geojson') {
      this.geojsonComponent.createFeaturesFromGeoJson$().subscribe()
    } else if (type == "rasterImage") {
      this.rasterComponent.createRasterLayer$().subscribe()
    } else {
      this._commandService.create.feature(feature)
        .pipe(
          switchMap(feat => scenesLoaded$.pipe(
            filter(scene => scene.loaded),
            take(1),
            tap(() => {
              this.featureService.selectedFeature = feat
              const model = this.envManager.sceneManager.getFeatureGroup(feat.id)
              this.envManager.sceneManager.transformControls.detach()
              this.envManager.sceneManager.transformControls.attach(model)
            })
          ))
        ).subscribe()
    }

    this.modal.hide()
  }

  close() {
    this.modal.hide()
  }

  getFocusedPosition(): [number, number, number] {
    const sceneType = this._sceneService.selectedScene.type

    if (sceneType == "Map") {
      let center = this.map.getCenter()
      let altitude = 0

      try {
        altitude = this.map.queryTerrainElevation(center) ?? 0
      } catch { }

      return [center.lng, center.lat, altitude]
    } else if (sceneType == "Standard" || sceneType == "Virtual Tour") {
      return this.orbitControls.target.clone().toArray()
    } else if (sceneType == "360 Image") {
      const controls = this.orbitControls
      const camera = this.envManager.modelSpace.camera
      const MAXSIZE = 25
      const FITRATIO = 1.5
      const fitHeightDistance = MAXSIZE / (2 * Math.atan((Math.PI * camera.fov) / 360))
      const fitWidthDistance = fitHeightDistance / camera.aspect
      const distance = FITRATIO * Math.max(fitHeightDistance, fitWidthDistance)
      const direction = controls.target
        .clone()
        .sub(camera.position)
        .normalize()
      const position = direction.multiplyScalar(distance)

      return position.toArray()
    }
  }

  onFileChange(file: FileList) {
    for (let i = 0; i < file.length; i++) {
      this.selectedFiles.push(file.item(i))

      const newFeature = this._formBuilder.group({
        name: [file.item(i).name, [Validators.required]],
        description: [""],
      })

      this.multipleFeaturesFormArray.controls.push(newFeature)
    }
  }

  replaceFile(file: File, index: number) {
    const singleFeatureControl = this.multipleFeaturesFormArray.controls[index]

    if (singleFeatureControl.get("name").untouched) singleFeatureControl.get("name").setValue(file.name)
    this.selectedFiles[index] = file
  }

  getFeature(index) {
    return this.multipleFeaturesFormArray.controls[index]
  }

  removeFile(index: number) {
    this.multipleFeaturesFormArray.controls.splice(index, 1)
    this.selectedFiles.splice(index, 1)
  }

  setModel(modelSelect: { event: PointerEvent, model: Model }) {
    this.model.setValue(modelSelect.model)
  }

  updateMarkerImage(file: File) {
    this.defaultMarkerImage = file
  }

  paste() {
    this.longitude.setValue(this.coordinates[0][0])
    this.latitude.setValue(this.coordinates[0][1])
  }

  showRequiredFields() {
    /** Shows the user what is invalid */
    if (this.latLngRequired) {
      this.longitude.markAsTouched()
      this.latitude.markAsTouched()
    } else if (this.modelRequired) {
      this.model.markAsTouched()
    } else if (this.form.value.type == 'image') {
      this.image.markAsTouched()
      this.multipleFeaturesFormArray.markAsTouched()
    } else if (this.textRequired) {
      this.text.markAsTouched()
    }

    if (this.form.value.type == 'geojson')
      this.geojsonComponent.showRequiredFields()

    if (this.form.value.type == 'line' || this.form.value.type == 'polygon')
      this.coordinateListComponent.showRequiredFields()

    if (this.form.value.type == 'rasterImage')
      this.rasterComponent.files.markAsTouched()
  }
}