import { Modal } from 'bootstrap'
import { LngLat, LngLatBounds, PointLike } from 'mapbox-gl'

import { Component, ElementRef, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { Feature } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { Model } from '@classes/Model'
import { Project } from '@classes/Project'
import { Scene } from '@classes/Scene'
import { SceneProperty } from '@classes/SceneProperty'
import { CommandService } from '@services/command.service'
import { FeatureService } from '@services/feature.service'
import { ModalBody } from '@services/modal.service'
import { ModelService } from '@services/model.service'
import { ProjectService } from '@services/project.service'
import { SceneService } from '@services/scene.service'
import { SubscriptionService } from '@services/subscription.service'
import { ToastColor, ToastService } from '@services/toast.service'

export type SceneType = 'standard' | 'map' | 'virtualTour';

@Component({
  selector: 'app-upload-project-files',
  templateUrl: './upload-project-files.component.html',
  styleUrls: ['./upload-project-files.component.css']
})
export class UploadProjectFilesComponent implements ModalBody, OnChanges {
  @Input() events: EventEmitter<'close' | 'submit'>
  @Input() modal: Modal
  @ViewChild('replacementFileInput') replacementFileInput: ElementRef;
  public submitting: boolean = false
  public isComplete = false

  public title = 'Upload Files'
  public submitColor: ToastColor = 'green'
  public submitText = 'Upload'
  public _message = 'Modal Message'
  public isDragOver = false;

  private readonly standardScenes = ['.glb', '.las', '.laz'];
  private readonly virtualTour = ['.e57'];
  private readonly mapScenes = ['.geojson', '.tiff', '.tif'];
  // All file types are merged into one list so a generic upload location is easy.

  // If a project only consists of these file types make sure it's invisible until at least one is done processing.
  private readonly cloudProcessingTypes = ['.e57']

  public sceneTypes: SceneType[] = ['standard', 'map', 'virtualTour'];

  public form = new FormGroup({
    projectTitle: new FormControl<string>('New Project', [Validators.required, Validators.maxLength(50)]),
    description: new FormControl<string>(''),
    scenes: new FormArray<FormGroup>([])
  })

  get allFileTypes() {
    const acceptedTypes = [...this.standardScenes, ...this.virtualTour, ...this.mapScenes]
    if (this.inFreeTier)
      return acceptedTypes.filter(ext => ext != '.tiff' && ext != '.tif')
    else return acceptedTypes
  }
  get inFreeTier() { return this._subscriptionService.inFreeTier }
  get projectTitle() { return this.form.get('projectTitle') }
  get description() { return this.form.get('description') }
  get scenes() { return this.form.get('scenes') as FormArray }

  constructor(
    private _projectService: ProjectService,
    private _sceneService: SceneService,
    private _commandService: CommandService,
    private _subscriptionService: SubscriptionService,
    private _toastService: ToastService,
    public modelService: ModelService,
    public featureService: FeatureService,
  ) { }


  // Helper method to get all valid extensions for a category
  private getValidExtensions(category: SceneType): string[] {
    switch (category) {
      case 'standard':
        return this.standardScenes;
      case 'map':
        return [...this.mapScenes, ...this.standardScenes];
      case 'virtualTour':
        return this.virtualTour;
      default:
        return [];
    }
  }

  private createFileFormGroup(file: File): FormGroup {
    return new FormGroup({
      title: new FormControl<string>(''),
      file: new FormControl<File>(file),
      status: new FormControl<string>('pending'),
      error: new FormControl<string>('')
    });
  }

  private createSceneFormGroup(): FormGroup {
    return new FormGroup({
      title: new FormControl<string>('', [Validators.required]),
      type: new FormControl<SceneType>('virtualTour'),
      files: new FormArray<FormGroup>([])
    });
  }

  // Used in upload flow 2.0 for an add scene button
  addScene() {
    this.scenes.push(this.createSceneFormGroup());
  }

  // Used for trashcan button
  removeScene(index: number) {
    this.scenes.removeAt(index);
  }

  getSceneFiles(sceneIndex: number): FormArray {
    const scene = this.scenes?.at(sceneIndex);
    return scene ? scene.get('files') as FormArray : null;
  }


  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    if (event.dataTransfer?.files?.length) {
      this.handleFiles(Array.from(event.dataTransfer.files));
    }
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.handleFiles(Array.from(input.files));
      input.value = ''; // Clear input after processing
    }
  }

  handleFiles(files: File[]) {
    files.forEach(file => {
      // Get file extension to determine scene type
      const ext = file.name.split('.').pop()?.toLowerCase();
      let sceneType: SceneType;

      // Determine scene type based on file extension
      if (this.standardScenes.map(ext => ext.slice(1)).includes(ext)) {
        sceneType = 'standard';
      } else if (this.virtualTour.map(ext => ext.slice(1)).includes(ext)) {
        sceneType = 'virtualTour';
      } else if (this.mapScenes.map(ext => ext.slice(1)).includes(ext)) {
        sceneType = 'map';
      } else {
        // Invalid file type
        this.form.setErrors({ invalidFileType: true });
        return;
      }

      // Create new scene form group
      const newSceneGroup = this.createSceneFormGroup();

      // Set scene title to file name without extension
      newSceneGroup.patchValue({
        title: file.name.split('.')[0],
        type: sceneType
      });

      // Create file form group and add it to the scene
      const fileFormGroup = this.createFileFormGroup(file);
      const filesArray = newSceneGroup.get('files') as FormArray;
      filesArray.push(fileFormGroup);

      // Add the new scene to the scenes array
      this.scenes.push(newSceneGroup);
    });
  }

  // Used in upload flow 2.0 to remove a specific file from a scene
  removeFile(sceneIndex: number, fileIndex: number) {
    this.getSceneFiles(sceneIndex).removeAt(fileIndex);
  }

  // Used in upload flow 2.0 to replce a specific file in a scene.
  replaceFile(event: Event, sceneIndex: number, fileIndex: number) {
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) {
      return;
    }

    const file = input.files[0];
    const scene = this.scenes.at(sceneIndex);
    const sceneType = scene.get('type').value;
    const ext = file.name.split('.').pop()?.toLowerCase();
    const validExtensions = this.getValidExtensions(sceneType).map(ext => ext.slice(1));

    if (!ext || !validExtensions.includes(ext)) {
      this.form.setErrors({ invalidFileType: true });
      input.value = '';
      return;
    }

    const formGroup = this.getSceneFiles(sceneIndex).at(fileIndex) as FormGroup;
    formGroup.patchValue({
      file: file,
      status: 'pending',
      error: ''
    });

    input.value = '';
  }

  // Returns the file icon for the upload screen.
  getFileIconClass(filename: string): string {
    const ext = filename.split('.').pop()?.toLowerCase();
    const iconMap = {
      'e57': 'fas fa-cube',
      'glb': 'fas fa-cube',
      'las': 'fas fa-layer-group',
      'laz': 'fas fa-layer-group',
      'json': 'fas fa-map',
      'geojson': 'fas fa-map',
      'tiff': 'fas fa-image',
      'tif': 'fas fa-image'
    };
    return iconMap[ext] || 'fas fa-file';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.events?.subscribe(event => {
      if (event === 'submit') {
        this.submit();
      } else if (event === 'close') {
        this.modal.hide();
      }
    });
  }

  // Returns true if a project needs to be hidden due to not containing any scenes until its done processing.
  private hasOnlyCloudFileTypes(): boolean {
    let hasFiles = false;

    for (let sceneIndex = 0; sceneIndex < this.scenes.length; sceneIndex++) {
      const files = this.getSceneFiles(sceneIndex);
      if (files.length > 0) {
        hasFiles = true;
        for (const fileGroup of files.controls as FormGroup[]) {
          const file = fileGroup.get('file').value as File;
          const ext = '.' + file.name.split('.').pop()?.toLowerCase();
          if (!this.cloudProcessingTypes.includes(ext)) {
            return false;
          }
        }
      }
    }
    return hasFiles; // Return true only if there were files and they all matched allowed extensions
  }

  getProject() {
    const options: any = {
      description: this.description.value
    };

    if (this.hasOnlyCloudFileTypes()) {
      options.processingStatus = 'not_processed';

      this._toastService.toast({ title: 'Note', message: "Your project will be available once it finishes processing.", color: 'green' })
    }

    return new Project(this.projectTitle.value, options);
  }

  private isPriorityFile(fileGroup: FormGroup): boolean {
    const file = fileGroup.get('file').value as File;
    const ext = file.name.split('.').pop()?.toLowerCase() || '';
    return ['e57', 'las', 'laz', 'tif', 'tiff'].includes(ext);
  }

  private sortFilesByPriority(fileGroups: FormGroup[]): FormGroup[] {
    return [...fileGroups].sort((a, b) => {
      const aIsPriority = this.isPriorityFile(a);
      const bIsPriority = this.isPriorityFile(b);
      if (aIsPriority && !bIsPriority) return -1;
      if (!aIsPriority && bIsPriority) return 1;
      return 0;
    });
  }

  private getFileType(file: File): string {
    return file.name.split('.').pop()?.toLowerCase() || '';
  }

  private createVirtualTour(project: Project, sceneIndex: number, fileGroup: FormGroup) {
    const file = fileGroup.get('file').value;
    const scene = this.scenes.at(sceneIndex);
    const title = scene.get('title').value;
    return this._sceneService.createVirtualTour$(project, title, file);
  }

  private createStandardScene(project: Project, sceneIndex: number, fileGroup: FormGroup): Promise<void> {
    const sceneForm = this.scenes.at(sceneIndex);
    const newScene = new Scene(project.id, sceneForm.get('title').value, 'Standard');

    return new Promise((resolve, reject) => {
      this._sceneService.createStandardScene$(newScene).subscribe({
        next: (scene) => {
          const fileType = this.getFileType(fileGroup.get('file').value);

          if (fileType === 'glb') {
            let model = new Model(
              fileGroup.get('file').value.name,
              [],
              { description: "" }
            );

            this.modelService.createModel(model, fileGroup.get('file').value).subscribe({
              next: (model) => {
                const feature = new Feature(
                  scene.id,
                  fileGroup.get("file").value.name,
                  '3D',
                  { modelID: model?.id }
                );

                feature.properties.push(
                  new FeatureProperty("boolean", "loadOnStart", "true"),
                  new FeatureProperty("boolean", "showOnTop", "false"),
                  new FeatureProperty("integer", "renderSide", JSON.stringify(0))
                );

                this.featureService.createFeature(feature, { toast: true, updateLocally: false }).subscribe({
                  next: () => {
                    resolve();
                  },
                  error: (error) => reject(error)
                });
              },
              error: (error) => reject(error)
            });
          } else if (fileType === 'las' || fileType === 'laz') {
            this.modelService.createPointCloud(fileGroup.get('file').value, {
              name: fileGroup.get("file").value.name,
              description: "",
              normalizePosition: false,
              sceneId: scene.id
            }).subscribe({
              next: () => {
                resolve();
              },
              error: (error) => reject(error)
            });
          } else {
            resolve();
          }
        },
        error: (error) => reject(error)
      });
    });
  }

  private async createMapScene(project: Project, sceneIndex: number, fileGroup: FormGroup): Promise<void> {
    try {
      const sceneForm = this.scenes.at(sceneIndex);
      const newScene = new Scene(project.id, sceneForm.get('title').value, 'Standard');
      const scene = await this._sceneService.createMapScene$(newScene).toPromise();
      this._sceneService.editSceneLocally(scene, 'create')

      const file = fileGroup.get('file').value;
      const fileType = this.getFileType(file);

      switch (fileType) {
        case 'json':
        case 'geojson':
          await this.handleGeoJsonFile(file, scene);
          break;

        case 'tiff':
        case 'tif':
          await this.featureService.createRasterLayer$([file], scene.id).toPromise();
          break;

        default:
          throw new Error(`Unsupported map file type: ${fileType}`);
      }
    } catch (error) {
      throw error;
    }
  }

  private async handleGeoJsonFile(file: File, scene: Scene): Promise<void> {
    const features = await this.featureService.createFeaturesFromGeoJson(
      [file],
      scene.id,
      { nameKey: file.name }
    ).toPromise();

    // This Centers the scene on the GeoJSON. 
    // For some reason the scene manager doesn't like it if you close and reopen the modal

    const bounds = this.calculateBounds(features);
    const properties = [
      scene.properties.find(i => i.key === "longitude"),
      scene.properties.find(i => i.key === "latitude"),
      scene.properties.find(i => i.key === "bounds"),
      scene.properties.find(i => i.key === "zoom"),
    ];

    properties[0].value = bounds._ne.lng + bounds._ne.lng - bounds._sw.lng;
    properties[1].value = bounds._ne.lat + bounds._ne.lat - bounds._sw.lat;
    properties[2].value = {
      northEast: [bounds._ne.lng, bounds._ne.lat],
      southWest: [bounds._sw.lng, bounds._sw.lat]
    };
    await this._sceneService.updateSceneProperties(scene.id, ...properties).toPromise()

  }

  private calculateBounds(features: any[]): LngLatBounds {
    const bounds = new LngLatBounds();

    features.forEach(feature => {
      if (feature.type === 'marker') {
        const [longitude, latitude] = feature.position;
        if (longitude != null && latitude != null) {
          bounds.extend(new LngLat(longitude, latitude));
        }
      } else if (['line', 'rasterImage', 'polygon'].includes(feature.type)) {
        const coordinateString: PointLike[] = JSON.parse(
          feature.properties.find(p => p.key === 'coordinateString').value
        );
        coordinateString.forEach(c => bounds.extend(new LngLat(c[0], c[1])));
      }
    });

    return bounds;
  }

  private async uploadFilesSequentially(project: Project): Promise<void> {
    this._sceneService.scenes = []

    for (let sceneIndex = 0; sceneIndex < this.scenes.length; sceneIndex++) {
      const scene = this.scenes.at(sceneIndex);
      const sceneType = scene.get('type').value as SceneType;
      const files = this.getSceneFiles(sceneIndex);
      const sortedFileGroups = this.sortFilesByPriority(files.controls as FormGroup[]);

      for (const fileGroup of sortedFileGroups) {
        try {
          fileGroup.patchValue({ status: 'uploading' });
          const file = fileGroup.get('file').value as File;
          const fileType = this.getFileType(file);

          let result;
          switch (sceneType) {
            case 'virtualTour':
              if (!this.virtualTour.includes("." + fileType)) {
                throw new Error(`Invalid file type for virtual tour: ${fileType}`);
              }
              result = await this.createVirtualTour(project, sceneIndex, fileGroup).toPromise();
              break;

            case 'standard':
              if (!this.standardScenes.includes("." + fileType)) {
                throw new Error(`Invalid file type for standard scene: ${fileType}`);
              }
              result = await this.createStandardScene(project, sceneIndex, fileGroup);
              break;

            case 'map':
              if (!this.mapScenes.includes("." + fileType)) {
                throw new Error(`Invalid file type for map scene: ${fileType}`);
              }
              result = await this.createMapScene(project, sceneIndex, fileGroup);
              break;

            default:
              throw new Error(`Unknown scene type: ${sceneType}`);
          }

          fileGroup.patchValue({ status: 'success' });
        } catch (error) {
          fileGroup.patchValue({
            status: 'error',
            error: error.message
          });
          console.error(`Error uploading ${fileGroup.get('file').value.name}:`, error);
        }
      }
    }
  }

  submit(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    if (!this.submitting) {
      this.submitting = true;
      const project = this.getProject();

      this._projectService.createProject(project).subscribe(
        project => this.uploadFilesSequentially(project)
          .catch(error => {
            console.error('Error during upload sequence:', error);
            // Handle error
          })
          .finally(() => {
            this.isComplete = true
          })
      )
      return true;
    }
    return false;
  }

  cancel() {
    this.modal?.hide()
  }
}