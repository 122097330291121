import { Modal } from 'bootstrap'

import { Component, ElementRef, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Model } from '@classes/Model'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { ModelService } from '@services/model.service'
import { ToastColor, ToastService } from '@services/toast.service'

@Component({
  selector: 'shared-modal-create-model',
  templateUrl: './create-model.component.html',
  styleUrls: ['./create-model.component.css'],
})
export class CreateModelComponent {
  public cancelText = 'Cancel'
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Create'
  public title = 'Create Model'

  public onSubmit: CallableFunction
  public onCancel: CallableFunction

  public form: UntypedFormGroup
  @ViewChild('fileInput') fileInput: ElementRef
  public selectedFiles: File[] = []
  public isPointCloud = false

  constructor(
    private elementRef: ElementRef,
    private _formBuilder: UntypedFormBuilder,
    public modelService: ModelService,
    public envManager: EnvironmentManagerService,
    private _toastService: ToastService,
  ) {

    this.form = this._formBuilder.group({
      name: ["New Model", [Validators.required]],
      description: [""],
      files: ["", [Validators.required]],
      isCentered: false
    })
  }

  submit(): boolean {
    if (this.form.invalid || this.selectedFiles.length == 0) {
      this._toastService.toast({ title: "Error", message: "Invalid input in the model creation", color: "red" })
      return false
    }

    if (this.selectedFiles.length != 1 && this.isPointCloud) {
      this._toastService.toast({ title: "Error", message: "Invalid input, only one pointcloud can be made at a time.", color: "red" })
      return false
    }

    let model = new Model(
      this.form.value.name,
      [],
      { description: this.form.value.description }
    )

    const description = this.form.value.description
    const name = this.form.value.name
    const normalizePosition = this.form.value.isCentered

    if (this.isPointCloud && this.selectedFiles[0].size > 182_536_110_080) { 
      // If file is above 170gb don't let them upload
      this._toastService.toast({ title: "Error", message: "Invalid input: Point Cloud is too large.", color: "red"})
      return false
    } else if (
      this.isPointCloud && (this.selectedFiles[0].name.endsWith(".e57") || this.selectedFiles[0].name.endsWith(".laz")) && 
      this.selectedFiles[0].size > 107_374_182_400
    ) { 
      // Above 100gb there's a chance the job will fail due to running out of file space, 
      // As such we force users to convert it to las which has a limit somewhere above 100gb, but below 170gb
      this._toastService.toast({ title: "Error", message: "Invalid input: Point Cloud is too large. Please convert file to LAS and try again.", color: "red"})
      return false
    } else if (this.isPointCloud) {
      this.modelService.createPointCloud(this.selectedFiles[0], { description, name, normalizePosition: normalizePosition }).subscribe()
    } else {
      this.modelService.createModel(model, ...this.selectedFiles).subscribe()
    }

    return true
  }

  onFileChange(files: FileList) {
    this.selectedFiles.push(...Array.from(files))

    if (this.selectedFiles.length > 0) {
      this.form.get('files').setErrors(null);
    }
    
    this.form.get('files').markAsTouched()

    let pointcloudUpdated = false
    for (let i = 0; i < files.length; i++) {
      if (files[i].name.slice(-3) == 'laz' || files[i].name.slice(-3) == 'las' || files[i].name.slice(-3) == 'e57') {
        this.isPointCloud = true
        pointcloudUpdated = true
      }
    }
    if (!pointcloudUpdated) this.isPointCloud = false
  }

  removeFile(idx: number) {
    this.selectedFiles.splice(idx, 1)

    let pointcloudUpdated = false
    for (let i = 0; i < this.selectedFiles.length; i++) {
      if (this.selectedFiles[i].name.slice(-3) == 'laz' || this.selectedFiles[i].name.slice(-3) == 'las' || this.selectedFiles[i].name.slice(-3) == 'e57') {
        this.isPointCloud = true
        pointcloudUpdated = true
      }
    }
    if (!pointcloudUpdated) this.isPointCloud = false
  }

  _submit() {
    if (this.onSubmit) {
      this.onSubmit()
    }
    const submitSuccessful = this.submit()
    if (submitSuccessful) {
      this.modal?.hide()
      this.ngOnDestroy()
    }
  }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
    this.ngOnDestroy()
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.remove()
  }
}