import { Component, ElementRef } from '@angular/core'
import { ToastAction, ToastColor } from '@services/toast.service'

@Component({
  selector: 'shared-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {
  public actionButton: ToastAction
  public color: ToastColor = 'green'
  public count = 1
  public message: string = ''
  public percentDone = 1
  public showProgressBar = false
  public title: string = ''
  public estimatedTimeInSeconds: number = 0

  constructor(public elementRef: ElementRef) { }

  public getFormattedTime(): string {
    const hours = Math.floor(this.estimatedTimeInSeconds / 3600);
    const minutes = Math.floor(this.estimatedTimeInSeconds / 60) % 60;
    const seconds = this.estimatedTimeInSeconds % 60;

    let remainingTime = ""
    if(hours   != 0) remainingTime += `${hours} ${hours === 1 ? 'hour' : 'hours'} `
    if(minutes != 0) remainingTime += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} `
    if(seconds != 0) remainingTime += `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`

    return remainingTime;
  }
}