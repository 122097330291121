import Stripe from 'stripe'

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'
import { StripePrice, StripeService } from '@services/stripe.service'
import { SubscriptionService, SubscriptionTiers, TierPrices } from '@services/subscription.service'

@Component({
  selector: 'billing-select-tier',
  templateUrl: './select-tier.component.html',
  styleUrls: ['./select-tier.component.css']
})
export class SelectTierComponent {
  @Input() backgroundColor: string = '#f0f0f0'
  @Input() buttonColor: string = '#36454f'
  @Input() callToActionProduct: string | null = 'Enterprise'
  @Input() callToActionUrl: string = 'https://kartorium.com/contact'
  @Input() defaultInterval: 'month' | 'year' = 'year'
  @Input() highlightProduct: string | null = 'Pro Tier'
  @Input() interval: Stripe.Price.Recurring.Interval = 'year'
  @Input() lookupKeys: string[] = [
    'free_monthly',
    'pro_monthly',
    'enterprise_monthly',
    'free_yearly',
    'pro_yearly',
    'enterprise_yearly'
  ]
  @Input() selectable: ['free' | 'pro'] = ['pro']
  @Input() selected: 'free' | 'pro' = 'pro'
  @Output() selectTier = new EventEmitter<SubscriptionTiers>()

  public monthlyPlans: StripePrice[] = []
  public yearlyPlans: StripePrice[] = []
  public tiers: TierPrices

  get isMonthly() { return this.interval === 'month' }
  get currentUser() { return this.authenticationService.currentUser }
  get freeSelected() { return this.selected == 'free' }
  get intervalCheckbox() { return this.interval == 'year' }
  set intervalCheckbox(checked: boolean) { this.interval = checked ? 'year' : 'month' }
  get proSelected() { return this.selected == 'pro' }

  constructor(
    private authenticationService: AuthenticationService,
    private stripeService: StripeService,
    private subscriptionService: SubscriptionService
  ) {
    this.subscriptionService.getTiers().subscribe(tiers => this.tiers = tiers)
  }

  ngOnInit(): void {
    this.loadPrices()
    this.applyStyles()
  }

  isSelectable(price: StripePrice): boolean {
    return this.selectable.some(tier => price.lookup_key.includes(tier))
  }

  chooseTier(price: StripePrice) {
    if (price.lookup_key.includes('free')) {
      var tier = 'free' as any
    } else if (price.lookup_key.includes('pro')) {
      var tier = 'pro' as any
    }

    if (this.selectable.includes(tier)) {
      this.selected = tier
      this.selectTier.emit(this.selected)
    }
  }

  loadPrices(): void {
    this.stripeService.fetchPricesByLookup(this.lookupKeys).subscribe((prices) => {
      // Sort prices to match the order of lookupKeys
      const sortByLookupKeys = (plans: StripePrice[]) =>
        plans.sort((a, b) =>
          this.lookupKeys.indexOf(a.lookup_key || '') - this.lookupKeys.indexOf(b.lookup_key || '')
        )

      // Separate and sort monthly vs. yearly
      this.monthlyPlans = sortByLookupKeys(
        prices.filter((price) => price.recurring?.interval === 'month')
      )
      this.yearlyPlans = sortByLookupKeys(
        prices.filter((price) => price.recurring?.interval === 'year')
      )

      // Optionally highlight the product
      if (this.highlightProduct) {
        [...this.monthlyPlans, ...this.yearlyPlans].forEach(plan => {
          if (plan.product.name === this.highlightProduct) {
            plan.metadata = { ...plan.metadata, recommended: true }
          }
        })
      }

      if (this.callToActionProduct) {
        [...this.monthlyPlans, ...this.yearlyPlans].forEach(plan => {
          if (plan.product.name === this.callToActionProduct) {
            plan.metadata = { ...plan.metadata, callToAction: true }
          }
        })
      }
    })
  }

  applyStyles(): void {
    // Apply background color
    document.body.style.backgroundColor = this.backgroundColor

    // Apply button styles dynamically using CSS variables
    const root = document.documentElement
    root.style.setProperty('--background-color', this.backgroundColor)
    root.style.setProperty('--button-color', this.buttonColor)
  }

  // For an enterprise plan, maybe route them to a contact form
  contactUs() {
    window.location.href = this.callToActionUrl
  }
}