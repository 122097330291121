import { Subscription } from 'rxjs'
import * as THREE from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'

import { CSSObject, CSSObjectOptions } from './CSSObject'
import { MapManager } from './MapManager'
import { MapSpace } from './MapSpace'
import { ModelSpace, Space } from './ModelSpace'
import { drag$, move$ } from './Raycaster'
import { Scene, SceneType } from './Scene'
import { SceneManager } from './SceneManager'
import { PointerService } from '@services/pointer.service'

const METERS_TO_FEET = 3.28084
const METERS_TO_MILE = 0.000621
const METERS_TO_KILOMETER = 0.001
var MEASUREMENT_UNITS: "ft" | "m" | "mi" | "km" = "ft"

class Measurement {
    private _controller: MeasurementController
    public isMovingPoint: boolean = false
    public selectedMeasurement: Measurement
    public sceneType: SceneType

    // Dragging and Point Manipulation
    public mousePositionSubscription: Subscription // Used when placing points
    public mouseDragSubscription: Subscription // Used when moving points
    public draggedPoint: THREE.Mesh = null
    public intersections: any
    public mousePosition: THREE.Vector3
    public oppositePoint: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>
    public prevIntersect: THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>>

    // Measurement Points and Lines
    public line: Line2
    public horizontalLine: Line2 = null
    public verticalLine: Line2 = null
    public measurementPoints: Map<string, THREE.Mesh>
    public point1: THREE.Mesh
    public point2: THREE.Mesh
    public previewLine: Line2 = null
    public previewHorizontalLine: Line2 = null
    public previewVerticalLine: Line2 = null

    // Labels and Popups
    public label: CSSObject
    public horizontalLabel: CSSObject
    public verticalLabel: CSSObject
    public previewLabel: CSSObject
    public previewHorizontalLabel: CSSObject
    public previewVerticalLabel: CSSObject

    get distance() {
        let distance: number
        if (this.point2) {
            distance = this.point1.position.distanceTo(this.point2.position)
        } else if (this.mousePosition) {
            distance = this.point1.position.distanceTo(this.mousePosition)
        } else {
            distance = 0
        }
    
        if (MEASUREMENT_UNITS == "ft") {
            return (distance * METERS_TO_FEET)
        } else if (MEASUREMENT_UNITS == "mi") {
            return (distance * METERS_TO_MILE)
        } else if (MEASUREMENT_UNITS == "km") {
            return (distance * METERS_TO_KILOMETER)
        } else {
            return distance
        }
    }

    get horizontalDistance() {
        let distance: number
        if (this.point2) {
            const p1 = this.point1.position.clone()
            const p2 = this.point2.position.clone()
            if(this.sceneType == 'Virtual Tour') p1.z = p2.z
            else p1.y = p2.y
            distance = p1.distanceTo(p2)
        } else if (this.mousePosition) {
            const p1 = this.point1.position.clone()
            const mouse = this.mousePosition.clone()
            if(this.sceneType == 'Virtual Tour') p1.z = mouse.z
            else p1.y = mouse.y
            distance = p1.distanceTo(mouse)
        } else {
            distance = 0
        }

        if (MEASUREMENT_UNITS == "ft") {
            return (distance * METERS_TO_FEET)
        } else if (MEASUREMENT_UNITS == "mi") {
            return (distance * METERS_TO_MILE)
        } else if (MEASUREMENT_UNITS == "km") {
            return (distance * METERS_TO_KILOMETER)
        } else {
            return distance
        }
    }

    get verticalDistance() {
        let distance: number
        if (this.point2) {
            // Calculate just the absolute difference in Z coordinates
            if(this.sceneType == 'Virtual Tour') distance = Math.abs(this.point1.position.z - this.point2.position.z)
            else distance = Math.abs(this.point1.position.y - this.point2.position.y)
        } else if (this.mousePosition) {
            // Calculate just the absolute difference in Z coordinates for preview
            if(this.sceneType == 'Virtual Tour') distance = Math.abs(this.point1.position.z - this.mousePosition.z)
                else distance = Math.abs(this.point1.position.y - this.mousePosition.y)
        } else {
            distance = 0
        }

        if (MEASUREMENT_UNITS == "ft") {
            return (distance * METERS_TO_FEET)
        } else if (MEASUREMENT_UNITS == "mi") {
            return (distance * METERS_TO_MILE)
        } else if (MEASUREMENT_UNITS == "km") {
            return (distance * METERS_TO_KILOMETER)
        } else {
            return distance
        }
    }

    get isMapScene() { return this._controller.mapManager != null }
    get raycaster() { return this._controller.raycaster }
    private get _renderedScene(): THREE.Scene { return this._controller.sceneManager.renderedScene }
    private get _scene(): Scene { return this._controller.scene }

    constructor(
        controller: MeasurementController,
        public pointerService: PointerService
    ) {
        this._controller = controller
        this.measurementPoints = new Map<string, THREE.Mesh>()

        this.mousePositionSubscription = move$.subscribe((mousePos) => {
            if (this._controller.raycaster) {
                this.mousePosition = this._controller.raycaster.getIntersection(mousePos).point
                this.previewMeasurementLine(this.mousePosition)
            } else if (this._controller.mapSpace) {
                this.mousePosition = this._controller.mapSpace.raycaster.getIntersection(mousePos).point
                this.previewMeasurementLine(this.mousePosition)
            }
        })
        
        this.mouseDragSubscription = drag$.subscribe(dragEvent => this.handleDrag(dragEvent))
        this._controller.sceneManager.canvas.addEventListener('pointerdown', this.handleDragDown.bind(this), false)
        this._controller.sceneManager.canvas.addEventListener('pointerup', this.handleDragEnd.bind(this), false)
    }

    /**
     * This is kinda weird, intersecton objects is checking every single item in the array
     * therefore when you hover over a point that isnt one of the two most recently placed points it will not work because
     * its 0 for each point that its not intersecting with, itll get its value, but because its not at the top of the array itll just be overwritten by the ones before it. 
     */
    handleHoverPoint() {
        if (this._controller.state === 'measureMode') {
            const intersects = this.raycaster.raycaster.intersectObjects(Array.from(this.measurementPoints.values()), true)
            const currentIntersect = intersects.length > 0 ? intersects[0] : null

            if (currentIntersect && (!this.prevIntersect || this.prevIntersect.object !== currentIntersect.object)) {
                this.prevIntersect = currentIntersect
            } else if (!currentIntersect && this.prevIntersect) {
                this.prevIntersect = null
            }
        }
    }

    handleDragDown(event: MouseEvent) {
        if (this._controller.state == 'measureMode') {

            this.intersections = this.raycaster.raycaster.intersectObjects(Array.from(this.measurementPoints.values()), true)

            if (this.intersections.length > 0) {
                this.pointerService.setPointerState('grabbing', this._controller.sceneManager.canvas.style)
                this.isMovingPoint = true
                this.draggedPoint = this.intersections[0].object

                this.selectedMeasurement = this._controller.getMeasurementByPointUUID(this.draggedPoint.uuid)

                if (this.isMapScene) {
                    this._controller.mapSpace.toggleControls(false)
                } else {
                    this._controller.modelSpace.orbitControls.enabled = false
                }
            }
        }
    }

    handleDrag(dragPos: MouseEvent | TouchEvent | mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) {
        if (this.isMovingPoint && (this.draggedPoint != null)) {
            this._renderedScene.remove(this.selectedMeasurement.line)
            this.selectedMeasurement.label.dispose()
            
            if (this.selectedMeasurement.horizontalLine) {
                this._renderedScene.remove(this.selectedMeasurement.horizontalLine)
                this.selectedMeasurement.horizontalLabel.dispose()
            }
            
            if (this.selectedMeasurement.verticalLine) {
                this._renderedScene.remove(this.selectedMeasurement.verticalLine)
                this.selectedMeasurement.verticalLabel.dispose()
            }

            this.mousePosition = this.raycaster.getIntersection(dragPos).point

            if (this.mousePosition) {
                if (this.draggedPoint.uuid == this.selectedMeasurement.point1.uuid) {
                    this.selectedMeasurement.point1.position.set(this.mousePosition.x, this.mousePosition.y, this.mousePosition.z)
                    this.oppositePoint = this.selectedMeasurement.point2
                    this.previewMeasurementLine(this.mousePosition)
                } else {
                    this.selectedMeasurement.point2.position.set(this.mousePosition.x, this.mousePosition.y, this.mousePosition.z)
                    this.oppositePoint = this.selectedMeasurement.point1
                    this.previewMeasurementLine(this.mousePosition)
                }
            }
        }
    }

    handleDragEnd(event: MouseEvent) {
        if (this.previewLine && this.isMovingPoint) {
            this.clearPreviewLine()
            this._connectPoints(this.selectedMeasurement.point1, this.selectedMeasurement.point2)
            this.pointerService.setPointerState('grab', this._controller.sceneManager.canvas.style)
        }

        if (this.isMapScene) {
            this._controller.mapSpace.toggleControls(true)
        } else {
            this._controller.modelSpace.orbitControls.enabled = true
        }

        this.isMovingPoint = false
        this.draggedPoint = null
        this.intersections = []
        this.selectedMeasurement = null
    }

    placeMeasurementPoint(point: THREE.Vector3) {
        const pointGeometry = new THREE.SphereGeometry(0.5, 16, 12)
        const pointMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            depthTest: false,
            depthWrite: false,
            transparent: true,
        })
        const pointMesh = new THREE.Mesh(pointGeometry, pointMaterial)

        pointGeometry.center()
        pointMesh.position.set(point.x, point.y, point.z)

        this._scaleWithCamera(pointMesh) // scale before adding to scene to avoid resizing blip

        this._renderedScene.attach(pointMesh)

        pointMesh.onBeforeRender = () => this._scaleWithCamera(pointMesh)
        pointMesh.layers.enable(1)

        if (this.point1) {
            this.point2 = pointMesh
            this._connectPoints(this.point1, this.point2)
            this.clearPreviewLine()
            this.mousePositionSubscription.unsubscribe()
        } else {
            this.point1 = pointMesh
        }

        pointMesh.userData = { isMeasurementPoint: true }

        this.measurementPoints.set(pointMesh.uuid, pointMesh)
    }

    previewMeasurementLine(mousePosition: THREE.Vector3) {
        this.handleHoverPoint()
        if (this.isMovingPoint || this._controller.state == 'placingFinalPoint') {
            if (!this.previewLine) {
                // Create horizontal line
                const previewHorizontalLineGeometry = new LineGeometry()
                const previewHorizontalLineMaterial = new LineMaterial({
                    color: (this.sceneType == 'Virtual Tour') ? 0x00ff00 : 0x0000ff, // Green for horizontal
                    linewidth: 3,
                    vertexColors: false,
                    dashed: true,
                    dashSize: 0.1,      // Size of the dash
                    gapSize: 0.05,      // Size of the gap
                    alphaToCoverage: true,
                    transparent: true,
                    opacity: 0.3       // More transparent
                })
                this.previewHorizontalLine = this._createLine(
                    previewHorizontalLineMaterial, previewHorizontalLineGeometry,
                    null, null, 
                    "horizontal",
                    true
                )
    
                // Create vertical line
                const previewVerticalLineGeometry = new LineGeometry()
                const previewVerticalLineMaterial = new LineMaterial({
                    color: (this.sceneType == 'Virtual Tour') ? 0x0000ff : 0x00ff00, // Blue for vertical
                    linewidth: 3,
                    vertexColors: false,
                    dashed: true,
                    dashSize: 0.1,      // Size of the dash
                    gapSize: 0.05,      // Size of the gap
                    alphaToCoverage: true,
                    transparent: true,
                    opacity: 0.3       // More transparent
                })
                this.previewVerticalLine = this._createLine(
                    previewVerticalLineMaterial, previewVerticalLineGeometry,
                    null, null, 
                    "vertical",
                    true
                )
                // Create diagonal line
                const previewLineGeometry = new LineGeometry()
                const previewLineMaterial = new LineMaterial({
                    color: 0xffffff,
                    linewidth: 4,
                    vertexColors: false,
                    dashed: false,
                    alphaToCoverage: true,
                    transparent: true,
                    opacity: 0.9
                })
                
                this.previewLine = this._createLine(
                    previewLineMaterial, previewLineGeometry,
                    null, null, 
                    "diagonal",
                    true
                )
            }
    
            // Prevents reading mouse position when null
            if (mousePosition) {
                const startPoint = this.isMovingPoint 
                    ? (this.draggedPoint.uuid === this.selectedMeasurement.point1.uuid 
                        ? mousePosition 
                        : this.selectedMeasurement.point1.position)
                    : this.point1.position;
                
                const endPoint = this.isMovingPoint 
                    ? (this.draggedPoint.uuid === this.selectedMeasurement.point1.uuid 
                        ? this.selectedMeasurement.point2.position 
                        : mousePosition)
                    : mousePosition;
    
                if(this.sceneType == 'Virtual Tour'){
                    // Diagonal line positions
                    var diagonalPositions = [
                        startPoint.x, startPoint.y, startPoint.z,
                        endPoint.x, endPoint.y, endPoint.z
                    ];
                    // Horizontal line positions - using world coordinate system
                    var verticalPositions = [
                        startPoint.x, startPoint.y, startPoint.z,
                        startPoint.x, startPoint.y, endPoint.z
                    ];
        
                    // Vertical line positions - straight up/down along world Y axis
                    var horizontalPositions = [
                        startPoint.x, startPoint.y, endPoint.z,
                        endPoint.x, endPoint.y, endPoint.z
                    ];
                }else{
                    // Diagonal line positions
                    var diagonalPositions = [
                        startPoint.x, startPoint.y, startPoint.z,
                        endPoint.x, endPoint.y, endPoint.z
                    ];
                    // Horizontal line positions - using world coordinate system
                    var verticalPositions = [
                        startPoint.x, startPoint.y, startPoint.z,
                        startPoint.x, endPoint.y, startPoint.z
                    ];
        
                    // Vertical line positions - straight up/down along world Y axis
                    var horizontalPositions = [
                        startPoint.x, endPoint.y, startPoint.z,
                        endPoint.x, endPoint.y, endPoint.z
                    ];
                }
                
    
                // Update diagonal line
                this.previewLine.geometry.setPositions(diagonalPositions);
                this.previewLine.geometry.attributes.position.needsUpdate = true;
                this.previewLine.computeLineDistances();
    
                // Update horizontal line
                this.previewHorizontalLine.geometry.setPositions(horizontalPositions);
                this.previewHorizontalLine.geometry.attributes.position.needsUpdate = true;
                this.previewHorizontalLine.computeLineDistances();
    
                // Update vertical line
                this.previewVerticalLine.geometry.setPositions(verticalPositions);
                this.previewVerticalLine.geometry.attributes.position.needsUpdate = true;
                this.previewVerticalLine.computeLineDistances();
    
                // Update all labels - check for existence first
                const previewLabelPosition = new THREE.Vector3(
                    (startPoint.x + endPoint.x) / 2,
                    (startPoint.y + endPoint.y) / 2,
                    (startPoint.z + endPoint.z) / 2
                )

                if(this.sceneType != 'Virtual Tour'){

                    var horizontalLabelPosition = new THREE.Vector3(
                        (startPoint.x + endPoint.x) /2,
                        endPoint.y,
                        (startPoint.z + endPoint.z) / 2,
                    )
                    var verticalLabelPosition = new THREE.Vector3(
                        startPoint.x,
                        (startPoint.y + endPoint.y) / 2,
                        startPoint.z
                    )
                }else{

                    var horizontalLabelPosition = new THREE.Vector3(
                        (startPoint.x + endPoint.x) /2,
                        (startPoint.y + endPoint.y) / 2,
                        endPoint.z
                    )
                    var verticalLabelPosition = new THREE.Vector3(
                        startPoint.x,
                        startPoint.y,
                        (startPoint.z + endPoint.z) / 2
                    )
                }
                if (this.previewHorizontalLabel) {
                    this.previewHorizontalLabel.content = this.horizontalDistance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS;
                    this.previewHorizontalLabel.vectorPosition = horizontalLabelPosition
                }
    
                if (this.previewVerticalLabel) {
                    this.previewVerticalLabel.content = this.verticalDistance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS;
                    this.previewVerticalLabel.vectorPosition = verticalLabelPosition
                }
    
                if (this.previewLabel) {
                    this.previewLabel.content = this.distance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS;
                    this.previewLabel.vectorPosition = previewLabelPosition
                }
            }
        }
    }

    clearPreviewLine() {
        if (this.previewLine) {
            this._renderedScene.remove(this.previewLine)
            this.previewLine.geometry.dispose()
            this.previewLine.material.dispose()
            this.previewLine = null
        }

        if (this.previewHorizontalLine) {
            this._renderedScene.remove(this.previewHorizontalLine)
            this.previewHorizontalLine.geometry.dispose()
            this.previewHorizontalLine.material.dispose()
            this.previewHorizontalLine = null
        }

        if (this.previewVerticalLine) {
            this._renderedScene.remove(this.previewVerticalLine)
            this.previewVerticalLine.geometry.dispose()
            this.previewVerticalLine.material.dispose()
            this.previewVerticalLine = null
        }

        if (this.previewLabel) {
            this.previewLabel.dispose()
            this.previewLabel = null
        }

        if (this.previewHorizontalLabel) {
            this.previewHorizontalLabel.dispose()
            this.previewHorizontalLabel = null
        }

        if (this.previewVerticalLabel) {
            this.previewVerticalLabel.dispose()
            this.previewVerticalLabel = null
        }
    }
    private _createLine(
        lineMaterial: LineMaterial, 
        lineGeometry:LineGeometry, 
        point1: THREE.Vector3, 
        point2: THREE.Vector3, 
        direction: "diagonal" | "horizontal" | "vertical",
        isPreview: boolean = false
    ){
        const layer = isPreview ? 2 : 1

        const lineMesh = new Line2(lineGeometry, lineMaterial)

        if(point1 && point2){
            lineGeometry.setPositions([
                point1.x, point1.y, point1.z,
                point2.x, point2.y, point2.z
            ])
            lineMesh.computeLineDistances()
        }
            

        lineMaterial.resolution.set(this._controller.sceneManager.canvas.clientWidth, this._controller.sceneManager.canvas.clientHeight)
        lineMaterial.depthWrite = false
        lineMaterial.depthTest = false
        lineMaterial.side = THREE.DoubleSide

        lineMesh.scale.set(1, 1, 1)
        lineMesh.layers.enable(layer)
        this._renderedScene.add(lineMesh)

        // Align the geometry and mesh positions
        lineGeometry.computeBoundingBox()
        const geometryCenter = lineGeometry.boundingBox.getCenter(new THREE.Vector3())
        lineGeometry.center()
        lineMesh.position.copy(geometryCenter)
        if(direction == "diagonal") lineMesh.renderOrder = 999
        else  lineMesh.renderOrder = 998
        
        this._addLabel(lineMesh, lineMesh.position, isPreview, direction)
        return lineMesh
    }

    private _connectPoints(point1: THREE.Mesh, point2: THREE.Mesh) {
        // Create diagonal line
        const lineGeometry = new LineGeometry()
        const lineMaterial = new LineMaterial({
            color: 0xffffff,
            linewidth: 4,
            vertexColors: false,
            dashed: false,
            alphaToCoverage: true,
            transparent: true,
            opacity: 0.9
        })
    
        // Create vertical line (runs vertically along the Z axis) - FIXED: changed from horizontal to vertical
        const verticalLineGeometry = new LineGeometry()
        const verticalLineMaterial = new LineMaterial({
            color: 0x0000ff, // Blue for vertical
            linewidth: 3,
            vertexColors: false,
            dashed: true,
            dashSize: 0.1,      // Size of the dash
            gapSize: 0.05,      // Size of the gap
            alphaToCoverage: true,
            transparent: true,
            opacity: 0.3        // More transparent
        })
    
        // Create horizontal line (runs horizontally in X-Y plane) - FIXED: changed from vertical to horizontal
        const horizontalLineGeometry = new LineGeometry()
        const horizontalLineMaterial = new LineMaterial({
            color: 0x00ff00, // Green for horizontal
            linewidth: 3,
            vertexColors: false,
            dashed: true,
            dashSize: 0.1,      // Size of the dash
            gapSize: 0.05,      // Size of the gap
            alphaToCoverage: true,
            transparent: true,
            opacity: 0.3        // More transparent
        })

        if(this.sceneType == "Virtual Tour"){
    
            // Calculate points for horizontal and vertical lines
            const horizontalPoint = new THREE.Vector3(point1.position.x, point1.position.y, point2.position.z);
            
            this.verticalLine = this._createLine(verticalLineMaterial, verticalLineGeometry,
                new THREE.Vector3(point1.position.x, point1.position.y, point1.position.z), 
                new THREE.Vector3(horizontalPoint.x, horizontalPoint.y, horizontalPoint.z),
                "vertical"
            )
    
            this.horizontalLine = this._createLine(horizontalLineMaterial, horizontalLineGeometry,
                new THREE.Vector3(horizontalPoint.x, horizontalPoint.y, horizontalPoint.z),
                new THREE.Vector3(point2.position.x, point2.position.y, point2.position.z),
                "horizontal"
            )
            this.line = this._createLine(lineMaterial, lineGeometry, 
                new THREE.Vector3(point1.position.x, point1.position.y, point1.position.z), 
                new THREE.Vector3(point2.position.x, point2.position.y, point2.position.z),
                "diagonal"
            )
        }else{
            this.line = this._createLine(lineMaterial, lineGeometry, 
                new THREE.Vector3(point1.position.x, point1.position.y, point1.position.z), 
                new THREE.Vector3(point2.position.x, point2.position.y, point2.position.z),
                "diagonal"
            )
            
            this.verticalLine = this._createLine(verticalLineMaterial, verticalLineGeometry,
                new THREE.Vector3(point1.position.x, point2.position.y, point1.position.z), 
                new THREE.Vector3(point2.position.x, point2.position.y, point2.position.z),
                "horizontal"
            )
    
            this.horizontalLine = this._createLine(horizontalLineMaterial, horizontalLineGeometry,
                new THREE.Vector3(point1.position.x, point1.position.y, point1.position.z),
                new THREE.Vector3(point1.position.x, point2.position.y, point1.position.z),
                "vertical"
            )
        }
        
    
        this.pointerService.setPointerState('grab', this._controller.sceneManager.canvas.style)
    }

    private _scaleWithCamera(mesh: THREE.Mesh): void {
        const camera = this._controller.sceneManager.camera
        let cameraPosition = camera.position.clone()

        if (this._scene.type == 'Map') {
            var camInverseProjection = new THREE.Matrix4().copy(camera.projectionMatrix)
            camInverseProjection = camInverseProjection.invert()
            cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection)
        }

        // Scale the mesh by a ratio of new camera height to height of mesh
        const meshWorldPosition = mesh.getWorldPosition(new THREE.Vector3())
        const z = new THREE.Vector3().subVectors(meshWorldPosition, cameraPosition).length()
        const heightInPixels = 10
        const meshSize = new THREE.Box3().setFromObject(mesh).getSize(new THREE.Vector3())
        const frustumHeight = Math.tan(THREE.MathUtils.degToRad((camera as THREE.PerspectiveCamera).fov) / 2) * 2 * z
        const meshNewHeight = (heightInPixels / this._controller.sceneManager.canvas.clientHeight) * frustumHeight

        if (Math.abs(1 - meshNewHeight / meshSize.y) > 0.000000001) {
            mesh.scale.multiplyScalar(meshNewHeight / meshSize.y)
        }

        if (this._scene.type == 'Map') {
            mesh.scale.multiplyScalar(0.5)
        }
    }

    /* CREATING MEASUREMENT LABEL ELEMENTS */
    private _addLabel(measurement: THREE.Mesh, center: THREE.Vector3, isPreview: boolean = false, type: "vertical" | "horizontal" | "diagonal") {
        const labelElement = document.createElement('div')
        labelElement.className = 'd-flex me-auto position-relative align-items-center'
        labelElement.style.pointerEvents = 'all'
    
        let span = document.createElement('span')
        span.id = isPreview ? 'preview-content' : 'content'
        labelElement.appendChild(span)
    
        // Only add the delete button for the main measurement (not horizontal or vertical)
        // and only for non-preview labels
        if (!isPreview && type === "diagonal") {
            let anchor = document.createElement('a')
            anchor.addEventListener('click', (e) => {
                e.stopPropagation()
                this.remove()
            })
            anchor.className = 'ms-1'
            anchor.style.pointerEvents = 'all'
    
            let deleteBtn = document.createElement('i')
            deleteBtn.className = 'fad fa-times-circle text-secondary'
            
            // Safely get the distance for the title
            try {
                labelElement.title = this.distance + MEASUREMENT_UNITS
            } catch (e) {
                labelElement.title = "0.00" + MEASUREMENT_UNITS
            }
    
            anchor.appendChild(deleteBtn)
            labelElement.appendChild(anchor)
        }
    
        // Safely determine which measurement to display based on type
        let measurementValue: number;
        try {
            if (type === 'horizontal') {
                measurementValue = this.horizontalDistance;
            } else if (type === 'vertical') {
                measurementValue = this.verticalDistance;
            } else {
                measurementValue = this.distance;
            }
        } catch (e) {
            // If there's an error calculating the distance, default to 0
            measurementValue = 0;
        }
    
        let labelOptions: CSSObjectOptions = {
            element: labelElement,
            className: 'measurement-label',
            content: measurementValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS,
            visible: true,
            vectorPosition: center
        }
    
        if (isPreview) {
            // Set the appropriate preview label based on the type parameter
            if (type === 'horizontal') {
                this.previewHorizontalLabel = new CSSObject(measurement, labelOptions);
            } else if (type === 'vertical') {
                this.previewVerticalLabel = new CSSObject(measurement, labelOptions);
            } else {
                this.previewLabel = new CSSObject(measurement, labelOptions);
            }
        } else {
            // Set the appropriate regular label based on the type parameter
            if (type === 'horizontal') {
                this.horizontalLabel = new CSSObject(measurement, labelOptions);
            } else if (type === 'vertical') {
                this.verticalLabel = new CSSObject(measurement, labelOptions);
            } else {
                this.label = new CSSObject(measurement, labelOptions);
            }
        }
    }

    remove() {
        // Remove points from the scene and the measurement points collection
        if (this.point1) {
            this._renderedScene.remove(this.point1)
            this.measurementPoints.delete(this.point1.uuid)
        }
        
        if (this.point2) {
            this._renderedScene.remove(this.point2)
            this.measurementPoints.delete(this.point2.uuid)
        }
        
        // Remove all lines from the scene
        if (this.line) this._renderedScene.remove(this.line)
        if (this.horizontalLine) this._renderedScene.remove(this.horizontalLine)
        if (this.verticalLine) this._renderedScene.remove(this.verticalLine)
        
        // Dispose all labels
        if (this.label) this.label.dispose()
        if (this.horizontalLabel) this.horizontalLabel.dispose()
        if (this.verticalLabel) this.verticalLabel.dispose()
        
        // Clean up preview elements if they exist
        this.clearPreviewLine()
        
        // Remove event listeners
        this._controller.sceneManager.canvas.removeEventListener('pointerdown', this.handleDragDown.bind(this), false)
        this._controller.sceneManager.canvas.removeEventListener('pointerup', this.handleDragEnd.bind(this), false)
        
        // Unsubscribe from observables
        this.mousePositionSubscription.unsubscribe()
        this.mouseDragSubscription.unsubscribe()
    }

    show(show: boolean) {
        this.point1.visible = show
        this.point2.visible = show
        
        this.line.visible = show
        this.label.visible = show

        this.verticalLine.visible = show
        this.verticalLabel.visible = show

        this.horizontalLine.visible = show
        this.horizontalLabel.visible = show
        if(show){
            this._controller.sceneManager.canvas.addEventListener('pointerdown', this.handleDragDown.bind(this), false)
            this._controller.sceneManager.canvas.addEventListener('pointerup', this.handleDragEnd.bind(this), false)
        } else {
            this._controller.sceneManager.canvas.removeEventListener('pointerdown', this.handleDragDown.bind(this), false)
            this._controller.sceneManager.canvas.removeEventListener('pointerup', this.handleDragEnd.bind(this), false)
        }
    }
}

export class MeasurementController {
    private _measurements: Measurement[] = []
    public state: 'placingFinalPoint' | 'placingFirstPoint' | 'measureMode' | 'disabled' = 'disabled'

    get enabled() { return this.state != 'disabled' }
    get featureService() { return this.sceneManager.featureService }
    get lastMeasurement() { return this._measurements[this._measurements.length - 1] }
    get mapSpace() { return this.space as MapSpace }
    get modelSpace() { return this.space as ModelSpace }
    get raycaster() { return this.space.raycaster }
    get units() { return MEASUREMENT_UNITS }

    constructor(
        public sceneManager: SceneManager, 
        public scene: Scene, 
        public space: Space, 
        public pointerService: PointerService, 
        public mapManager?: MapManager
    ) { }

    disable() {
        this._measurements.forEach((measurement, i) => {
            if (!measurement.line) { // Remove incomplete measurement
                measurement.clearPreviewLine()
                measurement.mousePositionSubscription.unsubscribe()
                measurement.remove()
                this._measurements.pop()
            } else measurement.show(false) // Hide other measurements
        })

        this.state = 'disabled'
    }

    finishMeasuring() {
        this._measurements.forEach((measurement, i) => {
            if (!measurement.line) { // Remove incomplete measurement
                measurement.remove()
                measurement.clearPreviewLine()
                measurement.mousePositionSubscription.unsubscribe()
                measurement.mouseDragSubscription.unsubscribe()
                this._measurements.pop()
            }
        })

        this.state = 'measureMode'
        this.pointerService.setPointerState('grab', this.sceneManager.canvas.style)
    }

    enableMeasuring() {
        this._measurements.forEach(m => {
            m.show(true)
            m.sceneType = this.scene.type
        })
        this.state = 'placingFirstPoint'
        this.pointerService.setPointerState('placing', this.sceneManager.canvas.style)
    }

    enable() {
        this._measurements.forEach(m => m.show(true))
        this.state = 'measureMode'
    }

    click(point: THREE.Vector3) {
        if (this.state == 'disabled') return
        else if (this.state == 'placingFirstPoint') {
            const measurement = new Measurement(this, this.pointerService)
            measurement.sceneType = this.scene.type
            measurement.placeMeasurementPoint(point)

            this._measurements.push(measurement)
            this.state = 'placingFinalPoint'
        } else if (this.state == 'placingFinalPoint') {
            this.lastMeasurement.placeMeasurementPoint(point)
            this.lastMeasurement.clearPreviewLine()
            this.finishMeasuring()
        }
    }

    changeUnit(newUnit: 'm' | 'ft' | 'mi' | 'km') {
        if (newUnit == 'm') {
            MEASUREMENT_UNITS = 'm'
        } else if (newUnit == 'km') {
            MEASUREMENT_UNITS = 'km'
        } else if (newUnit == 'mi') {
            MEASUREMENT_UNITS = 'mi'
        } else {
            MEASUREMENT_UNITS = 'ft'
        }

        this._measurements.forEach(measurement => {
            measurement.label.options.element.title = measurement.distance + MEASUREMENT_UNITS
            measurement.label.content = measurement.distance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS
            measurement.horizontalLabel.options.element.title = measurement.horizontalDistance + MEASUREMENT_UNITS
            measurement.horizontalLabel.content = measurement.horizontalDistance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS
            measurement.verticalLabel.options.element.title = measurement.verticalDistance + MEASUREMENT_UNITS
            measurement.verticalLabel.content = measurement.verticalDistance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS
        })
    }

    getMeasurementByPointUUID(pointUUID: string): Measurement | null {
        for (let measurement of this._measurements) {
            if (measurement.measurementPoints.has(pointUUID)) {
                return measurement
            }
        }
        return null
    }
}