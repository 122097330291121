import { Component } from '@angular/core'
import { UserService } from '@services/user.service'

@Component({
  selector: 'shared-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.css']
})
export class ProfilePictureComponent {
  get currentUser() { return this._userService.currentUser }

  constructor(private _userService: UserService) { }
}