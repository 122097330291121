import { ProjectPermission } from './ProjectPermission'

export type ProcessingStatus = 'not_processed' | 'processing' | 'completed'

export type ProjectOptions = {
    id?: number,
    description?: string,
    createdOn?: string,
    lastEdited?: Date,
    thumbnail?: string,
    sharedWithOrganization?: boolean,
    organizationID?: string,
    permissions?: ProjectPermission[],
    backButtonEnabled?: boolean,
    showLeftPanelAtStart?: boolean,
    showPanelToggles?: boolean,
    showNavBar?: boolean,
    publicStatus?: boolean,
    showEditors?: boolean,
    logoUrl?: string,
    websiteUrl?: string,
    processingStatus?: ProcessingStatus  
}

export class Project {
    public id: number
    public title: string
    public description: string
    public createdOn: string
    public lastEdited: Date
    public thumbnail: string
    public sharedWithOrganization: boolean
    public organizationID: string
    public permissions: ProjectPermission[]
    public backButtonEnabled: boolean
    public showLeftPanelAtStart: boolean
    public showPanelToggles: boolean
    public showNavBar: boolean
    public publicStatus: boolean
    public showEditors: boolean
    public logoUrl: string
    public websiteUrl: string
    public processingStatus: ProcessingStatus  

    constructor(title: string, options?: ProjectOptions) {
        this.id = options && options.id ? +options.id : null
        this.title = title
        this.description = options ? options.description : ''
        this.createdOn = options ? options.createdOn : null
        this.lastEdited = options ? options.lastEdited : null
        this.thumbnail = options ? options.thumbnail : null
        this.sharedWithOrganization = options?.sharedWithOrganization ?? false
        this.organizationID = options ? options.organizationID : null
        this.permissions = options?.permissions?.map(perm => new ProjectPermission(perm.userID, perm.projectID, perm.permission, perm.user, perm)) ?? []
        this.backButtonEnabled = options ? options.backButtonEnabled : false
        this.showLeftPanelAtStart = options ? options.showLeftPanelAtStart : true
        this.showPanelToggles = options ? options.showPanelToggles : true
        this.showNavBar = options ? options.showNavBar : true
        this.logoUrl = options?.logoUrl
        this.websiteUrl = options?.websiteUrl
        this.processingStatus = options?.processingStatus ?? 'completed'

        this.publicStatus = options ? options.publicStatus : false
        this.showEditors = options ? options.showEditors : true
    }
}