import * as Mapbox from 'mapbox-gl'
import { fromEvent, Observable } from 'rxjs'
import * as THREE from 'three'

import { SceneManager } from './SceneManager'
import { TransformControls } from './TransformControls'

interface Controls {
    camera: THREE.Camera;
    object: THREE.Object3D | undefined;
    enabled: boolean;
    axis: string | null;
    mode: string;
    translationSnap: number | null;
    rotationSnap: number | null;
    space: string;
    size: number;
    dragging: boolean;
    showX: boolean;
    showY: boolean;
    showZ: boolean;
    readonly isTransformControls: true;
    mouseButtons: {
        LEFT: THREE.MOUSE;
        MIDDLE: THREE.MOUSE;
        RIGHT: THREE.MOUSE;
    };
}

type TransformControlsParameters = {
    map?: Mapbox.Map
    size?: number
}

/**
 * Events:
 * axis-changed, camera-changed, object-changed,
 * dragging-changed, mode-changed, space-changed, 
 * rotationSnap-changed, scaleSnap-changed, translationSnap-changed, 
 * showX-changed, showY-changed, showZ-changed, size-changed,
 * change, enabled-changed, objectChange,
 * mouseUp, mouseDown,
 */
/**
 * This Class handles the TransformControls object
 * while providing useful observables from the events 
 * and functions. 
 */
export class CustomTransformControls extends TransformControls implements Controls {
    get attached() { return this.attachedChildren?.length > 0 }
    get attachedChildren() { return this.object?.children ?? [] }
    get scene() { return this.parent }

    public event = {
        objectChange$: fromEvent(<any>this, 'objectChange') as Observable<{ target: TransformControls, value: THREE.Group }>,
        objectAttach$: fromEvent(<any>this, 'object-changed') as Observable<{ target: TransformControls, value: THREE.Group }>,
        modeChange$: fromEvent(<any>this, 'mode-changed') as Observable<{ target: TransformControls, value: 'rotate' | 'translate' | 'scale' }>,
        drag$: fromEvent(<any>this, 'dragging-changed') as Observable<{ target: TransformControls, value: boolean }>,
        change$: fromEvent(<any>this, 'change') as Observable<{ target: TransformControls }>,
        mouseDown$: fromEvent(<any>this, 'mouseDown') as Observable<{ target: TransformControls, mode: 'rotate' | 'translate' | 'scale' }>,
        mouseUp$: fromEvent(<any>this, 'mouseUp') as Observable<{ target: TransformControls, mode: 'rotate' | 'translate' | 'scale' }>,
    }

    constructor(
        private _camera: THREE.Camera,
        private _canvas: HTMLCanvasElement,
        private _sceneManager: SceneManager,
        private _params: TransformControlsParameters = {}
    ) {
        super(_camera, _canvas, _params.map !== undefined)

        if (_params.size != null) {
            this.setSize(_params.size)
        }
        this.userData.type = 'Controller'
        this.userData.isTransformControls = true
        this.traverse((o) => {o.layers.enable(1)})
    }

    private _recenter() {
        if (!this.object) return

        const box = new THREE.Box3().setFromObject(this.object)
        const center = box.getCenter(new THREE.Vector3())
        const children = this.attachedChildren?.slice()

        children.forEach(child => this.scene.attach(child))
        this.object.position.copy(center)
        children.forEach(child => this.object.attach(child))
    }

    public attach(object: THREE.Object3D) {
        if (object.userData.cannotBeTransformed) {
            return this
        } else if (this.object) {
            this.object.attach(object)
        } else {
            const group = new THREE.Group()
            group.name = 'Transform Control Exterior Group' // @ts-ignore
            group.type = 'ControllerExteriorGroup'
            this._sceneManager.renderedScene.attach(this)
            this._sceneManager.renderedScene.attach(group)
            group.attach(object)

            super.attach(group)
        }
        this._recenter()
        return this
    }

    public detach() {
        if (this.attached) {
            const children = this.attachedChildren?.slice()
            children?.forEach(child => this.scene.attach(child))
        }
        this.object?.removeFromParent()
        return super.detach()
    }

    public setMode(mode: 'rotate' | 'translate' | 'scale') {
        super.setMode(mode)
    }

    public dispose() {
        const children = this.attachedChildren.slice()
        this.detach()
        children.forEach(child => this._sceneManager.scene.remove(child))
        super.dispose()
    }
}