import { Subscription } from 'rxjs'
import * as THREE from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'

import { CSSObject, CSSObjectOptions } from './CSSObject'
import { MapManager } from './MapManager'
import { MapSpace } from './MapSpace'
import { move$, Raycaster } from './Raycaster'
import { Scene } from './Scene'
import { SceneManager } from './SceneManager'

const METERS_TO_FEET = 3.28084
const METERS_TO_MILE = 0.000621
const METERS_TO_KILOMETER = 0.001
var MEASUREMENT_UNITS: "ft" | "m" | "mi" | "km" = "ft"

class Measurement {
    private _controller: MeasurementController
    public label: CSSObject
    public line: THREE.Mesh
    public point1: THREE.Mesh
    public point2: THREE.Mesh
    public previewLine: Line2 = null
    public mousePosition: THREE.Vector3
    public mousePositionSubscription: Subscription
    public oneLine: boolean = true
    public previewLabel: CSSObject

    get distance() {
        let distance: number
        if (this.point2) {
            distance = this.point1.position.distanceTo(this.point2.position)
        } else {
            distance = this.point1.position.distanceTo(this.mousePosition)
        }

        if (MEASUREMENT_UNITS == "ft") {
            return (distance * METERS_TO_FEET)
        } else if (MEASUREMENT_UNITS == "mi") {
            return (distance * METERS_TO_MILE)
        } else if (MEASUREMENT_UNITS == "km") {
            return (distance * METERS_TO_KILOMETER)
        } else {
            return distance
        }
    }
    private get _renderedScene(): THREE.Scene { return this._controller.sceneManager.renderedScene }
    private get _scene(): Scene { return this._controller.scene }

    constructor(controller: MeasurementController) {
        this._controller = controller

        this.mousePositionSubscription = move$.subscribe((mousePos) => {
            if (this._controller.raycaster) {
                this.mousePosition = this._controller.raycaster.getIntersection(mousePos).point
                this.previewMeasurementLine(this.mousePosition)
            } else if (this._controller.mapSpace) {
                this.mousePosition = this._controller.mapSpace.raycaster.getIntersection(mousePos).point
                this.previewMeasurementLine(this.mousePosition)

            }
        })
    }

    placeMeasurementPoint(point: THREE.Vector3) {
        const pointGeometry = new THREE.SphereGeometry(0.5, 16, 12)
        const pointMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            depthTest: false,
            depthWrite: false,
            transparent: true,
        })
        const pointMesh = new THREE.Mesh(pointGeometry, pointMaterial)

        pointGeometry.center()
        pointMesh.position.set(point.x, point.y, point.z)

        this._scaleWithCamera(pointMesh) // scale before adding to scene to avoid resizing blip

        this._renderedScene.attach(pointMesh)

        pointMesh.onBeforeRender = () => this._scaleWithCamera(pointMesh)

        if (this.point1) {
            this.point2 = pointMesh
            this._connectPoints(this.point1, this.point2)
            this.mousePositionSubscription.unsubscribe()
        } else {
            this.point1 = pointMesh
        }
    }

    previewMeasurementLine(mousePosition: THREE.Vector3) {
        if (!this.point1) return // Only preview if first point is set

        if (!this.previewLine) {
            const previewLineGeometry = new LineGeometry()
            const previewLineMaterial = new LineMaterial({
                color: 0xffffff,
                linewidth: 4,
                vertexColors: false,
                dashed: true,
                alphaToCoverage: true,
                transparent: true,
                opacity: 0.9
            })
            this.previewLine = new Line2(previewLineGeometry, previewLineMaterial)
            previewLineMaterial.resolution.set(this._controller.sceneManager.canvas.clientWidth, this._controller.sceneManager.canvas.clientHeight)
            previewLineMaterial.depthWrite = false
            previewLineMaterial.depthTest = false
            previewLineMaterial.side = THREE.DoubleSide
            this.previewLine.scale.set(1, 1, 1)
            this._renderedScene.add(this.previewLine)

            previewLineGeometry.computeBoundingBox()
            const geometryCenter = previewLineGeometry.boundingBox.getCenter(new THREE.Vector3())
            previewLineGeometry.center()
            this.previewLine.renderOrder = 999
            this.line = this.previewLine

            this._addLabel(this.previewLine, geometryCenter, true)
        }

        // Prevents reading mouse position when in the V O I D
        if (mousePosition) {
            const positions = [
                this.point1.position.x, this.point1.position.y, this.point1.position.z,
                mousePosition.x, mousePosition.y, mousePosition.z
            ]
            this.previewLine.geometry.setPositions(positions)
            this.previewLine.geometry.attributes.position.needsUpdate = true
            this.previewLine.computeLineDistances()

            // Update preview label content and position
            this.previewLabel.content = this.distance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS
            this.previewLabel.vectorPosition = new THREE.Vector3(
                (this.point1.position.x + mousePosition.x) / 2,
                (this.point1.position.y + mousePosition.y) / 2,
                (this.point1.position.z + mousePosition.z) / 2
            )
        }

    }

    clearPreviewLine() {
        if (this.previewLine) {
            this._renderedScene.remove(this.previewLine)
            this.previewLine.geometry.dispose()
            this.previewLine.material.dispose()
            this.previewLine = null
        }
        if (this.previewLabel) {
            this.previewLabel.dispose()
            this.previewLabel = null
        }
    }

    private _connectPoints(point1: THREE.Mesh, point2: THREE.Mesh) {
        const lineGeometry = new LineGeometry()
        const lineMaterial = new LineMaterial({
            color: 0xffffff,
            linewidth: 4,
            vertexColors: false,
            dashed: false,
            alphaToCoverage: true,
            transparent: true,
            opacity: 0.9
        })
        const lineMesh = new Line2(lineGeometry, lineMaterial)

        lineGeometry.setPositions([
            point1.position.x, point1.position.y, point1.position.z,
            point2.position.x, point2.position.y, point2.position.z
        ])
        lineMaterial.resolution.set(this._controller.sceneManager.canvas.clientWidth, this._controller.sceneManager.canvas.clientHeight)
        lineMaterial.depthWrite = false
        lineMaterial.depthTest = false
        lineMaterial.side = THREE.DoubleSide
        lineMesh.computeLineDistances()
        lineMesh.scale.set(1, 1, 1)
        this._renderedScene.add(lineMesh)

        // align the geometry and mesh positions, so there isn't an offset between the two
        lineGeometry.computeBoundingBox()
        const geometryCenter = lineGeometry.boundingBox.getCenter(new THREE.Vector3())
        lineGeometry.center()
        lineMesh.position.copy(geometryCenter)
        lineMesh.renderOrder = 999
        this.line = lineMesh
        this._addLabel(lineMesh, lineMesh.position)
    }

    private _scaleWithCamera(mesh: THREE.Mesh): void {
        const camera = this._controller.sceneManager.camera
        let cameraPosition = camera.position.clone()

        if (this._scene.type == 'Map') {
            var camInverseProjection = new THREE.Matrix4().copy(camera.projectionMatrix)
            camInverseProjection = camInverseProjection.invert()
            cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection)
        }

        // Scale the mesh by a ratio of new camera height to height of mesh
        const meshWorldPosition = mesh.getWorldPosition(new THREE.Vector3())
        const z = new THREE.Vector3().subVectors(meshWorldPosition, cameraPosition).length()
        const heightInPixels = 10
        const meshSize = new THREE.Box3().setFromObject(mesh).getSize(new THREE.Vector3())
        const frustumHeight = Math.tan(THREE.MathUtils.degToRad((camera as THREE.PerspectiveCamera).fov) / 2) * 2 * z
        const meshNewHeight = (heightInPixels / this._controller.sceneManager.canvas.clientHeight) * frustumHeight

        if (Math.abs(1 - meshNewHeight / meshSize.y) > 0.000000001) {
            mesh.scale.multiplyScalar(meshNewHeight / meshSize.y)
        }

        if (this._scene.type == 'Map') {
            mesh.scale.multiplyScalar(0.5)
        }
    }

    /* CREATING MEASUREMENT LABEL ELEMENT */
    private _addLabel(measurement: THREE.Mesh, center: THREE.Vector3, isPreview: boolean = false) {
        const labelElement = document.createElement('div')
        labelElement.className = 'd-flex me-auto position-relative align-items-center'
        labelElement.style.pointerEvents = 'all'

        let span = document.createElement('span')
        span.id = isPreview ? 'preview-content' : 'content'

        labelElement.appendChild(span)

        if (!isPreview) {
            let anchor = document.createElement('a')
            anchor.addEventListener('click', (e) => {
                e.stopPropagation()
                this.remove()
            })
            anchor.className = 'ms-1'
            anchor.style.pointerEvents = 'all'

            let span = document.createElement('span')
            span.id = 'content'

            let deleteBtn = document.createElement('i')
            deleteBtn.className = 'fad fa-times-circle text-secondary'
            labelElement.title = this.distance + MEASUREMENT_UNITS

            anchor.appendChild(deleteBtn)

            labelElement.appendChild(anchor)
        }

        let labelOptions: CSSObjectOptions = {
            element: labelElement,
            className: 'measurement-label',
            content: this.distance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS,
            visible: true,
            vectorPosition: center
        }

        if (isPreview) {
            this.previewLabel = new CSSObject(measurement, labelOptions)
        } else {
            this.label = new CSSObject(measurement, labelOptions)
        }
    }

    remove() {
        this._renderedScene.remove(this.point1)
        if (this.line) this._renderedScene.remove(this.line)
        if (this.point2) this._renderedScene.remove(this.point2)
        if (this.label) this.label.dispose()
    }

    show(show: boolean) {
        this.line.visible = show
        this.point1.visible = show
        this.point2.visible = show
        this.label.visible = show
    }
}

export class MeasurementController {
    private _measurements: Measurement[] = []
    public state: 'placingFinalPoint' | 'placingFirstPoint' | 'measureMode' | 'disabled' = 'disabled'

    get enabled() { return this.state != 'disabled' }
    get featureService() { return this.sceneManager.featureService }
    get lastMeasurement() { return this._measurements[this._measurements.length - 1] }
    get units() { return MEASUREMENT_UNITS }

    constructor(public sceneManager: SceneManager, public scene: Scene, public raycaster: Raycaster, public mapSpace: MapSpace, public mapManager?: MapManager) { }

    disable() {
        this._measurements.forEach((measurement, i) => {
            if (!measurement.line) { // Remove incomplete measurement
                measurement.clearPreviewLine()
                measurement.mousePositionSubscription.unsubscribe()
                measurement.remove()
                this._measurements.pop()
            } else if (measurement.previewLine) {
                measurement.clearPreviewLine()
                measurement.mousePositionSubscription.unsubscribe()
                measurement.remove()
                this._measurements.pop()
            } else measurement.show(false) // Hide other measurements
        })

        this.state = 'disabled'

        this.sceneManager.canvas.style.cursor = 'auto'
    }

    finishMeasuring() {
        this._measurements.forEach((measurement, i) => {
            if (!measurement.line) { // Remove incomplete measurement
                measurement.remove()
                this._measurements.pop()
            }
        })
        this.state = 'measureMode'
        this.sceneManager.canvas.style.cursor = 'auto'
    }

    enableMeasuring() {
        this._measurements.forEach(m => m.show(true))
        this.state = 'placingFirstPoint'
        this.sceneManager.canvas.style.cursor = 'crosshair'
    }

    enable() {
        this._measurements.forEach(m => m.show(true))
        this.state = 'measureMode'
    }

    click(point: THREE.Vector3) {
        if (this.state == 'disabled') return
        else if (this.state == 'placingFirstPoint') {
            const measurement = new Measurement(this)

            measurement.placeMeasurementPoint(point)

            this._measurements.push(measurement)
            this.state = 'placingFinalPoint'
        } else if (this.state == 'placingFinalPoint') {
            this.lastMeasurement.placeMeasurementPoint(point)
            this.state = 'measureMode'
            this.finishMeasuring()
            this.lastMeasurement.clearPreviewLine()
        }
    }

    changeUnit(newUnit: 'm' | 'ft' | 'mi' | 'km') {
        if (newUnit == 'm') {
            MEASUREMENT_UNITS = 'm'
        } else if (newUnit == 'km') {
            MEASUREMENT_UNITS = 'km'
        } else if (newUnit == 'mi') {
            MEASUREMENT_UNITS = 'mi'
        } else {
            MEASUREMENT_UNITS = 'ft'
        }

        this._measurements.forEach(measurement => {
            measurement.label.options.element.title = measurement.distance + MEASUREMENT_UNITS
            measurement.label.content = measurement.distance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + MEASUREMENT_UNITS
        })
    }
}