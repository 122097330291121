<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
            </div>
            <div class="container modal-body p-4">
                <form [formGroup]="form">
                    <div class="mb-3">
                        <label class="form-label" for="model-name">Model Name</label>
                        <input type="text" id="model-name" class="form-control" formControlName="name" required>
                        <small *ngIf="form.get('name').status == 'INVALID'" class="form-text text-danger">Model name is
                            required</small>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="project-description">Description</label>
                        <textarea class="form-control" rows="5" id="project-description"
                            formControlName="description"></textarea>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="file">Model Files</label>
                        <div class="input-group">
                            <input formControlName="files" id="modelFiles" #fileInput type="file" multiple
                                (change)="onFileChange($event.target.files)" [style.display]="'none'"
                                accept=".glb,.gltf,.bin,.png,.jpg,.jpeg,.las,.laz,.e57">
                            <label class="input-group-text rounded-start" for="modelFiles">Upload</label>
                            <label class="input-group-text bg-white" for="modelFiles">
                                {{ selectedFiles.length == 0 ? "Choose file(s)" : "Add files (" + selectedFiles.length +
                                " file" + (selectedFiles.length > 1 ? "s" : "") + " selected)" }}
                            </label>

                            <label *ngIf="isPointCloud" class="input-group-text input-group-append" for="centering">
                                <input type="checkbox" formControlName="isCentered" id="centering">
                            </label>

                            <label *ngIf="isPointCloud" class="input-group-text bg-white input-group-append">
                                Center Pointcloud
                            </label>
                        </div>

                        <small *ngIf="form.get('files').status == 'INVALID' || selectedFiles.length == 0"
                            class="form-text text-danger">
                            At least one file is required
                        </small>
                    </div>
                    <ul class="list-group">
                        <li *ngFor="let file of selectedFiles; let i = index" [attr.data-index]="i"
                            class="list-group-item d-flex justify-content-between align-items-center">
                            <span class="text-nowrap text-truncate" style="min-width:0;">
                                <span class="text-center"><i [ngClass]="modelService.iconClasses(file.name)"
                                        style="width:20px"></i></span>
                                <span style="padding-left: 10px;">{{ file.name }}</span>
                            </span>
                            <a class="btn text-secondary" (click)="removeFile(i)"><i class="fas fa-trash"></i></a>
                        </li>
                    </ul>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn" [ngClass]="{
                        'btn-primary': submitColor == 'blue' || submitColor == null,
                        'btn-success': submitColor == 'green',
                        'btn-danger': submitColor == 'red',
                        'btn-warning': submitColor == 'yellow'
                    }" (click)="_submit()">{{submitText}}</button>
                <button type="button" class="btn btn-secondary" (click)="_cancel()">{{ cancelText }}</button>
            </div>
        </div>
    </div>
</div>