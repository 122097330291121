<div *ngIf="selectedFeature$ | async as feature" class="d-grid gap-3">
    <ul class="list-group">
        <!-- Change visibility -->
        <li class="list-group-item d-flex form-switch">
            <label class="form-check-label" for="feature-visibility-switch">
                Visible
            </label>
            <input class="ms-auto form-check-input" type="checkbox" role="switch" id="feature-visibility-switch"
                [(ngModel)]="feature.visible" (change)="updateFeature(feature)" style="cursor: pointer;">
        </li>

        <!-- Change opacity -->
        <li class="list-group-item d-flex align-items-center justify-content-between d-grid gap-2">
            <label>Opacity</label>
            <input class="form-range" type="range" min="0" max="1" step="0.05" (change)="updateFeature(feature)"
                [(ngModel)]="feature.opacity">
            <span class="badge rounded-pill bg-primary bg-gradient">
                {{feature.opacity | number:'1.2-2'}}
            </span>
        </li>

        <!-- Change marker size -->
        <li *ngIf="feature.size" class="list-group-item d-flex align-items-center justify-content-between d-grid gap-2">
            <label>Size</label>

            <input class="form-range" type="range" min="0.01" max="{{maxSize}}" step="0.01"
                (change)="updateSize(feature.size)" [(ngModel)]="feature.size.value">

            <span class="badge rounded-pill bg-primary bg-gradient">
                {{(feature.size.value / this.maxSize) | percent }}
            </span>
        </li>

        <!-- Change marker style between icon, image or label -->
        <li class="list-group-item d-flex align-items-center d-grid gap-2">
            <span class="text-nowrap">
                Style
            </span>

            <div class='ms-auto btn-group btn-group-sm' role="group">
                <input type="radio" class="btn-check" id='tag-type-icon-radio-btn' value='icon' name="type"
                    autocomplete="off" (change)="updateDisplayType(feature.displayType)"
                    [(ngModel)]="feature.displayType.value">
                <label class="btn btn-outline-primary" for="tag-type-icon-radio-btn">
                    Icon
                </label>

                <input type="radio" class="btn-check" id='tag-type-image-radio-btn' value='image' name="type"
                    autocomplete="off" (change)="updateDisplayType(feature.displayType)"
                    [(ngModel)]="feature.displayType.value">
                <label class="btn btn-outline-primary" for="tag-type-image-radio-btn">
                    Image
                </label>

                <input type="radio" class="btn-check" id="tag-type-label-radio-btn" value='label' name="type"
                    autocomplete="off" (change)="updateDisplayType(feature.displayType)"
                    [(ngModel)]="feature.displayType.value">
                <label class="btn btn-outline-primary" for="tag-type-label-radio-btn">
                    Label
                </label>
            </div>
        </li>
    </ul>

    <!-- Marker - image -->
    <ul *ngIf="feature.displayType.value == 'image'" class="list-group">
        <!-- Change marker custom image -->
        <li class="list-group-item d-flex align-items-center d-grid gap-2">
            <span class="text-nowrap">Image</span>

            <!-- Replace or remove Marker image -->
            <div *ngIf="feature.image?.fileReference" class="d-flex align-items-center gap-2 ms-auto">
                <label for="replace-image-input" class="text-truncate text-muted" style="max-width: 95px"
                    [title]="feature.image.fileReference.filename">
                    {{feature.image.fileReference.filename}}
                </label>

                <input id="replace-image-input" type="file" accept="image/*"
                    (change)="replaceImage($event.target.files[0], i)" title="Replace image file" hidden>
                <label for="replace-image-input" class="btn btn-sm btn-primary text-nowrap">
                    <i class="fas fa-sync"></i> Replace
                </label>

                <a type="button" (click)="removeImage()" title="Remove">
                    <i class="fas fa-close"></i>
                </a>
            </div>

            <!-- Add image to Marker -->
            <div *ngIf="!feature.image?.fileReference" class="d-flex justify-content-end w-100">
                <input id="add-image-input" type="file" accept="image/*" [disabled]="atDocumentLimit"
                    (change)="addImage($event.target.files[0])" title="Add image file" hidden>
                <label for="add-image-input" class="ms-auto btn btn-sm btn-primary text-truncate"
                    style="text-align: center; width: 150px" [ngClass]="{'disabled': atDocumentLimit}">
                    <i *ngIf="atDocumentLimit" class="fa fa-lock"></i>
                    <i *ngIf="!atDocumentLimit" class="fa fa-upload"></i>
                    Choose File
                </label>
            </div>
        </li>
    </ul>

    <!-- Marker - icon -->
    <ul *ngIf="feature.displayType.value == 'icon'" class="list-group">
        <!-- Change icon -->
        <li class="list-group-item d-flex align-items-center d-grid gap-2">
            <span class="text-nowrap me-auto">
                Selected Icon
            </span>

            <div class="dropstart">
                <button type="button" class="btn btn-light" data-bs-toggle="dropdown" aria-expanded="false">
                    <i [style.width]="'20px'" [class]="feature.icon.value"></i>
                </button>

                <shared-font-awesome-icon-picker class="dropdown-menu shadow" [selected]="feature.icon.value"
                    (picked)="updateIcon($event)">
                </shared-font-awesome-icon-picker>
            </div>
        </li>

        <!-- Icon Color -->
        <li *ngIf="feature.color" class="list-group-item d-flex align-items-center">
            <label class="form-check-label" for="feature-color-picker">
                Icon Color
            </label>
            <shared-color-picker class="ms-auto" [value]="feature.color.value"
                (valueChange)="updateColor(feature.color, $event)"></shared-color-picker>
        </li>

        <!-- Change background shape -->
        <li *ngIf="feature.backgroundShape" class="list-group-item d-flex flex-column d-grid gap-1">
            <span class="text-nowrap">
                Background Shape
            </span>

            <div class='btn-group btn-group-sm' role="group">
                <input type="radio" class="btn-check" id="tag-shape-none-radio-btn" name="backgroundShape" value='none'
                    autocomplete="off" (change)="updateProperty(feature.backgroundShape)"
                    [(ngModel)]="feature.backgroundShape.value">
                <label class="btn btn-outline-primary" for="tag-shape-none-radio-btn">
                    None
                </label>

                <input type="radio" class="btn-check" id="tag-shape-square-radio-btn" name="backgroundShape"
                    value='square' autocomplete="off" (change)="updateProperty(feature.backgroundShape)"
                    [(ngModel)]="feature.backgroundShape.value">
                <label class="btn btn-outline-primary" for="tag-shape-square-radio-btn">
                    Square
                </label>

                <input type="radio" class="btn-check" id="tag-shape-rounded-radio-btn" name="backgroundShape"
                    value='rounded' autocomplete="off" (change)="updateProperty(feature.backgroundShape)"
                    [(ngModel)]="feature.backgroundShape.value">
                <label class="btn btn-outline-primary" for="tag-shape-rounded-radio-btn">
                    Rounded
                </label>

                <input type="radio" class="btn-check" id="tag-shape-circle-radio-btn" name="backgroundShape"
                    value='circle' autocomplete="off" (change)="updateProperty(feature.backgroundShape)"
                    [(ngModel)]="feature.backgroundShape.value">
                <label class="btn btn-outline-primary" for="tag-shape-circle-radio-btn">
                    Circle
                </label>
            </div>
        </li>

        <!-- Change background color -->
        <li *ngIf="feature.backgroundColor" class="list-group-item d-flex align-items-center">
            <label class="form-check-label" for="feature-color-picker">
                Background Color
            </label>
            <shared-color-picker class="ms-auto" [value]="feature.backgroundColor.value"
                (valueChange)="updateColor(feature.backgroundColor, $event)"></shared-color-picker>
        </li>
    </ul>

    <!-- Marker - label -->
    <ul *ngIf="feature.displayType.value == 'label'" class="list-group">
        <!-- Change text content -->
        <li *ngIf="feature.text" class="list-group-item d-flex align-items-center d-grid gap-2">
            <span class="text-nowrap me-auto">
                Text Content
            </span>
            <input class="form-control" [(ngModel)]="feature.text.value" placeholder="Display Text"
                (blur)="updateProperty(feature.text)" [maxlength]="200">
        </li>

        <!-- Text Color -->
        <li *ngIf="feature.color" class="list-group-item d-flex align-items-center">
            <label class="form-check-label" for="feature-color-picker">
                Text Color
            </label>
            <shared-color-picker class="ms-auto" [value]="feature.color.value"
                (valueChange)="updateColor(feature.color, $event)"></shared-color-picker>
        </li>

        <!-- Change background color -->
        <li *ngIf="feature.backgroundColor" class="list-group-item d-flex align-items-center">
            <label class="form-check-label" for="feature-color-picker">
                Background Color
            </label>
            <shared-color-picker class="ms-auto" [value]="feature.backgroundColor.value"
                (valueChange)="updateColor(feature.backgroundColor, $event)"></shared-color-picker>
        </li>
    </ul>
</div>