import { FileReference } from './FileReference'

export type ScenePropertyOptions = {
    id?: number,
    fileReferenceID?: number,
    fileReference?: FileReference
}

export class SceneProperty {
    public id: number
    public sceneID: number
    public fileReferenceID: number
    public fileReference: FileReference
    public type: "decimal" | "integer" | "string" | "boolean" | "vector3" | "image"
    public key: string
    public value: [number, number, number] | boolean | number | string | undefined | object

    constructor(sceneID: number, type: "decimal" | "integer" | "string" | "boolean" | "vector3" | "image", key: string, value: any, options?: ScenePropertyOptions) {
        this.id = options && options.id ? +options.id : null
        this.sceneID = sceneID ? +sceneID : null
        this.fileReferenceID = options && options.fileReferenceID ? +options.fileReferenceID : null
        this.fileReference = options && options.fileReference ? options.fileReference : null
        this.type = type
        this.key = key
        try { this.value = JSON.parse(value) }
        catch { this.value = value }
    }
}