import * as GeoJSON from 'geojson'
import mapboxgl, * as Mapbox from 'mapbox-gl'
import { Subscription } from 'rxjs'
import { map, startWith, take } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { Injectable } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'
import { features$, FeatureService } from '@services/feature.service'
import { FileReferenceService } from '@services/file-reference.service'
import { FilterService } from '@services/filter.service'
import { ProjectService } from '@services/project.service'
import { SceneService } from '@services/scene.service'
import { MarkerTypes } from '@services/spatial-annotation.service'
import { calculateStatistic, MathOperation, normalizeValues, simpleHash } from '@utils/Objects'
import { createIconSVGString, svgToCanvas, TagOptions } from '@utils/TagFeatures'

import { Feature, FeatureTypes, isMapFeature } from './Feature'
import { map$ } from './MapSpace'
import { PointerService } from '@services/pointer.service'

export type DataPoint = {
    coordinates: number[]
    featureID: number
    key: string
    value: number
}

interface MarkerGeoJsonProperties extends GeoJSON.GeoJsonProperties {
    anchor: string
    backgroundColor: string
    backgroundShape: string
    displayType: MarkerTypes
    featureName: string
    fileReferenceID?: number
    fontColor: string
    hash?: string
    icon?: string
    imageName: string
    interacted: boolean
    opacity: number
    origin: "karta"
    position: number[]
    showText: boolean
    size: number
    textContent: string
    type: "marker"
    visibility: boolean
}

function getLineGeometry(coordinates: GeoJSON.Position[]): GeoJSON.LineString {
    return { 'type': 'LineString', 'coordinates': coordinates }
}

function getPointGeometry(coordinates: GeoJSON.Position): GeoJSON.Point {
    return { 'type': 'Point', 'coordinates': coordinates }
}

function getPolygonGeometry(coordinates: GeoJSON.Position[]): GeoJSON.Polygon {
    return { 'type': 'Polygon', 'coordinates': [coordinates] }
}

/**
 * Feature to Mapbox flow: 
 * - Define geometry/properties of the feature
 * - Convert to GeoJSON format
 * - Create/update the Mapbox source & layer
 * - Add to/update the map
 **/
@Injectable({
    providedIn: 'root'
})
export class MapManager {
    private _map: Mapbox.Map
    private _subscriptions: Subscription[] = []
    public mapFeatures$ = features$.pipe(
        map(features => features.filter(feature => isMapFeature(feature)))
    )
    public geoJsonSourceMap = new Map<string, GeoJSON.Feature[]>()
    public initialCenter: mapboxgl.LngLat
    public markerImageMap = new Map<string, string>()
    public popup: mapboxgl.Popup
    public rasterSourceMap = new Map<string, Feature>()
    public styleLoaded = false
    public settingRaster = false

    get lines() { return this.geoJsonSourceMap.get('line') }
    get mapFeatures() { return this.featureService.features.filter(feature => isMapFeature(feature)) }
    get markers() { return this.geoJsonSourceMap.get('pointSource') }
    get polygons() { return this.geoJsonSourceMap.get('polygon') }
    get rasterImages() { return this.mapFeatures.filter(feature => feature.type == 'rasterImage') }
    get selectedFeatureID() { return this.featureService.selectedFeatureID }

    constructor(
        private _fileReferenceService: FileReferenceService,
        public authenticationService: AuthenticationService,
        public featureService: FeatureService,
        public filterService: FilterService,
        public pointerService: PointerService,
        public projectService: ProjectService,
        public sceneService: SceneService,
    ) {
        this._subscriptions.push(
            map$.subscribe(map => {
                this._map = map
                this.styleLoaded = false

                this.initialCenter = map.getCenter()

                /** IMPORTANT NOTE: This event listener re-adds Features to the map if the map style has changed **/
                this._map.on('style.load', async () => {
                    this.styleLoaded = true

                    // Add an empty layer to reference
                    this._map.addLayer({ id: 'base-layer', type: 'line', source: { type: 'geojson', data: { type: 'FeatureCollection', features: [] } } })

                    if (this.lines) {
                        let data = { 'type': "FeatureCollection", 'features': this.lines } as GeoJSON.FeatureCollection
                        let source = { type: 'geojson', data: data, } as Mapbox.SourceSpecification

                        this._addSourceToMap('line', source, 'line')
                    }

                    if (this.polygons) {
                        let data = { 'type': "FeatureCollection", 'features': this.polygons } as GeoJSON.FeatureCollection
                        let source = { type: 'geojson', data: data, } as Mapbox.SourceSpecification

                        this._addSourceToMap('polygon', source, 'polygon')
                    }

                    if (this.markers) {
                        let data = { 'type': "FeatureCollection", 'features': this.markers } as GeoJSON.FeatureCollection
                        let source = { type: 'geojson', data: data, } as Mapbox.SourceSpecification

                        this._addSourceToMap('pointSource', source, 'marker')
                    }

                    if (!this.projectService.currentProject.publicStatus) var token = await this.authenticationService.getToken().toPromise()
                    this._setRasterSourceData(this.rasterImages, token)
                })

                if (this.authenticationService.currentModule == 'builder') { // Line Vertice Edit
                    map.on('load', () => {
                        let draggedPointIndex = null
                        let isDragging = false
                        const jointPointsSourceID = 'joint-points-source'

                        let lastUpdateTime = 0
                        const throttleInterval = 5 // Update every ...

                        // Function to update joint points
                        const updateJointPoints = (feature) => {
                            if (feature) {
                                const pointFeatures = feature.geometry.coordinates.map((coord, index) => ({
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: coord,
                                    },
                                    properties: { index },
                                }))

                                const source = map.getSource(jointPointsSourceID) as mapboxgl.GeoJSONSource
                                source.setData({
                                    type: 'FeatureCollection',
                                    features: pointFeatures,
                                })
                            } else {
                                const source = map.getSource(jointPointsSourceID) as mapboxgl.GeoJSONSource
                                source.setData({
                                    type: 'FeatureCollection',
                                    features: [],
                                })
                            }
                        }

                        // Clear points when deselecting
                        const clearJointPoints = () => {
                            const source = map.getSource(jointPointsSourceID) as mapboxgl.GeoJSONSource
                            if (source) {
                                source.setData({
                                    type: 'FeatureCollection',
                                    features: [],
                                })
                            }
                        }
                        map.on('mousedown', (e) => {
                            // line is not used because lines in mapbox are not always lines and the line event is not always triggered while clicking them
                            if (!this.featureService.selectedFeatureID) return

                            const source = map.getSource(jointPointsSourceID) as mapboxgl.GeoJSONSource

                            if (!source?._data) return

                            const coords = e.lngLat
                            const points = source._data["features"]
                            // Scale click error based on zoom level
                            const errorRange = 0.000002 * Math.pow(2, 22 - map.getZoom())
                            draggedPointIndex = points.findIndex(
                                (point) =>
                                    Math.abs(point.geometry.coordinates[0] - coords.lng) < errorRange &&
                                    Math.abs(point.geometry.coordinates[1] - coords.lat) < errorRange
                            )

                            if (draggedPointIndex !== -1) {
                                isDragging = true
                                this.pointerService.setPointerState('grabbing', map.getCanvas().style)
                                map.dragPan.disable()
                            }

                        })

                        map.on('mousemove', (e) => {
                            if (!isDragging || draggedPointIndex === null) return

                            const now = Date.now()
                            if (now - lastUpdateTime < throttleInterval) return
                            lastUpdateTime = now

                            const sourceID = this._findGeoJsonSource(this.featureService.selectedFeatureID.toString())
                            const geoJsonFeatures = this.geoJsonSourceMap.get(sourceID)
                            const sourceFeature = geoJsonFeatures.find(data => data.id == this.featureService.selectedFeatureID)

                            const pixelBuffer = 10 // Half the box size
                            const bounds = [
                                [e.point.x - pixelBuffer, e.point.y - pixelBuffer] as [number, number], // Top-left corner
                                [e.point.x + pixelBuffer, e.point.y + pixelBuffer] as [number, number], // Bottom-right corner
                            ]

                            const boundsLngLat = [
                                map.unproject(bounds[0]), // Top-left lnglat
                                map.unproject(bounds[1]), // Bottom-right lnglat
                            ]

                            const [minLng, minLat] = [boundsLngLat[0].lng, boundsLngLat[0].lat]
                            const [maxLng, maxLat] = [boundsLngLat[1].lng, boundsLngLat[1].lat]

                            let minDistance = this.getDistance(minLng, minLat, maxLng, maxLat)
                            let closestPosition: number[] | null = null
                            const coords = [e.lngLat.lng, e.lngLat.lat]
                            const [lng, lat] = coords

                            for (const [id, position] of this.featureService.featurePositions) {
                                if (id == sourceFeature.id) continue

                                if (typeof (position[0]) == "number") {
                                    const [posLng, posLat] = position

                                    // Calculate the distance between the current position and coords
                                    const distance = this.getDistance(lng, lat, posLng, posLat)

                                    // If the distance is within maxDistance, update coords and return
                                    if (distance <= minDistance) {
                                        minDistance = distance
                                        closestPosition = position
                                    }
                                } else {
                                    position.forEach(p => {
                                        // @ts-ignore
                                        const [posLng, posLat] = p

                                        // Calculate the distance between the current position and coords
                                        const distance = this.getDistance(lng, lat, posLng, posLat)

                                        // If the distance is within maxDistance, update coords and return
                                        if (distance <= minDistance) {
                                            minDistance = distance
                                            // @ts-ignore
                                            closestPosition = p
                                        }
                                    })
                                }
                            }

                            if (closestPosition) {
                                closestPosition = [closestPosition[0], closestPosition[1]]
                                const updatedCoordinates = sourceFeature.geometry['coordinates']
                                let coorIndex = -1
                                let smallest = minDistance
                                updatedCoordinates.forEach((coor, index) => {
                                    let distance = this.getDistance(coor[0], coor[1], coords[0], coords[1])
                                    if (distance < smallest) {
                                        coorIndex = index
                                        smallest = distance
                                    }
                                })

                                updatedCoordinates[coorIndex] = closestPosition
                                geoJsonFeatures.find(gf => gf.id == sourceFeature.id).geometry['coordinates'] = updatedCoordinates
                                sourceFeature.geometry['coordinates'] = updatedCoordinates
                                this._updateSourceData(sourceID, geoJsonFeatures)
                                updateJointPoints(sourceFeature)
                            } else {
                                sourceFeature.geometry['coordinates'][draggedPointIndex] = coords
                                this._updateSourceData(sourceID, geoJsonFeatures)
                                updateJointPoints(sourceFeature)
                            }
                        })

                        map.on('mouseup', () => {
                            if (!isDragging) return

                            isDragging = false
                            draggedPointIndex = null
                            this.pointerService.setPointerState('grab', map.getCanvas().style)
                            map.dragPan.enable()

                            // Update the selected feature's coordinates
                            const sourceID = this._findGeoJsonSource(this.featureService.selectedFeatureID.toString())
                            const geoJsonFeatures = this.geoJsonSourceMap.get(sourceID)
                            const sourceFeature = geoJsonFeatures.find(data => data.id == this.featureService.selectedFeatureID)

                            if (sourceFeature) {
                                const updatedCoordinates = sourceFeature.geometry['coordinates']

                                // Log or handle the updated coordinates of the line
                                let coorProperty = this.featureService.selectedFeature.properties.find(p => p.key == "coordinateString")
                                if (coorProperty) {
                                    coorProperty.value = JSON.stringify(updatedCoordinates)
                                    this.featureService.updateFeatureProperty(coorProperty).subscribe()
                                    this.featureService.featurePositions.set(this.featureService.selectedFeatureID, updatedCoordinates)
                                }
                            }
                        })

                        map.on('click', (e) => {
                            if (map.getLayer('line')) {
                                const features = map.queryRenderedFeatures(e.point, { layers: ['line'] })
                                if (!features.length) {
                                    clearJointPoints()
                                }
                            }
                        })
                    })
                }

                this._map.on('styleimagemissing', (event) => {
                    const imageID = event['id']
                    const url = this.markerImageMap.get(imageID)

                    if (this._map.hasImage(imageID)) {
                        this._map.removeImage(imageID)
                    }

                    this._map.loadImage(url, (error, image) => {
                        if (!error) {
                            // Check if the image is already loaded
                            if (!this._map.hasImage(imageID)) {
                                this._map.addImage(imageID, image)
                            }
                        } else {
                            console.error("Error loading image:", error)
                        }
                    })
                })
            })
        )
    }

    getDistance(lng1: number, lat1: number, lng2: number, lat2: number): number {
        const R = 6371e3 // Earth radius in meters
        const rad = Math.PI / 180
        const dLat = (lat2 - lat1) * rad
        const dLng = (lng2 - lng1) * rad

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * rad) *
            Math.cos(lat2 * rad) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2)

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

        return R * c // Distance in meters
    }

    addDataPointsToGrid(dataPoints: DataPoint[], operation: MathOperation = 'average', options: { gridSize: number, firstColor: string, secondColor: string, betweenColor: Function }) {
        const zoom = Math.ceil(this._map.getZoom())
        const grid = new Map<string, { coordinates: [number, number], values: number[] }>()
        const earthRadiusKm = 6371 // Earth's radius in kilometers
        const kmPerDegLat = 2 * Math.PI * earthRadiusKm / 360 // Kilometers per degree of latitude
        const scaleFactor = 1.5
        const kmPerDegLonOrigin = kmPerDegLat * Math.cos(this.initialCenter.lat * Math.PI / 180)
        const latGridSize = options.gridSize * Math.pow(scaleFactor, 22 - zoom)
        const lngGridSize = latGridSize * (kmPerDegLat / kmPerDegLonOrigin)
        // Small padding for visibility
        const latPadding = latGridSize * 0.05
        const lngPadding = lngGridSize * 0.05

        // Associate data points with grid cells
        dataPoints.forEach(({ value, coordinates }) => {
            const [longitude, latitude] = coordinates
            const roundedLon = Math.floor(longitude / lngGridSize) * lngGridSize
            const roundedLat = Math.floor(latitude / latGridSize) * latGridSize
            const key = `${roundedLon},${roundedLat}`

            if (!grid.has(key)) {
                grid.set(key, { coordinates: [roundedLon, roundedLat], values: [] })
            }
            grid.get(key).values.push(value)
        })

        // Calculate the statistic for each grid cell
        const statisticValues = Array.from(grid, ([, { coordinates, values }]) => calculateStatistic(values, operation))
        const min = Math.min(...statisticValues)
        const max = Math.max(...statisticValues)

        const zoomToAltMap: { [key: number]: number } = {
            1: 53500000, 2: 9588000, 3: 4400900, 4: 2802000, 5: 1569400, 6: 830000, 7: 422000, 8: 215750, 9: 108600, 10: 54000,
            11: 27550, 12: 13570, 13: 6875, 14: 3415, 15: 1700, 16: 870, 17: 435, 18: 225, 19: 120, 20: 65, 21: 40, 22: 26
        }
        const maxHeight = (zoomToAltMap[zoom] || zoomToAltMap[22]) / 2

        function getColorFromNormalizedValue(normalizedValue) {
            return options.betweenColor(options.firstColor, options.secondColor, normalizedValue)
        }

        const features = Array.from(grid, ([, { coordinates, values }]) => {
            const [longitude, latitude] = coordinates

            const statisticValue = calculateStatistic(values, operation)
            const [normalizedStatistic] = normalizeValues([statisticValue], { min, max })
            const barHeight = normalizedStatistic * maxHeight // Scales to max height based on normalized value
            const color = getColorFromNormalizedValue(normalizedStatistic)

            return {
                type: "Feature",
                properties: {
                    height: barHeight,
                    value: statisticValue,
                    color: color
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [[
                        [longitude + lngPadding, latitude + latPadding],
                        [longitude + lngGridSize - lngPadding, latitude + latPadding],
                        [longitude + lngGridSize - lngPadding, latitude + latGridSize - latPadding],
                        [longitude + lngPadding, latitude + latGridSize - latPadding],
                        [longitude + lngPadding, latitude + latPadding] // Closing the polygon
                    ]]
                }
            } as GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>
        })

        if (!this._map.getSource('data-grid-source')) {
            this._createDataGridSource()
        }

        const data = { 'type': "FeatureCollection", 'features': features } as GeoJSON.FeatureCollection
        const source = this._map.getSource('data-grid-source') as Mapbox.GeoJSONSource

        source.setData(data)
        this._map.moveLayer('grid-extrusion')

        return { min, max }
    }

    public startListeners() {
        return this.mapFeatures$.pipe(
            startWith(this.featureService.features.filter(feature => isMapFeature(feature)))
        ).subscribe(features => this._addFeaturesToMap(features))
    }

    public removeDataGrid() {
        if (this._map.getLayer('grid-extrusion')) {
            this._map.removeLayer('grid-extrusion')
        }

        if (this._map.getSource('data-grid-source')) {
            this._deleteSource('data-grid-source')
        }
    }

    private _createDataGridSource() {
        this._map.addSource('data-grid-source', { type: 'geojson', data: { type: 'FeatureCollection', 'features': [] } })

        this._map.addLayer({
            id: 'grid-extrusion',
            type: 'fill-extrusion',
            source: 'data-grid-source',
            paint: {
                'fill-extrusion-height': ["get", "height"],
                'fill-extrusion-base': 0,
                'fill-extrusion-color': ["get", "color"],
                'fill-extrusion-opacity': 0.75
            }
        })

        this._map.on('click', 'grid-extrusion', (e) => {
            // Check that the feature exists
            if (e.features.length > 0) {
                const { lng, lat } = e.lngLat
                // Display the value in an alert or UI element
                const feature = e.features[0]
                const value = feature.properties.value

                this.popup = new mapboxgl.Popup({ closeOnClick: true, closeOnMove: true })
                    .setLngLat([lng, lat])
                    .setHTML(`<h1 class="m-0">${value}</h1>`)
                    .addTo(this._map)
            }
        })
    }

    private async _addFeaturesToMap(features: Feature[]) {
        const geoJsonFeatures = features.filter(feature => feature.type != 'rasterImage').map(feature => this._createGeoJsonFeature(feature))
        const rasterFeatures = features.filter(feature => feature.type == 'rasterImage')

        const lines = geoJsonFeatures.filter(({ geometry }) => geometry.type == 'LineString') as GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]
        const markers = geoJsonFeatures.filter(({ geometry }) => geometry.type == 'Point') as GeoJSON.Feature<GeoJSON.Point, MarkerGeoJsonProperties>[]
        const polygon = geoJsonFeatures.filter(({ geometry }) => geometry.type == 'Polygon') as GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]

        this._setMarkerSourceData(markers)
        if (!this.projectService.currentProject.publicStatus) var token = await this.authenticationService.getToken().toPromise()
        this._setRasterSourceData(rasterFeatures, token)
        this._setSourceData(lines, 'line')
        this._setSourceData(polygon, 'polygon')
    }

    /**
     * Converts a `Kartorium.Feature` to a `GeoJSON.Feature`.
     * @returns `GeoJSON.Feature`
     */
    private _createGeoJsonFeature(feature: Feature): GeoJSON.Feature {
        if (feature.type == 'line') {
            return this._createLineGeoJson(feature)
        } else if (feature.type == 'marker') {
            return this._createPointGeoJson(feature)
        } else if (feature.type == 'polygon') {
            return this._createPolygonGeoJson(feature)
        } else if (feature.type == 'rasterImage') {
            return this._createRasterImageGeoJson(feature)
        }
    }

    /** 
    * Converts a **marker** `Kartorium.Feature` to a `GeoJSON.Feature`.
    * @returns `GeoJSON.Feature`
    */
    private _createPointGeoJson(feature: Feature): GeoJSON.Feature<GeoJSON.Point, MarkerGeoJsonProperties> {
        const backgroundColor = feature.backgroundColor?.value
        const backgroundShape = feature.backgroundShape?.value
        const displayType = feature.displayType?.value as MarkerTypes
        const fileReference = feature?.image?.fileReference
        const fontColor = feature.color?.value
        const icon = feature.icon?.value
        const isFilteredIn = this.filterService.isFilteredIn(feature)
        const isSelected = this.selectedFeatureID == feature.id
        const size = feature.size?.value ?? 0.06
        const pointGeometry = getPointGeometry(feature.position)
        const showText = displayType == 'label'
        const textContent = feature.text?.value

        if (displayType == 'icon') {
            const options = {
                backgroundColor: feature.backgroundColor.value,
                backgroundShape: feature.backgroundShape.value,
                color: feature.color.value,
                icon: feature.icon.value,
            } as TagOptions

            var imageName = "icon-" + simpleHash(JSON.stringify(options))
        } else if (displayType == 'image' && feature.image?.fileReference) {
            var imageName = feature.image.fileReference.hash
        } else if (displayType == 'image') {
            var imageName = 'defaultMarker'
        }

        return {
            'id': feature.id?.toString(),
            'type': "Feature",
            'geometry': pointGeometry,
            'properties': {
                backgroundColor: backgroundColor,
                backgroundShape: backgroundShape,
                displayType: displayType,
                featureName: feature.name,
                fileReferenceID: fileReference?.id,
                fontColor: fontColor,
                hash: fileReference?.hash,
                icon: icon,
                imageName: imageName,
                interacted: isSelected,
                size: +size,
                opacity: feature.opacity,
                origin: 'karta',
                position: feature.position as any,
                showText: showText,
                textContent: textContent,
                type: 'marker',
                visibility: feature.visible && isFilteredIn, // the layer will filter features where visibility == false
            } as MarkerGeoJsonProperties
        } as GeoJSON.Feature<GeoJSON.Point, MarkerGeoJsonProperties>
    }

    /** 
    * Converts a **line** `Kartorium.Feature` to a `GeoJSON.Feature`.
    * @returns `GeoJSON.Feature`
    */
    private _createLineGeoJson(feature: Feature): GeoJSON.Feature<GeoJSON.LineString, GeoJSON.GeoJsonProperties> {
        const isSelected = this.selectedFeatureID == feature.id
        const isFilteredIn = this.filterService.isFilteredIn(feature)
        const dashed: any = feature.properties.find(p => p.key == 'dashed').value
        const coordinates: number[][] = JSON.parse(feature.coordinateString.value)
        const lineGeometry = getLineGeometry(coordinates)

        return {
            'id': feature.id.toString(),
            'type': "Feature",
            'geometry': lineGeometry,
            'properties': {
                type: 'line',
                origin: 'karta',
                interacted: isSelected,
                width: parseInt(feature.properties.find(p => p.key == 'width').value),
                offset: parseInt(feature.properties.find(p => p.key == 'offset').value),
                color: feature.properties.find(p => p.key == 'color').value,
                dashed: dashed,
                visibility: feature.visible && isFilteredIn, // the layer will filter features where visibility == false
                opacity: feature.opacity,
            }
        } as GeoJSON.Feature<GeoJSON.LineString, GeoJSON.GeoJsonProperties>
    }

    /**
    * Converts a **polygon** `Kartorium.Feature` to a `GeoJSON.Feature`.
    * @returns `GeoJSON.Feature`
    */
    private _createPolygonGeoJson(feature: Feature): GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties> {
        const isSelected = this.selectedFeatureID == feature.id
        const isFilteredIn = this.filterService.isFilteredIn(feature)
        const outlineVisibility: any = feature.properties.find(p => p.key == 'outlineVisibility').value
        const coordinates: number[][] = JSON.parse(feature.coordinateString.value)

        coordinates.push(coordinates[0]) // Connects the last point to the first point

        const polygonGeometry = getPolygonGeometry(coordinates)

        return {
            'id': feature.id.toString(),
            'type': "Feature",
            'geometry': polygonGeometry,
            'properties': {
                type: 'polygon',
                origin: 'karta',
                interacted: isSelected, // Might need to check onHover type for the line, poly and marker
                color: feature.properties.find(p => p.key == 'color').value,
                outlineColor: feature.properties.find(p => p.key == 'outlineColor').value,
                outlineVisibility: outlineVisibility,
                visibility: feature.visible && isFilteredIn,
                opacity: feature.opacity,
            }
        } as GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>
    }

    /**
    * Converts a **rasterImage** `Kartorium.Feature` to a `GeoJSON.Feature`.
    * @returns `GeoJSON.Feature`
    */
    private _createRasterImageGeoJson(feature: Feature): GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties> {
        const isSelected = this.selectedFeatureID == feature.id
        const isFilteredIn = this.filterService.isFilteredIn(feature)
        const coordinates: number[][] = JSON.parse(feature.coordinateString.value)

        coordinates.push(coordinates[0]) // Connects the last point to the first point

        const polygonGeometry = getPolygonGeometry(coordinates)

        return {
            'id': feature.id.toString(),
            'type': "Feature",
            'geometry': polygonGeometry,
            'properties': {
                type: 'rasterImage',
                origin: 'karta',
                opacity: 0,
                color: '#ffffff',
                visibility: feature.visible && isFilteredIn,
                outlineVisibility: false,
                interacted: isSelected,
            }
        } as GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>
    }

    private _setMarkerSourceData(markers: GeoJSON.Feature<GeoJSON.Point, MarkerGeoJsonProperties>[]) {
        const sourceID = 'pointSource'
        const prevMarkers = this.geoJsonSourceMap.get(sourceID) ?? []
        const prevImageNames = new Set<string>()

        // Collect previous image names
        prevMarkers.forEach(marker => prevImageNames.add(marker.properties.imageName))
        // Exclude image names from prevImageNames that are still in use
        markers.forEach(marker => prevImageNames.delete(marker.properties.imageName))

        // Remove unused images from the map's style
        prevImageNames.forEach(imageName => {
            if (imageName != null && this._map.hasImage(imageName)) {
                this._map.removeImage(imageName)
                this.markerImageMap.delete(imageName)
            }
        })

        this.geoJsonSourceMap.set(sourceID, markers)

        if (markers.length == 0 && this.styleLoaded) { // Delete source if there are no markers
            if (this._map.getLayer('pointLayer')) {
                this._map.removeLayer('pointLayer')
            }

            this._deleteSource(sourceID)
        } else { // Update source data or create source
            const data = { 'type': "FeatureCollection", 'features': markers } as GeoJSON.FeatureCollection
            const source = this._map.getSource(sourceID) as Mapbox.GeoJSONSource

            Promise.all(markers.map(marker => this._addImageForMarker(marker)))
                .then(() => {
                    if (source) {
                        source.setData(data)
                    } else {
                        const source = { type: 'geojson', data: data } as Mapbox.SourceSpecification

                        this._addSourceToMap(sourceID, source, 'marker')
                    }
                })
        }
    }

    private _addImageForMarker(marker: GeoJSON.Feature<GeoJSON.Point, MarkerGeoJsonProperties>): Promise<HTMLImageElement | ImageBitmap> {
        if (marker.properties.displayType == 'icon') {
            const options = {
                backgroundColor: marker.properties.backgroundColor,
                backgroundShape: marker.properties.backgroundShape,
                color: marker.properties.fontColor,
                icon: marker.properties.icon,
            } as TagOptions
            const imageName = "icon-" + simpleHash(JSON.stringify(options))

            if (!this._map.hasImage(imageName)) {
                return svgToCanvas(createIconSVGString(options))
                    .then(iconCanvas => iconCanvas.toDataURL())
                    .then(url => this._addImageToMap(url, imageName))
                    .catch(error => console.error(`Could not load Map image for ${marker.properties.featureName}`))
            }
        } else if (marker.properties.displayType == 'image') {
            if (marker.properties.fileReferenceID) {
                const fileReferenceID = marker.properties.fileReferenceID
                const imageName = marker.properties.hash

                if (!this._map.hasImage(imageName)) {
                    return this.authenticationService.getToken().toPromise().then(token => {
                        const projectID = this.projectService.currentProject.id
                        const url = this._fileReferenceService.getUrlForFileDownload(fileReferenceID, projectID, imageName, token)

                        return this._addImageToMap(url, imageName)
                            .catch(error => console.error(`Could not load Map icon for ${marker.properties.featureName}`))
                    })
                }
            } else {
                const imageName = 'defaultMarker'

                if (!this._map.hasImage(imageName)) {
                    const defaultMarkerURL = 'assets/BlueDot.png'

                    return this._addImageToMap(defaultMarkerURL, imageName)
                }
            }
        }

        return Promise.resolve(null)
    }

    private _findGeoJsonSource(featureID: string) {
        for (let src of this.geoJsonSourceMap.keys())
            if (this.geoJsonSourceMap.get(src).find(f => f.id == featureID))
                return src

        return undefined
    }

    private _setSourceData(geoJsonFeatures: GeoJSON.Feature[], sourceID: string, type?: FeatureTypes) {
        if (geoJsonFeatures.length == 0 && this.styleLoaded) {
            this._deleteSource(sourceID)
        } else {
            const data = { 'type': "FeatureCollection", 'features': geoJsonFeatures } as GeoJSON.FeatureCollection
            const source = this._map.getSource(sourceID) as Mapbox.GeoJSONSource

            this.geoJsonSourceMap.set(sourceID, geoJsonFeatures)

            if (source) {
                source.setData(data)
            } else {
                const source = { type: 'geojson', data: data } as Mapbox.SourceSpecification

                this._addSourceToMap(sourceID, source, type ?? sourceID)
            }
        }
    }

    private async _setRasterSourceData(rasterImages: Feature[], token?: any) {
        if (this.settingRaster) return
        this.settingRaster = true

        // 1) Sort incoming rasterImages
        const sortedImages = rasterImages.sort((a, b) => {
            const orderA = a.properties.find(prop => prop.key === "order")?.value
            const orderB = b.properties.find(prop => prop.key === "order")?.value
            return (orderA ?? 0) - (orderB ?? 0)
        })

        // 2) Build a Set of the new source IDs that *should* exist
        const newSourceIDs = new Set(
            sortedImages.map(img => `tileset-${img.id}`)
        )

        // 3) Get all existing tileset layers (and thus their source IDs)
        const existingTilesetLayers = this._map
            .getStyle()
            .layers.filter(layer => layer.id.includes('tileset-'))

        // 4) Remove layers and sources that are NOT in the incoming rasterImages
        for (const layer of existingTilesetLayers) {
            if (!newSourceIDs.has(layer.id)) {
                this._map.removeLayer(layer.id)
                this._map.removeSource(layer.id)
            }
        }

        // 5) For each Feature in sortedImages, add or update the source/layer
        for (const rasterImage of sortedImages) {
            const sourceID = `tileset-${rasterImage.id}`
            const existingSource = this._map.getSource(sourceID) as Mapbox.RasterTileSource

            // Construct the tile URL (different if public project or not)
            const url = this.projectService.currentProject.publicStatus
                ? `${environment.api}/public/feature/tileset/${rasterImage.id}/{z}/{x}/{y}`
                : `${environment.api}/feature/tileset/${rasterImage.id}/{z}/{x}/{y}?token=${token}`

            if (!existingSource) {
                // Source does not exist yet, so add it
                this._map.addSource(sourceID, {
                    type: 'raster',
                    tiles: [url],
                    tileSize: 256
                })

                // Keep track of the source in our Map
                this.rasterSourceMap.set(sourceID, rasterImage)

                // Add a new layer for this source
                const layer = {
                    id: sourceID,
                    type: 'raster',
                    source: sourceID,
                    paint: {
                        'raster-fade-duration': 0,
                        'raster-opacity': rasterImage.opacity
                    } as Mapbox.RasterLayerSpecification['paint'],
                    filter: ['==', 'visibility', true],
                    layout: {
                        'visibility': rasterImage.visible ? 'visible' : 'none'
                    }
                } as Mapbox.RasterLayerSpecification

                // Add the layer, placing it just above 'base-layer'
                this._map.addLayer(layer, 'base-layer')

            } else {
                // Source already exists. Optionally update any properties you need.
                // For example, to update opacity or visibility, you can do:
                this._map.setPaintProperty(sourceID, 'raster-opacity', rasterImage.opacity)
                this._map.setLayoutProperty(
                    sourceID,
                    'visibility',
                    rasterImage.visible ? 'visible' : 'none'
                )
            }
        }

        this.settingRaster = false
    }

    private _updateSourceData(sourceID: string, features: GeoJSON.Feature[]) {
        const mapSource = (this._map.getSource(sourceID) as Mapbox.GeoJSONSource)
        const srcData = {
            'type': "FeatureCollection",
            'features': features
        } as GeoJSON.FeatureCollection<GeoJSON.Geometry>

        if (mapSource) {
            mapSource.setData(srcData)
        } else {
            const source = { type: 'geojson', data: srcData, } as Mapbox.SourceSpecification

            this._map.addSource(sourceID, source)
        }
    }

    private _deleteSource(sourceID: string) {
        const removeFromMap = () => {
            if (this._map.getLayer(sourceID))
                this._map.removeLayer(sourceID)

            if (sourceID == 'polygon' && this._map.getLayer('outlinepolygon'))
                this._map.removeLayer('outlinepolygon')

            if (sourceID && this._map.getSource(sourceID))
                this._map.removeSource(sourceID)

            if (this.geoJsonSourceMap.has(sourceID))
                this.geoJsonSourceMap.delete(sourceID)

            if (this.rasterSourceMap.has(sourceID))
                this.rasterSourceMap.delete(sourceID)

            if (sourceID && this._map.hasImage(sourceID))
                this._map.removeImage(sourceID)
        }

        if (this.styleLoaded) {
            removeFromMap()
        } else {
            this._map.once('style.load', () => removeFromMap())
        }
    }

    private _addSourceToMap(sourceID: string, source: Mapbox.SourceSpecification, type: string) {
        if (this.styleLoaded) {
            this._map.addSource(sourceID, source)

            if (type == 'line') {
                this._createLineLayer()
            } else if (type == 'polygon') {
                this._createPolygonLayer()
            } else if (type == 'marker') {
                this._createSymbolLayer()
            }
        }
    }

    private _createSymbolLayer(extraFilters: any[] = []): Mapbox.SymbolLayer {
        let filters = ['==', 'visibility', true]

        // Combine the base filter with additional filters if provided
        if (extraFilters.length > 0) {
            filters = ['all', filters, ...extraFilters]
        }

        const overlap = !this.sceneService.selectedScene.overlap.value

        const layoutProperties = {
            'icon-allow-overlap': overlap,
            'icon-image': ['get', 'imageName'], // Use 'imageName' for point layers
            'icon-size': [
                'case',
                ['boolean', ['get', 'interacted'], false],
                ['case',
                    ['==', ['get', 'displayType'], 'image'],
                    ['*', 1.25, ['get', 'size']],
                    ['*', 10, ['get', 'size']]
                ],
                ['case',
                    ['==', ['get', 'displayType'], 'image'],
                    ['get', 'size'],
                    ['*', 8, ['get', 'size']]
                ]
            ],
            'text-allow-overlap': overlap,
            'text-anchor': 'center',
            'text-field': [
                'case',
                ['boolean', ['get', 'showText'], false], // Check the 'showText' property
                ['get', 'textContent'], // If true, use 'textContent'
                '' // If false, display nothing
            ],
            'text-letter-spacing': 0.1,
            'text-radial-offset': 2,
            'text-size': [
                'case',
                ['boolean', ['get', 'interacted'], false],
                ['*', 1.25, ['*', 100, ['get', 'size']]],
                ['*', 100, ['get', 'size']]
            ],
        } as Mapbox.SymbolLayerSpecification['layout']

        const paintProperties = {
            'icon-opacity': ['get', 'opacity'],
            'text-color': ['get', 'fontColor'],
            'text-halo-blur': 1,
            'text-halo-color': ['get', 'backgroundColor'],
            'text-halo-width': 1,
            'text-opacity': ['get', 'opacity'],
        } as Mapbox.SymbolLayerSpecification['paint']

        const layer = {
            'id': 'pointLayer',
            'source': 'pointSource', // reference the data source
            'filter': filters,
            'layout': layoutProperties,
            'paint': paintProperties,
            'type': 'symbol',
        } as Mapbox.SymbolLayerSpecification

        if (this._map.getLayer('pointLayer') == undefined) {
            this._map.addLayer(layer)
        }

        return layer
    }

    private _addImageToMap(url: string, imageName: string) {
        if (!this.markerImageMap.has(imageName)) {
            this.markerImageMap.set(imageName, url)

            return new Promise<HTMLImageElement | ImageBitmap | ImageData>((resolve, reject) => {
                this._map.loadImage(url, (error, image) => {
                    if (error) {
                        reject(error)
                    } else {
                        this._map.addImage(imageName, image)
                        resolve(image)
                    }
                })
            })
        } else {
            return Promise.resolve(null)
        }
    }

    private _createRasterImageLayer(sourceID: string) {
        const generateRasterLayer = (sourceID: string): Mapbox.RasterLayer => {
            const feature = this.rasterSourceMap.get(sourceID)

            return {
                'id': sourceID.toString(),
                'type': 'raster',
                'source': sourceID.toString(), // reference the data source
                'paint': {
                    'raster-fade-duration': 0,
                    "raster-opacity": feature.opacity,
                } as Mapbox.RasterLayerSpecification['paint'],
                'layout': {
                    'visibility': feature.visible ? 'visible' : 'none'
                } as Mapbox.RasterLayerSpecification['layout']
            } as Mapbox.RasterLayer
        }

        const layer = generateRasterLayer(sourceID)

        this._map.addLayer(layer, 'polygon')
    }

    private _createLineLayer() {
        const generateLineLayer = (sourceID: string): Mapbox.LineLayer => {
            return {
                'id': sourceID,
                'type': 'line',
                'source': sourceID, // reference the data source,
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round',
                } as Mapbox.LineLayout,
                'paint': {
                    'line-blur': 1,
                    'line-color': ['get', 'color'],
                    'line-offset': ['get', 'offset'],
                    'line-opacity': ['get', 'opacity'],
                    'line-width': ['case', ['boolean', ['get', 'interacted'], false], 10, ['get', 'width']],
                    'line-dasharray': ['case', ['boolean', ['get', 'dashed'], false], ['literal', [2, 2]], ['literal', [1, 0]]]
                } as Mapbox.LineLayerSpecification['paint'],
                'filter': ['==', 'visibility', true]
            } as Mapbox.LineLayerSpecification
        }

        let sourceID = 'line'
        let layer: Mapbox.LineLayerSpecification = generateLineLayer(sourceID)

        if (this._map.getLayer(sourceID) == null) {
            this._map.addLayer(layer)
        }
    }

    private _createPolygonLayer() {
        const generatePolygonLayer = (sourceID: string): Mapbox.FillLayer => {
            return {
                'id': sourceID,
                'type': 'fill',
                'source': sourceID, // reference the data source,
                'layout': {} as Mapbox.FillLayout,
                'paint': {
                    'fill-color': ['get', 'color'],
                    'fill-opacity': ['case', ['==', ['get', 'type'], 'rasterImage'], 0, ['case', ['boolean', ['get', 'interacted'], false], 0.9, ['get', 'opacity']]],
                } as Mapbox.FillPaint,
                'filter': ['==', 'visibility', true]
            } as Mapbox.FillLayer
        }

        /* Using a line layer here makes make it look nice */
        const generateOutlineLayer = (sourceID: string): Mapbox.LineLayer => {
            return {
                'id': 'outline' + sourceID,
                'type': 'line',
                'source': sourceID, // reference the data source,
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round',
                } as Mapbox.LineLayout,
                'paint': {
                    'line-blur': 1,
                    'line-color': ['get', 'outlineColor'],
                    'line-width': ['case', ['boolean', ['get', 'interacted'], false], 4, 3],
                } as Mapbox.LinePaint,
                /* only shows the outline if the polygon is visible && the outline is visible */
                'filter': ['==', ['all', ['boolean', ['get', 'visibility']], ['boolean', ['get', 'outlineVisibility']]], true],
            } as Mapbox.LineLayer
        }

        let sourceID = 'polygon'
        let polygonLayer: Mapbox.FillLayer = generatePolygonLayer(sourceID)
        let outlineLayer: Mapbox.LineLayer = generateOutlineLayer(sourceID)

        if (this._map.getLayer(sourceID) === undefined) this._map.addLayer(polygonLayer, '3d-models')
        if (this._map.getLayer('outline' + sourceID) === undefined) this._map.addLayer(outlineLayer, '3d-models')
    }

    public setInteracted(feature: Feature, interacted: boolean) {
        const featureID = feature.id.toString()

        if (feature.type == 'rasterImage') {
            const sourceID = feature.image.fileReference.hash

            this._map.setPaintProperty(sourceID, 'raster-contrast', interacted ? 0.5 : 0)
        } else {
            const sourceID = this._findGeoJsonSource(featureID)
            const geoJsonFeatures = this.geoJsonSourceMap.get(sourceID)
            const sourceFeature = geoJsonFeatures.find(data => data.id == featureID)

            sourceFeature.properties.interacted = interacted

            this._updateSourceData(sourceID, geoJsonFeatures)
        }
    }

    public dehighlightAll() {
        for (const sourceID of this.geoJsonSourceMap.keys()) {
            const sourceFeatures = this.geoJsonSourceMap.get(sourceID)

            sourceFeatures.forEach(feature => feature.properties.interacted = false)

            this._updateSourceData(sourceID, sourceFeatures)
        }
    }

    public isVisible(featureID: string) {
        const sourceID = this._findGeoJsonSource(featureID)

        return this.geoJsonSourceMap.get(sourceID).find(geoJSON => geoJSON.id == featureID).properties.visibility
    }

    /* Change visibility property of the feature rather than removing it from the source
    so we don't have to deal with the polygon outline layer as well */
    public hide(feature: Feature) {
        const featureID = feature.id.toString()

        if (feature.type == 'rasterImage') {
            const sourceID = `tileset-${feature.id}`

            this._map.setLayoutProperty(sourceID, 'visibility', 'none')
        } else {
            const sourceID = this._findGeoJsonSource(featureID)

            if (sourceID) {
                this.geoJsonSourceMap.get(sourceID).find(geoJSON => geoJSON.id == featureID).properties.visibility = false

                this._updateSourceData(sourceID, this.geoJsonSourceMap.get(sourceID))
            }
        }
    }

    /* Change visibility property of the feature rather than adding it back to the source
    so we don't have to deal with the polygon outline layer as well */
    public show(feature: Feature) {
        const featureID = feature.id.toString()

        if (feature.type == 'rasterImage') {
            const sourceID = `tileset-${feature.id}`

            this._map.setLayoutProperty(sourceID, 'visibility', 'visible')
        } else {
            const sourceID = this._findGeoJsonSource(featureID)

            if (sourceID) {
                this.geoJsonSourceMap.get(sourceID).find(geoJSON => geoJSON.id == featureID).properties.visibility = true

                this._updateSourceData(sourceID, this.geoJsonSourceMap.get(sourceID))
            }
        }
    }

    /* Change visibility property of the feature rather than adding it back to/removing it from the source
    so we don't have to deal with the polygon outline layer as well */
    public toggleVisibility(feature: Feature) {
        const featureID = feature.id.toString()

        if (feature.type == 'rasterImage') {
            const sourceID = `tileset-${feature.id}`

            this._map.setLayoutProperty(sourceID, 'visibility', feature.visible ? 'none' : 'visible')
        }

        const sourceID = this._findGeoJsonSource(featureID)

        if (sourceID) {
            const geoJSON = this.geoJsonSourceMap.get(sourceID).find(geoJSON => geoJSON.id == featureID)

            geoJSON.properties.visibility = !geoJSON.properties.visibility

            this._updateSourceData(sourceID, this.geoJsonSourceMap.get(sourceID))
        }
    }
}
