<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="TitleLabel" style="margin-bottom: 0">
          {{ title }}
        </h2>
        <a class="btn btn-close" aria-label="Close" (click)="cancel()"></a>
      </div>
      <form [formGroup]="form" class="modal-body d-grid gap-2">
        <!-- Upload Progress View -->
        <div *ngIf="submitting" class="d-grid gap-2">
          <h4>Upload Progress</h4>
          <div class="list-group">
            <!-- Iterate through all scenes and their files to show upload status -->
            <ng-container *ngFor="let scene of scenes.controls; let sceneIndex = index">
              <div *ngFor="let fileGroup of getSceneFiles(sceneIndex)?.controls"
                class="list-group-item d-flex justify-content-between align-items-center">
                <span *ngIf="fileGroup" class="d-flex justify-content-between align-items-center w-100">
                  <div class="d-flex align-items-center">
                    <!-- Progress Icons -->
                    <span class="text-center me-2">
                      <i *ngIf="fileGroup.get('status').value === 'uploading'"
                        class="fa spinner-border spinner-border-sm text-primary" role="status"></i>
                      <i *ngIf="fileGroup.get('status').value === 'success'" class="fas fa-check text-success"></i>
                      <i *ngIf="fileGroup.get('status').value === 'pending'" class="fas fa-cloud-upload"></i>
                      <i *ngIf="fileGroup.get('status').value === 'error'" class="fas fa-times text-danger"></i>
                    </span>
                    <!-- File Type Icon -->
                    <span class="text-center me-2">
                      <i [class]="
                          getFileIconClass(fileGroup.get('file').value.name)
                        " style="width: 20px"></i>
                    </span>
                    {{ scene.get("title").value }}
                  </div>
                  <span class="text-muted ms-auto">
                    {{ fileGroup.get("file").value.name }}
                  </span>
                </span>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Show file upload form when not submitting -->
        <div *ngIf="!submitting" class="d-grid gap-2">
          <!-- File Upload Zone -->
          <div class="upload-zone" [class.dragover]="isDragOver" (dragenter)="onDragEnter($event)"
            (dragleave)="onDragLeave($event)" (dragover)="onDragOver($event)" (drop)="onDrop($event)"
            (click)="fileInput.click()">
            <input #fileInput type="file" multiple (change)="onFileChange($event, 0)" [accept]="allFileTypes.join(',')"
              style="display: none" />
            <i class="fas fa-cloud-upload-alt"></i>
            <p>Drag and drop files here or click to select files</p>
            <p class="file-types">
              Accepted file types: {{ allFileTypes.join(", ") }}
            </p>
          </div>
          <!-- Project Title Input -->
          <div>
            <label for="projectTitle" class="form-label">Project Title</label>
            <input type="text" id="projectTitle" formControlName="projectTitle" class="form-control" [maxlength]="50"
              [class.is-invalid]="projectTitle.invalid && projectTitle.touched" [readonly]="submitting" />
            <div class="invalid-feedback" *ngIf="projectTitle.invalid">
              Project title is required.
            </div>
          </div>
          <!-- Scenes Section -->
          <div formArrayName="scenes" *ngIf="scenes.length > 0">
            <label class="form-label">Scenes</label>

            <div *ngFor="let scene of scenes.controls; let sceneIndex = index" [formGroupName]="sceneIndex"
              class="border rounded p-3 mb-3">
              <!-- Scene Title and Type Row -->
              <div class="col-12">
                <div class="d-flex justify-content-between align-items-center">
                  <label [for]="'sceneTitle' + sceneIndex" class="form-label mb-0">
                    <span *ngIf="scene.get('type').value == 'virtualTour'" class="modal-title">
                      Virtual Tour
                    </span>
                    <span *ngIf="scene.get('type').value == 'map'"> Map </span>
                    <span *ngIf="scene.get('type').value == 'standard'">
                      Standard
                    </span>
                    Scene Title
                  </label>
                  <button type="button" class="btn btn-link text-dark p-0" (click)="removeScene(sceneIndex)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <!-- Scene Title and File Name -->
                <div class="d-flex align-items-center mt-2">
                  <div class="col-8 pe-2">
                    <input type="text" [id]="'sceneTitle' + sceneIndex" formControlName="title" class="form-control"
                      [class.is-invalid]="
                        scene.get('title').invalid && scene.get('title').touched
                      " />
                    <div class="invalid-feedback" *ngIf="scene.get('title').invalid">
                      Scene title is required.
                    </div>
                  </div>
                  <div class="col-4 text-end">
                    <span *ngIf="getSceneFiles(sceneIndex)?.controls[0]">
                      {{
                      getSceneFiles(sceneIndex).controls[0].get("file").value
                      .name
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button type="button" class="btn" [ngClass]="{
            'btn-primary': submitColor == 'blue' || submitColor == null,
            'btn-success': submitColor == 'green',
            'btn-danger': submitColor == 'red',
            'btn-warning': submitColor == 'yellow'
          }" (click)="submit()" *ngIf="!submitting" [disabled]="scenes?.length == 0">
          {{ submitText }}
        </button>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
          <span> Close </span>
        </button>
      </div>
    </div>
  </div>
</div>