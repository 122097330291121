import { PointCloudOctree, Potree } from 'potree-core'

import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class PointCloudService {
  public pointClouds: PointCloudOctree[] = []
  public potreeInstance: any = new Potree()

  constructor() {
    this.potreeInstance.pointBudget = 10_000_000
  }

  addPointCloud(pointCloudOctree: PointCloudOctree) {
    this.pointClouds.push(pointCloudOctree)
  }

  setPointBudget(isMobile: boolean) {
    if (isMobile) {
      this.potreeInstance.pointBudget = 1_000_000 // Adjust as needed
    } else {
      this.potreeInstance.pointBudget = 10_000_000 // Adjust as needed
    }
  }
}