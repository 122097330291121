<div class="scroll-container p-2">
    <div *ngIf="projects?.['error'] != null" class="pt-4 px-3 text-center text-muted lead">
        No Projects at this time.<br>Check back later!
    </div>

    <div class="accordion" id="projectAccordion">
        <div class="accordion-item" *ngFor="let project of projects; let i = index">
            <h2 class="accordion-header" id="heading{{ i }}">
                <button class="accordion-button" type="button" (click)="setIndex(i)" [class.collapsed]="openIndex !== i"
                    [attr.aria-expanded]="openIndex === i">
                    {{ project['Project Name'] || 'Untitled Project' }}
                </button>
            </h2>
            <div [id]="'collapse' + i" class="accordion-collapse collapse" [class.show]="openIndex === i"
                [attr.aria-labelledby]="'heading' + i" data-bs-parent="#projectAccordion">
                <div class="accordion-body">
                    <p>
                        <strong>Name:</strong>
                        {{ project['Project Name'] || 'N/A' }}
                    </p>
                    <p>
                        <strong>Estimated Completion Date:</strong>
                        {{
                        project['Estimated Completion Date'] ?
                        (project['Estimated Completion Date'] | date) :
                        'N/A'
                        }}
                    </p>
                    <p>
                        <strong>Current Status:</strong>
                        {{ project.Status || 'N/A' }}
                    </p>
                    <p>
                        <strong>Description:</strong>
                        {{ project['Project Description/Use Case'] || 'N/A' }}
                    </p>
                    <p>
                        <strong>Location:</strong>
                        {{ project.Location || 'N/A' }}
                    </p>
                    <p title="Size and capacity details of the project">
                        <strong>Project Size:</strong>
                        {{ project['Project Size'] != null ? project['Project Size'] : 'N/A' }}
                    </p>

                    <p><strong>Financials:</strong></p>
                    <ul>
                        <li>
                            <strong>Capital Expenditure:</strong>
                            {{ project.CapEx != null ? (project.CapEx | currency) : 'N/A' }}
                        </li>
                        <li>
                            <strong>Lifetime Savings:</strong>
                            {{ project['Lifetime Savings'] != null ? (project['Lifetime Savings'] | currency) : 'N/A' }}
                        </li>
                        <li>
                            <strong>Net Present Value:</strong>
                            {{ project.NPV != null ? (project.NPV | currency) : 'N/A' }}
                        </li>
                        <li>
                            <strong>Internal Rate Of Return:</strong>
                            {{ project.IRR != null ? (project.IRR | percent) : 'N/A' }}
                        </li>
                        <li title="Payback in years">
                            <strong>Estimated Payback Period:</strong>
                            {{
                            project['Estimated Payback Period'] != null ?
                            project['Estimated Payback Period'] + ' year(s)' :
                            'N/A'
                            }}
                        </li>
                    </ul>

                    <p><strong>Environmental Impact:</strong></p>
                    <ul>
                        <li title="Gallons per year">
                            <strong>Annual Fuel Reduction:</strong>
                            {{
                            project['Annual Fuel Reduction'] != null ?
                            project['Annual Fuel Reduction'] + ' gal/year' :
                            'N/A'
                            }}
                        </li>
                        <li title="CO₂ reduced per year">
                            <strong>Annual Emissions Reduction:</strong>
                            {{
                            project['Annual Emissions Reduction'] != null ?
                            project['Annual Emissions Reduction'] + ' mt/year' :
                            'N/A'
                            }}
                        </li>
                        <li title="Annual percentage reduction">
                            <strong>Annual Emissions Reduction:</strong>
                            {{
                            project['Annual Emissions Reduction (%)'] != null ?
                            (project['Annual Emissions Reduction (%)'] | percent) :
                            'N/A'
                            }}
                        </li>
                    </ul>

                    <p><strong>Technologies:</strong></p>
                    <ul>
                        <li *ngFor="let tech of project.Technologies">
                            {{ tech.name || 'Unknown' }} ({{ tech.size != null ? tech.size : 'N/A' }} {{ tech.unit || ''
                            }})
                        </li>
                    </ul>

                    <p><strong>Custom Technologies:</strong></p>
                    <ul>
                        <li *ngFor="let customTech of project['Custom Technologies']">
                            {{ customTech.description || 'N/A' }}
                        </li>
                    </ul>

                    <p><strong>Feedback:</strong></p>
                    <div class="d-flex align-items-center justify-content-center">
                        <!-- Upvotes Section -->
                        <div class="d-flex align-items-center mx-2">
                            <span>{{ project['Sum of Upvotes'] != null ? project['Sum of Upvotes'] : 0 }}</span>
                            <i class="fa fa-thumbs-up mx-1 text-success"></i>
                        </div>
                        <!-- Downvotes Section -->
                        <div class="d-flex align-items-center mx-2">
                            <i class="fa fa-thumbs-down mx-1 text-danger"></i>
                            <span>{{ project['Sum of Downvotes'] != null ? project['Sum of Downvotes'] : 0 }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>