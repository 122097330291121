import { Offcanvas } from 'bootstrap'
import { environment } from 'src/environments/environment'
import { Stripe } from 'stripe'

import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CreateOrganizationComponent } from '@billing/create-organization/create-organization.component'
import { SelectTierComponent } from '@billing/select-tier/select-tier.component'
import { AuthenticationService } from '@services/authentication.service'
import { SubscriptionService } from '@services/subscription.service'
import { ToastService } from '@services/toast.service'
import { loadStripe, Stripe as StripeJS } from '@stripe/stripe-js'

type UpgradePhase = 'collectingInformation' | 'submitting' | 'success'

/** Provides methods and properties to manage the upgrade flow of users' tier. */
@Component({
  selector: 'billing-upgrade-tier',
  templateUrl: './upgrade-tier.component.html',
  styleUrls: ['./upgrade-tier.component.css']
})
export class UpgradeTierComponent implements AfterViewInit, OnDestroy {
  @ViewChild('createOrg') createOrganization: CreateOrganizationComponent
  @ViewChild('selectTier') selectTier: SelectTierComponent

  public checkoutSessionID: any
  public currentPhase: UpgradePhase = 'collectingInformation'
  public invoice: Stripe.Invoice
  public organization: string
  public proProductPackage: Stripe.Price[] = []
  public stripeJS: StripeJS

  get interval() { return this.selectTier?.interval }
  get isValid() { return this.createOrganization?.organizationForm?.valid }
  get orgID() { return this.createOrganization.orgID.value as string }
  get orgName() { return this.createOrganization.orgName.value as string }
  get priceMultiplier() { return this.selectTier?.interval == 'year' ? 1 : .1 }
  get rightPanel() { return new Offcanvas(document.getElementById('right-panel')) }
  get submitting() { return this.currentPhase == 'submitting' }
  get totalAmount() {
    return this.proProductPackage.reduce((total, price) =>
      total + (price.unit_amount * (price['quantity'] ?? 0) * this.priceMultiplier / 100), 0
    )
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _subscriptionService: SubscriptionService,
    private _toastService: ToastService,
  ) {
    this._subscriptionService.getProductPackage('pro', 'year').subscribe(
      proProductPackage => this.proProductPackage = proProductPackage
    )
  }

  ngAfterViewInit() {
    this._activatedRoute.queryParams.subscribe(queryParams => {
      const orgID = queryParams['org_id']
      const interval = queryParams['interval']
      const orgName = queryParams['org_name']
      const sessionId = queryParams['session_id']

      this.createOrganization.orgID.setValue(orgID ?? '')
      this.createOrganization.orgName.setValue(orgName ?? '')

      if (interval) {
        this.selectTier.interval = interval
      }

      if (sessionId) {
        this.checkoutSessionID = sessionId

        if (this.isValid) {
          this.openCheckoutPanel()
        }
      }
    })
  }

  /** Validates the organization's information and then opens the checkout panel. */
  async validateOrganizationInfo() {
    if (await this.createOrganization.testValidity()) {
      if (this.isValid) {
        if (this.checkoutSessionID) {
          this.openCheckoutPanel()
        } else {
          this.redirectToPaymentInfo()
        }
      }
    }
  }

  /** Redirects the user to the payment checkout session page. */
  redirectToPaymentInfo() {
    const params = {
      cancel_route: 'projects',
      success_route: 'upgrade',
      success_params: [
        { 'interval': this.interval },
        { 'org_id': this.createOrganization.orgID.value },
        { 'org_name': this.createOrganization.orgName.value }
      ]
    }

    return Promise.all([
      loadStripe(environment.stripePublishableKey),
      this._subscriptionService.createCheckoutSession(params).toPromise()
    ]).then(([stripeJS, session]) => {
      this.stripeJS = stripeJS
      this.stripeJS.redirectToCheckout({ sessionId: session.id })
    })
  }

  /** Opens the right panel to show what upgrade items you are purchasing */
  openCheckoutPanel() {
    this.rightPanel.show()
  }

  /** Finalizes the upgrade from the free to pro tier, sending the information to the backend. */
  completeProTierCheckout() {
    if (this.createOrganization.orgID.valid && !this.submitting) {
      this.currentPhase = 'submitting'

      const interval = this.selectTier.interval

      this._subscriptionService.upgradeToProTier(this.checkoutSessionID, interval, { orgName: this.orgName, orgID: this.orgID })
        .subscribe(
          response => {
            this.currentPhase = 'success'
            this.invoice = response.invoice
            this.organization = response.organization
          },
          error => {
            this.currentPhase = 'collectingInformation'
            this.invoice = error.invoice
            this.organization = error.organization
            this._toastService.toast({ color: 'red', title: 'Upgrade Error', message: error, autohide: false })
          }
        )
    }
  }

  /** Method to navigate the user back to the dashboard */
  returnToDashboard() {
    this._router.navigate(['projects'])
  }

  /** Logs user into their newly created organization */
  loginToOrganization() {
    sessionStorage.setItem('redirectDashboard', 'true')
    this._authenticationService.loginWithRedirect({ authorizationParams: { organization: this.organization } })
  }

  ngOnDestroy(): void {
    this.loginToOrganization()
  }
}