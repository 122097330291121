<ul class="list-group" style='cursor: pointer; user-select: none;'>
    <li *ngIf="sceneService.selectedScene.type != '360 Image'" (click)="focusOn()"
        class="list-group-item list-group-item-action">
        Focus on Features
    </li>
    <li (click)="group()" class="list-group-item list-group-item-action">
        Group Features
    </li>
    <li (click)="copyFeatures()"
        class="list-group-item list-group-item-action">
        Copy Features
    </li>
    <li (click)="duplicateFeatures()"
        class="list-group-item list-group-item-action">
        Duplicate Features
    </li>
    <li (click)="deleteFeatures()" class="list-group-item list-group-item-action list-group-item-danger">
        Delete Features
    </li>
</ul>