import * as Mapbox from 'mapbox-gl'

import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { styles } from '@classes/MapSpace'
import { Scene } from '@classes/Scene'
import { SceneProperty } from '@classes/SceneProperty'
import { SceneService } from '@services/scene.service'
import { CreateSceneBody } from '@shared/create-scene/create-scene.component'

@Component({
  selector: 'shared-create-map-scene',
  templateUrl: './create-map-scene.component.html',
  styleUrls: ['./create-map-scene.component.css']
})
export class CreateMapSceneComponent implements CreateSceneBody {
  public defaultBearing = 0
  public defaultCenter = new Mapbox.LngLat(-94.22473927523083, 37.68426163908313)
  public defaultPitch = 0
  public defaultZoom = 3
  public form: FormGroup = new FormGroup({
    bearing: new FormControl<number>(this.defaultBearing, [Validators.min(-360), Validators.max(360), Validators.required]),
    latitude: new FormControl<number>(this.defaultCenter.lat, [Validators.min(-90), Validators.max(90), Validators.required]),
    longitude: new FormControl<number>(this.defaultCenter.lng, [Validators.min(-180), Validators.max(180), Validators.required]),
    name: new FormControl<string>('New Map Scene', [Validators.required]),
    pitch: new FormControl<number>(this.defaultZoom, [Validators.min(0), Validators.max(85), Validators.required]),
    style: new FormControl<string>('mapbox://styles/mapbox/satellite-streets-v11'),
    terrain: new FormControl<boolean>(true),
    zoom: new FormControl<number>(this.defaultZoom, [Validators.min(0), Validators.max(22)]),
    bounds: new FormControl<string>('')
  })
  public mapStyles = styles

  get bearing() { return this.form.get('bearing') as FormControl<number> }
  get latitude() { return this.form.get('latitude') as FormControl<number> }
  get longitude() { return this.form.get('longitude') as FormControl<number> }
  get name() { return this.form.get('name') as FormControl<string> }
  get pitch() { return this.form.get('pitch') as FormControl<number> }
  get style() { return this.form.get('style') as FormControl<string> }
  get terrain() { return this.form.get('terrain') as FormControl<boolean> }
  get zoom() { return this.form.get('zoom') as FormControl<number> }
  get bounds() { return this.form.get('bounds') as FormControl<string> }

  constructor(private _sceneService: SceneService) { }

  createScene$(projectID: number) {
    const scene = this.getScene(projectID)

    return this._sceneService.createMapScene$(scene)
  }

  getScene(projectID?: number) {
    const properties = [
      new SceneProperty(null, "boolean", "fullscreenBtn", false),
      new SceneProperty(null, "boolean", "overlap", false),
      new SceneProperty(null, "boolean", "resetBtn", false),
      new SceneProperty(null, "boolean", "shareBtn", false),
      new SceneProperty(null, "boolean", "terrain", this.terrain.value),
      new SceneProperty(null, "decimal", "bearing", this.bearing.value),
      new SceneProperty(null, "decimal", "latitude", this.latitude.value),
      new SceneProperty(null, "decimal", "longitude", this.longitude.value),
      new SceneProperty(null, "decimal", "pitch", this.pitch.value),
      new SceneProperty(null, "decimal", "zoom", this.zoom.value),
      new SceneProperty(null, "string", "bounds", this.bounds.value),
      new SceneProperty(null, "string", "style", this.style.value),
      new SceneProperty(null, "string", "timeOfDay", "morning"),
    ]

    return new Scene(projectID, this.name.value, 'Map', { properties })
  }

  setPosition(mapData: { bearing: number, center: Mapbox.LngLat, pitch: number, zoom: number, bounds: Mapbox.LngLatBounds }) {
    this.bearing.setValue(mapData.bearing)
    this.latitude.setValue(mapData.center['lat'])
    this.longitude.setValue(mapData.center['lng'])
    this.pitch.setValue(mapData.pitch)
    this.zoom.setValue(mapData.zoom)

    const boundingBox = {
      northEast: mapData.bounds.getNorthEast().toArray(),
      southWest: mapData.bounds.getSouthWest().toArray(),
    }
    let stringify = JSON.stringify(boundingBox)
    this.bounds.setValue(stringify)
  }
}