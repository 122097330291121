import { Component, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'shared-file-dropzone',  // Changed from app-file-dropzone to shared-file-dropzone
  templateUrl: './file-dropzone.component.html',
  styleUrls: ['./file-dropzone.component.css'],
})
export class FileDropzoneComponent {
  @Input() acceptedFileTypes?: string;  // Changed to string since you're passing a comma-separated string
  @Output() filesSelected = new EventEmitter<File[]>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  isDragOver = false;

  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    const files = event.dataTransfer?.files;
    if (files) {
      this.handleFiles(Array.from(files));
    }
  }

  onFileChange(event: Event): void {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    if (files) {
      this.handleFiles(Array.from(files));
    }
  }

  private handleFiles(files: File[]): void {
    let filesToEmit = files;
    
    if (this.acceptedFileTypes) {
      const allowedTypes = this.acceptedFileTypes.split(',');
      filesToEmit = files.filter(file => {
        const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
        return allowedTypes.includes(fileExtension);
      });
    }

    if (filesToEmit.length > 0) {
      this.filesSelected.emit(filesToEmit);
    }

    // Reset the input
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }
}