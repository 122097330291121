import * as Mapbox from 'mapbox-gl'
import { EMPTY, forkJoin, Observable, of, ReplaySubject, Subscription } from 'rxjs'
import { filter, map, switchMap, takeUntil } from 'rxjs/operators'

import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Feature } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { Interaction } from '@classes/Interaction'
import { CreateInteractionComponent } from '@modal/create-interaction/create-interaction.component'
import { EditDetailsComponent } from '@modal/edit-details/edit-details.component'
import { CommandService } from '@services/command.service'
import { CoordinatesClipboard, CopyService } from '@services/copy.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { FileReferenceService } from '@services/file-reference.service'
import { InteractionService } from '@services/interaction.service'
import { ModalService } from '@services/modal.service'
import { ModelService } from '@services/model.service'
import { ProjectService } from '@services/project.service'
import { SceneService } from '@services/scene.service'
import { TooltipService } from '@services/tooltip.service'
import { UndoService } from '@services/undo.service'
import { SpatialAnnotationService } from '@services/spatial-annotation.service'


export type UpdateOptions = {
  toast?: boolean
  undoable?: boolean
}

@Component({
  selector: 'shared-edit-feature',
  templateUrl: './edit-feature.component.html',
  styleUrls: ['./edit-feature.component.css']
})
export class EditFeatureComponent implements OnDestroy, AfterViewChecked, AfterViewInit {
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>
  @ViewChild('fileInput') fileInput: ElementRef

  private _subscriptions: Subscription[] = []
  public axes: string[] = ['x', 'y', 'z']
  public clipboardCoordinates: [number, number][] = []
  public edited: any
  public form: UntypedFormGroup
  public selectedFeature$ = this.featureService.selectedFeature$.pipe(
    filter(feature => feature != null),
    map(feature => new Feature(feature.sceneID, feature.name, feature.type, feature)),
  )
  public showVector: Object = { position: "", rotation: "", scale: "" }
  public tab: string = "Details"
  public vectors: string[] = ['position', 'rotation', 'scale']
  public tooltips = {
    correctSeam: "Corrects any image stitching issues by overlapping the image a small amount",
  }
  private _destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>()
  public selectedFeaturesInteractions$: Observable<Interaction[]> = this.featureService.selectedFeature$.pipe(
    takeUntil(this._destroyed$),
    switchMap(feat => {
      if (!feat || !feat.interactions) return of([])
      return of(feat.interactions)
    })
  )
  public selectedFeature: Feature
  public unloaded: boolean
  public backdrop: boolean = false

  get type(): string { return this.form.get('type').value }
  get properties() { return this.form.get('properties') as UntypedFormArray }
  get position() { return this.form.get('position') as UntypedFormArray }
  get rotation() { return this.form.get('rotation') as UntypedFormArray }
  get scale() { return this.form.get('scale') as UntypedFormArray }
  get attachments() { return this.properties.controls.filter(c => c.get('key').value == "attachment") }
  get map() { return this.envManager.map }
  get isBackdrop() { return this.selectedFeature?.isBackdrop }
  get isPointCloud() { return this.selectedFeature?.model?.files[0].label == "point-cloud" }
  get isMap() { return this.sceneService.selectedScene.type == 'Map' }
  get correctSeam() { return this.specificPropertyGroup('correctSeam') }
  get coordinateString() { return this.specificPropertyGroup('coordinateString') }
  get transformControls() { return this.envManager.sceneManager.transformControls }

  public specificPropertyGroup(key: string) { return this.properties.controls.find(c => c.get("key").value == key) as UntypedFormGroup }
  /* For badge displaying opacity slider value */
  public isFocused(elementID: string): boolean { return document.activeElement.id == elementID }

  public updateFeature(feature: Feature, options: Partial<UpdateOptions> = {}) {
    options.undoable = options?.undoable ?? true
    options.toast = options?.toast ?? true

    if (options?.undoable) {
      this._commandService.update.feature(feature).subscribe()
    } else {
      this.featureService.updateFeature(feature, { toast: options?.toast }).subscribe()
    }
  }

  public updateProperty(property: FeatureProperty, options: Partial<UpdateOptions> = {}) {
    options.undoable = options?.undoable ?? true
    options.toast = options?.toast ?? true

    if(property.key == "showOnTop") this.envManager.sceneManager.setShowOnTop(property.featureID, property.value)
    
    this.featureService.updateFeatureProperty(property, options).subscribe()
  }

  public updateColor(property: FeatureProperty, color: Event) {
    property.value = color
    this.featureService.updateFeatureProperty(property).subscribe()
  }

  /** When updating a Feature's `interactable` property, set interact effects to nothing */
  public updateInteractability(feature: Feature) {
    feature.onClick = 'nothing'
    feature.onHover = 'nothing'

    this.updateFeature(feature)
  }

  /**
   * Used when updating a Feature's `onHover` or `onClick` effect.
   * Sets the value based on the Feature's type. 
   */
  public updateOnInteract(feature: Feature, key: 'onClick' | 'onHover', checked: boolean) {
    if (checked == false) {
      feature[key] = 'nothing'
    } else if (feature.type == 'polygon') {
      feature[key] = 'change opacity'
    } else if (feature.type == 'line' || this.isPointCloud) {
      feature[key] = 'enlarge'
    } else {
      feature[key] = 'outline'
    }

    this.updateFeature(feature)
  }

  trackByKey(property: any): string {
    return property.key
  }

  constructor(
    private _commandService: CommandService,
    private _formBuilder: UntypedFormBuilder,
    private _copyService: CopyService,
    public modelService: ModelService,
    public copyService: CopyService,
    public envManager: EnvironmentManagerService,
    public featureService: FeatureService,
    public fileReferenceService: FileReferenceService,
    public interactionService: InteractionService,
    public modalService: ModalService,
    public projectService: ProjectService,
    public sceneService: SceneService,
    public tooltipService: TooltipService,
    public undoService: UndoService,
    private _annotationService: SpatialAnnotationService,
  ) {
    this.form = this._formBuilder.group({
      id: [''],
      sceneID: [''],
      modelID: [''],
      model: [''],
      name: ['', Validators.required],
      type: [''],
      description: [''],
      position: this.getVectorForm(),
      rotation: this.getVectorForm(),
      scale: this.getVectorForm(),
      objectOfInterest: [''],
      visible: [''],
      opacity: [1],
      onHover: [''],
      filterable: [''],
      interactable: [''],
      lastChanged: [''],
      properties: this._formBuilder.array([])
    })

    this._subscriptions.push(
      this.featureService.selectedFeature$.subscribe((feature: Feature) => {
        if (!feature) return

        let topLevelAttributes = {
          id: feature.id,
          sceneID: feature.sceneID,
          modelID: feature.modelID,
          model: feature.model,
          name: feature.name,
          type: feature.type,
          description: feature.description,
          objectOfInterest: feature.objectOfInterest,
          onHover: feature.onHover,
          visible: feature.visible,
          opacity: feature.opacity,
          filterable: feature.filterable,
          interactable: feature.interactable,
          lastChanged: new Date(feature.lastChanged).toDateString()
        }

        this.form.patchValue(topLevelAttributes)
        this.form.get('position').setValue(feature.position)
        this.form.get('rotation').setValue(feature.rotation)
        this.form.get('scale').setValue(feature.scale)
        this.form.setControl('properties', this._formBuilder.array([]))
        feature.properties.forEach(property => {
          let propertyForm = this._formBuilder.group({
            id: [],
            featureID: [],
            fileReferenceID: [],
            type: [],
            key: [],
            value: []
          })

          propertyForm.patchValue(property)

          if (property.fileReferenceID) {
            propertyForm.addControl("fileReference", this.getFileReferenceForm())
            propertyForm.get("fileReference").patchValue(property.fileReference)
          }
          this.properties.push(propertyForm)
        })
        this.unloaded = feature.unloaded
        this.selectedFeature = feature
      })
    )
  }

  ngAfterViewInit(): void {
    this.tooltipService.intializeTooltips()
  }

  ngAfterViewChecked(): void {
    if (!this.selectedFeature) return

    if (this.selectedFeature.type == 'group') {
      const children = this.featureService.getDescendants(this.selectedFeature).filter(f => f.type != 'group')
      const keys = ['onHover', 'filterable', 'interactable', 'objectOfInterest', 'visible']

      keys.forEach(key => {
        if (children.every(f => f[key])) this.setChecked(key)
        else if (children.every(f => !f[key])) this.setUnchecked(key)
        else this.setIndeterminate(key)
      })
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  getVectorForm() {
    return this._formBuilder.array([
      this._formBuilder.control(''), // x
      this._formBuilder.control(''), // y
      this._formBuilder.control('')  // z
    ])
  }

  getFileReferenceForm() {
    return this._formBuilder.group({
      id: [],
      modelID: [],
      filename: [],
      hash: [],
      extension: []
    })
  }

  toggleVectorFocus(element, key: 'position' | 'rotation' | 'scale') {
    this.showVector[key] = this.showVector[key] == "" ? element.id : ""
  }

  toggleEdit(key: string, group: UntypedFormGroup | UntypedFormArray, isProperty: boolean = true) {
    // Check to see if there is an edited value, if not, save it for later
    if (!this.edited) {
      if (['objectOfInterest', 'visible', 'interactable', 'onHover'].includes(key)) {
        const selectedFeature = this.featureService.selectedFeature

        for (const key in group.value) selectedFeature[key] = group.value[key]

        const feature = new Feature(
          group.value.sceneID,
          group.value.name,
          group.value.type,
          selectedFeature
        )

        this._commandService.update.feature(feature).pipe(switchMap(() => {
          if (key == 'visible') {
            let scene = this.sceneService.selectedScene
            scene.updateThumbnail = true
            return this.sceneService.updateScene(scene)
          }
          return EMPTY
        })).subscribe()
      } else this.edited = group.value

    } else { // This means you are clicking out of the box and want to update values

      // Check if input is valid, if so, do it
      if (group.get(key).valid) {
        if (this.vectors.includes(key)) {
          for (let axis of (group.get(key) as UntypedFormArray).controls) {
            if (axis.value == "" || !axis.value) {
              axis.setValue(0)
            }
          }
        }
        const selectedFeature = this.featureService.selectedFeature

        if (!isProperty) for (const key in group.value) selectedFeature[key] = group.value[key]

        const feature = new Feature(
          group.value.sceneID,
          group.value.name,
          group.value.type,
          selectedFeature
        )

        const subscription: Observable<any | FeatureProperty> = isProperty ? this.featureService.updateFeatureProperty(group.value) : this._commandService.update.feature(feature)
        subscription.pipe(
          switchMap(() => {
            // If turning off trackCamera or scaleWithCamera, put it back to starting orientation
            if (isProperty && ["trackCamera", "scaleWithCamera"].includes(group.get("key").value) && group.get("value").value == "false") {
              const feature = this.selectedFeature
              const group = this.envManager.sceneManager.getFeatureGroup(feature.id)
              this.transformControls.detach()
              this.envManager.sceneManager.updateOrientation(group, feature)
              this.transformControls.attach(group)
            }

            this.edited = null

            if (this.vectors.includes(key)) {
              let scene = this.sceneService.selectedScene
              scene.updateThumbnail = true
              return this.sceneService.updateScene(scene)
            }
            return EMPTY
          })
        ).subscribe()
      } else { // This means the input was invalid and needs to be filled with what was there before
        group.get(key).setValue(this.edited)
        this.edited = null
      }
    }
  }

  /**
   * Updates a single attribute of the Selected Feature.
   * @param form the form that was edited
   * @param key the attribute of the Feature to update
   * @param undoable whether or not the update appears on the undo stack. Default `true`
   */
  updateAttribute(form: UntypedFormControl, key: string, undoable: boolean = true) {
    const feature = this.featureService.selectedFeature

    feature[key] = form.value

    if (undoable) this._commandService.update.feature(feature).subscribe()
    else this.featureService.updateFeature(feature).subscribe()
  }

  toggleBooleanProperty(key: string) {
    this.edited = this.specificPropertyGroup(key).get("value").value
    this.specificPropertyGroup(key).get('value').setValue(this.edited == "true" ? "false" : "true")
    this.toggleEdit('value', this.specificPropertyGroup(key))
  }

  editVector(event, key: string) {
    this.edited = event.vector
    this.toggleEdit(key, this.form, false)
  }

  editCoordinateString(coordinateString: string) {
    this.coordinateString.get('value').setValue(coordinateString)
    this.featureService.updateFeatureProperty(this.coordinateString.value).subscribe()
  }

  /**
   * @param key Must be the name of a variable in a Feature which is of type boolean.
   */
  updateChildren(key: string, event: PointerEvent) {
    const parent = this.featureService.selectedFeature

    if (parent.type != 'group') return

    const children: Feature[] = this.featureService.getDescendants(parent).filter(f => f.type != 'group')
    const element = event.currentTarget as HTMLInputElement
    let value

    if (element.type == 'checkbox') value = element.checked
    else if (element.type == 'radio') value = element.value

    children.forEach(child => child[key] = value)
    parent[key] = value

    forkJoin(
      children.map(f => this.featureService.updateFeature(f))
        .concat(this.featureService.updateFeature(parent))
    ).subscribe()
  }

  setIndeterminate(id: string) {
    let el = this.checkboxes.find(el => el.nativeElement.id == id)
    if (!el) return

    el.nativeElement.indeterminate = true
    el.nativeElement.checked = true
  }

  setUnchecked(id: string) {
    let el = this.checkboxes.find(el => el.nativeElement.id == id)
    if (!el) return

    el.nativeElement.indeterminate = false
    el.nativeElement.checked = false
  }

  setChecked(id: string) {
    let el = this.checkboxes.find(el => el.nativeElement.id == id)
    if (!el) return

    el.nativeElement.indeterminate = false
    el.nativeElement.checked = true
  }

  createInteraction() {
    let name = this.selectedFeature.name
    this.modalService.showAsModal(CreateInteractionComponent, { destroyOnClose: false }).then(componentRef => {
      componentRef.instance.title = name.charAt(0).toUpperCase() + name.slice(1) + "'s interactions"
    })
  }

  editDescription() {
    this.modalService.showAsModal<EditDetailsComponent>(EditDetailsComponent)
  }

  groundFeature() {
    const position = this.position.value as number[]
    const [longitude, latitude] = position
    const altitude = this.map.queryTerrainElevation({ lng: longitude, lat: latitude } as Mapbox.LngLat)

    this.edited = position
    this.position.controls[2].setValue(altitude)

    this.toggleEdit("position", this.form, false)
  }

  onFileChange(file: File) {
    if (!file) {
      return
    }

    const feature = this.featureService.selectedFeature
    const image = feature.image

    if (image.fileReference) {
      const fileReference = image.fileReference
      fileReference.file = file

      this.fileReferenceService.updateFileReference(fileReference).subscribe(updatedReference => {
        image.fileReference = updatedReference
        image.fileReferenceID = updatedReference.id

        this.featureService.updateFeaturesLocally(feature)
      })
    } else {
      this.fileReferenceService.createFileReferences([file]).pipe(
        switchMap(([fileReference]) => {
          image.fileReference = fileReference
          image.fileReferenceID = fileReference.id

          return this.featureService.updateFeatureProperty(image)
        })
      ).subscribe()
    }
  }

  load() {
    if (this.unloaded) this.envManager.sceneManager.loadIn(this.selectedFeature).subscribe()
    else this.envManager.sceneManager.loadOut(this.selectedFeature)
  }

  copyPosition(feature: Feature) {
    this._copyService.getCoordinates()
      .then(coordinates => {
        coordinates.push([feature.position[0], feature.position[1]])

        const contents = { type: 'coordinates', coordinates } as CoordinatesClipboard
        this._copyService.copyContentsToClipboard(contents)
      })
  }

  pasteCoordinates(feature: Feature) {
    this._copyService.getCoordinates()
      .then(coordinates => {
        this.clipboardCoordinates = coordinates
        if (feature && this.clipboardCoordinates[0]) {
          const [longitude, latitude] = this.clipboardCoordinates[this.clipboardCoordinates.length - 1]
          const position = [longitude, latitude, feature.position[2]] as [number, number, number]

          feature.position = position
          this.updateFeature(feature)
        }
      })
  }

  moveMarker() {
    this._annotationService.enable(this.envManager.modelSpace.canvas, "moving")
  }
}