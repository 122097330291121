<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true"
    style="user-select: none;">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    Add Feature
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="close()"></a>
            </div>

            <!-- These all extend ModalBodyComponent, so that's how we select them -->
            <div class="modal-body modal-details" style="overflow-y: auto;">
                <form [formGroup]="form" style="user-select: none;">
                    <!-- Type -->
                    <div class="mb-3">
                        <label class="form-label">Feature Type</label>
                        <select class="form-select fontawesome-select" [formControl]="type">
                            <option value="3D">3D Model</option>
                            <option [disabled]="atFileLimit" value='image'>
                                Image <a *ngIf="atFileLimit">🔒</a>
                            </option>
                            <option [hidden]='selectedScene.type != "Map"' value='geojson'>GeoJSON</option>
                            <option [hidden]='selectedScene.type != "Map"' value='line'>Line</option>
                            <option [hidden]='selectedScene.type != "Map"' value='polygon'>Polygon</option>
                            <option [hidden]='selectedScene.type != "Map"' [disabled]="atFileLimit" value='marker'>
                                Marker <a *ngIf="atFileLimit">🔒</a>
                            </option>
                            <option [hidden]="selectedScene.type !== 'Map'" [disabled]="atFileLimit || inFreeTier"
                                value="rasterImage">
                                Raster Image <a *ngIf="atFileLimit || inFreeTier">🔒</a>
                            </option>
                        </select>
                    </div>

                    <!-- Feature from GeoJSON -->
                    <shared-create-features-from-geojson *ngIf="type.value == 'geojson'">
                    </shared-create-features-from-geojson>

                    <!-- Raster from GeoTIFF -->
                    <shared-create-raster-layers-from-geotiffs *ngIf="type.value == 'rasterImage'">
                    </shared-create-raster-layers-from-geotiffs>

                    <!-- ImageFeatures -->
                    <div *ngIf="type.value == 'image'">
                        <ng-container *ngIf="selectedFiles && selectedFiles.length != 0">
                            <label class="form-label">Files</label>

                            <div class="card bg-light mb-2 p-3 d-grid gap-3">
                                <ng-container
                                    *ngFor="let singleFeatForm of multipleFeaturesFormArray.controls; index as i">
                                    <div [formGroup]="singleFeatForm"
                                        class="bg-white rounded d-flex align-items-center">
                                        <label class="text-center btn rounded-1 btn-primary" for="replace-file-{{i}}">
                                            <i class="fas fa-file-search"></i>
                                            <input [hidden]="true" id="replace-file-{{i}}" type="file" accept="image/*"
                                                (change)="replaceFile($event.target.files[0], i)">
                                        </label>

                                        <input
                                            [ngClass]="{'is-valid': name.valid, 'is-invalid': name.invalid && name.touched }"
                                            formControlName="name" type="text" class="form-control">

                                        <a class="btn text-secondary border" (click)="removeFile(i)">
                                            <i class="fas fa-trash"></i>
                                        </a>
                                    </div>
                                    <small *ngIf="name.invalid && name.touched" class="form-text text-danger">
                                        Name is required.
                                    </small>
                                </ng-container>
                            </div>
                        </ng-container>

                        <div class="d-flex align-items-center mb-2">
                            <div class="me-3 d-flex" style="width:100px">
                                <label tabindex="0" class="form-control btn btn-success btn-sm">+ Add Files
                                    <input [disabled]="atFileLimit" type="file" class="invisible"
                                        id="featureInputGroupImage" style="width: 1px" accept="image/*"
                                        [required]="type.value == 'image'" (change)="onFileChange($event.target.files)"
                                        multiple>
                                </label>
                            </div>
                            <div class="form-check" *ngIf="selectedFiles.length > 1">
                                <input class="form-check-input" type="checkbox" formControlName="createAsGroup"
                                    style="cursor: pointer;">
                                <label class="form-check-label">Create as Group</label>
                            </div>
                        </div>
                        <small
                            *ngIf="multipleFeaturesFormArray.controls.length == 0 && multipleFeaturesFormArray.touched"
                            class="form-text text-danger">
                            Cannot create feature(s) without images
                        </small>
                    </div>

                    <ng-container *ngIf="showNameAndDesc">
                        <!-- Name -->
                        <div class="mb-3">
                            <label class="form-label" for="feature-name">Feature Name</label>
                            <input type="text" id="feature-name" class="form-control"
                                [ngClass]="{'is-valid': name.valid, 'is-invalid': name.invalid && name.touched }"
                                formControlName="name" required>
                            <small *ngIf="name.invalid && name.touched" class="form-text text-danger">
                                Name is required.
                            </small>
                        </div>

                        <!-- Description -->
                        <div class="mb-3">
                            <label class="form-label" for="feature-description">Description</label>
                            <textarea class="form-control" rows="5" id="feature-description"
                                formControlName="description"></textarea>
                        </div>
                    </ng-container>

                    <!-- ModelFeature -->
                    <div [hidden]="type.value != '3D'">
                        <label class="form-label">Model</label>
                        <ng-container *ngIf="models$ | async as models">
                            <shared-model-picker [models]="models" class="card" style="max-height: 450px"
                                (onSelect)="setModel($event)"></shared-model-picker>
                        </ng-container>
                        <input [hidden]="true" formControlName="model" [required]="modelRequired">
                        <div *ngIf="model.touched && model.invalid" class="pb-2">
                            <small class="form-text text-danger">
                                Model must be selected
                            </small>
                        </div>
                    </div>

                    <!-- Marker Feature -->
                    <div *ngIf="type.value == 'marker'" class="d-grid gap-2">
                        <div class="d-flex align-items-stretch align-items-center rounded border"
                            style="width: fit-content">
                            <label tabindex="0" class="form-control btn btn-success btn-sm"
                                [ngClass]="{'rounded-start': defaultMarkerImage}" style="width: fit-content;">
                                + Marker Icon
                                <input [hidden]="true" type="file" accept="image/*"
                                    (change)="updateMarkerImage($event.target.files[0])">
                            </label>

                            <div *ngIf='defaultMarkerImage' class="d-flex align-items-center border-start"
                                style="min-width: fit-content;">
                                <span class="px-3 me-auto">{{ defaultMarkerImage.name }}</span>
                                <a class="btn btn-sm btn-close mx-1" (click)="defaultMarkerImage = undefined"></a>
                            </div>
                        </div>

                        <div *ngIf="selectedFiles?.length > 0" class="form-switch">
                            <input class="form-check-input me-1" type="checkbox" formControlName="useMetadata"
                                style="cursor: pointer;">
                            <label class="form-check-label me-1">Use Image's GPS location</label>
                        </div>

                        <div *ngIf="selectedFiles?.length < 2" [hidden]="useMetadata.value == true">
                            <div class="flex-grow-1 pe-2">
                                <label class="form-label" for="map-lon">Longitude</label>
                                <input
                                    [ngClass]="{'is-valid': longitude.valid, 'is-invalid': longitude.invalid && longitude.touched }"
                                    type="text" id="map-lon" class="form-control" formControlName="longitude"
                                    [required]="latLngRequired">

                                <small *ngIf="longitude.touched && longitude.invalid"
                                    class="form-text text-danger">Longitude must be
                                    between -180 and 180</small>
                            </div>

                            <div class="flex-grow-1 pe-2">
                                <label class="form-label" for="map-lat">Latitude</label>
                                <input
                                    [ngClass]="{'is-valid': latitude.valid, 'is-invalid': latitude.invalid && latitude.touched }"
                                    type="text" id="map-lat" class="form-control" formControlName="latitude"
                                    [required]="latLngRequired">

                                <small *ngIf="latitude.touched && latitude.invalid" class="form-text text-danger">
                                    Latitude must be between -90 and 90
                                </small>
                            </div>

                            <a *ngIf="coordinates?.length > 0" class="w-100 btn btn-outline-primary mb-1"
                                (click)="paste()">
                                <i class="fas fa-paste fa-fw"></i>
                                Paste Coordinates
                            </a>
                        </div>

                        <ng-container *ngIf="selectedFiles?.length > 0">
                            <div class="d-flex align-items-center">
                                <label class="form-label">Attached Images</label>

                                <div class="form-check ms-auto" *ngIf="selectedFiles.length > 1">
                                    <input class="form-check-input" type="checkbox" formControlName="createAsGroup"
                                        style="cursor: pointer;">
                                    <label class="form-check-label">Create as Group</label>
                                </div>
                            </div>

                            <div class="card bg-light p-3 d-grid gap-3">
                                <ng-container
                                    *ngFor="let singleFeatForm of multipleFeaturesFormArray.controls; index as i">
                                    <div [formGroup]="singleFeatForm"
                                        class="bg-white rounded d-flex align-items-center">
                                        <label class="text-center btn rounded-1 btn-primary" for="replace-file-{{i}}">
                                            <i class="fas fa-file-search"></i>
                                            <input [hidden]="true" id="replace-file-{{i}}" type="file" accept="image/*"
                                                (change)="replaceFile($event.target.files[0], i)">
                                        </label>

                                        <input
                                            [ngClass]="{'is-valid': name.valid, 'is-invalid': name.invalid && name.touched }"
                                            formControlName="name" type="text" class="form-control">

                                        <a class="btn text-secondary border" (click)="removeFile(i)">
                                            <i class="fas fa-trash"></i>
                                        </a>
                                    </div>

                                    <small *ngIf="name.invalid && name.touched" class="form-text text-danger">
                                        Name is required.
                                    </small>
                                </ng-container>
                            </div>
                        </ng-container>

                        <label tabindex="0" class="form-control btn btn-secondary btn-sm" style="width: fit-content">
                            + Attach Images
                            <input [hidden]="true" type="file" id="featureInputGroupImage" accept="image/*"
                                [required]="type.value == 'image'" (change)="onFileChange($event.target.files)"
                                multiple>
                        </label>
                    </div>

                    <!-- LineFeature & PolygonFeature -->
                    <div [hidden]="type.value != 'line' && type.value != 'polygon'">
                        <shared-edit-coordinate-list *ngIf='type.value == "line"'
                            [type]='"line"'></shared-edit-coordinate-list>
                        <shared-edit-coordinate-list *ngIf='type.value == "polygon"' [type]='"polygon"'>
                        </shared-edit-coordinate-list>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button (click)="submit()" class="btn btn-success">
                    Create
                </button>

                <a class="btn btn-secondary" (click)="close()">
                    Close
                </a>
            </div>
        </div>
    </div>
</div>