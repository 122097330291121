import { catchError, tap } from 'rxjs/operators'

import { Component } from '@angular/core'
import { Feature } from '@classes/Feature'
import { TypeToConfirmComponent } from '@modal/type-to-confirm/type-to-confirm.component'
import { ContextMenuService } from '@services/context-menu.service'
import { CopyService } from '@services/copy.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { LoadingService } from '@services/loading.service'
import { ModalService } from '@services/modal.service'
import { SceneService } from '@services/scene.service'
import { VirtualTourService } from '@services/virtual-tour.service'
import { EMPTY } from 'rxjs'
import { UiService } from '@services/ui.service'

@Component({
  selector: 'shared-leftside-multi-menu',
  templateUrl: './leftside-multi-menu.component.html',
  styleUrls: ['./leftside-multi-menu.component.css']
})
export class LeftSideMultiMenuComponent {
  get clickInfo() { return this.contextMenuService.clickInfo }
  get isMapScene() { return this.sceneService.selectedScene.type == 'Map' }

  get selectedFeatures() { return this._featureService.selectedFeatures }
  get submittingChanges() { return this._featureService.submittingChanges }
  set submittingChanges(submiting: boolean) { this._featureService.submittingChanges = submiting }
  get canCopyFeatures() { return this.selectedFeatures.size > 0 && !this.submittingChanges }
  get canDeleteFeatures() { return this.selectedFeatures.size > 0 && !this.submittingChanges }
  get canDuplicateFeatures() { return this.selectedFeatures.size > 0 && !this.submittingChanges }

  constructor(
    private _copyService: CopyService,
    private _featureService: FeatureService,
    private _loadingService: LoadingService,
    private _modalService: ModalService,
    private _uiService: UiService,
    private _virtualTourService: VirtualTourService,
    public contextMenuService: ContextMenuService,
    public envManager: EnvironmentManagerService,
    public sceneService: SceneService,
  ) { }

  focusOn() {
    const featureIDs = Array.from(this.selectedFeatures)
    const features = this._featureService.features.filter(feature => featureIDs.includes(feature.id))

    if (this.isMapScene) {
      this.envManager.focusOnFeatures(...features)
    } else {
      if (this._virtualTourService.viewpointMode) {
        this._virtualTourService.exitViewpoint()
      }

      this.envManager.focusOnFeatures(...features)
    }

    this.contextMenuService.closeContext()
  }

  group() {
    // Get all selected features.
    const featureIDs = Array.from(this.selectedFeatures)
    const features = this._featureService.features.filter(feature =>
      featureIDs.includes(feature.id)
    )

    // Filter the features so that only the heads of each ancestry are included.
    const headFeatures = this.getHeads(features)
    const headParentID = headFeatures[0].parentID

    let parentID = undefined
    if (headFeatures.every(feature => feature.parentID === headParentID)) {
      parentID = headParentID
    } else {
      // Determine the common ancestor for all selected features.
      // If the features come from different trees, commonAncestor will be undefined.
      const commonAncestor = this.findHighestCommonAncestor(features)
      parentID = commonAncestor?.id
    }


    const groupOptions = {
      name: 'Group',
      // The group’s parentID is the common ancestor’s id (if one exists), otherwise undefined.
      parentID: parentID,
      children: headFeatures,
    }

    this._featureService.createFeatureGroup(groupOptions).subscribe()
    this.contextMenuService.closeContext()
  }

  private getHeads(features: Feature[]): Feature[] {
    // Build a set of selected feature IDs.
    const selectedIds = new Set(features.map(f => f.id))
    return features.filter(feature => {
      // Get the full chain from the root to this feature.
      const chain = this.getFullAncestorChain(feature)
      // If any ancestor (other than the feature itself) is in the selected set,
      // then this feature is not a head.
      return !chain.some(ancestor => ancestor.id !== feature.id && selectedIds.has(ancestor.id))
    })
  }

  private getFullAncestorChain(feature: Feature): Feature[] {
    // Build the full ancestry chain from the root to the feature itself.
    const chain: Feature[] = []
    let current: Feature | undefined = feature
    while (current) {
      chain.push(current)
      if (current.parentID === undefined) break
      current = this._featureService.features.find(f => f.id === current.parentID)
    }
    return chain.reverse()
  }

  private findHighestCommonAncestor(features: Feature[]): Feature | undefined {
    if (features.length === 0) return undefined

    // Start with the full chain for the first feature.
    let commonChain = this.getFullAncestorChain(features[0])

    // For each subsequent feature, compute the common prefix.
    for (let i = 1; i < features.length; i++) {
      const chain = this.getFullAncestorChain(features[i])
      const newCommon: Feature[] = []
      const minLength = Math.min(commonChain.length, chain.length)
      for (let j = 0; j < minLength; j++) {
        if (commonChain[j].id === chain[j].id) {
          newCommon.push(commonChain[j])
        } else {
          break
        }
      }
      commonChain = newCommon
    }

    // Return the highest common ancestor, i.e. the first element in the chain.
    return commonChain.length > 0 ? commonChain[0] : undefined
  }

  copyFeatures() {
    if (this.canCopyFeatures) {
      const selectedFeatures = Array.from(this.selectedFeatures)

      this._copyService.copyFeaturesToClipboard(selectedFeatures)
      this.contextMenuService.closeContext()
    }
  }

  duplicateFeatures() {
    if (this.canDuplicateFeatures) {
      this.submittingChanges = true

      const selectedFeatures = Array.from(this.selectedFeatures)
      const sceneID = this.sceneService.selectedSceneID
      const features = this._featureService.getFeatures()

      // Recursively check if any ancestor of the feature is selected
      const hasAncestorSelected = (featureID: number): boolean => {
        const feature = features.find(f => f.id === featureID)

        if (!feature || !feature.parentID) {
          return false
        } else if (selectedFeatures.includes(feature.parentID)) {
          return true
        } else {
          return hasAncestorSelected(feature.parentID)
        }
      }

      // Filter out features where any ancestor is already selected
      const featuresToDuplicate = selectedFeatures.filter(featureID => !hasAncestorSelected(featureID))

      featuresToDuplicate.forEach(featureID => {
        this._loadingService.await(
          this._featureService.duplicateFeature(featureID, sceneID).pipe(
            catchError(this.handleError()),
            tap(() => this.submittingChanges = false)
          )
        )
        this.contextMenuService.closeContext()
      })
    }
  }

  deleteFeatures() {
    if (this.canDeleteFeatures) {
      this._modalService.showAsModal<TypeToConfirmComponent>(TypeToConfirmComponent)
        .then((componentRef) => {
          componentRef.instance.title = `Delete ${this.selectedFeatures.size} Features`
          componentRef.instance.message = `Are you sure you want to delete these Features? <strong>THIS ACTION CANNOT BE UNDONE.</strong>`
          componentRef.instance.submitText = 'Delete'
          componentRef.instance.submitColor = 'red'
          componentRef.instance.confirmWord = 'DELETE FEATURES'
          this.contextMenuService.closeContext()
          componentRef.instance.onSubmit = () => {
            this.submittingChanges = true

            const selectedFeatures = Array.from(this.selectedFeatures)

            this._featureService.deleteFeatures(this.sceneService.selectedSceneID, selectedFeatures)
              .pipe(catchError(this.handleError()))
              .subscribe(() => this.submittingChanges = false)
          }
        })
    }
  }

  handleError() {
    return () => {
      this.submittingChanges = false
      return EMPTY
    }
  }
}