import { Component } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'
import { SubscriptionService } from '@services/subscription.service'

@Component({
  selector: 'shared-resource-limit-alert',
  templateUrl: './resource-limit-alert.component.html',
  styleUrls: ['./resource-limit-alert.component.css']
})
export class ResourceLimitAlertComponent {
  get atFileLimit() { return this._subscriptionService.atFileLimit }
  get atRealityAssetLimit() { return this._subscriptionService.atRealityAssetLimit }
  get hasFinancialAccess() { return this._authService.hasFinancialAccess }
  get inProTier() { return this._subscriptionService.inProTier }
  get limitReached() { return this.atFileLimit || this.atRealityAssetLimit }

  constructor(private _authService: AuthenticationService, private _subscriptionService: SubscriptionService) { }
}