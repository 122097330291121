<div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel">{{ title }}</h2>
                <button type="button" class="btn-close" aria-label="Close" (click)="_cancel()"></button>
            </div>

            <!-- Modal Body (Unified Layout) -->
            <div *ngIf="selectedProject$ | async as project" class="modal-body">
                <!-- Top Section: Two-column layout -->
                <div class="d-flex mb-3">
                    <!-- Left Column: Thumbnail (with edit and clear button overlays if edit permissions) -->
                    <div class="thumbnail-container">
                        <img [src]="project.thumbnail || kartoriumLogo" alt="Project Thumbnail">
                        <!-- Edit Button (for non-Viewers) -->
                        <label *ngIf="currentUserPermission != 'Viewer'" class="btn btn-light btn-sm edit-btn"
                            for="project-thumbnail-input">
                            <i class="fas fa-edit"></i>
                        </label>
                        <!-- Clear Button (shows only if a thumbnail exists) -->
                        <button *ngIf="currentUserPermission != 'Viewer' && project.thumbnail" type="button"
                            class="btn btn-light btn-sm clear-btn" (click)="clearThumbnail()">
                            <i class="fas fa-times"></i>
                        </button>
                        <input id="project-thumbnail-input" type="file" accept="image/*"
                            (change)="addThumbnail($event.target.files[0])" hidden>
                    </div>

                    <!-- Right Column: Title, Description, Timestamps -->
                    <div class="ms-3 flex-grow-1">
                        <!-- Title -->
                        <div *ngIf="currentUserPermission != 'Viewer'; else viewTitle">
                            <div class="form-floating mb-2">
                                <input type="text" class="form-control" id="floatingTitle" [(ngModel)]="project.title"
                                    (blur)="updateProject(project)" placeholder="Project Title">
                                <label for="floatingTitle">Project Title</label>
                            </div>
                        </div>
                        <ng-template #viewTitle>
                            <h3 class="h5 mb-2">{{ project.title }}</h3>
                        </ng-template>

                        <!-- Description -->
                        <div *ngIf="currentUserPermission != 'Viewer'; else viewDescription">
                            <div class="form-floating mb-2">
                                <textarea class="form-control" id="floatingDescription" style="height: 100px;"
                                    [(ngModel)]="project.description" (blur)="updateProject(project)"
                                    placeholder="Project description"></textarea>
                                <label for="floatingDescription">Project Description</label>
                            </div>
                        </div>
                        <ng-template #viewDescription>
                            <p class="text-muted mb-2">{{ project.description }}</p>
                        </ng-template>

                        <!-- Timestamps -->
                        <div class="text-muted mb-1">Created: {{ project.createdOn | date:'medium' }}</div>
                        <div class="text-muted">Last edited: {{ project.lastEdited | date:'medium' }}</div>
                    </div>
                </div>

                <!-- Editor-Only Additional Controls -->
                <ng-container *ngIf="currentUserPermission != 'Viewer'">
                    <!-- Project Settings -->
                    <fieldset class="project-settings">
                        <legend class="section-header">Project Settings</legend>
                        <div class="form-check form-switch mb-2">
                            <input id="public-switch" type="checkbox" class="form-check-input"
                                [(ngModel)]="project.publicStatus" (change)="updateProject(project)"
                                [disabled]="inFreeTier" style="cursor: pointer">
                            <label class="form-check-label" for="public-switch">
                                <i *ngIf="inFreeTier" class="fas fa-lock"></i> Public Project
                            </label>
                            <div class="toggle-helper" *ngIf="inFreeTier">
                                Private projects are disabled in free tier.
                            </div>
                            <div class="toggle-helper" *ngIf="!inFreeTier">
                                Make project visible to everyone.
                            </div>
                        </div>
                        <div *ngIf="hasOrganization" class="form-check form-switch mb-2">
                            <input id="share-org" type="checkbox" class="form-check-input"
                                [(ngModel)]="project.sharedWithOrganization" (change)="updateProject(project)"
                                [disabled]="inFreeTier" style="cursor: pointer">
                            <label class="form-check-label" for="share-org">
                                <i *ngIf="inFreeTier" class="fas fa-lock"></i> Share with Organization
                            </label>
                            <div class="toggle-helper" *ngIf="inFreeTier">
                                Organization sharing is disabled in free tier.
                            </div>
                            <div class="toggle-helper" *ngIf="!inFreeTier">
                                Make project visible to your Organization.
                            </div>
                        </div>
                    </fieldset>
                </ng-container>

                <!-- Collaborators Section -->
                <div *ngIf="hasOrganization" class="mt-3">
                    <h4 *ngIf="currentUserPermission == 'Owner'" class="section-header">Manage Collaborators</h4>
                    <h4 *ngIf="currentUserPermission != 'Owner'" class="section-header">Collaborators</h4>
                    <div class="permissions-wrapper">
                        <shared-edit-project-permissions></shared-edit-project-permissions>
                    </div>
                </div>
            </div>

            <div class="modal-footer d-flex align-items-center">
                <div *ngIf="currentUserPermission == 'Owner'">
                    <button type="button" class="btn btn-danger" (click)="deleteProject()">Delete</button>
                </div>
                <div class="ms-auto">
                    <button type="button" class="btn btn-secondary" (click)="_cancel()">{{ cancelText }}</button>
                </div>
            </div>
        </div>
    </div>
</div>