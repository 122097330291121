<div class="main" style="overflow-y: auto;">
  <!-- Navigation -->
  <div class="d-flex justify-content-between align-items-center py-3 px-4">
    <button class="btn btn-outline-primary" (click)="returnToPreviousPage()">
      <i class="fas fa-arrow-left me-2"></i>Return
    </button>
    <img *ngIf="currentUser$ | async" [src]="(currentUser$ | async)?.picture" class="border rounded-circle thumbnail"
      role="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" width="50px">
  </div>

  <!-- Feedback Form -->
  <div class="container mt-5">
    <div *ngIf="submissionSuccess" class="alert alert-success text-center">
      <strong>Thank you!</strong> Your feedback has been submitted successfully. Redirecting...
    </div>

    <div *ngIf="!submissionSuccess" class="row justify-content-center">
      <div class="col-lg-8 col-md-10">
        <div class="card shadow-sm">
          <div class="card-header text-center bg-primary text-white">
            <h3>Submit Your Feedback</h3>
          </div>
          <div class="card-body">
            <!-- Feedback Form -->
            <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
              <!-- Feedback Type -->
              <div class="mb-4">
                <label class="form-label fw-bold">What type of feedback are you submitting?</label>
                <div class="d-flex gap-4">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="type" value="bug" id="typeIssue">
                    <label class="form-check-label" for="typeIssue">Issue</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="type" value="enhancement"
                      id="typeSuggestion">
                    <label class="form-check-label" for="typeSuggestion">Suggestion</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="type" value="question"
                      id="typeQuestion">
                    <label class="form-check-label" for="typeQuestion">Question</label>
                  </div>
                </div>
                <div *ngIf="feedbackForm.get('type')?.invalid && feedbackForm.get('type')?.touched"
                  class="text-danger small mt-2">
                  Please select a feedback type.
                </div>
              </div>

              <!-- Title -->
              <div class="mb-4">
                <label for="title" class="form-label fw-bold">Feedback Title</label>
                <input id="title" type="text" class="form-control" formControlName="title"
                  placeholder="Enter a concise title">
                <div *ngIf="feedbackForm.get('title')?.invalid && feedbackForm.get('title')?.touched"
                  class="text-danger small mt-2">
                  A title is required.
                </div>
              </div>

              <!-- Description -->
              <div class="mb-4">
                <label for="description" class="form-label fw-bold">Description</label>
                <textarea id="description" class="form-control" formControlName="description" rows="6"
                  placeholder="Provide detailed information about your feedback"></textarea>
                <div *ngIf="feedbackForm.get('description')?.invalid && feedbackForm.get('description')?.touched"
                  class="text-danger small mt-2">
                  A description is required.
                </div>
              </div>

              <!-- File Upload -->
              <div class="mb-4">
                <label for="file" class="form-label fw-bold">Upload Supporting File (Optional)</label>
                <input id="file" type="file" class="form-control" accept="video/*,image/*,application/pdf"
                  (change)="onFileSelected($event)">
                <div *ngIf="fileError" class="text-danger small mt-2">
                  {{ fileError }}
                </div>
                <div *ngIf="uploadedFile" class="text-success small mt-2">
                  File uploaded: {{ uploadedFile.name }}
                </div>
              </div>

              <!-- Submit Button -->
              <div class="d-flex justify-content-between align-items-center">
                <button type="button" class="btn btn-outline-secondary" (click)="returnToPreviousPage()">Cancel</button>
                <button type="submit" class="btn btn-primary px-5" [disabled]="feedbackForm.invalid || submitting">
                  <span *ngIf="!submitting">Submit</span>
                  <span *ngIf="submitting">Submitting...</span>
                  <div *ngIf="submitting" class="spinner-border spinner-border-sm ms-2" role="status"
                    aria-hidden="true"></div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="toast-container d-flex flex-column m-3">
    <shared-toast-container></shared-toast-container>
  </div>
</div>