<div class="offcanvas offcanvas-end bg-light" tabindex="-1" #rightPanel aria-labelledby="offcanvasLabel"
    data-bs-scroll="true" data-bs-backdrop="false" [style.width]="width" [style.z-index]="zIndex"
    [ngClass]="{'off-screen ': !isOpen, 'responsive-panel': isResponsive, 'w-100': isMobile}">
    <div class="offcanvas-header border-bottom">
        <h1 class="offcanvas-title" id="offcanvasLabel">{{title}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="closePanel()">
        </button>
    </div>

    <div class="offcanvas-body p-0" *ngIf="bodyComponent && shouldShowComponent">
        <ng-container *ngComponentOutlet="bodyComponent"></ng-container>
    </div>
</div>