<!-- Navigation Bar -->
<billing-nav-bar [showTabs]="false"></billing-nav-bar>

<div class="main" [ngSwitch]="currentPhase">
    <div class="body d-flex flex-column align-items-center p-4 gap-4">
        <ng-container *ngSwitchCase="'collectingInformation'">
            <div class="ms-auto">
                <a class="btn btn-close" aria-label="Close" (click)="returnToDashboard()"></a>
            </div>

            <billing-select-tier #selectTier></billing-select-tier>

            <billing-create-organization #createOrg class="px-5"></billing-create-organization>

            <div>
                <button class="btn text-white scheme-bg-dark rounded-1 py-2 w-100" (click)="validateOrganizationInfo()">
                    Proceed to Payment
                </button>
            </div>
        </ng-container>

        <h1 *ngSwitchCase="'submitting'" class="fw-bold text-secondary fs-1">
            Submitting Payment Information...
        </h1>

        <ng-container *ngSwitchCase="'success'">
            <billing-view-invoice [invoice]="invoice" [title]="'Payment Confirmation'"
                [subtitle]="'Pro Tier Upgrade'"></billing-view-invoice>

            <div>
                <button class="btn text-white scheme-bg-dark rounded-1 py-2 w-100" (click)="loginToOrganization()">
                    Go to Dashboard
                </button>
            </div>
        </ng-container>
    </div>

    <!-- Toasts -->
    <div class="toast-container d-flex flex-column m-3">
        <shared-toast-container></shared-toast-container>
    </div>
</div>

<!-- Right panel -->
<div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="right-panel"
    aria-labelledby="right-panel-label">
    <div class="offcanvas-header">
        <h1 class="offcanvas-title" id="right-panel-label">Review & Confirm</h1>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>

    <div *ngIf="proTierPrices" class="offcanvas-body d-flex flex-column gap-4">
        <div class="plan-summary p-3 bg-light rounded">
            <h4 class="mb-2">{{ proTierProduct.name }}</h4>
            <p class="mb-0 text-muted">
                {{proTierProduct.description}}
            </p>
        </div>

        <div class="cart-item d-flex justify-content-between align-items-center p-3 border rounded">
            <div class="d-flex flex-column">
                <strong>{{ proTierProduct.name }}</strong>
                <small class="text-muted">Quantity: x1</small>
            </div>
            <span class="fw-bold">{{ this.totalAmount | currency }}</span>
        </div>

        <div class="total-cost p-3 rounded bg-light d-flex justify-content-between align-items-center">
            <span class="fw-semibold">Total Cost</span>
            <h4 class="m-0 text-primary">
                {{ this.totalAmount | currency }}
            </h4>
        </div>

        <div class="d-flex justify-content-center mt-auto">
            <button [disabled]="!isValid" (click)="completeProTierCheckout()" class="btn btn-success btn-lg px-4"
                type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                Finish Upgrade
            </button>
        </div>
    </div>
</div>