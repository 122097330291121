<body id="root">
    <div class="PricingTable is-roundedBorder is-whiteButtonText is-lightColorBackground has-threeItems flex-container direction-column justify-content-center align-items-center"
        data-testid="pricing-table-container"
        style="--pt-color-primary: #274754; background-color: var(--background-color);">
        <div class="IntervalTabs-refContainer">
            <div class="Tabs IntervalTabs is-insettabs is-desktop">
                <div class="Tabs-Container">
                    <div role="tablist" aria-orientation="horizontal" aria-label="Billing intervals"
                        class="Tabs-TabList">
                        <div (click)="interval = 'month'"
                            [ngClass]="{'Tabs-TabListItemContainer--is-selected': isMonthly}" role="presentation"
                            class="Tabs-TabListItemContainer">
                            <button [ngClass]="{'Tabs-TabListItem--is-selected': isMonthly}" class="Tabs-TabListItem"
                                id="1-month-tab" data-testid="1-month-tab-button" role="tab" type="button"
                                aria-controls="1-month-tab-panel" aria-label="Monthly" [style.aria-selected]="isMonthly"
                                tabindex="0" title="Monthly">
                                <div class="Tabs-TabListItemContent">
                                    <div class="Tabs-TabListPaymentMethod">
                                        <div class="Tabs-TabListPaymentLabel" data-text="Monthly">Monthly</div>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div (click)="interval = 'year'"
                            [ngClass]="{'Tabs-TabListItemContainer--is-selected': !isMonthly}" role="presentation"
                            class="Tabs-TabListItemContainer">
                            <button [ngClass]="{'Tabs-TabListItem--is-selected': !isMonthly}" class="Tabs-TabListItem"
                                id="1-year-tab" data-testid="1-year-tab-button" role="tab" type="button"
                                aria-controls="1-year-tab-panel" aria-label="Yearly" [style.aria-selected]="!isMonthly"
                                tabindex="-1" title="Yearly">
                                <div class="Tabs-TabListItemContent">
                                    <div class="Tabs-TabListPaymentMethod">
                                        <div class="Tabs-TabListPaymentLabel" data-text="Yearly">Yearly</div>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <span *ngIf="isMonthly" class="IntervalTabs-glider"
                            style="background-color: var(--button-color); --offset: 2px; height: 36px; width: 117px;">
                        </span>
                        <span *ngIf="!isMonthly" class="IntervalTabs-glider"
                            style="background-color: var(--button-color); --offset: 119px; height: 36px; width: 100px;">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="PricingTable-grid">
            <!-- Free Tier -->
            <div *ngFor="let plan of (isMonthly ? monthlyPlans : yearlyPlans )"
                [ngClass]="{'is-highlight': plan.lookup_key.includes(selected)}"
                class="PriceColumn flex-container direction-column justify-content-flex-start align-items-center"
                data-testid="price-column" style="--pt-animate-in-order: 0;">
                <div
                    class="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                    <div class="PriceColumn-aboutProduct flex-container direction-column">
                        <div *ngIf="plan.lookup_key.includes(selected)" class="PriceColumn-badge flex-container">
                            <div class="Badge is-highlightBadge flex-container align-items-center"><span
                                    class="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Selected</span>
                            </div>
                        </div>
                        <div *ngIf="!plan.lookup_key.includes(selected)"
                            class="PriceColumn-badge is-placeholderForHeight flex-container"></div>
                        <div class="PriceColumn-textAndProductImage flex-container">
                            <div class="PriceColumn-textContainerHeightAligner" style="height: 181px;">
                                <div class="PriceColumn-textContainer flex-container direction-column">
                                    <div class="PriceColumn-name flex-container"><span
                                            class="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">
                                            {{ plan.product?.name || 'Plan Name' }}
                                        </span>
                                    </div>
                                    <span class="PriceColumn-description Text Text-color--default Text-fontSize--14">
                                        {{ plan.product?.description || 'Description of this plan.' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="PriceColumn-badge is-placeholderForHeight flex-container"></div>
                    <div class="PriceColumn-priceAndButton flex-container direction-column">
                        <div class="PriceColumn-priceContainerHeightAligner" style="height: 44px;">
                            <div class="PriceColumn-priceContainer">
                                <div class="flex-container spacing-4 direction-column align-items-flex-start">
                                    <div *ngIf="!plan.lookup_key.includes('enterprise')"
                                        class="flex-container align-items-center">
                                        <span
                                            class="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700">
                                            <span class="CurrencyAmount">
                                                {{ (plan.unit_amount / 100) || 0 | currency:'USD':'symbol':'1.0-0' }}
                                            </span>
                                        </span>
                                        <span class="PriceColumn-interval Text Text-color--default Text-fontSize--13">
                                            per <br>{{ plan.recurring?.interval }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button *ngIf="plan.metadata?.callToAction" (click)="contactUs()"
                            class="Button PriceColumn-button Button--primary" type="button"
                            style="background-color: var(--button-color); border-color: rgb(39, 71, 84);">
                            <div class="flex-container justify-content-center align-items-center">
                                <span class="Text-color--default Text-fontWeight--500 Text--truncate">
                                    Contact Us
                                </span>
                            </div>
                        </button>
                        <button *ngIf="!plan.metadata?.callToAction" (click)="chooseTier(plan)"
                            [disabled]="!isSelectable(plan)" class="btn Button PriceColumn-button Button--primary"
                            type="button" style="background-color: var(--button-color); border-color: rgb(39, 71, 84);">
                            <div class="flex-container justify-content-center align-items-center">
                                <span class="Text-color--default Text-fontWeight--500 Text--truncate">
                                    Select
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
                <div *ngIf="plan.product?.marketing_features?.length"
                    class="PriceColumn-featureListContainer flex-container direction-column"><span
                        class="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14">
                        This includes:
                    </span>
                    <div class="PriceColumn-featureList flex-container direction-column align-items-flex-start">
                        <div *ngFor="let feature of plan.product.marketing_features"
                            class="PriceColumn-feature flex-container align-items-flex-start">
                            <div class="PriceColumn-checkContainer flex-container">
                                <svg class="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#1a1a1a"
                                    color="#1a1a1a" fill-opacity="0.5" height="16" viewBox="0 0 16 16" width="16">
                                    <path
                                        d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                                        fill-rule="evenodd"></path>
                                </svg>
                            </div>
                            <span class="PriceColumn-featureText Text Text-color--default Text-fontSize--13">
                                {{ feature.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>