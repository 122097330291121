import { Organization } from 'auth0'
import { combineLatest } from 'rxjs'
import { filter, switchMap, tap } from 'rxjs/operators'

import { Component } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'

@Component({
  selector: 'shared-organization-picker',
  templateUrl: './organization-picker.component.html',
  styleUrls: ['./organization-picker.component.css']
})
export class OrganizationPickerComponent {
  public hasPersonalAccount = false
  public organizations: Organization[]
  public userOrg: Organization

  get user$() { return this.authenticationService.user$ }

  constructor(
    public authenticationService: AuthenticationService
  ) {
    combineLatest([
      this.authenticationService.isAuthenticated$,
      this.authenticationService.user$
    ]).pipe(
      filter(([isAuthenticated, user]) => isAuthenticated && !!user),  // Ensure both are truthy
      switchMap(([isAuthenticated, user]) =>
        this.authenticationService.getUserAccounts().pipe(
          tap(({ organizations, hasFreeTrial }) => {
            this.hasPersonalAccount = hasFreeTrial
            this.userOrg = organizations.find(org => org.id === user.org_id)
            this.organizations = organizations.sort((a, b) => a.display_name.localeCompare(b.display_name))
          })
        )
      )
    ).subscribe()
  }

  /** Login to personal or organization account */
  accountLogin(organization?: Organization) {
    const orgID = organization?.id
    const storedOrigin = sessionStorage.getItem('authRedirectOrigin')
    const target = storedOrigin ? storedOrigin : 'projects'
    sessionStorage.removeItem('authRedirectOrigin')

    sessionStorage.setItem('redirectDashboard', 'true')
    this.authenticationService.loginWithRedirect({ appState: { target }, authorizationParams: { organization: orgID } })
  }

  navigateToAuthLogin() {
    this.authenticationService.loginWithRedirect({ appState: { target: 'login' }, authorizationParams: { screen_hint: 'login' } })
  }
}