<div class="card-header d-grid gap-2 p-2 bg-white">
    <div class="input-group input-group-sm">
        <span class="input-group-text">
            <i class="fas fa-search"></i>
        </span>
        <input #search type="search" class="form-control" placeholder="Search" [(ngModel)]="searchValue">
        <a class="btn btn-sm" (click)="refresh()">
            <i class="fas fa-arrows-rotate fa-fw"></i>
        </a>
    </div>

    <div *ngIf="inProject" class="form-check form-switch">
        <input class="form-check-input" role="button" type="checkbox" [(ngModel)]="showProjectsModels"
            id="limitToProject" style="cursor: pointer;">
        <label class="form-check-label" role="button" for="limitToProject">
            Used in Project
        </label>
    </div>
</div>

<div class="d-flex flex-column card-body custom-card-body" [ngClass]="{'loading-overlay': !loaded}">
    <shared-model-grid #modelGrid *ngIf='showProjectsModels'
        [models]="projectsModels | searchSortByKeys: ['name', 'description'] : searchValue"
        (onClick)="pickModel($event)"></shared-model-grid>

    <shared-model-grid #modelGrid *ngIf='!showProjectsModels'
        [models]="models | searchSortByKeys: ['name', 'description'] : searchValue"
        (onClick)="pickModel($event)"></shared-model-grid>
</div>

<div class="d-flex justify-content-center bg-white position-absolute translate-middle start-50 custom-button p-3">
    <button class="btn btn-success" (click)="openCreateModal()" [disabled]="atModelLimit">
        <i class="fas fa-plus fa-fw"></i>
        Create new model
        <i *ngIf="atModelLimit" class="fa-solid fa-lock"></i>
    </button>
</div>