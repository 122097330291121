import { Component } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { FeatureService } from '@services/feature.service'
import { SceneService } from '@services/scene.service'
import { validateFileExtensions } from '@validators/Files'

@Component({
  selector: 'shared-create-raster-layers-from-geotiffs',
  templateUrl: './create-raster-layers-from-geotiffs.component.html',
  styleUrls: ['./create-raster-layers-from-geotiffs.component.css']
})
export class CreateRasterLayersFromGeotiffsComponent {
  public files = new FormControl<File[]>([], [Validators.required, Validators.minLength(1), validateFileExtensions(['tif', 'tiff'])])

  get selectedSceneID() { return this._sceneService.selectedSceneID }

  constructor(private _featureService: FeatureService, private _sceneService: SceneService) { }

  createRasterLayer$() {
    return this._featureService.createRasterLayer$(this.files.value, this.selectedSceneID)
  }

  addFiles(files: FileList) {
    this.files.setValue(this.files.value.concat(...Array.from(files)))
    this.files.markAsTouched()
  }

  removeFile(index: number) {
    this.files.value.splice(index, 1)
    this.files.setValue(this.files.value)
  }

  replaceFile(file: File, index: number) {
    this.files.value[index] = file
    this.files.setValue(this.files.value)
  }
}