import { FormControl, ValidatorFn } from '@angular/forms'

export function validateFileExtensions(allowedExtensions: string[]): ValidatorFn {
    return (control: FormControl<File[]>): { [key: string]: any } | null => {
        const files = control.value

        if (files && files.length > 0) {
            for (const file of files) {
                const extension = file.name.split('.').pop().toLowerCase()
                if (!allowedExtensions.includes(extension)) {
                    return { invalidFileType: true }
                }
            }
        }

        // Validation passed
        return null
    }
}