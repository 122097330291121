<ng-container *ngIf="selectedFeature$ | async as feature" [ngSwitch]="feature.type">
    <div class="p-3 d-flex flex-column d-grid gap-3" style="user-select: none;">
        <!-- Name -->
        <div>
            <label class='form-label'>Name</label>
            <input class='form-control' type="text" [(ngModel)]="feature.name"
                (focusout)="updateFeature(feature, {toast: true})">
        </div>

        <!-- Type -->
        <label>
            Feature type: {{ feature.type == "3D" ? "3D Model" : feature.type | titlecase }}
        </label>


        <!-- Replace Image File -->
        <div *ngIf='feature.image && feature.type != "view" && feature.type != "marker"'
            class="mb-3 d-flex flex-column list-group">
            <ng-container *ngIf="feature.image.fileReference; else noImageFile">
                <label role="button" for="replace-image-input" class="form-label text-truncate list-group-item mb-0">
                    <i class="fa-solid fa-file-image"></i>
                    File: {{feature?.image.fileReference.filename}}
                </label>
                <input class="form-control form-control-sm" id="replace-image-input" type="file" accept="image/*"
                    (change)="onFileChange($event.target.files[0])" title="Replace image file">
            </ng-container>
            <ng-template #noImageFile>
                <label role="button" for="replace-image-input" class="form-label text-truncate list-group-item mb-0">
                    Upload Icon
                </label>
                <input class="form-control form-control-sm" id="replace-image-input" type="file" accept="image/*"
                    (change)="onFileChange($event.target.files[0])" title="Replace image file">
            </ng-template>
        </div>

        <!-- Top Block - Groups -->
        <ng-container *ngSwitchCase="'group'">
            <ul class="list-group">
                <!-- Children's Visibility -->
                <li class="list-group-item d-flex align-items-center">
                    <span class="text-nowrap text-truncate">Visible</span>
                    <input #checkboxes id="visible" class="ms-auto form-check-input" type="checkbox"
                        [ngModel]="feature.visible" (click)="updateChildren('visible', $event)"
                        style="cursor: pointer;">
                </li>

                <!-- Children's Filterability -->
                <li class="list-group-item d-flex align-items-center">
                    <span class="text-nowrap text-truncate">Filterable</span>
                    <input #checkboxes id="filterable" class="ms-auto form-check-input" type="checkbox"
                        [ngModel]="feature.filterable" (click)="updateChildren('filterable', $event)"
                        style="cursor: pointer;">
                </li>

                <!-- Children's Interactability -->
                <li class="list-group-item d-flex align-items-center">
                    <span class="text-nowrap text-truncate">Interactable</span>
                    <input #checkboxes id="interactable" class="ms-auto form-check-input" type="checkbox"
                        [ngModel]="feature.interactable" (click)="updateChildren('interactable', $event)"
                        style="cursor: pointer;">
                </li>
            </ul>

            <!-- Object of Interest -->
            <ul class="list-group">
                <li class="list-group-item d-flex form-check form-switch">
                    <label class="form-check-label" for="group-viewer-panel-switch">
                        Show in Viewer navigation panel
                    </label>
                    <input class="ms-auto form-check-input" type="checkbox" role="switch" id="group-viewer-panel-switch"
                        [(ngModel)]="feature.objectOfInterest" (change)="updateFeature(feature)"
                        style="cursor: pointer;">
                </li>
            </ul>

            <!-- Last Updated -->
            <div class="d-flex text-muted">
                <label class="me-auto">Last edited</label>
                <label>{{ feature.lastChanged | date: 'short' }}</label>
            </div>
        </ng-container>

        <!-- Top Block - All Other Features -->
        <ng-container *ngSwitchDefault [ngSwitch]="feature.type">
            <shared-edit-tag *ngIf="feature.type == 'icon' || feature.type == 'label'">
            </shared-edit-tag>

            <shared-edit-marker *ngIf="feature.type == 'marker'"></shared-edit-marker>

            <ul *ngIf="feature.type != 'icon' && feature.type != 'label' && feature.type != 'marker'"
                class="list-group">
                <!-- Visibility -->
                <li class="list-group-item d-flex form-switch">
                    <label class="form-check-label" for="feature-visibility-switch">
                        Visible
                    </label>
                    <input class="ms-auto form-check-input" type="checkbox" role="switch" id="feature-visibility-switch"
                        [(ngModel)]="feature.visible" (change)="updateFeature(feature)" style="cursor: pointer;">
                </li>

                <!-- Opacity -->
                <li class="list-group-item d-flex align-items-center justify-content-between d-grid gap-2">
                    <label>Opacity</label>
                    <input class="form-range" type="range" min="0" max="1" step="0.05" (change)="updateFeature(feature)"
                        [(ngModel)]="feature.opacity">
                    <span class="badge rounded-pill bg-primary bg-gradient">
                        {{feature.opacity | number:'1.2-2'}}</span>
                </li>

                <ng-container *ngSwitchCase="'view'">
                    <li class="list-group-item form-switch d-flex align-items-center">
                        <label class="form-check-label text-nowrap text-truncate" for="feature-show-in-panel-switch">
                            Show in Viewer navigation panel
                        </label>
                        <input class="ms-auto form-check-input" type="checkbox" [(ngModel)]="feature.objectOfInterest"
                            id="feature-show-in-panel-switch" (change)="updateFeature(feature)"
                            style="cursor: pointer;">
                    </li>
                </ng-container>
                <ng-container *ngFor="let property of feature.properties | orderBy: 'key'; trackBy: trackByKey">
                    <!-- Load on Start - 3D, Images, ViewPoints, 360 Spheres -->
                    <ng-container *ngIf="property.key == 'loadOnStart'">
                        <li class="list-group-item d-flex form-switch align-items-center">
                            <label class="form-check-label" for="feature-load-on-start-switch">
                                Load on Start
                            </label>
                            <a class='ms-auto btn btn-sm light-btn' (click)='load()'>
                                {{unloaded ? 'Load Feature' : 'Unload Feature'}}
                            </a>
                            <input class="ms-auto form-check-input" type="checkbox" role="switch"
                                id="feature-load-on-start-switch" [(ngModel)]="property.value"
                                (change)="updateProperty(property)" style="cursor: pointer;">
                        </li>
                    </ng-container>

                    <!-- Show On Top - 3D, Images, ViewPoints, 360 Spheres -->
                    <ng-container *ngIf="property.key == 'showOnTop'">
                        <li class="list-group-item d-flex form-switch align-items-center">
                            <label class="form-check-label" for="feature-show-on-top-switch">
                                Show On Top
                            </label>
                            <shared-beta-pill [size]="5" class="ms-1"></shared-beta-pill>
                            <input class="ms-auto form-check-input" type="checkbox" role="switch"
                                id="feature-show-on-top-switch" [(ngModel)]="property.value"
                                (change)="updateProperty(property)" style="cursor: pointer;">
                        </li>
                    </ng-container>

                    <!-- Render Side - 3D -->
                    <ng-container *ngSwitchCase="'3D'">
                        <ng-container *ngIf="property.key == 'renderSide'">
                            <li class="list-group-item d-flex align-items-center d-grid gap-2">
                                <span class="text-nowrap">
                                    Render Side
                                </span>

                                <div class='ms-auto btn-group btn-group-sm' role="group">
                                    <input type="radio" class="btn-check" id="render-front-side-radio-btn" value=0
                                        autocomplete="off" (change)="updateProperty(property)"
                                        [(ngModel)]="property.value">
                                    <label class="btn btn-outline-primary" for="render-front-side-radio-btn">
                                        Front
                                    </label>

                                    <input type="radio" class="btn-check" id="render-back-side-radio-btn" value=1
                                        autocomplete="off" (change)="updateProperty(property)"
                                        [(ngModel)]="property.value">
                                    <label class="btn btn-outline-primary" for="render-back-side-radio-btn">
                                        Back
                                    </label>

                                    <input type="radio" class="btn-check" id="render-double-side-radio-btn" value=2
                                        autocomplete="off" (change)="updateProperty(property)"
                                        [(ngModel)]="property.value">
                                    <label class="btn btn-outline-primary" for="render-double-side-radio-btn">
                                        Both
                                    </label>
                                </div>
                            </li>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'image'">
                        <!-- Always Face Camera - Image -->
                        <ng-container *ngIf="property.key == 'trackCamera'">
                            <li class="list-group-item d-flex form-switch align-items-center">
                                <label class="form-check-label" for="text-always-face-camera-switch">
                                    Always face camera
                                </label>
                                <input class="ms-auto form-check-input" type="checkbox" role="switch"
                                    id="text-always-face-camera-switch" [(ngModel)]="property.value"
                                    (change)="updateProperty(property)" style="cursor: pointer;">
                            </li>
                        </ng-container>
                    </ng-container>

                    <!-- 
                    <ng-container *ngIf="property.key == 'scaleWithCamera'">
                        <li class="list-group-item d-flex form-switch align-items-center">
                            <label class="form-check-label" for="text-scale-with-camera-switch">
                                Fixed size
                            </label>
                            <input class="ms-auto form-check-input" type="checkbox" role="switch"
                                id="text-scale-with-camera-switch" [(ngModel)]="property.value"
                                (change)="updateProperty(property)" style="cursor: pointer;">
                        </li>
                    </ng-container> 
                    -->

                    <!-- Color - Polygon, Line, & Text -->
                    <ng-container *ngIf="property.key == 'color'">
                        <li class="list-group-item d-flex align-items-center">
                            <label class="form-check-label" for="feature-color-picker">Color</label>
                            <shared-color-picker class="ms-auto" [value]="property.value" (valueChange)="updateColor(property, $event)"></shared-color-picker>
                        </li>
                    </ng-container>

                    <ng-container *ngSwitchCase="'polygon'">
                        <ng-container *ngIf="property.key == 'outlineVisibility'">
                            <!-- Outline Visibility - Polygon -->
                            <li class="list-group-item d-flex form-switch align-items-center">
                                <label class="form-check-label" for="polygon-outline-switch">
                                    Outline
                                </label>
                                <input class="ms-auto form-check-input" type="checkbox" role="switch"
                                    id="polygon-outline-switch" [(ngModel)]="property.value" #outline
                                    (change)="updateProperty(property)" style="cursor: pointer;">
                            </li>

                            <!-- Outline Color - Polygon -->
                            <ng-container *ngIf="outline.checked">
                                <ng-container *ngFor="let property of feature.properties">
                                    <ng-container *ngIf="property.key == 'outlineColor'">
                                        <li class="list-group-item d-flex align-items-center">
                                            <label class="form-check-label" for="polygon-outline-color-picker">
                                                Outline Color
                                            </label>
                                            <shared-color-picker class="ms-auto" [value]="property.value" (valueChange)="updateColor(property, $event)"></shared-color-picker>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'line'">
                        <!-- Dashed - Line -->
                        <ng-container *ngIf="property.key == 'dashed'">
                            <li class="list-group-item d-flex form-switch">
                                <label class="form-check-label" for="line-dashed-property">
                                    Dashed
                                </label>
                                <input class="ms-auto form-check-input" type="checkbox" role="switch"
                                    id="line-dashed-property" [(ngModel)]="property.value"
                                    (change)="updateProperty(property)" style="cursor: pointer;">
                            </li>
                        </ng-container>

                        <!-- Width - Line -->
                        <ng-container *ngIf="property.key == 'width'">
                            <li class="list-group-item d-flex align-items-center">
                                <label class="form-check-label" for="line-width-property">
                                    Width
                                </label>
                                <select class="form-select ms-auto w-50" id="line-width-property"
                                    [(ngModel)]="property.value" (change)="updateProperty(property)">
                                    <option value=3 selected>Thin</option>
                                    <option value=5>Default</option>
                                    <option value=7>Thick</option>
                                </select>
                            </li>
                        </ng-container>

                        <!-- Line - Offset -->
                        <ng-container *ngIf="property.key == 'offset'">
                            <li class="list-group-item d-flex align-items-center justify-content-between d-grid gap-2">
                                <label>Offset</label>
                                <input class="form-range" type="range" min="-5" max="5" step="1" (change)="updateProperty(property)"
                                    [(ngModel)]="property.value">
                                <span class="badge rounded-pill bg-primary bg-gradient">
                                    {{property.value}}</span>
                            </li>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ul>
        </ng-container>
    </div>

    <!-- Tabs for Features -->
    <ul [hidden]="type == 'group' || type == 'view'" class="nav nav-tabs flex-nowrap mx-2 bg-light"
        style="user-select: none;">
        <li class="nav-item">
            <a class="nav-link active" style="color: black;" (click)="tab = 'Details'"
                [ngClass]="{'active': tab == 'Details'}">
                Details
            </a>
        </li>
        <li [hidden]="type == 'group'" class="nav-item">
            <a class="nav-link" style="color: black;" (click)="tab = 'Placement'"
                [ngClass]="{'active': tab == 'Placement'}">
                Placement
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" style="color: black;" (click)="tab = 'Interactions'"
                [ngClass]="{'active': tab == 'Interactions'}">
                Interactions
            </a>
        </li>
    </ul>

    <form class="px-3 bg-white card mx-2 border-top-0" [formGroup]="form" [style.margin-bottom]="'65px'"
        [style.border-radius]="'0 0 5px 5px'" style="user-select: none;">
        <!-- 2nd Section for Features -->
        <div [hidden]="type == 'group' || type == 'view'">
            <div [hidden]="tab != 'Details'">
                <div>
                    <div class="my-3">
                        <a class="btn btn-primary d-flex justify-content-evenly" (click)="editDescription()">
                            <div>
                                <i class="far fa-edit"></i>
                                Edit feature details
                            </div>
                        </a>
                    </div>
                </div>
                <ul class="list-group mb-3">
                    <li class="list-group-item form-switch d-flex align-items-center">
                        <label class="form-check-label" for="feature-filterable-switch">
                            Filterable
                        </label>
                        <input id="feature-filterable-switch" class="ms-auto form-check-input" type="checkbox"
                            role="switch" [(ngModel)]="feature.filterable" [ngModelOptions]="{standalone: true}"
                            (change)="updateFeature(feature)" style="cursor: pointer;">
                    </li>
                    <li class="list-group-item form-switch d-flex align-items-center">
                        <label class="form-check-label text-nowrap text-truncate" for="feature-show-in-panel-switch">
                            Show in Viewer navigation panel
                        </label>
                        <input class="ms-auto form-check-input" type="checkbox" formControlName="objectOfInterest"
                            id="feature-show-in-panel-switch" (change)="toggleEdit('objectOfInterest', form, false)"
                            style="cursor: pointer;">
                    </li>
                    <li *ngIf='correctSeam' class="list-group-item d-flex align-items-center pe-1"
                        data-bs-toggle="tooltip" [title]="tooltips.correctSeam">
                        <span class="text-nowrap text-truncate me-auto" style="min-width:0;">Correct Seam</span>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox"
                                (click)="toggleBooleanProperty('correctSeam')"
                                [checked]="correctSeam.get('value').value" style="cursor: pointer;">
                        </div>
                    </li>
                </ul>
            </div>

            <div [hidden]="tab != 'Placement'" class="mt-3">
                <div *ngIf='type == "line" || type == "polygon" || type == "rasterImage"' class="mb-3">
                    <shared-edit-coordinate-list *ngIf='coordinateString' [type]='type'
                        [valueString]='coordinateString.get("value").value' (edit)="editCoordinateString($event.value)">
                    </shared-edit-coordinate-list>
                </div>

                <div *ngIf='type != "line" && type != "polygon" && type != "rasterImage"' class="mb-3">
                    <!-- Position -->
                    <div class="d-flex d-grid gap-1">
                        <label>Position</label>
                        <a *ngIf="sceneService.selectedScene.type == 'Map'" class="btn btn-sm ms-auto p-0"
                            (click)="copyPosition(feature)">
                            Copy
                            <i class="fa-regular fa-copy fa-fw"></i>
                        </a>

                        <a *ngIf="sceneService.selectedScene.type == 'Map'" class="btn btn-sm text-success p-0"
                            (click)="pasteCoordinates(feature)">
                            Paste
                            <i class="fas fa-paste fa-fw"></i>
                        </a>
                    </div>

                    <shared-edit-vector *ngIf='type == "marker"' [form]="position" [axes]="['lng','lat']"
                        (edit)="editVector($event, 'position')">
                    </shared-edit-vector>
                    <shared-edit-vector *ngIf='type != "marker"' [form]="position"
                        [axes]="isMap ? ['lng','lat','alt'] : ['x','y','z']" [disabled]="isBackdrop"
                        (edit)="editVector($event, 'position')">
                    </shared-edit-vector>

                    <!-- Rotation -->
                    <label [hidden]='type == "marker"' class="form-label">Rotation</label>
                    <shared-edit-vector [hidden]='type == "marker"' [form]="rotation" [disabled]="isBackdrop"
                        (edit)="editVector($event, 'rotation')">
                    </shared-edit-vector>

                    <!-- Scale -->
                    <label [hidden]='type == "marker"' class="form-label">Scale</label>
                    <shared-edit-vector [hidden]='type == "marker"' [form]="scale" [disabled]="isBackdrop"
                        (edit)="editVector($event, 'scale')">
                    </shared-edit-vector>

                    <!-- Ground -->
                    <div [hidden]='type == "marker"' class="btn-group" style="width: 100%;padding-top: 10px;"
                        *ngIf="isMap">
                        <a class="btn btn-sm btn-secondary" (click)="groundFeature()">Ground</a>
                    </div>
                </div>
            </div>
            <div [hidden]="tab != 'Interactions'" class="mt-3">
                <ul class="list-group mb-3">
                    <li class="list-group-item form-switch d-flex">
                        <label class="form-check-label" for="feature-interactable-switch">
                            Interactable
                        </label>
                        <input [hidden]="isBackdrop" #interactable id="feature-interactable-switch"
                            class="ms-auto form-check-input" type="checkbox" role="switch"
                            [(ngModel)]="feature.interactable" [ngModelOptions]="{standalone: true}"
                            (change)="updateInteractability(feature)" style="cursor: pointer;">
                        <input [hidden]="!isBackdrop" id="feature-interactable-switch" class="ms-auto form-check-input"
                            type="checkbox" role="switch" disabled style="cursor: pointer;">
                    </li>

                    <!-- On Click -->
                    <li class="list-group-item form-switch d-flex flex-column d-grid gap-3">
                        <div class='d-flex'>
                            <label class="form-check-label" for="feature-on-click-switch">
                                On Click
                            </label>
                            <input class="ms-auto form-check-input" type="checkbox" role="switch"
                                [checked]="feature.onClick != 'nothing'" id="feature-on-click-switch"
                                (change)="updateOnInteract(feature, 'onClick', $event.target.checked)" #onClick
                                [disabled]="!interactable.checked" style="cursor: pointer;">
                        </div>

                        <div class='btn-group btn-group-sm w-100' role="group" *ngIf="onClick.checked">
                            <input name="feature-on-click-radio-group" type="radio" class="btn-check"
                                id="feature-on-click-outline-radio-btn" value='outline' autocomplete="off"
                                (change)="updateFeature(feature)" [(ngModel)]="feature.onClick"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="feature.type == 'polygon' || feature.type == 'line' || feature.type == 'rasterImage'">
                            <label class="btn btn-outline-primary" for="feature-on-click-outline-radio-btn">
                                Outline
                            </label>

                            <input name="feature-on-click-radio-group" type="radio" class="btn-check"
                                id="feature-on-click-enlarge-radio-btn" value='enlarge' autocomplete="off"
                                (change)="updateFeature(feature)" [(ngModel)]="feature.onClick"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="feature.type == 'polygon' || feature.type == 'rasterImage'">
                            <label class="btn btn-outline-primary" for="feature-on-click-enlarge-radio-btn">
                                Enlarge
                            </label>

                            <input name="feature-on-click-radio-group" type="radio" class="btn-check"
                                id="feature-on-click-change-opacity-radio-btn" value='change opacity' autocomplete="off"
                                (change)="updateFeature(feature)" [(ngModel)]="feature.onClick"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="feature.type != 'polygon' || feature.type == 'rasterImage'">
                            <label class="btn btn-outline-primary" for="feature-on-click-change-opacity-radio-btn">
                                Change Opacity
                            </label>
                        </div>
                    </li>

                    <!-- On Hover -->
                    <li class="list-group-item form-switch d-flex flex-column d-grid gap-3">
                        <div class='d-flex'>
                            <label class="form-check-label" for="feature-on-hover-switch">
                                On Hover
                            </label>
                            <input class="ms-auto form-check-input" type="checkbox" role="switch"
                                [checked]="feature.onHover != 'nothing'" id="feature-on-hover-switch"
                                (change)="updateOnInteract(feature, 'onHover', $event.target.checked)" #onHover
                                [disabled]="!interactable.checked" style="cursor: pointer;">
                        </div>

                        <div class='btn-group btn-group-sm w-100' role="group" *ngIf="onHover.checked">
                            <input name="feature-on-hover-radio-group" type="radio" class="btn-check"
                                id="feature-on-hover-outline-radio-btn" value='outline' autocomplete="off"
                                (change)="updateFeature(feature)" [(ngModel)]="feature.onHover"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="feature.type == 'polygon' || feature.type == 'line' || feature.type == 'rasterImage'">
                            <label class="btn btn-outline-primary" for="feature-on-hover-outline-radio-btn">
                                Outline
                            </label>

                            <input name="feature-on-hover-radio-group" type="radio" class="btn-check"
                                id="feature-on-hover-enlarge-radio-btn" value='enlarge' autocomplete="off"
                                (change)="updateFeature(feature)" [(ngModel)]="feature.onHover"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="feature.type == 'polygon' || feature.type == 'rasterImage' ">
                            <label class="btn btn-outline-primary" for="feature-on-hover-enlarge-radio-btn">
                                Enlarge
                            </label>

                            <input name="feature-on-hover-radio-group" type="radio" class="btn-check"
                                id="feature-on-hover-change-opacity-radio-btn" value='change opacity' autocomplete="off"
                                (change)="updateFeature(feature)" [(ngModel)]="feature.onHover"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="feature.type != 'polygon' || feature.type == 'rasterImage'">
                            <label class="btn btn-outline-primary" for="feature-on-hover-change-opacity-radio-btn">
                                Change Opacity
                            </label>
                        </div>
                    </li>
                </ul>

                <!-- Interactions  -->
                <button *ngIf="selectedFeaturesInteractions$ | async as interactions" [disabled]="!interactable.checked"
                    (click)="createInteraction()"
                    class="btn btn-primary d-flex align-items-center justify-content-center d-grid gap-1"
                    style="text-align: center; width: 100%;">
                    <shared-beta-pill [size]="6"></shared-beta-pill>
                    <span>
                        Edit Interactions
                    </span>
                    <div class="d-flex align-items-center justify-content-center">
                        <span *ngIf="interactions?.length > 0" class="badge rounded-pill bg-light text-primary"
                            [style.fontSize]="'0.7em'">
                            {{interactions?.length}}
                        </span>
                    </div>
                </button>
            </div>

            <div class="d-flex my-3 text-muted">
                <label class="me-auto">Last edited</label>
                <label class="pe-0">{{ feature.lastChanged | date: 'short' }}</label>
            </div>
        </div>
    </form>
</ng-container>