import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { Project } from '@classes/Project'
import { TypeToConfirmComponent } from '@modal/type-to-confirm/type-to-confirm.component'
import { CreateProjectComponent } from '@shared/create-project/create-project.component'
import { clone } from '@utils/Objects'

import { AuthenticationService } from './authentication.service'
import { ModalService } from './modal.service'
import { ProjectService, usersProjects$ } from './project.service'
import { TooltipService } from './tooltip.service'

export type OrganizationDashboardTab = "Edit Members" | "Edit Organization"

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private _filter: string = ''
  public currentUserDashboardTab: OrganizationDashboardTab = "Edit Members"

  private get currentUser() { return this._authenticationService.currentUser }
  get filter(): string { return this._filter }
  set filter(string: string) { this._filter = string }

  public showPanel: boolean = true
  public closePanel() { this.showPanel = false }
  public togglePanelShow() { this.showPanel = !this.showPanel }

  private _projectsViewSource: BehaviorSubject<"card" | "list"> = new BehaviorSubject<"card" | "list">("card")
  public projectsView$: Observable<"card" | "list"> = this._projectsViewSource.asObservable()
  get projectsView(): "card" | "list" { return this._projectsViewSource.getValue() }
  set projectsView(view: "card" | "list") { this._projectsViewSource.next(view) }

  private _selectedProjectForDetailsSource: BehaviorSubject<Project | undefined> = new BehaviorSubject<Project | undefined>(undefined)
  public selectedProjectForDetails$: Observable<Project> = this._selectedProjectForDetailsSource.asObservable()

  get selectedProjectForDetails(): Project | undefined {
    const project = clone(this._selectedProjectForDetailsSource.getValue())
    if (project) return new Project(project.title, project)
    else return undefined
  }
  set selectedProjectForDetails(project: Project | undefined) {
    if (project === undefined || this.allProjects.some(p => p.id == project.id)) {
      this._selectedProjectForDetailsSource.next(project)
    }
  }

  public myProjects$ = usersProjects$.pipe(
    map(projects => projects?.filter(
      ({ permissions }) => permissions.some(
        ({ permission, userID }) => userID == this.currentUser.sub && permission == 'Owner')
    ))
  )

  public sharedProjects$ = usersProjects$.pipe(
    startWith([]),
    map(projects => projects
      .filter(p => p.permissions.some(({ userID, permission }) => userID == this.currentUser.sub && (permission == 'Viewer' || permission == 'Editor')))
    )
  )
  public organizationProjects$ = this._projectService.organizationProjects$.pipe(startWith([]))
  get allProjects() { return this._projectService.usersProjects.concat(this._projectService.organizationProjects) }

  constructor(
    private _authenticationService: AuthenticationService,
    private _modalService: ModalService,
    private _projectService: ProjectService,
    private _tooltipService: TooltipService,
  ) {

    combineLatest([this.myProjects$, this.organizationProjects$])
      .subscribe(([mp, op]) => {
        if (this.selectedProjectForDetails) {
          let projects = mp.concat(op)
          let updatedProject = projects.find(p => p.id == this.selectedProjectForDetails.id)
          if (updatedProject) this.selectedProjectForDetails = updatedProject
        }
      })
  }

  getUserPermissionForProject(project: Project) {
    let projectPermission = project.permissions.find(p => p.userID == this.currentUser.sub)
    if (!projectPermission) return ''
    return projectPermission.permission
  }

  toggleProjectsView() {
    if (this.projectsView == "card") this.projectsView = "list"
    else this.projectsView = "card"

    setTimeout(() => this._tooltipService.intializeTooltips())
  }

  openDeleteProjectModal(project: Project) {
    const onSubmit = () => {
      this._projectService.deleteProject(project).subscribe(() => this.setFilter(this.filter))
    }

    this._modalService.showAsModal<TypeToConfirmComponent>(TypeToConfirmComponent)
      .then((componentRef) => {
        componentRef.instance.title = `Delete ${project.title}`
        componentRef.instance.message = `"${project.title}" and all of its data will be deleted. This action cannot be undone, and project's data cannot be recovered.`
        componentRef.instance.submitText = 'Delete'
        componentRef.instance.submitColor = 'red'
        componentRef.instance.confirmWord = project.title
        componentRef.instance.onSubmit = onSubmit.bind(this)
      })
  }

  setFilter(title: string) {
    this.filter = title
    setTimeout(() => this._tooltipService.intializeTooltips())
  }

  openCreateProjectModal() {
    this._modalService.insertIntoModal(CreateProjectComponent, { title: 'Create Project', submitText: 'Create', closeText: 'Cancel', closeOnSubmit: false })
  }

  projectHasFilter(project: Project) {
    return project.title.toLowerCase().includes(this.filter.toLowerCase()) || project.description.toLowerCase().includes(this.filter.toLowerCase())
  }
}
