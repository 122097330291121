<ul class="list-group" *ngIf="selectedFeature$ | async as feature">
    <!-- Change visibility -->
    <li class="list-group-item d-flex form-switch">
        <label class="form-check-label" for="feature-visibility-switch">
            Visible
        </label>
        <input class="ms-auto form-check-input" type="checkbox" role="switch" id="feature-visibility-switch"
            [(ngModel)]="feature.visible" (change)="updateFeature(feature)" style="cursor: pointer;">
    </li>

    <!-- Change between label or icon -->
    <li class="list-group-item d-flex align-items-center d-grid gap-2">
        <span class="text-nowrap">
            Style
        </span>

        <div class='ms-auto btn-group btn-group-sm' role="group">
            <input type="radio" class="btn-check" id="tag-type-text-radio-btn" value='label' name="type"
                autocomplete="off" (change)="updateType(feature)" [(ngModel)]="feature.type">
            <label class="btn btn-outline-primary" for="tag-type-text-radio-btn">
                Label
            </label>

            <input type="radio" class="btn-check" id='tag-type-icon-radio-btn' value='icon' name="type"
                autocomplete="off" (change)="updateType(feature)" [(ngModel)]="feature.type">
            <label class="btn btn-outline-primary" for="tag-type-icon-radio-btn">
                Icon
            </label>
        </div>
    </li>

    <!-- Change text content or icon -->
    <li class="list-group-item d-flex align-items-center d-grid gap-2">
        <span class="text-nowrap me-auto">
            {{feature.type == 'label' ? 'Text Content' : 'Selected Icon'}}
        </span>

        <!-- If Text, show a text display -->
        <input *ngIf='feature.type == "label"' class="form-control" [(ngModel)]="feature.text.value"
            placeholder="Display Text" (blur)="updateProperty(feature.text)" [maxlength]="100">

        <!-- If Icon, show a picker of a bunch of different icons -->
        <div *ngIf='feature.type == "icon"' class="dropstart">
            <button type="button" class="btn btn-light" data-bs-toggle="dropdown" aria-expanded="false">
                <i [style.width]="'20px'" [class]="feature.icon.value"></i>
            </button>

            <shared-font-awesome-icon-picker class="dropdown-menu shadow" [selected]="feature.icon.value"
                (picked)="updateIcon($event)">
            </shared-font-awesome-icon-picker>
        </div>
    </li>

    <!-- Change background shape -->
    <li *ngIf="feature.backgroundShape" class="list-group-item d-flex flex-column d-grid gap-1">
        <span class="text-nowrap">
            Background Shape
        </span>

        <div class='btn-group btn-group-sm' role="group">
            <input type="radio" class="btn-check" id="tag-shape-square-radio-btn" name="backgroundShape" value='square'
                autocomplete="off" (change)="updateProperty(feature.backgroundShape)"
                [(ngModel)]="feature.backgroundShape.value">
            <label class="btn btn-outline-primary" for="tag-shape-square-radio-btn">
                Square
            </label>

            <input type="radio" class="btn-check" id="tag-shape-rounded-radio-btn" name="backgroundShape"
                value='rounded' autocomplete="off" (change)="updateProperty(feature.backgroundShape)"
                [(ngModel)]="feature.backgroundShape.value">
            <label class="btn btn-outline-primary" for="tag-shape-rounded-radio-btn">
                Rounded
            </label>

            <input *ngIf="feature.type == 'icon'" type="radio" class="btn-check" id="tag-shape-circle-radio-btn"
                name="backgroundShape" value='circle' autocomplete="off"
                (change)="updateProperty(feature.backgroundShape)" [(ngModel)]="feature.backgroundShape.value">
            <label *ngIf="feature.type == 'icon'" class="btn btn-outline-primary" for="tag-shape-circle-radio-btn">
                Circle
            </label>
        </div>
    </li>

    <!-- Change background color -->
    <li *ngIf="feature.backgroundColor" class="list-group-item d-flex align-items-center">
        <label class="form-check-label" for="feature-color-picker">
            Background Color
        </label>
        <shared-color-picker class="ms-auto" [value]="feature.backgroundColor.value"
            (valueChange)="updateColor(feature.backgroundColor, $event)"></shared-color-picker>
    </li>

    <!-- Icon or Text Color -->
    <li *ngIf="feature.color" class="list-group-item d-flex align-items-center">
        <label class="form-check-label" for="feature-color-picker">
            {{feature.type == 'label' ? 'Text' : 'Icon'}} Color
        </label>
        <shared-color-picker class="ms-auto" [value]="feature.color.value"
            (valueChange)="updateColor(feature.color, $event)"></shared-color-picker>
    </li>

    <!-- Change opacity -->
    <li class="list-group-item d-flex align-items-center justify-content-between d-grid gap-2">
        <label>Opacity</label>
        <input class="form-range" type="range" min="0" max="1" step="0.05" (change)="updateFeature(feature)"
            [(ngModel)]="feature.opacity">
        <span class="badge rounded-pill bg-primary bg-gradient">
            {{feature.opacity | number:'1.2-2'}}</span>
    </li>

    <!-- Always show tag on top -->
    <li *ngIf="feature.showOnTop" class="list-group-item d-flex form-switch align-items-center">
        <label class="form-check-label" for="text-show-on-top-switch">
            Always on Top
        </label>
        <shared-beta-pill [size]="5" class="ms-1"></shared-beta-pill>
        <input class="ms-auto form-check-input" type="checkbox" role="switch" id="text-render-on-top-switch"
            [(ngModel)]="feature.showOnTop.value" (change)="updateProperty(feature.showOnTop)">
    </li>

    <!-- Always face towards the camera -->
    <li *ngIf="feature.trackCamera" class="list-group-item d-flex form-switch align-items-center">
        <label class="form-check-label" for="text-always-face-camera-switch">
            Always Face Camera
        </label>
        <input class="ms-auto form-check-input" type="checkbox" role="switch" id="text-always-face-camera-switch"
            [(ngModel)]="feature.trackCamera.value" (change)="updateProperty(feature.trackCamera)">
    </li>

    <!-- Always scale with the camera -->
    <!-- <li *ngIf="feature.scaleWithCamera" class="list-group-item d-flex form-switch align-items-center">
        <label class="form-check-label" for="text-scale-with-camera-switch">
            Fixed Size
        </label>
        <input class="ms-auto form-check-input" type="checkbox" role="switch" id="text-scale-with-camera-switch"
            [(ngModel)]="feature.scaleWithCamera.value" (change)="updateProperty(feature.scaleWithCamera)">
    </li> -->
</ul>