import { BehaviorSubject, of } from 'rxjs'
import { distinctUntilKeyChanged, filter, switchMap, tap } from 'rxjs/operators'

import { Component } from '@angular/core'
import { Asset } from '@classes/Asset'
import { AssetField } from '@classes/AssetField'
import { AssetType } from '@classes/AssetType'
import { FeatureProperty } from '@classes/FeatureProperty'
import { AssetPaginationOptions, AssetRow, AssetService, AssetTypePaginationOptions } from '@services/asset.service'
import { FeatureService } from '@services/feature.service'

@Component({
  selector: 'shared-asset-picker',
  templateUrl: './asset-picker.component.html',
  styleUrls: ['./asset-picker.component.css']
})
export class AssetPickerComponent {
  private _selectedAssetTypeSource: BehaviorSubject<AssetType> = new BehaviorSubject(undefined)
  public selectedAssetType$ = this._selectedAssetTypeSource.asObservable()
  get selectedAssetType() { return this._selectedAssetTypeSource.getValue() }
  set selectedAssetType(assetType: AssetType) {
    this.filterMap = new Map<number, string>()
    this.assetsOffset = 0
    this._selectedAssetTypeSource.next(assetType)
  }

  public selectedAsset: Asset
  public compiledAssetData: { key: string, value: any }[]

  get typeForSelectedAsset() {
    return this.assetTypes?.find(at => this.selectedAsset.assetTypeID == at.id)
  }

  public assetTypesLoading: boolean = true
  private loadingMoreAssetTypes: boolean = false
  public assetTypes: AssetType[] = []
  private assetTypesCount: number = 0
  private assetTypeSize: number = 100
  private assetTypeOffset: number = 0
  public assetTypeSearch = ''

  private scrollTimeout: any

  public assetTableRows: AssetRow[] = []
  public assetFields: AssetField[] = []
  public sortByFieldID: number
  public sortByAssetID: boolean
  public assetsSize: number = 100
  public assetsOffset: number = 0
  public sortOrder: 'ASC' | 'DESC' = 'ASC'

  public filterMap = new Map<number, string>()

  get filterFieldIDs() {
    const fieldIDs: number[] = []
    for (let key of this.filterMap.keys()) {
      fieldIDs.push(key)
    }
    return fieldIDs
  }

  get filterValues() {
    const fieldValues: string[] = []
    for (let value of this.filterMap.values()) {
      fieldValues.push(value)
    }
    return fieldValues
  }

  private loadingMoreAssets: boolean = false
  public loading = true

  getValueType(valueIndex: number) {
    return this.assetFields[valueIndex]?.type
  }

  constructor(private assetService: AssetService, private featureService: FeatureService) {
    this._getAssetTypes().pipe(
      tap(({ assetTypes, assetTypesCount }) => {
        this.assetTypesLoading = false
        this.assetTypes = assetTypes
        this.assetTypesCount = assetTypesCount
      }),
      switchMap(({ assetTypes }) => {
        const selectedAssetID = this.featureService.selectedFeature.properties.find(prop => prop.key == 'assetID')?.value
        if (selectedAssetID) return this.assetService.getAsset(selectedAssetID).pipe(
          tap(asset => {
            this.selectedAssetType = assetTypes.find(t => t.id == asset.assetTypeID)
            this.selectedAsset = asset
            this.compiledAssetData = this.compileAssetData(asset)
          }))
        else return of(assetTypes)
      })
    ).subscribe()

    this.selectedAssetType$.pipe(
      filter(at => at !== undefined),
      distinctUntilKeyChanged('id'),
      switchMap(assetType => {
        return this._getAssetTypeData(assetType.id)
      })
    ).subscribe(assetType => {
      this.assetService.selectedAssetType = assetType
      this.assetFields = assetType.fields
      this.assetTableRows = assetType.rows
      this.loading = false

    })
  }


  _getAssetTypes(options?: Partial<AssetTypePaginationOptions>) {
    return this.assetService.getAssetTypes({
      size: options?.size ?? this.assetTypeSize,
      offset: options?.offset ?? this.assetTypeOffset,
      sortBy: options?.sortBy ?? 'name',
      sortOrder: options?.sortOrder ?? 'ASC',
      filterColumn: options?.filterColumn ?? 'name',
      filterValue: options?.filterValue ?? ''
    })
  }

  getAssetTypes(options?: Partial<AssetTypePaginationOptions>) {
    this.assetTypesLoading = true
    this._getAssetTypes(options).subscribe(({ assetTypes, assetTypesCount }) => {
      this.assetTypesLoading = false
      this.assetTypes = assetTypes
      this.assetTypesCount = assetTypesCount

    })
  }

  compileAssetData(asset: Asset) {
    const data = []
    const orderedFields = this.orderFieldsByNextFieldID(asset.fields)
    orderedFields.forEach(field => {
      const obj = {
        fieldName: field.name,
        type: field.type,
        value: asset.fieldValues.find(fv => fv.assetFieldID == field.id).value
      }
      data.push(obj)
    })
    return data
  }

  orderFieldsByNextFieldID(fields: AssetField[]) {
    const fieldMap = new Map(fields.map(field => [field.id, field]));

    let currentField = fields.find(field => !fields.some(f => f.nextFieldID === field.id));

    const orderedFields: AssetField[] = [];
    while (currentField) {
      orderedFields.push(currentField);
      currentField = fieldMap.get(currentField.nextFieldID);
    }

    return orderedFields;
  }

  searchAssetTypes() {
    if (this.assetTypeSearch == '') {
      this.assetTypeOffset = 0
      this.getAssetTypes()
    } else {
      this.getAssetTypes({
        filterValue: this.assetTypeSearch
      })
    }
  }

  onListScroll(event) {
    if (this.assetTypesCount <= this.assetTypes.length) return
    if (this.loadingMoreAssetTypes) return
    const target = event.target
    const position = target.scrollTop + target.clientHeight + 20
    const bottom = target.scrollHeight
    const atBottomOfList = position >= bottom
    if (atBottomOfList && !this.scrollTimeout) {
      this.loadingMoreAssetTypes = true
      this.scrollTimeout = setTimeout(() => {
        this.loadMoreAssetTypes()
        this.scrollTimeout = null
      }, 300)
    }
  }

  loadMoreAssetTypes() {
    this.assetTypeOffset += this.assetTypeSize
    this.appendAssetTypes()
  }

  appendAssetTypes() {
    this._getAssetTypes({ filterValue: this.assetTypeSearch, offset: this.assetTypeOffset }).subscribe(({ assetTypes }) => {
      this.assetTypes.push(...assetTypes)
      this.loadingMoreAssetTypes = false
    })
  }

  selectAssetType(assetType: AssetType) {
    this.selectedAssetType = assetType
  }


  private _getAssetTypeData(assetTypeID: number, options?: Partial<AssetPaginationOptions>) {
    return this.assetService.getAssetTypeData(assetTypeID, {
      size: options?.size ?? this.assetsSize,
      offset: options?.offset ?? this.assetsOffset,
      sortByFieldID: options?.sortByFieldID ?? this.sortByFieldID,
      sortOrder: options?.sortOrder ?? this.sortOrder,
      filterFieldIDs: options?.filterFieldIDs ?? this.filterFieldIDs,
      filterValues: options?.filterValues ?? this.filterValues
    })
  }

  onTableScroll(event) {
    if (this.assetTableRows.length == this.assetService.selectedAssetType.assetCount) return
    if (this.loadingMoreAssets) return
    const target = event.target
    const position = target.scrollTop + target.clientHeight + 20
    const bottom = target.scrollHeight

    const atBottomOfTable = position >= bottom

    if (atBottomOfTable && !this.scrollTimeout) {
      this.loadingMoreAssets = true
      this.scrollTimeout = setTimeout(() => {
        this.loadMoreAssets()
        this.scrollTimeout = null
      }, 300)
    }
  }

  loadMoreAssets() {
    this.assetsOffset += this.assetsSize
    this.appendAssetTableData()
  }

  appendAssetTableData() {
    this._getAssetTypeData(this.selectedAssetType.id).subscribe(results => {
      this.assetTableRows.push(...results.rows)
      this.loadingMoreAssets = false
    })
  }

  selectAsset(assetID: number) {
    this.assetService.getAsset(assetID).subscribe(asset => {
      this.selectedAsset = asset
      this.compiledAssetData = this.compileAssetData(asset)
    })

    const featureProperty = this.featureService.selectedFeature.properties.find(prop => prop.key == 'assetID')
    if (featureProperty) {
      featureProperty.value = assetID
      this.featureService.updateFeatureProperty(featureProperty).subscribe()
    }
    else this.featureService.createFeatureProperty(new FeatureProperty('decimal', 'assetID', assetID.toString(), { featureID: this.featureService.selectedFeature.id })).subscribe()
  }

  removeSelectedAsset() {
    this.selectedAsset = undefined
    this.compiledAssetData = undefined
    const featureProperty = this.featureService.selectedFeature.properties.find(prop => prop.key == 'assetID')
    this.featureService.deleteFeatureProperty(featureProperty).subscribe()
  }

  toggleSortOrder() {
    this.sortOrder = this.sortOrder == 'ASC' ? 'DESC' : 'ASC'
  }

  public sortByField(field: AssetField) {
    if (this.sortByFieldID == field.id) this.toggleSortOrder()
    else {
      this.sortOrder = 'ASC'
      this.sortByFieldID = field.id
      this.assetsOffset = 0
    }
    this._getAssetTypeData(this.selectedAssetType.id).subscribe(assetType => {
      this.assetTableRows = assetType.rows
    })
  }

  public filter(field: AssetField, search: string) {
    if (search == '') {
      this.filterMap.delete(field.id)
    } else {
      this.filterMap.set(field.id, search)
    }

    this.assetsOffset = 0
    this._getAssetTypeData(this.selectedAssetType.id).subscribe(assetType => {
      this.assetTableRows = assetType.rows
    })
  }

  isArray(value: any): boolean {
    return Array.isArray(value)
  }
}
