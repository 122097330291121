<div class="upload-zone" [class.dragover]="isDragOver" (dragenter)="onDragEnter($event)"
            (dragleave)="onDragLeave($event)" (dragover)="onDragOver($event)" (drop)="onDrop($event)"
            (click)="fileInput.click()">
  <input #fileInput type="file" multiple (change)="onFileChange($event)" 
    [accept]="acceptedFileTypes || '*'"
    style="display: none" />
  <i class="fas fa-cloud-upload-alt"></i>
  <p>Drag and drop files here or click to select files</p>
  <p class="file-types" *ngIf="acceptedFileTypes">
    Accepted file types: {{ acceptedFileTypes }}
  </p>
</div>