<div class="card" style="border:none;" *ngIf="interactions.length > 0">
    <div class="card p-2 bg-white" *ngFor="let interaction of interactions; index as i">
        <div class="d-flex p-2 gap-3 mb-1 align-items-center">
            <div class="fs-4"> Interaction {{i+1}}</div>

            <!-- Select Interaction Type -->
            <div class="dropdown me-auto">
                <button class="btn btn-primary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    On {{interaction.type | titlecase}}
                </button>

                <ul class="dropdown-menu">
                    <li *ngFor="let type of interactionTypes" style="cursor: pointer;">
                        <a class="dropdown-item" [ngClass]="{'text-primary': interaction.type == type}"
                            (click)="interaction.type = type; updateInteraction(interaction)">
                            <span>{{type | titlecase}}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <a class="btn btn-close" (click)="deleteInteraction(interaction)"></a>
        </div>
        <div class="form-group">
            <div class='card' style="border: none;margin-bottom: 4px;">
                <!-- Edit Actions -->
                <div class='card-body border mb-2 mt-2' *ngFor="let action of interaction.actions">
                    <ng-container [ngSwitch]="action.key">
                        <div class="d-flex gap-2">
                            <!-- Edit Action's Key -->
                            <div class="flex-fill text-truncate" style="min-width: 20%;">
                                <select class="form-control form-select form-select bg-light" [(ngModel)]="action.key"
                                    (change)="updateAction(action, interaction, true)">
                                    <option *ngFor="let key of allActionKeys" [value]="key">
                                        {{ transformKey(key) | titlecase }}</option>
                                </select>
                            </div>

                            <!-- Edit values for move, rotate, scale, disableInteractions, enableInteractions, toggleInteractions, focus, hide, show, & toggleHidden -->
                            <ng-container *ngSwitchCase="['focus', 'hide', 'show', 'toggleHidden'].includes(action.key) 
                                    ? action.key : ''">
                                <ng-select style="width: 65%" [items]="sortedFeatures" [bindValue]="'id'"
                                    [bindLabel]="'name'" placeholder="Select feature"
                                    (change)="updateAction(action, interaction)" [(ngModel)]="action.value.id">
                                </ng-select>
                            </ng-container>

                            <ng-container *ngSwitchCase="['loadOut', 'loadIn', 'toggleLoad'].includes(action.key) 
                                    ? action.key : ''">
                                <ng-select style="width: 65%" [items]="modelFeatures" [bindValue]="'id'"
                                    [bindLabel]="'name'" placeholder="Select feature"
                                    (change)="updateAction(action, interaction)" [(ngModel)]="action.value.id">
                                </ng-select>
                            </ng-container>

                            <!-- Edit scene id for openScene -->
                            <ng-container *ngSwitchCase="['openScene'].includes(action.key) 
                                    ? action.key : ''">
                                <ng-select style="width: 65%" [items]="otherScenes" [bindValue]="'id'"
                                    [bindLabel]="'name'" placeholder="Select scene"
                                    (change)="updateAction(action, interaction)" [(ngModel)]="action.value.id">
                                </ng-select>
                            </ng-container>

                            <ng-container *ngSwitchCase="'showDetails'">
                                <!-- Edit display type for showDetails -->
                                <div class="d-flex d-grid gap-1">
                                    <div class="flex-fill text-truncate">
                                        <select class="form-control form-select form-select bg-light"
                                            [(ngModel)]="action.value.string"
                                            (change)="updateAction(action, interaction)">
                                            <option [ngClass]="{'text-primary': action.value.string == 'panel'}"
                                                [value]="'panel'">
                                                In Right Panel
                                            </option>
                                            <option [ngClass]="{'text-primary': action.value.string == 'modal'}"
                                                [value]="'modal'">
                                                In Overlay Dialog
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="action.value.string == 'modal'" class="flex-fill text-truncate">
                                        <select class="form-control form-select form-select bg-light"
                                            [(ngModel)]="action.value.size"
                                            (change)="updateAction(action, interaction)">
                                            <option [ngClass]="{'text-primary': action.value.size == 'medium'}"
                                                [value]="'medium'">
                                                Medium
                                            </option>
                                            <option [ngClass]="{'text-primary': action.value.size == 'large'}"
                                                [value]="'large'">
                                                Large
                                            </option>
                                            <option [ngClass]="{'text-primary': action.value.size == 'fullscreen'}"
                                                [value]="'fullscreen'">
                                                Fullscreen
                                            </option>
                                        </select>
                                    </div>


                                </div>
                            </ng-container>

                            <!-- Edit position for moveTo -->
                            <ng-container *ngSwitchCase="'moveTo'">
                                <div class="d-flex flex-wrap gap-1">
                                    <ng-container *ngIf="selectedScene.type == 'Map'; else otherScenes">
                                        <div class="">
                                            <div class="input-group input-group-sm">
                                                <span class="input-group-text fw-bold">bearing</span>
                                                <input [(ngModel)]="action.value.bearing"
                                                    (change)="updateAction(action, interaction)" type="number"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="input-group input-group-sm">
                                                <span class="input-group-text fw-bold">longitude</span>
                                                <input [(ngModel)]="action.value.longitude"
                                                    (change)="updateAction(action, interaction)" type="number"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="input-group input-group-sm">
                                                <span class="input-group-text fw-bold">latitude</span>
                                                <input [(ngModel)]="action.value.latitude"
                                                    (change)="updateAction(action, interaction)" type="number"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="input-group input-group-sm">
                                                <span class="input-group-text fw-bold">pitch</span>
                                                <input [(ngModel)]="action.value.pitch"
                                                    (change)="updateAction(action, interaction)" type="number"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="input-group input-group-sm">
                                                <span class="input-group-text fw-bold">zoom</span>
                                                <input [(ngModel)]="action.value.zoom"
                                                    (change)="updateAction(action, interaction)" type="number"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #otherScenes>
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text" style="min-width: 150px;">
                                                Camera Position
                                            </span>
                                            <span class="input-group-text fw-bold">x</span>
                                            <input [(ngModel)]="action.value.cameraPosition.x"
                                                (change)="updateAction(action, interaction)" type="number"
                                                class="form-control">
                                            <span class="input-group-text fw-bold">y</span>
                                            <input [(ngModel)]="action.value.cameraPosition.y"
                                                (change)="updateAction(action, interaction)" type="number"
                                                class="form-control">
                                            <span class="input-group-text fw-bold">z</span>
                                            <input [(ngModel)]="action.value.cameraPosition.z"
                                                (change)="updateAction(action, interaction)" type="number"
                                                class="form-control">
                                        </div>
                                        <div *ngIf="selectedScene.type != '360 Image'">
                                            <div class="input-group input-group-sm">
                                                <span class="input-group-text" style="min-width: 150px;">
                                                    Controls Position
                                                </span>
                                                <span class="input-group-text fw-bold">x</span>
                                                <input [(ngModel)]="action.value.controlsPosition.x"
                                                    (change)="updateAction(action, interaction)" type="number"
                                                    class="form-control">
                                                <span class="input-group-text fw-bold">y</span>
                                                <input [(ngModel)]="action.value.controlsPosition.y"
                                                    (change)="updateAction(action, interaction)" type="number"
                                                    class="form-control">
                                                <span class="input-group-text fw-bold">z</span>
                                                <input [(ngModel)]="action.value.controlsPosition.z"
                                                    (change)="updateAction(action, interaction)" type="number"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </ng-template>

                                    <button class="btn btn-primary btn-sm"
                                        (click)="updatePosition(action, interaction)">
                                        Update Position
                                    </button>

                                </div>
                            </ng-container>

                            <ng-container *ngSwitchCase="'openTab'">
                                <div class="input-group w-auto">
                                    <label class="input-group-text">URL</label>
                                    <input class="form-control" [(ngModel)]="action.value.string"
                                        (change)="updateAction(action, interaction)" type="text">
                                </div>

                                <div class="d-flex align-items-center d-grid gap-2">
                                    <label [for]="'new-tab-switch-' + action.id" type="button">Open in New Tab</label>
                                    <div class="form-check form-switch d-flex align-items-center">
                                        <input class="form-check-input" type="checkbox" role="button" [value]="false"
                                            [id]="'new-tab-switch-' + action.id" [(ngModel)]="action.value.newTab"
                                            (change)="updateAction(action, interaction)">
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Create, Delete Button Group -->
                            <div class="flex-grow">
                                <a class="btn btn-danger btn" (click)="deleteAction(action.id, interaction)"><i
                                        class="fas fa-trash"></i></a>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- Add Action -->
                <div class="d-flex justify-content-center m-2">
                    <a class="btn btn-outline-secondary" (click)="createAction(interaction)">
                        <i class="far fa-plus pe-1"></i>Add Action</a>
                </div>
            </div>
        </div>
    </div>

    <br>
</div>

<div class="d-grid gap-2 col-6 mx-auto">
    <a class="btn btn-success" (click)="submit()"><i class="far fa-plus pe-1"></i>Add Interaction</a>
</div>