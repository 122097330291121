import { Component } from '@angular/core'
import { LngLatLike } from 'mapbox-gl'
import { Object3D } from 'three'

import { ContextMenuService } from '@services/context-menu.service'
import { CoordinatesClipboard, CopyService } from '@services/copy.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { RightSidebarService } from '@services/right-sidebar.service'
import { FeatureService } from '@services/feature.service'
import { Feature } from '@classes/Feature'
import { InteractionService } from '@services/interaction.service'
import { AuthenticationService } from '@services/authentication.service'



@Component({
  selector: 'shared-map-menu',
  templateUrl: './map-menu.component.html',
  styleUrls: ['./map-menu.component.css']
})
export class MapMenuComponent {
  public currentPosition: [number, number] = [0, 0]
  public clipboardCoordinates: [number, number][] = []
  public overlappingMarkers: Feature[] = []
  public coordinates: LngLatLike

  get mapSpace() { return this.envManager.mapSpace }
  get transformControls() { return this.envManager.sceneManager.transformControls }

  constructor(
    public contextMenuService: ContextMenuService,
    public copyService: CopyService,
    public envManager: EnvironmentManagerService,
    public rightSidebarService: RightSidebarService,
    public featureService: FeatureService,
    public interactionService: InteractionService,
    public authenticationService: AuthenticationService,
  ) {
    this.currentPosition = [
      this.mapSpace?.mouseXY?.x,
      this.mapSpace?.mouseXY?.y
    ]
    this.coordinates = [
      this.mapSpace?.mouseLngLat?.lng,
      this.mapSpace?.mouseLngLat?.lat
    ]

    this.copyService.getCoordinates().then(coordinates => this.clipboardCoordinates = coordinates)

    this.overlappingMarkers = []
    let selectedFeatures = this.mapSpace.raycaster.raycaster.intersectObjects(this.mapSpace.raycaster.meshes)
    const clickedMapFeatures = this.mapSpace.map.queryRenderedFeatures([this.mapSpace?.mouseXY?.x, this.mapSpace?.mouseXY?.y])

    // If the first object is an icon, label, or image then grab it
    const firstObj = selectedFeatures[0]?.object?.parent?.userData?.type
    if(firstObj == 'icon' || firstObj == 'label'){
      // Get all Markers
      selectedFeatures.map(feature => {
        const featureType = feature.object?.parent?.userData?.type
        if(featureType == 'icon' || featureType == 'label'){
          this.overlappingMarkers.push(this.featureService.getFeature(feature.object.parent.userData.featureID))
        }
      })
    }

    // Get all Icons
    clickedMapFeatures.map(mapFeature =>{
      if(mapFeature.properties.origin === 'karta' && mapFeature.layer.type === 'symbol'){
        this.overlappingMarkers.push(this.featureService.getFeature(+mapFeature.id))
      }
    })

    // If there's just one marker or icon then get rid of it
    if(this.overlappingMarkers.length == 1) this.overlappingMarkers = []
    
  }
  
  displayCoordinate(coordinate: LngLatLike) {    
    return `${parseFloat(coordinate[0]?.toString()).toFixed(5)}, ${parseFloat(coordinate[1]?.toString()).toFixed(5)}`
  }

  copyPosition() {
    this.copyService.getCoordinates()
      .then(coordinates => {
        coordinates.push([this.coordinates[0], this.coordinates[1]])

        const contents = { type: 'coordinates', coordinates } as CoordinatesClipboard

        this.copyService.copyContentsToClipboard(contents)
        this.copyService.copiedContentType = 'coordinates'

        this.contextMenuService.closeContext()
      })
  }

  clear() {
    this.copyService.clearClipboard()
    this.contextMenuService.closeContext()
  }

  focus(feature: Feature){
    if (!feature?.interactable) return

    this.envManager.highlight(feature, 'onClick', false)
    this.featureService.setSelectedFeatureByID(feature.id)

    if(this.authenticationService.currentModule == "viewer"){

      this.interactionService.initiateActions(feature, 'click')

    }else if(this.authenticationService.currentModule == "builder"){

      this.rightSidebarService.setTab("Feature")
      this.rightSidebarService.openPanel()
      if(feature.type == 'icon'){
        this.transformControls.detach()
        this.transformControls.attach(this.envManager.sceneManager.getFeatureGroup(feature.id))
      }
    }

    this.overlappingMarkers = []
  }
}