<shared-loading-screen [showTips]='currentModule == "builder"'
    *ngIf="currentModule == 'builder' || currentModule == 'viewer'">
</shared-loading-screen>

<!-- Sign Out Modal -->
<div class="modal fade in" id="staticBackdrop" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div *ngIf="bothUsers$ | async as user" class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title m-0" id="staticBackdropLabel">{{ user.name }}</h1>
                <button class="btn btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row align-items-center profile-header">
                    <div class="col-md-2 mb-3">
                        <img [src]="user.picture" alt="User's profile picture"
                            class="rounded-circle img-fluid profile-picture" />
                    </div>

                    <div class="col-md">
                        <div class="text-center text-md-left">
                            <p class="lead text-muted">{{ user.email }}</p>
                        </div>
                    </div>

                    <div *ngIf="editing" class="col-md-1 ms-auto me-2">
                        <a (click)="editing=!editing" class="btn-sm btn-primary text-center me-1">
                            <i class="fas fa-edit"></i>
                        </a>
                    </div>

                    <div *ngIf="editing">
                        <div class="d-flex pb-3">
                            <span class="input-group-text">Name</span>
                            <input type="text" class="form-control" [(ngModel)]='user.name' (change)="updateUser(user)">
                        </div>
                        <div class="d-flex pb-3">
                            <span class="input-group-text">Picture</span>
                            <input type="text" class="form-control" [(ngModel)]='user.picture' (change)="updateUser(user)">
                        </div>
                    </div>

                    <div *ngIf="!editing" class="col-md-1">
                        <a (click)="editing=!editing" class="btn-sm btn-primary text-center me-1">
                            <i class="fas fa-edit"></i>
                        </a>
                    </div>
                    <shared-organization-picker></shared-organization-picker>
                </div>
                <div class="pt-3">
                    <button (click)="resetPassword()" class="btn btn-primary">Reset Password</button>
                </div>
            </div>
            <small class="text-muted ps-3 pb-3">
                Having trouble? Contact <a href="mailto:support@kartorium.com">support@kartorium.com</a>
            </small>
            <div class="modal-footer">
                <button (click)="logout()" class="btn btn-danger">Sign Out</button>
            </div>
        </div>
    </div>
</div>

<div (pointerdown)="closeContextMenu()" style="position: absolute; height: 100dvh; width:100dvw; overflow: hidden;">
    <router-outlet></router-outlet>
</div>

<ng-template contextMenu></ng-template>