import { CollapsibleDirective } from 'src/app/directives/collapsible.directive'
import { ContextMenuDirective } from 'src/app/directives/context-menu.directive'
import { OpacityHandlerDirective } from 'src/app/directives/opacity-handler.directive'
import { LimitArrayPipe } from 'src/app/pipes//limit-array.pipe'
import { EqualsPipe } from 'src/app/pipes/equals.pipe'
import { OrderByPipe } from 'src/app/pipes/orderby.pipe'
import { SearchScenesSortPipe } from 'src/app/pipes/search-scenes-sort.pipe'
import { SearchSortByKeysPipe } from 'src/app/pipes/search-sort-by-keys.pipe'
import { SearchSortByNamePipe } from 'src/app/pipes/search-sort-by-name.pipe'
import { TrimPipe } from 'src/app/pipes/trim.pipe'
import { TypeOfPipe } from 'src/app/pipes/typeof.pipe'

import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatExpansionModule } from '@angular/material/expansion'
import { RouterModule } from '@angular/router'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { NgSelectModule } from '@ng-select/ng-select'
import { ResourceLimitAlertComponent } from '@shared/resource-limit-alert/resource-limit-alert.component'

import { AssetPickerComponent } from './asset-picker/asset-picker.component'
import { BetaPillComponent } from './beta-pill/beta-pill.component'
import { ColorPickerComponent } from './color-picker/color-picker.component'
import { ConnectionAttachmentEditor } from './connection-attachment-editor/connection-attachment-editor.component'
import { ConnectionCustomFieldsEditorComponent } from './connection-custom-fields-editor/connection-custom-fields-editor.component'
import { ConnectionDataPicker } from './connection-data-picker/connection-data-picker.component'
import { LeftSideMenuComponent } from './context-menus/leftside-menu/leftside-menu.component'
import { MapMenuComponent } from './context-menus/map-menu/map-menu.component'
import { ModelMenuComponent } from './context-menus/model-menu/model-menu.component'
import { ControlBtnsComponent } from './control-btns/control-btns.component'
import { CreateFeaturesFromGeojsonComponent } from './create-features-from-geojson/create-features-from-geojson.component'
import { CreateMapSceneComponent } from './create-map-scene/create-map-scene.component'
import { CreateMarkerComponent } from './create-marker/create-marker.component'
import { CreatePermissionComponent } from './create-permission/create-permission.component'
import { CreateProjectComponent } from './create-project/create-project.component'
import { UploadProjectFilesComponent } from './upload-project-files/upload-project-files.component'
import { CreateRasterLayersFromGeotiffsComponent } from './create-raster-layers-from-geotiffs/create-raster-layers-from-geotiffs.component'
import { CreateSceneComponent } from './create-scene/create-scene.component'
import { CreateStandardSceneComponent } from './create-standard-scene/create-standard-scene.component'
import { CreateThreeSixtySceneComponent } from './create-three-sixty-scene/create-three-sixty-scene.component'
import { CreateVirtualTourComponent } from './create-virtual-tour/create-virtual-tour.component'
import { CustomFieldViewerComponent } from './custom-field-viewer/custom-field-viewer.component'
import { CustomFieldsUndoRedoBtnsComponent } from './custom-fields-undo-redo-btns/custom-fields-undo-redo-btns.component'
import { EditAttachmentListComponent } from './edit-attachment-list/edit-attachment-list.component'
import { EditConnectionAttachmentComponent } from './edit-connection-attachment/edit-connection-attachment.component'
import { EditConnectionCustomFieldComponent } from './edit-connection-custom-field/edit-connection-custom-field.component'
import { EditConnectionComponent } from './edit-connection/edit-connection.component'
import { EditCoordinateListComponent } from './edit-coordinate-list/edit-coordinate-list.component'
import { EditFeatureCustomFieldsComponent } from './edit-feature-custom-fields/edit-feature-custom-fields.component'
import { EditFeatureComponent } from './edit-feature/edit-feature.component'
import { EditInteractionsComponent } from './edit-interactions/edit-interactions.component'
import { EditMarkerComponent } from './edit-marker/edit-marker.component'
import { EditModelPermissionsComponent } from './edit-model-permissions/edit-model-permissions.component'
import { EditProjectPermissionsComponent } from './edit-project-permissions/edit-project-permissions.component'
import { EditRemoteAttachmentComponent } from './edit-remote-attachment/edit-remote-attachment.component'
import { EditRuleComponent } from './edit-rule/edit-rule.component'
import { EditSceneComponent } from './edit-scene/edit-scene.component'
import { EditTagComponent } from './edit-tag/edit-tag.component'
import { EditVectorComponent } from './edit-vector/edit-vector.component'
import { ErrorPageComponent } from './error-page/error-page.component'
import { FeatureIconComponent } from './feature-icon/feature-icon.component'
import { FeatureVisibilityIconComponent } from './feature-visibility-icon/feature-visibility-icon.component'
import { FilterConnectionsComponent } from './filter-connections/filter-connections.component'
import { FontAwesomeIconPickerComponent } from './font-awesome-icon-picker/font-awesome-icon-picker.component'
import { JSONLiteralComponent } from './json-literal/json-literal.component'
import { JSONObjectComponent } from './json-object/json-object.component'
import { KartaLogoComponent } from './karta-logo/karta-logo.component'
import { LatlngHelperComponent } from './latlng-helper/latlng-helper.component'
import { LeftMenuButtonComponent } from './left-menu-button/left-menu-button.component'
import { LoadingScreenComponent } from './loading-screen/loading-screen.component'
import { LogoComponent } from './logo/logo.component'
import { MeasurementBtnComponent } from './measurement-btn/measurement-btn.component'
import { MiniMapComponent } from './mini-map/mini-map.component'
import { AddAssetComponent } from './modals/add-asset/add-asset.component'
import { AddMemberComponent } from './modals/add-member/add-member.component'
import { AdvancedSearchComponent } from './modals/advanced-search/advanced-search.component'
import { ConfirmActionComponent } from './modals/confirm-action/confirm-action.component'
import { CreateAssetTypeComponent } from './modals/create-asset-type/create-asset-type.component'
import { CreateConnectionComponent } from './modals/create-connection/create-connection.component'
import { CreateFeatureComponent } from './modals/create-feature/create-feature.component'
import { CreateInteractionComponent } from './modals/create-interaction/create-interaction.component'
import { HtmlFieldComponent } from './modals/create-interaction/html-field/html-field.component'
import { IdPickerComponent } from './modals/create-interaction/id-picker/id-picker.component'
import { StringFieldComponent } from './modals/create-interaction/string-field/string-field.component'
import { Vector3PickerComponent } from './modals/create-interaction/vector3-picker/vector3-picker.component'
import { CreateModelComponent } from './modals/create-model/create-model.component'
import { DeleteComponent } from './modals/delete/delete.component'
import { EditConnectionDetailsComponent } from './modals/edit-connection-details/edit-connection-details.component'
import { EditDetailsComponent } from './modals/edit-details/edit-details.component'
import { ModalComponent } from './modals/modal/modal.component'
import { ModelDetailsComponent } from './modals/model-details/model-details.component'
import { ProjectDetailsComponent } from './modals/project-details/project-details.component'
import { ShareLinkComponent } from './modals/share-link/share-link.component'
import { ShowAssetFilesComponent } from './modals/show-asset-files/show-asset-files.component'
import { ShowAssetHistoryComponent } from './modals/show-asset-history/show-asset-history.component'
import { TypeToConfirmComponent } from './modals/type-to-confirm/type-to-confirm.component'
import { ModelGridComponent } from './model-grid/model-grid.component'
import { ModelPickerComponent } from './model-picker/model-picker.component'
import { MultipleFilePickerComponent } from './multiple-file-picker/multiple-file-picker.component'
import { NavbarMenuMobileComponent } from './navbar-menu-mobile/navbar-menu-mobile.component'
import { NavbarMobileComponent } from './navbar-mobile/navbar-mobile.component'
import { NavigationTabsComponent } from './navigation-tabs/navigation-tabs.component'
import { ProfilePictureComponent } from './profile-picture/profile-picture.component'
import { RightPanelComponent } from './right-panel/right-panel.component'
import { RightSidebarBtnsComponent } from './right-sidebar-btns/right-sidebar-btns.component'
import { RuleGroupComponent } from './rule-group/rule-group.component'
import { SceneIconComponent } from './scene-icon/scene-icon.component'
import { ShareBtnComponent } from './share-btn/share-btn.component'
import { ToastContainerComponent } from './toast-container/toast-container.component'
import { ToastComponent } from './toast/toast.component'
import { UndoRedoBtnsComponent } from './undo-redo-btns/undo-redo-btns.component'
import { UpgradeTierBannerComponent } from './upgrade-tier-banner/upgrade-tier-banner.component'
import { UpgradeTierButtonComponent } from './upgrade-tier-button/upgrade-tier-button.component'
import { FloatingLabelComponent } from './utilities/floating-label/floating-label.component'
import { SwitchComponent } from './utilities/switch/switch.component'
import { ViewAssetEntriesTableComponent } from './view-asset-entries-table/view-asset-entries-table.component'
import { ViewAttachmentListComponent } from './view-attachment-list/view-attachment-list.component'
import { ViewFeatureDetailsComponent } from './view-feature-details/view-feature-details.component'
import { ViewInteractionComponent } from './view-interaction/view-interaction.component'
import { ViewRemoteAttachmentComponent } from './view-remote-attachment/view-remote-attachment.component'
import { ViewpointBtnComponent } from './viewpoint-btn/viewpoint-btn.component';
import { OrganizationPickerComponent } from './organization-picker/organization-picker.component'
import { CreateAssetFieldComponent } from '@modal/create-asset-field/create-asset-field.component'

@NgModule({
  declarations: [
    AddAssetComponent,
    AddMemberComponent,
    AdvancedSearchComponent,
    AssetPickerComponent,
    BetaPillComponent,
    CollapsibleDirective,
    ColorPickerComponent,
    ConfirmActionComponent,
    ConnectionAttachmentEditor,
    ConnectionCustomFieldsEditorComponent,
    ConnectionDataPicker,
    ContextMenuDirective,
    ControlBtnsComponent,
    CreateAssetFieldComponent,
    CreateAssetTypeComponent,
    CreateConnectionComponent,
    CreateFeatureComponent,
    CreateFeaturesFromGeojsonComponent,
    CreateInteractionComponent,
    CreateMapSceneComponent,
    CreateMarkerComponent,
    CreateModelComponent,
    CreatePermissionComponent,
    CreateProjectComponent,
    UploadProjectFilesComponent,
    CreateRasterLayersFromGeotiffsComponent,
    CreateSceneComponent,
    CreateSceneComponent,
    CreateStandardSceneComponent,
    CreateThreeSixtySceneComponent,
    CreateVirtualTourComponent,
    CustomFieldsUndoRedoBtnsComponent,
    CustomFieldViewerComponent,
    DeleteComponent,
    EditAttachmentListComponent,
    EditConnectionAttachmentComponent,
    EditConnectionComponent,
    EditConnectionCustomFieldComponent,
    EditConnectionDetailsComponent,
    EditCoordinateListComponent,
    EditDetailsComponent,
    EditFeatureComponent,
    EditFeatureCustomFieldsComponent,
    EditInteractionsComponent,
    EditMarkerComponent,
    EditModelPermissionsComponent,
    EditProjectPermissionsComponent,
    EditRemoteAttachmentComponent,
    EditRuleComponent,
    EditSceneComponent,
    EditTagComponent,
    EditVectorComponent,
    EqualsPipe,
    ErrorPageComponent,
    FeatureIconComponent,
    FeatureVisibilityIconComponent,
    FilterConnectionsComponent,
    FloatingLabelComponent,
    FontAwesomeIconPickerComponent,
    HtmlFieldComponent,
    IdPickerComponent,
    JSONLiteralComponent,
    JSONObjectComponent,
    KartaLogoComponent,
    LatlngHelperComponent,
    LeftMenuButtonComponent,
    LeftSideMenuComponent,
    LimitArrayPipe,
    LoadingScreenComponent,
    LogoComponent,
    MapMenuComponent,
    MeasurementBtnComponent,
    MiniMapComponent,
    ModalComponent,
    ModelDetailsComponent,
    ModelGridComponent,
    ModelMenuComponent,
    ModelPickerComponent,
    MultipleFilePickerComponent,
    NavbarMenuMobileComponent,
    NavbarMobileComponent,
    NavigationTabsComponent,
    OpacityHandlerDirective,
    OrderByPipe,
    ProfilePictureComponent,
    ProjectDetailsComponent,
    ResourceLimitAlertComponent,
    RightPanelComponent,
    RightSidebarBtnsComponent,
    RuleGroupComponent,
    SceneIconComponent,
    SearchScenesSortPipe,
    SearchSortByKeysPipe,
    SearchSortByNamePipe,
    ShareBtnComponent,
    ShareLinkComponent,
    ShowAssetFilesComponent,
    ShowAssetHistoryComponent,
    StringFieldComponent,
    SwitchComponent,
    ToastComponent,
    ToastContainerComponent,
    TrimPipe,
    TypeOfPipe,
    TypeToConfirmComponent,
    UndoRedoBtnsComponent,
    UpgradeTierBannerComponent,
    UpgradeTierButtonComponent,
    Vector3PickerComponent,
    ViewAssetEntriesTableComponent,
    ViewAttachmentListComponent,
    ViewFeatureDetailsComponent,
    ViewInteractionComponent,
    ViewpointBtnComponent,
    ViewRemoteAttachmentComponent,
    OrganizationPickerComponent,
  ],
  imports: [
    CKEditorModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    MatExpansionModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    AddMemberComponent,
    AdvancedSearchComponent,
    BetaPillComponent,
    CollapsibleDirective,
    ColorPickerComponent,
    ConfirmActionComponent,
    ContextMenuDirective,
    ControlBtnsComponent,
    CreateFeaturesFromGeojsonComponent,
    CreateMapSceneComponent,
    CreatePermissionComponent,
    CreateRasterLayersFromGeotiffsComponent,
    CreateThreeSixtySceneComponent,
    CreateVirtualTourComponent,
    CustomFieldsUndoRedoBtnsComponent,
    CustomFieldViewerComponent,
    EditAttachmentListComponent,
    EditConnectionComponent,
    EditFeatureComponent,
    EditModelPermissionsComponent,
    EditProjectPermissionsComponent,
    EditRuleComponent,
    EditSceneComponent,
    EditVectorComponent,
    EqualsPipe,
    ErrorPageComponent,
    FeatureIconComponent,
    FeatureVisibilityIconComponent,
    FloatingLabelComponent,
    FontAwesomeIconPickerComponent,
    FormsModule,
    KartaLogoComponent,
    LatlngHelperComponent,
    LeftMenuButtonComponent,
    LeftSideMenuComponent,
    LoadingScreenComponent,
    LogoComponent,
    MapMenuComponent,
    MatExpansionModule,
    MeasurementBtnComponent,
    MiniMapComponent,
    ModalComponent,
    ModelGridComponent,
    ModelMenuComponent,
    ModelPickerComponent,
    NavbarMobileComponent,
    NavigationTabsComponent,
    NavbarMenuMobileComponent,
    OpacityHandlerDirective,
    OrderByPipe,
    OrganizationPickerComponent,
    ProfilePictureComponent,
    ResourceLimitAlertComponent,
    RightPanelComponent,
    RightSidebarBtnsComponent,
    SceneIconComponent,
    SearchScenesSortPipe,
    SearchSortByKeysPipe,
    SearchSortByNamePipe,
    ShareBtnComponent,
    SwitchComponent,
    ToastContainerComponent,
    TrimPipe,
    TypeOfPipe,
    TypeToConfirmComponent,
    UndoRedoBtnsComponent,
    UpgradeTierBannerComponent,
    UpgradeTierButtonComponent,
    ViewAssetEntriesTableComponent,
    ViewFeatureDetailsComponent,
    ViewpointBtnComponent,
  ]
})
export class SharedModule { }
