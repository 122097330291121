<ul *ngIf="selectedFeature$ | async as feature" class="list-group" style="max-height: 400px; overflow-y: auto;">
    <ng-container *ngIf="(attachments$ | async).length > 0">
        <ng-container *ngFor="let attachment of (attachments$ | async); let i = index">
            <ng-container *ngIf="attachment.fileReference">
                <li [attr.data-index]="i" class="list-group-item d-flex align-items-center pe-2 d-grid gap-2">
                    <span class="text-center">
                        <i [ngClass]="getIcon(attachment)" style="width: 20px;"></i>
                    </span>
                    <span class="text-nowrap text-truncate">{{ attachment.fileReference.filename }}</span>
                    <a class="btn btn-sm btn-secondary ms-auto" title="Download"
                        (click)='downloadAttachment(attachment)'>
                        <i class="fas fa-download"></i>
                    </a>
                    <a class="btn btn-sm btn-danger" title="Delete" (click)="removeAttachment(attachment)">
                        <i class="fa fa-trash"></i>
                    </a>
                </li>
            </ng-container>
        </ng-container>
        <li *ngFor="let attachment of remoteAttachments; let i = index" [attr.data-index]="i"
            class="list-group-item d-flex align-items-center pe-2 d-grid gap-2">
            <shared-edit-remote-attachment [attachment]="attachment" (remove)="removeAttachment(attachment)"
                class="w-100">
            </shared-edit-remote-attachment>
        </li>
    </ng-container>
</ul>

<div class="d-flex gap-1 justify-content-end flex-column pt-2">
    <div class="d-flex justify-content-end gap-1">
        <label for="newAttachment" class="btn btn-outline-success btn-sm" [ngClass]="{'disabled': atFileLimit}">
            <i class="fas fa-plus"></i>
            Add file attachments
            <input [hidden]="true" id='newAttachment' #fileInput type="file" multiple class='btn btn-link'
                [disabled]="atFileLimit" (change)="addLocalAttachments($event.target.files)">
        </label>
        <a class="btn btn-outline-success btn-sm" (click)="addRemoteAttachment()">
            <i class="fas fa-plus"></i>
            Add remote attachment
        </a>
    </div>
    <div *ngIf='atFileLimit' class="text-danger d-flex justify-content-end">
        Contact your administrator to increase limit
    </div>
</div>