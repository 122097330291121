import { forkJoin, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { AuthenticationService, hasUserAdminAccess } from '@services/authentication.service'
import { SubscriptionService } from '@services/subscription.service'

export const CustomAuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService)
  const router = inject(Router)

  return authService.isAuthenticated$.pipe(
    tap(authenticated => {
      /** Redirect to our login page if activating route while unauthenticated */
      if (!authenticated) {
        sessionStorage.setItem('authRedirectOrigin', state.url)
        router.navigate(['login'])
      }
    })
  )
}

/** Checks if the authenticated user's organization role is an Admin role */
export const isUserAdminGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService)
  const router = inject(Router)

  return authService.getCurrentUsersRoles$().pipe(
    map(roles => {
      if (hasUserAdminAccess(...roles)) {
        return true
      } else {
        router.navigate(['projects'])
        return false
      }
    })
  )
}

/** Checks if authenticated user is in free tier or has an admin role */
export const canAccessBillingGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService)
  const router = inject(Router)
  const subscriptionService = inject(SubscriptionService)

  return subscriptionService.getSubscriptionDetails().pipe(
    switchMap(details => {
      if (details.tier == 'free') {
        return of(true)
      } else {
        return authService.getCurrentUsersRoles$().pipe(
          map(roles => {
            if (hasUserAdminAccess(...roles)) {
              return true
            } else {
              router.navigate(['projects'])
              return false
            }
          })
        )
      }
    })
  )
}

export const hasSubscriptionGuard: CanActivateFn = (route, state) => {
  const subscriptionService = inject(SubscriptionService)
  const router = inject(Router)

  return subscriptionService.getSubscriptionDetails().pipe(
    catchError(((e) => {
      sessionStorage.setItem('authRedirectOrigin', state.url)
      router.navigate(['login'])
    }) as any),
    map(details => {
      if (details.status == 'active' || details.status == 'trialing') {
        return true
      } else {
        router.navigate(['error/402'])

        return false
      }
    })
  )
}

export const hasNoOrganizationGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService)
  const router = inject(Router)

  return authService.getUserAccounts().pipe(
    catchError(error => of({ organizations: [], hasFreeTrial: false })),
    map(account => {
      sessionStorage.setItem('authRedirectOrigin', state.url)
      if (account.organizations.length > 0) {
        router.navigate(['login'])
        return false
      } else if (account.hasFreeTrial) {
        router.navigate(['login'])
      }

      return true
    })
  )
}

export const hasNoSubscriptionGuard: CanActivateFn = (route, state) => {
  const subscriptionService = inject(SubscriptionService)
  const router = inject(Router)

  return subscriptionService.getSubscriptionDetails().pipe(
    catchError(error => of({ status: 'none' })),
    map(details => {
      if (details.status == 'active' || details.status == 'trialing') {
        router.navigate(['projects'])
        return false
      } else {
        return true
      }
    }),
  )
}


export const signupGuard: CanActivateFn = (route, state) => {
  const authenticationService = inject(AuthenticationService)

  return authenticationService.isAuthenticated$.pipe(
    tap(isAuthenticated => {
      if (!isAuthenticated) {
        authenticationService.loginWithRedirect({
          appState: { target: 'signup' },
          authorizationParams: { screen_hint: 'signup' }
        })
      }
    }),
    map(() => !authenticationService.hasOrganization)
  )
}

/** Checks if the authenticated user's subscription is valid */
export const subscriptionIsActiveGuard: CanActivateFn = (route, state) => {
  const authenticationService = inject(AuthenticationService)
  const subscriptionService = inject(SubscriptionService)
  const router = inject(Router)

  return forkJoin([
    subscriptionService.getSubscriptionDetails(),
    authenticationService.getCurrentUsersRoles$()
  ]).pipe(
    map(([details, roles]) => {
      if (details.status == 'active' || details.status == 'trialing') {
        return true
      } else if (hasUserAdminAccess(...roles)) {
        router.navigate(['billing'])
      } else {
        router.navigate(['error/402'])
      }

      return false
    })
  )
}

/** Checks if the project's subscription is valid */
export const projectIsValidGuard: CanActivateFn = (route, state) => {
  const router = inject(Router)
  const subscriptionService = inject(SubscriptionService)

  const projectID = +route.params.id

  return subscriptionService.getStatusByProject(projectID).pipe(
    catchError(() => router.navigate(['error/404'])),
    map(subscriptionStatus => {
      if (subscriptionStatus == 'active' || subscriptionStatus == 'trialing') {
        return true
      } else {
        router.navigate(['error/402'])
        return false
      }
    })
  )
}

/** Checks if the authenticated user's subscription is in Pro Tier */
export const isProTierGuard: CanActivateFn = (route, state) => {
  const router = inject(Router)
  const subscriptionService = inject(SubscriptionService)

  return subscriptionService.getSubscriptionDetails().pipe(
    map(details => {
      if (details.tier == 'pro') {
        return true
      } else {
        router.navigate(['projects'])
        return false
      }
    })
  )
}

/** Checks if the authenticated user's subscription is in Free Tier */
export const isFreeTierGuard: CanActivateFn = (route, state) => {
  const router = inject(Router)
  const subscriptionService = inject(SubscriptionService)

  return subscriptionService.getSubscriptionDetails().pipe(
    map(details => {
      if (details.tier == 'free') {
        return true
      } else {
        router.navigate(['billing'])
        return false
      }
    })
  )
}

export const isNotLegacySubscription: CanActivateFn = (route, state) => {
  const subscriptionService = inject(SubscriptionService)
  const router = inject(Router)

  return subscriptionService.getSubscriptionDetails().pipe(
    map(() => {
      if (subscriptionService.inFreeTier) {
        return true
      } else if (subscriptionService.isLegacy) {
        router.navigate(['projects'])
        return false
      } else {
        return true
      }
    })
  )
}