import { filter, map } from 'rxjs/operators'

import { Component } from '@angular/core'
import { Feature } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { CommandService } from '@services/command.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { SpatialAnnotationService, TagTypes } from '@services/spatial-annotation.service'
import { UpdateOptions } from '@shared/edit-feature/edit-feature.component'
import { TagOptions } from '@utils/TagFeatures'
import { modifyModel } from '@utils/ThreeJS'

@Component({
  selector: 'shared-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.css']
})
export class EditTagComponent {
  public selectedFeature: Feature
  public selectedFeature$ = this._featureService.selectedFeature$.pipe(
    filter(feature => feature != null),
    map(feature => new Feature(feature.sceneID, feature.name, feature.type, feature))
  )

  get backgroundColor() { return this.selectedFeature.backgroundColor }
  get backgroundShape() { return this.selectedFeature.backgroundShape }
  get color() { return this.selectedFeature.color }
  get icon() { return this.selectedFeature.icon }
  get model() { return this.sceneManager.getFeatureGroup(this.selectedFeature.id) }
  get scaleWithCamera() { return this.selectedFeature.scaleWithCamera }
  get sceneManager() { return this._envManager.sceneManager }
  get showOnTop() { return this.selectedFeature.showOnTop }
  get text() { return this.selectedFeature.text }
  get trackCamera() { return this.selectedFeature.trackCamera }
  get type() { return this.selectedFeature.type }
  get tagOptions(): TagOptions {
    return {
      backgroundColor: this.backgroundColor.value,
      backgroundShape: this.backgroundShape.value,
      color: this.color.value,
      icon: this.icon.value,
      showOnTop: this.showOnTop.value,
      text: this.text.value,
      type: this.selectedFeature.type as 'icon' | 'label',
    }
  }

  constructor(
    private _commandService: CommandService,
    private _envManager: EnvironmentManagerService,
    private _featureService: FeatureService,
    private _spacialAnnotationService: SpatialAnnotationService,
    public envManager: EnvironmentManagerService,
  ) {
    this.selectedFeature$.subscribe(feature => this.selectedFeature = feature)
  }

  updateFeature(feature: Feature, options: UpdateOptions = {}) {
    options.undoable = options?.undoable ?? true
    options.toast = options?.toast ?? true

    if (options?.undoable) {
      this._commandService.update.feature(feature).subscribe()
    } else {
      this._featureService.updateFeature(feature, { toast: options?.toast }).subscribe()
    }
  }

  updateProperty(property: FeatureProperty, options: UpdateOptions = {}) {
    options.undoable = options?.undoable ?? true
    options.toast = options?.toast ?? true

    const tagChanges = this.tagOptions

    tagChanges[property.key] = property.value

    this._spacialAnnotationService.defaultIcon = {
      backgroundColor: this.selectedFeature.backgroundColor.value,
      backgroundShape: this.selectedFeature.backgroundShape.value,
      color: this.selectedFeature.color.value,
      icon: this.selectedFeature.icon.value,
    }

    this._spacialAnnotationService.defaultIcon[property.key] = property.value

    if(property.key == "showOnTop") this.envManager.sceneManager.setShowOnTop(property.featureID, property.value)

    if (options?.undoable) {
      this._commandService.update.featureProperty(property).subscribe(
        () => modifyModel(this.model, { tagChanges })
      )
    } else {
      this._featureService.updateFeatureProperty(property, { toast: options?.toast }).subscribe(
        () => modifyModel(this.model, { tagChanges })
      )
    }
  }

  public updateColor(property: FeatureProperty, color: string) {
    property.value = color
    const tagChanges = this.tagOptions

    tagChanges[property.key] = property.value

    this._spacialAnnotationService.defaultIcon = {
      backgroundColor: this.selectedFeature.backgroundColor.value,
      backgroundShape: this.selectedFeature.backgroundShape.value,
      color: this.selectedFeature.color.value,
      icon: this.selectedFeature.icon.value,
    }

    this._spacialAnnotationService.defaultIcon[property.key] = property.value

    this._commandService.update.featureProperty(property).subscribe(
      () => modifyModel(this.model, { tagChanges })
    )
  }

  updateType(feature: Feature) {
    const tagChanges = this.tagOptions

    tagChanges.type = feature.type as TagTypes

    if (feature.type == 'label' && this.backgroundShape.value == 'circle') {
      this.backgroundShape.value = 'pill'
      tagChanges.backgroundShape = 'pill'

      this._commandService.update.featureAndProperty(feature, this.backgroundShape).subscribe(
        () => modifyModel(this.model, { tagChanges })
      )
    } else {
      this._commandService.update.feature(feature).subscribe(
        () => modifyModel(this.model, { tagChanges })
      )
    }
  }

  updateIcon(icon: string, options: UpdateOptions = {}) {
    this.icon.value = icon

    this.updateProperty(this.icon, options)
  }
}