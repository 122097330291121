import { Component, ElementRef } from '@angular/core'
import { ToastAction, ToastColor } from '@services/toast.service'

@Component({
  selector: 'shared-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {
  public actionButton: ToastAction
  public color: ToastColor = 'green'
  public count = 1
  public message: string = ''
  public percentDone = 1
  public showProgressBar = false
  public title: string = ''
  public estimatedTimeInSeconds: number = 0

  constructor(public elementRef: ElementRef) { }

  public getFormattedTime(): string {
    if (this.estimatedTimeInSeconds <= 59) {
      return `${this.estimatedTimeInSeconds} seconds`;
    }
    const minutes = Math.floor(this.estimatedTimeInSeconds / 60);
    const seconds = this.estimatedTimeInSeconds % 60;
    if (seconds === 0) {
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    }
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
  }
}