import * as Mapbox from 'mapbox-gl'
import * as THREE from 'three'

export type Coordinates = { lng: number, lat: number, alt: number }

export type MapConfiguration = {
  center: { lng: number, lat: number }
  map: Mapbox.Map
}

/**
 * @param longitude Geographic coordinates (non-Mercator)
 * @returns Scene position in terms of x,y,z
 */
export function getScenePositionFromMapCoords(center: Mapbox.LngLatLike, longitude: number, latitude: number, altitude: number) {
    const measurePointMercatorCoordinates = Mapbox.MercatorCoordinate.fromLngLat([longitude, latitude], altitude)
    const sceneMercatorCoordinates = Mapbox.MercatorCoordinate.fromLngLat(center, 0)
    const mercatorMeter = sceneMercatorCoordinates.meterInMercatorCoordinateUnits()

    /** 
    * IMPORTANT NOTEs: 
    * 1. In geographic coordiantes, Z is UP, but in our Scenes, Y is up. 
    * 2. Converting from Geographic coordinates to Scene coordinates involves converting to Mercator coordinates as an intermediary step. 
    */
    const mx = (measurePointMercatorCoordinates.x - sceneMercatorCoordinates.x) / mercatorMeter // Longitude
    const my = (measurePointMercatorCoordinates.z - sceneMercatorCoordinates.z) / mercatorMeter // Latitude
    const mz = (measurePointMercatorCoordinates.y - sceneMercatorCoordinates.y) / mercatorMeter // Altitude

    return new THREE.Vector3(mx, my, mz)
}

/**
 * @param position Scene position in terms of x,y,z
 * @returns Geographic coordinates (non-Mercator)
 */
export function getMapCoordsFromScenePosition(position: THREE.Vector3, center: Mapbox.LngLatLike): Coordinates {
    const sceneMercatorCoordinates = Mapbox.MercatorCoordinate.fromLngLat(center, 0)

    /** 
     * IMPORTANT NOTEs: 
     * 1. In geographic coordiantes, Z is UP, but in our Scenes, Y is up. 
     * 2. Converting from Scene coordinates to Geographic coordinates involves converting to Mercator coordinates as an intermediary step. 
     **/
    const mx = position.x * sceneMercatorCoordinates.meterInMercatorCoordinateUnits() + sceneMercatorCoordinates.x;
    const my = position.y * sceneMercatorCoordinates.meterInMercatorCoordinateUnits() + sceneMercatorCoordinates.z;
    const mz = position.z * sceneMercatorCoordinates.meterInMercatorCoordinateUnits() + sceneMercatorCoordinates.y;

    const mercatorCoordinate = new Mapbox.MercatorCoordinate(mx, mz, my);

    return { ...mercatorCoordinate.toLngLat(), alt: mercatorCoordinate.toAltitude() }
}

/* Given a query in the form "lng, lat" or "lat, lng"
 * returns the matching geographic coordinate(s)
 * as search results in carmen geojson format,
 * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
export function coordinatesGeocoder(query: string) {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(
    /^[ ]*(?:Lng: )?(-?\d+\.?\d*)[, ]+(?:Lat: )?(-?\d+\.?\d*)[ ]*$/i
  )
  if (!matches) {
    return null
  }
  function coordinateFeature(lng, lat, order) {
    if (order == "lng/lat") {
      return {
        center: [lng, lat],
        geometry: {
          type: 'Point',
          coordinates: [lng, lat]
        },
        place_name: 'Lng: ' + lng + ' Lat: ' + lat,
        place_type: ['coordinate'],
        properties: {},
        type: 'Feature'
      }
    } else {
      return {
        center: [lng, lat],
        geometry: {
          type: 'Point',
          coordinates: [lng, lat]
        },
        place_name: 'Lat: ' + lat + ' Lng: ' + lng,
        place_type: ['coordinate'],
        properties: {},
        type: 'Feature'
      }
    }
  }
  
  const coord1 = Number(matches[1])
  const coord2 = Number(matches[2])
  const geocodes = []
  
  if ((coord2 >= -90 && coord2 <= 90) && (coord1 >= -180 && coord1 <= 180)) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2, "lng/lat"))
  } else if ((coord1 >= -90 && coord1 <= 90) && (coord2 >= -180 && coord2 <= 180)) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1, "lat/lng"))
  }

  return geocodes
}