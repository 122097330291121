import { BehaviorSubject, forkJoin, Observable } from 'rxjs'
import { distinctUntilKeyChanged, filter, skip, take, tap } from 'rxjs/operators'
import * as THREE from 'three'

import { Injectable } from '@angular/core'
import { Feature } from '@classes/Feature'
import { DEFAULT_FOV } from '@classes/ModelSpace'
import { IntersectedObject } from '@classes/Raycaster'
import { CreateTextureOptions } from '@classes/SceneManager'
import { ease, reposition, TransitionOptions } from '@classes/Transitions'
import TWEEN from '@tweenjs/tween.js'

import { EnvironmentManagerService } from './environment-manager.service'
import { FeatureService } from './feature.service'
import { SceneService } from './scene.service'

const transitionInterruptSource = new BehaviorSubject<boolean>(undefined)
const transitionInterrupt$ = transitionInterruptSource.asObservable().pipe(skip(1))

const textureLoadInterruptSource = new BehaviorSubject<boolean>(undefined)
const textureLoadInterrupt$ = textureLoadInterruptSource.asObservable().pipe(skip(1))

const UP = new THREE.Vector3(0, 0, 1)
const MAX_VIEWPOINTS = 10

type CameraMovementOptions = {
  levelOutCamera?: boolean,
  easeTime?: number,
  positionOverride?: THREE.Vector3,
  controlsOverride?: THREE.Vector3
}

@Injectable({
  providedIn: 'root'
})
export class VirtualTourService {
  private _texturesLoadedSubject = new BehaviorSubject<boolean>(true)
  public backdrop: THREE.Group
  public backdropBoundingBox: THREE.Mesh
  public currentViewpoint: THREE.Group
  public cursorPlate: THREE.Group

  public skyboxA: { mesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>, group: THREE.Group, inUse: boolean }
  public skyboxB: { mesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>, group: THREE.Group, inUse: boolean }

  private _skybox1: { mesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>, group: THREE.Group, inUse: boolean }
  private _skybox2: { mesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>, group: THREE.Group, inUse: boolean }

  get availableSkybox() {
    if (!this._skybox1.inUse) {
      this._skybox1.inUse = true
      this.setOpacity(this._skybox1.mesh, 0)
      return this._skybox1
    }
    else if (!this._skybox2.inUse) {
      this._skybox2.inUse = true
      this.setOpacity(this._skybox2.mesh, 0)
      return this._skybox2
    }
    else return null
  }

  public backdropOpaque: boolean = true
  public loadedTextures: Array<{ featureID: number, textures: THREE.Texture[] }> = []
  public texturesLoaded$: Observable<boolean> = this._texturesLoadedSubject.pipe(skip(1))
  set texturesLoaded(loaded: boolean) {
    this._texturesLoadedSubject.next(loaded)
  }

  public selectedViewpointFeature: Feature
  public transitioning: boolean
  public entering: boolean = false
  public exiting: boolean = false

  get camera() { return this.modelSpace.camera }
  get controls() { return this.modelSpace.orbitControls }
  get exportType() { return this._sceneService.selectedScene?.exportType }
  get isPinholeProjection() { return this.exportType == 'leica' || this.exportType == 'matterport' }
  get isSphericalProjection() { return this.exportType == 'faro' || this.exportType == 'navvis' }
  get modelSpace() { return this._envManager.modelSpace }
  get sceneManager() { return this._envManager.sceneManager }
  get scene() { return this.sceneManager.scene }
  /** @returns `true` if currently in a ViewPoint */
  get viewpointMode() { return this.currentViewpoint !== undefined }
  /** @returns `true` if the current scene is considered a VirtualTour */
  get isVirtualTour() { return this._sceneService.selectedScene?.type == 'Virtual Tour' }

  setOpacity(mesh: THREE.Mesh, opacity: number) {
    if (mesh.material instanceof Array) {
      (mesh.material as THREE.Material[]).forEach(material => {
        material.opacity = opacity ?? 1
        material.transparent = opacity < 1
        material.transparent = (opacity < 1.0)
        material.needsUpdate = true
      })
    } else {
      mesh.material.opacity = opacity ?? 1
      mesh.material.transparent = opacity < 1
      mesh.material.transparent = (opacity < 1.0)
      mesh.material.needsUpdate = true
    }
  }

  constructor(
    private _envManager: EnvironmentManagerService,
    private _featureService: FeatureService,
    private _sceneService: SceneService,
  ) {
    this._sceneService.selectedScene$.pipe(
      filter(scene => scene.type == 'Virtual Tour'),
      distinctUntilKeyChanged('id')
    ).subscribe(() =>
      this.exitViewpoint(true).then(() => {
        this.skyboxA = undefined
        this.skyboxB = undefined

        this._skybox1 = this._createSkybox()
        this._skybox2 = this._createSkybox()

        this.backdrop = undefined
      })
    )
  }

  private _hideBackdrop() {
    this.backdrop.traverse(mesh => {
      if (mesh instanceof THREE.Mesh) {
        mesh.material.colorWrite = false
        mesh.material.transparent = false
      }
    })
  }

  private _showBackdrop() {
    this.backdrop.traverse(mesh => {
      if (mesh instanceof THREE.Mesh) {
        mesh.material.colorWrite = true
        mesh.material.transparent = true
        mesh.material.needsUpdate = true
      }
    })
  }

  private _easeOutBackdropOpacity(easeTime: number) {
    const easePromises: Promise<boolean>[] = []
    const startOpacity = this.backdropOpaque ? 1 : 0
    const endOpacity = 0

    if (startOpacity == endOpacity) easeTime = 100
    this.backdrop.traverse(child => {
      if (child instanceof THREE.Mesh) {
        const easePromise = this._easeOpacity(startOpacity, 0, easeTime, child, { interruptable: true, interruptions$: [transitionInterrupt$], finishOnStop: true })
        easePromises.push(easePromise)
      }
    })
    return Promise.all(easePromises).then(_ => this.backdropOpaque = false)
  }

  private _easeInBackdropOpacity(easeTime: number) {
    const easePromises: Promise<boolean>[] = []
    const startOpacity = this.backdropOpaque ? 1 : 0
    const endOpacity = 1
    if (startOpacity == endOpacity) easeTime = 100

    this.backdrop.traverse(child => {
      if (child instanceof THREE.Mesh) {
        const easePromise = this._easeOpacity(startOpacity, 1, easeTime, child, { interruptable: true, interruptions$: [transitionInterrupt$], finishOnStop: true })
        easePromises.push(easePromise)
      }
    })
    return Promise.all(easePromises).then(_ => this.backdropOpaque = true)
  }

  private async _moveCameraToViewpoint(position: THREE.Vector3, options: CameraMovementOptions): Promise<boolean> {
    if (options?.positionOverride || options?.positionOverride || options?.levelOutCamera) return this.easeCameraIntoPosition(position, options)
    else if (this.viewpointMode) return this._repositionCamera(position, options?.easeTime ?? 1500)
  }

  /** Repositions the camera to a new ViewPoint */
  private _repositionCamera(position: THREE.Vector3, easeTime: number) {
    const options: TransitionOptions = {
      interruptions$: [transitionInterrupt$], // add an interruption
      easeType: TWEEN.Easing.Quartic.In,
      finishOnStop: true,
      easeTime: easeTime
    }

    return reposition(this.camera, this.controls, position, true, options)
  }

  /** Eases the camera into the ViewPoint */
  public async easeCameraIntoPosition(position: THREE.Vector3, options: Partial<{ levelOutCamera: boolean, easeTime: number, levelOutEaseTime: number, positionOverride?: THREE.Vector3, controlsOverride?: THREE.Vector3 }>) {
    if (options?.positionOverride) position = options?.positionOverride

    const controls = this.modelSpace.orbitControls
    const camera = this.modelSpace.camera

    /** The closer the distance is to 0, 
      * the less that other objects will 
      * appear to move when camera is rotating. */
    const distance = 0.00001

    const direction = controls.target
      .clone()
      .sub(camera.position)
      .normalize()
      .multiplyScalar(distance)

    const newCameraPosition = position
      .clone()
      .sub(direction)

    const easeTo = (current: THREE.Vector3, target: THREE.Vector3, easeTime?: number) => {
      const options: TransitionOptions = {
        easeTime: easeTime,
        interruptable: true,
        interruptions$: [transitionInterrupt$],
        finishOnStop: true
      }

      return this._envManager.easeTo(current, target, options)
    }

    const levelOut = (uninterrupted: boolean) => {
      if (!options?.levelOutCamera) return Promise.resolve(true)
      const cameraHeight = camera.position.clone()

      cameraHeight.z = position.z

      if (uninterrupted) {
        return easeTo(camera.position, cameraHeight, options?.levelOutEaseTime ?? 250)
      } else {
        camera.position.setY(cameraHeight.y)
        return Promise.resolve(uninterrupted)
      }
    }

    if (options?.positionOverride) {
      const controlsDirection = new THREE.Vector3()
      controlsDirection.subVectors(newCameraPosition, options.controlsOverride).normalize()
      var finalControlsPosition = newCameraPosition.clone().sub(controlsDirection.multiplyScalar(0.01))
    }

    const controlsEasePromise = options?.positionOverride
      ? easeTo(controls.target, finalControlsPosition, options?.easeTime ?? 1000)
      : easeTo(controls.target, position, options?.easeTime ?? 1000)

    return Promise.all([
      controlsEasePromise,
      easeTo(camera.position, newCameraPosition, options?.easeTime ?? 1000)
        .then(uninterrupted => levelOut(uninterrupted))
    ]).then(([uninterrupted, _]) => {
      if (uninterrupted) return Promise.resolve(true)
      else return Promise.resolve(false)
    })
  }


  /**
   * Slowly eases the background sphere in or out of view 
   * @param start value between 0-1. Same for `end`
   */
  private _easeOpacity(start: number, end: number, easeTime = 1000, mesh: THREE.Mesh, options?: TransitionOptions) {
    return new Promise<boolean>((resolve, reject) => {
      const tween = new TWEEN.Tween({ x: start })

      const interruptionListener = transitionInterrupt$
        .pipe(take(1), filter(interrupt => interrupt))
        .subscribe(() => tween.stop())

      tween.to({ x: end }, easeTime)
        .easing(TWEEN.Easing.Linear.None)
        .onUpdate((opacity: { x: number }) => this.setOpacity(mesh, opacity.x))
        .onComplete(() => {
          interruptionListener.unsubscribe()
          this.setOpacity(mesh, end)
          if (options?.onComplete) options.onComplete()
          resolve(true)

        })
        .onStop(() => {
          interruptionListener.unsubscribe()
          this.setOpacity(mesh, end)
          if (options?.onComplete) options.onComplete()
          resolve(false)
        })
        .start()
    })
  }

  private async _easeOutPreviousSkybox(easeTime: number) {
    if (this.skyboxA) {
      return this._easeOpacity(1, 0, easeTime, this.skyboxA.mesh, { interruptable: true, interruptions$: [transitionInterrupt$], finishOnStop: true }).then(_ => this.skyboxA.inUse = false)
    }
    return Promise.resolve(true)
  }

  public async enterViewpoint(viewpoint: THREE.Group, options?: { interrupt?: boolean, levelOutCamera?: boolean, positionOverride?: THREE.Vector3, controlsOverride?: THREE.Vector3 }) {
    if (this.exiting) return
    this.entering = true

    const sameViewpoint = viewpoint?.userData?.featureID == this.currentViewpoint?.userData?.featureID
    const feature = this._featureService.features.find(f => f.id == viewpoint.userData.featureID)
    const notViewpoint = feature?.type != 'view'

    if (options?.interrupt) {
      transitionInterruptSource.next(true) // TODO: Handle interruptions better
    }

    if (this.transitioning || sameViewpoint || notViewpoint) {
      this.entering = false
      return
    }

    if (!this.viewpointMode) {
      this._addCursorPlate()
    }

    this._makePreviousViewpointsVisible()

    this.transitioning = true
    this.currentViewpoint = viewpoint
    this.selectedViewpointFeature = feature
    feature.visible = false

    this._setUpBackdropBoundingBox()
    this._setUpBackdrop()
    this._showBackdrop()

    this.skyboxA = this.skyboxB
    let textures = []
    this.skyboxB = this.availableSkybox

    this._applySkyboxMatrixTransformations(feature, this.skyboxB.mesh, this.skyboxB.group)

    const viewpointAlreadyLoaded = this.loadedTextures.some(({ featureID }) => featureID == feature.id)

    try {
      if (viewpointAlreadyLoaded) {
        const { textures: loadedTextures } = this.loadedTextures.find(entry => entry.featureID == feature.id)
        textures = loadedTextures
      } else {
        const moveCamera = () => Promise.all([
          this._easeOutPreviousSkybox(500),
          this._easeInBackdropOpacity(500),
        ]).then(_ => this._moveCameraToViewpoint(this.skyboxB.group.position, { easeTime: 1500, ...options }))

        var [_, uninterrupted] = await Promise.all([
          this._loadViewpointTextures().then(t => textures = t),
          moveCamera()
        ])
      }

      try {
        /** Load in textures for skybox*/
        if (!this.loadedTextures.some(({ featureID }) => featureID == feature.id)) {
          this.loadedTextures.push({ featureID: feature.id, textures })
        } else { // Reorder list
          this.loadedTextures = this.loadedTextures
            .filter(({ featureID }) => featureID != feature.id)
            .concat({ featureID: feature.id, textures })
        }

        if (this.loadedTextures.length > MAX_VIEWPOINTS) {
          this.loadedTextures.shift()
        }

        this._applySkyboxTextures(this.skyboxB.mesh, textures)

        // TODO: Fix issue where clicking back and forth between viewpoints using moveTos 
        //    - doesn't allow the backdrop mesh to fade in
        //    - doesn't fade in the new skybox appropriately. is already visible when moving into it
        //    - Workaround is to let a moveTo finish before doing another one

        this._featureService.updateFeaturesLocally(feature)

        const FOV = this.modelSpace.camera.fov

        this.modelSpace.setControls("360 Image")
        this.modelSpace.camera.fov = FOV
        this.texturesLoaded = true

        const fadeOutBackdrop = async (easeTime: number) => this._easeOutBackdropOpacity(easeTime)

        const easeInNewSkybox = async (easeTime: number) =>
          this._easeOpacity(0, 1, easeTime, this.skyboxB.mesh, {
            interruptable: true,
            interruptions$: [transitionInterrupt$],
            finishOnStop: true
          })

        const crossfadeBackdropOutSkyboxIn = async (easeTime: number) => {
          let [uninterrupted, _] = await Promise.all([
            fadeOutBackdrop(easeTime),
            easeInNewSkybox(easeTime),
          ])

          if (uninterrupted) return Promise.resolve(true)
          return Promise.resolve(false)
        }

        const positionsWithinOneUnit = vectorsAreWithinOneUnit(this.camera.position, this.skyboxB.group.position)

        if (viewpointAlreadyLoaded) {
          await Promise.all([
            this._easeOutPreviousSkybox(500),
            this._easeInBackdropOpacity(500)
          ])

          // Handles case where going into Viewpoint mode when camera is essentially in the correct place already
          var easeTime = 1500
          if (positionsWithinOneUnit) easeTime = 100
          const uninterrupted = await this._moveCameraToViewpoint(this.skyboxB.group.position, { easeTime, ...options })

          if (uninterrupted) {
            await crossfadeBackdropOutSkyboxIn(1000)
          } else {
            this.setOpacity(this.skyboxA?.mesh, 0)
          }

        } else {
          if (uninterrupted) {
            await Promise.all([
              fadeOutBackdrop(1500),
              easeInNewSkybox(1000)
            ]).then(_ => this.transitioning = false)
          }
        }
        this.transitioning = false

      } catch (e) {
        console.error(e)
      }
    } catch (error) {
      this.texturesLoaded = true
      this.currentViewpoint = undefined
      this.selectedViewpointFeature = undefined
      this.transitioning = false
      this.exitViewpoint(false)
    }

    this.entering = false
  }

  /** @param softExit should be made true when exiting before switching scenes */
  async exitViewpoint(softExit = false) {
    if (this.entering) return
    if (!this.currentViewpoint) return
    this.exiting = true
    textureLoadInterruptSource.next(true)
    transitionInterruptSource.next(true)

    if (this.cursorPlate) this.scene.remove(this.cursorPlate)
    if (this.cursorPlate) this.sceneManager.disposeGroup(this.cursorPlate)
    if (this.backdropBoundingBox) this.scene.remove(this.backdropBoundingBox)
    if (this.backdropBoundingBox) this.sceneManager.disposeGroup(this.backdropBoundingBox)
    this.backdropBoundingBox = undefined

    const easeOutSkybox = async (easeTime: number) => {
      if (this.skyboxB?.mesh) return this._easeOpacity(1, 0, easeTime, this.skyboxB?.mesh)
      else return Promise.resolve(true)
    }

    if (softExit) {
      this.setOpacity(this.skyboxB.mesh, 0)
      this.currentViewpoint.visible = true
      this.backdrop.traverse(child => {
        if (child instanceof THREE.Mesh) this.setOpacity(child, 1)
      })
      this.backdropOpaque = true
    } else {
      this.selectedViewpointFeature.visible = true
      this._featureService.updateFeaturesLocally(this.selectedViewpointFeature)

      await Promise.all([
        easeOutSkybox(500),
        this._easeInBackdropOpacity(500),
        ease(this.modelSpace.camera.fov, DEFAULT_FOV, 500, (fov: number) => this.modelSpace.camera.fov = fov)
      ])
    }
    // Move orbit controls further away from the camera 
    this.modelSpace.setControls("Standard")
    const position = this.modelSpace.camera.position.clone()
    const target = this.modelSpace.orbitControls.target.clone()
    const distance = 2
    const direction = position.clone().sub(target).normalize().multiplyScalar(distance)
    const newTarget = target.sub(direction)

    this.modelSpace.orbitControls.target.copy(newTarget)

    this.currentViewpoint = undefined
    this.selectedViewpointFeature = undefined

    this.skyboxB.inUse = false
    this.skyboxB = undefined
    this.exiting = false
  }

  /** @returns The ViewPoint which is closest to the `position` */
  getNearestViewPoint(position: THREE.Vector3) {
    const viewFeatures = this._featureService.features.filter(f => f.type == 'view')
    const viewGroups = this.sceneManager.featureGroups.filter(g =>
      viewFeatures.map(v => v.id).includes(g.userData.featureID)
    )

    if (viewGroups.length == 0) return

    let nearestGroup = viewGroups[0]

    viewGroups.forEach(nextGroup => {
      const currentDistance = nearestGroup.getWorldPosition(new THREE.Vector3()).distanceTo(position)
      const nextDistance = nextGroup.getWorldPosition(new THREE.Vector3()).distanceTo(position)

      if (currentDistance > nextDistance) nearestGroup = nextGroup
    })

    return nearestGroup
  }

  getNearestViewPointInDirection(position: THREE.Vector3, target: THREE.Vector3, direction: "UP" | "DOWN") {
    const nearestPoint = this.getNearestViewPoint(position)

    const viewFeatureIDs = new Set(this._featureService.features.filter(f => f.type === 'view').map(f => f.id))
    const viewGroups = this.sceneManager.featureGroups.filter(g => viewFeatureIDs.has(g.userData.featureID))

    if (viewGroups.length === 0) return null

    let nearestGroup = null
    let nearestDistance = Infinity

    for (const nextGroup of viewGroups) {
      if (nextGroup === nearestPoint) continue

      const groupPosition = nextGroup.getWorldPosition(new THREE.Vector3())
      const distanceToPosition = groupPosition.distanceTo(position)
      const distanceToTarget = groupPosition.distanceTo(target)

      if (direction === "UP" && distanceToTarget < distanceToPosition && distanceToPosition < nearestDistance) {
        nearestDistance = distanceToPosition
        nearestGroup = nextGroup
      }

      if (direction === "DOWN" && distanceToTarget > distanceToPosition && distanceToPosition < nearestDistance) {
        nearestDistance = distanceToPosition
        nearestGroup = nextGroup
      }
    }

    return nearestGroup
  }

  private _extractTransformationsFromMatrix(matrix: THREE.Matrix4) {
    const position = new THREE.Vector3()
    const rotation = new THREE.Quaternion()
    const scale = new THREE.Vector3()
    matrix.decompose(position, rotation, scale)
    position.set(matrix.elements[3], matrix.elements[7], matrix.elements[11])
    return { position, rotation, scale }
  }

  /** Creates new materials from textures and creates a new skybox at the position of the Viewpoint. */
  private _createSkybox() {
    if (this.isPinholeProjection) {
      var geometry = new THREE.BoxGeometry(1, 1, 1) as THREE.BufferGeometry
    } else if (this.isSphericalProjection) {
      var geometry = new THREE.SphereGeometry(1, 512, 512) as THREE.BufferGeometry
    }

    const material = new THREE.MeshBasicMaterial({
      side: THREE.BackSide,
      opacity: 0,
      transparent: true,
      depthTest: false
    })

    const mesh = new THREE.Mesh(geometry, material)
    const group = new THREE.Group()

    group.renderOrder = -1
    group.userData.ignoreRaycaster = true
    mesh.userData.ignoreRaycaster = true
    group.add(mesh)
    this.scene.add(group)

    return { mesh, group, inUse: false }
  }

  private _applySkyboxTextures(skybox: THREE.Mesh, textures: THREE.Texture[]) {
    const materials = this._createMaterialsFromTextures(textures)

    if (this.isSphericalProjection) {
      skybox.material = materials[0]
    } else {
      skybox.material = materials
    }
  }

  private _createMaterialsFromTextures(textures: THREE.Texture[]) {
    return textures.map(texture => new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.BackSide,
      opacity: 0,
      transparent: true,
      depthTest: false
    }))
  }

  private _applySkyboxMatrixTransformations(feature: Feature, skybox: THREE.Mesh, skyboxGroup: THREE.Group) {
    const matrix = new THREE.Matrix4().fromArray(JSON.parse(feature.matrix.value))
    const { position, rotation, scale } = this._extractTransformationsFromMatrix(matrix)

    rotation.invert()

    const x = new THREE.Vector3(1, 0, 0)
    const y = new THREE.Vector3(0, 1, 0)

    skybox.rotation.setFromQuaternion(rotation)
    skybox.scale.x = scale.x * -1
    skybox.rotateOnAxis(x, Math.PI / 2)

    if (this.exportType == 'leica') {
      skybox.rotateOnAxis(y, Math.PI)
    } else if (this.exportType == 'matterport') {
      skybox.rotateOnAxis(y, Math.PI / 2)
    } else if (this.isSphericalProjection) {
      skybox.rotateOnAxis(y, Math.PI)
      skybox.rotateOnAxis(x, Math.PI)
    }

    skyboxGroup.position.copy(position)
  }

  /** Updates the position and normal of the cursor plate from Raycaster intersection data */
  public updateCursorPlate(intersection: IntersectedObject) {
    const point = intersection.point
    const face = intersection.face
    if (!point || !face) return

    /** Returns a point in 3D space along the projection line of the normal from the starting point. */
    const getLookAtVector = (point: THREE.Vector3, normal: THREE.Vector3) => {
      var normalMatrix = new THREE.Matrix3()
      var worldNormal = new THREE.Vector3()
      normalMatrix.getNormalMatrix(this.scene.matrixWorld)
      worldNormal.copy(normal).applyMatrix3(normalMatrix)

      const newNormal = new THREE.Vector3(worldNormal.x, -worldNormal.z, worldNormal.y)

      const lookAtVector = new THREE.Vector3().addVectors(point, newNormal.multiplyScalar(0.3));

      return lookAtVector
    }

    const normal = new THREE.Vector3().copy(face.normal.clone())
    const position = point.clone()
    this.cursorPlate.position.copy(position)
    const lookAtVector = getLookAtVector(point, normal)
    this.cursorPlate.lookAt(lookAtVector)
  }

  private _addCursorPlate() {
    const group = new THREE.Group()
    const centerSphere = new THREE.Mesh(new THREE.CylinderGeometry(0.06, 0.08, 0.0002, 50, 1), new THREE.MeshPhongMaterial({ color: 0xDDDDDD, opacity: 0.5, transparent: true, depthTest: false }))
    centerSphere.renderOrder = 1
    centerSphere.rotateX(Math.PI / 2)
    centerSphere.up = UP
    centerSphere.geometry.computeBoundingBox()
    let box = centerSphere.geometry.boundingBox.clone()
    box.getCenter(group.position)
    group.add(centerSphere)
    group.userData.ignoreRaycaster = true
    this.cursorPlate = group
    this.scene.add(group)
  }

  /** Adds a bounding box mesh behind the backdrop mesh, so the Raycaster will always hit something, even with holes in the backdrop mesh */
  private _setUpBackdropBoundingBox() {
    if (this.backdropBoundingBox) return
    const backdropFeature = this._featureService.features.find(f => f.properties.find(prop => prop.key == 'virtualTourBackdrop'))
    const backdrop = this.sceneManager.getFeatureGroup(backdropFeature.id)
    const box = new THREE.Box3().setFromObject(backdrop)
    const size = new THREE.Vector3()

    box.getSize(size)
    const outerBoxGeometry = new THREE.BoxGeometry(size.x * 1.1, size.y * 1.1, size.z * 1.1)
    outerBoxGeometry.computeVertexNormals()

    const boundingBoxMesh = new THREE.Mesh(
      outerBoxGeometry,
      new THREE.MeshBasicMaterial({
        color: 0xffffff,
        colorWrite: false,
        side: THREE.BackSide,
        opacity: 0,
        transparent: true
      })
    )
    boundingBoxMesh.position.copy(box.getCenter(new THREE.Vector3()))
    boundingBoxMesh.up = UP

    boundingBoxMesh.userData.isVirtualTourBackdropBox = true
    boundingBoxMesh.userData.featureID = backdropFeature.id
    this.backdropBoundingBox = boundingBoxMesh
    this.scene.add(boundingBoxMesh)
  }

  private _setUpBackdrop() {
    if (this.backdrop) return
    const backdropFeature = this._featureService.features.find(f => f.virtualTourBackdrop?.value)
    const backdropMesh = this.sceneManager.getFeatureGroup(backdropFeature.id)
    this.backdrop = backdropMesh
  }

  private _makePreviousViewpointsVisible() {
    const previousViewpoints = this._featureService.features.filter(f => f.type == 'view' && !f.visible)

    previousViewpoints.forEach(v => v.visible = true)
    this._featureService.updateFeaturesLocally(...previousViewpoints)
  }

  /**
   * Order of images must be changed to match what ThreeJS expects:
   *  Leica Format: left, front, right, top, back, bottom
   *  Matterport Format: back, left, front, right, top, bottom
   *  Three Format: front, top, back, bottom, left, right
   */
  private async _loadViewpointTextures() {
    const feature = this.selectedViewpointFeature
    const images = feature.properties.filter(prop => prop.type == 'image')
      .sort((a, b) => a.id - b.id)
    const options = {} as CreateTextureOptions

    if (this.isSphericalProjection) {
      options.mapping = THREE.EquirectangularReflectionMapping
      options.wrapS = THREE.RepeatWrapping
    }

    const createTextures$ = images.map(image =>
      this._envManager.sceneManager.createTexture(image.fileReference, options, transitionInterrupt$)
    )

    this.texturesLoaded = false

    return await forkJoin(createTextures$).toPromise()
  }

  setupArrowKeyNavigation() {
    const handleKeyNavigation = (event) => {
      if (this.viewpointMode) {
        const cameraPosition = this._envManager.modelSpace.camera.position.clone()
        const targetPosition = this._envManager.modelSpace.orbitControls.target.clone()

        if (event.key === 'ArrowUp') {
          const nearestPoint = this.getNearestViewPointInDirection(cameraPosition, targetPosition, "UP")
          if (nearestPoint != null) {
            this.enterViewpoint(nearestPoint, { levelOutCamera: !this.viewpointMode })
          }
        }

        if (event.key === 'ArrowDown') {
          const nearestPoint = this.getNearestViewPointInDirection(cameraPosition, targetPosition, "DOWN")
          if (nearestPoint != null) {
            this.enterViewpoint(nearestPoint, { levelOutCamera: !this.viewpointMode })
          }
        }
      }
    }

    // Attach the event listener for arrow key navigation
    document.addEventListener('keydown', handleKeyNavigation)
  }

  setupArrowKeyPanning() {
    let movementDirection = 0 // -1 for left, 1 for right, 0 for no movement

    const handleKeyDown = (event) => {
      if (this._sceneService.selectedScene.type === 'Virtual Tour' && this.viewpointMode) {
        if (event.key === 'ArrowRight') movementDirection = 1
        if (event.key === 'ArrowLeft') movementDirection = -1
      }
    }

    const handleKeyUp = (event) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') movementDirection = 0
    }

    const smoothPan = () => {
      if (movementDirection !== 0 && this.viewpointMode) {
        const cameraPosition = this._envManager.modelSpace.camera.position.clone()
        const targetPosition = this._envManager.modelSpace.orbitControls.target.clone()

        const cameraToTarget = targetPosition.clone().sub(cameraPosition)
        const rightDirection = new THREE.Vector3()
        rightDirection.crossVectors(cameraToTarget, this._envManager.modelSpace.camera.up).normalize()

        const fovRadians = THREE.MathUtils.degToRad(this._envManager.modelSpace.camera.fov)
        const cameraDistance = cameraToTarget.length()
        const panDistance = cameraDistance * Math.tan(fovRadians / 2) * 0.02

        // Adjust target position based on movement direction
        targetPosition.add(rightDirection.clone().multiplyScalar(panDistance * movementDirection))

        // Keep the same camera-to-target distance
        const newCameraToTarget = targetPosition.clone().sub(cameraPosition).normalize()
        const distance = cameraToTarget.length()
        const newTargetPosition = cameraPosition.clone().add(newCameraToTarget.multiplyScalar(distance))

        // Update orbit controls with the new target position
        this._envManager.modelSpace.orbitControls.target.copy(newTargetPosition)
        this._envManager.modelSpace.orbitControls.update() // Ensure smooth update
      }

      requestAnimationFrame(smoothPan)
    }

    // Set up event listeners
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    // Start the smooth panning loop
    requestAnimationFrame(smoothPan)
  }
}

function vectorsAreWithinOneUnit(v1: THREE.Vector3, v2: THREE.Vector3) {
  const diffX = Math.abs(v1.x - v2.x);
  const diffY = Math.abs(v1.y - v2.y);
  const diffZ = Math.abs(v1.z - v2.z);

  return diffX <= 1 && diffY <= 1 && diffZ <= 1;
}