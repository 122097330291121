import { Modal } from 'bootstrap'
import { forkJoin } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { Stripe } from 'stripe'

import { Component } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthenticationService, AuthRole, isAdminRole, isEditor, isReadOnly, OrganizationMember } from '@services/authentication.service'
import { SubscriptionService } from '@services/subscription.service'
import { ToastColor } from '@services/toast.service'
import { UserService } from '@services/user.service'

@Component({
  selector: 'shared-modal-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css'],
})
export class AddMemberComponent {
  public cancelText = 'Cancel'
  public checkedSeat: Stripe.SubscriptionItem
  public memberForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    role: new UntypedFormControl(AuthRole.READ_ONLY, Validators.required),
  })
  public modal: Modal
  public onCancel: CallableFunction
  public onSubmit: CallableFunction
  public submitColor: ToastColor = 'green'
  public submitText = 'Add'
  public title = 'Add Member'

  get firstName() { return this.memberForm.get('firstName') }
  get lastName() { return this.memberForm.get('lastName') }
  get email() { return this.memberForm.get('email') }
  get role() { return this.memberForm.get('role') }

  get organizationRoles() { return this._authService.organizationRoles }
  get selectedRole() { return this.memberForm.value.role as string }
  get seatsAvailable() {
    if (isAdminRole(this.selectedRole)) {
      return this._subscriptionService.adminSeatsAvailable
    } else if (isReadOnly(this.selectedRole)) {
      return this._subscriptionService.readOnlySeatsAvailable
    }
  }
  get seatsAvailableBackgroundColor() {
    if (this.seatsAvailable == 0) {
      return 'bg-warning text-white'
    } else {
      return 'bg-light'
    }
  }

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _subscriptionService: SubscriptionService,
    private _userService: UserService,
  ) { }


  submit(): boolean {
    if (this.memberForm.invalid) {
      this.memberForm.markAllAsTouched()
      return false
    }

    const { firstName, lastName, email } = this.memberForm.value
    const name = `${firstName} ${lastName}`
    const role = this._authService.organizationRoles.find(role => role.id == this.selectedRole)

    const member = { name, email, roles: [role] } as OrganizationMember

    this._authService.addMemberToOrganization(name, email, [this.selectedRole]).pipe(
      switchMap(user => {
        const members = this._authService.organizationMembers.slice()

        member.user_id = user.user_id
        member.picture = user.picture
        members.push(member)

        this._authService.organizationMembers = members

        return forkJoin([
          this._userService.getOrganizationsUsers(),
          this._subscriptionService.getSubscriptionUsage()
        ])
      })
    ).subscribe(([users]) => this._userService.organizationUsers = users)

    return true
  }

  redirectToBillingDashboard() {
    this._router.navigate(['/billing']).then(() => this.modal?.hide())
  }

  _submit() {
    if (this.onSubmit) {
      this.onSubmit()
    }

    if (this.submit()) {
      this.modal?.hide()
    }
  }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }
}