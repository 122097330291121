import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

import { Component, Input, OnDestroy } from '@angular/core'
import { FeatureCustomField } from '@classes/Feature'
import { FeatureService } from '@services/feature.service'

@Component({
  selector: 'shared-custom-field-viewer',
  templateUrl: './custom-field-viewer.component.html',
  styleUrls: ['./custom-field-viewer.component.css']
})
export class CustomFieldViewerComponent implements OnDestroy {
  private _subscriptions: Subscription[] = []
  @Input() customFields: FeatureCustomField[] = []

  constructor(public featureService: FeatureService) { }

  ngOnChanges() {
    this.customFields = this.customFields?.sort((a, b) => {
      const aKey: string = a.key?.toString().toLowerCase() || ''
      const bKey: string = b.key?.toString().toLowerCase() || ''
      if (aKey > bKey) return 1
      else if (aKey < bKey) return -1
      else return 0
    }) ?? []
  }

  parseSingleArrayItem(value) {
    try {
      let parsedValue = JSON.parse(value)
      if (Array.isArray(parsedValue) && parsedValue.length == 1) return parsedValue[0]
      else return value
    } catch {
      return value
    }
  }

  isLink(value: any) {
    if (!value || typeof value !== 'string') {
      return false
    }

    return value.startsWith("http://") || value.startsWith("https://")
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}