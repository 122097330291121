import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'shared-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent {
  @Input() value: string = "#000001" //This allows the color black (#000000) to be chosen
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>()

  constructor() { }

  updateValue(event: Event) {
    this.valueChange.emit(this.value)
  }
}