<form class="container-fluid bg-light pt-2" [formGroup]="sceneForm" [style.margin-bottom]="'65px'"
    style="user-select: none;">
    <div class="mt-2 mb-3">
        <!-- Scene Name -->
        <label class="form-label">Scene name</label>
        <input class="form-control" formControlName="name" placeholder="Scene Name">
        <small *ngIf="sceneForm.get('name').status=='INVALID'" class="form-text text-danger">
            Scene name is required</small>
    </div>

    <div class="mb-3 d-flex align-items-center gap-1">
        <span>
            Scene type: {{ sceneForm.get('type').value }}
        </span>
        <shared-beta-pill *ngIf="sceneForm.get('type').value == 'Map'" [size]="6"></shared-beta-pill>
    </div>

    <ul class="list-group mb-3">
        <!-- Scene of Interest -->
        <li class="list-group-item d-flex align-items-center pe-1">
            <span class="text-nowrap text-truncate me-auto" style="min-width:0;">Show in Viewer navigation panel</span>
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" formControlName="sceneOfInterest"
                    style="cursor: pointer;">
            </div>
        </li>

        <!-- Terrain Property (Map scenes) -->
        <ng-container *ngIf="terrain && scene.type == 'Map'" [formGroup]="terrain">
            <li class="list-group-item d-flex align-items-center pe-1">
                <span class="text-nowrap text-truncate me-auto" style="min-width:0;">3D terrain</span>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" formControlName="value"
                        (change)="updateTerrain(terrain.value)" style="cursor: pointer;">
                </div>
            </li>
        </ng-container>

        <!-- Overlap Property (Map scenes) -->
        <ng-container *ngIf="scene.type == 'Map'" [formGroup]="overlap">
            <li class="list-group-item d-flex align-items-center pe-1">
                <span class="text-nowrap text-truncate me-auto" style="min-width:0;">Hide markers on overlap</span>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" formControlName="value"
                        (change)="updateOverlapProperty(overlap.value)" style="cursor: pointer;">
                </div>
            </li>
        </ng-container>
    </ul>

    <!-- Map scenes -->
    <ng-container *ngIf="scene.type == 'Map'">
        <!-- Map Style Property (Map scenes) -->
        <div class="mb-3" *ngIf="style" [formGroup]="style">
            <label class="form-label">Style</label>
            <select class="form-select" formControlName="value" (change)="updateSceneProperty(style.value)"
                style="cursor: pointer">
                <option *ngFor="let mapstyle of mapStyles" [value]="mapstyle.style">{{ mapstyle.display }}
                </option>
            </select>
        </div>

        <!-- Bearing, Longitude, Latitude, Pitch, and Zoom Properties (Map scenes) -->
        <div class="mb-3">
            <label class="form-label">Starting orientation</label>
            <div *ngFor="let propertyKey of orderedPropertyKeys; index as i"
                [ngClass]="{ 'input-group input-group-sm': true, 'mt-1': i > 0 }"
                [formGroup]="getPropertyFormGroup(propertyKey)">
                <div class="input-group-text" style="width: 80px;">
                    {{ propertyKey | titlecase }}
                </div>
                <input type="number" class="form-control" formControlName="value" style="text-align:right"
                    (blur)="sceneService.updateSceneProperty(getPropertyFormGroup(propertyKey).value).subscribe()">
            </div>
            <div class="d-flex justify-content-end">
                <a type="button" class="btn btn-sm btn-secondary mt-2" (click)="setMapsStartingOrientation()">Take
                    current</a>
            </div>
        </div>
    </ng-container>

    <div class="d-flex justify-content-end mb-3">
        <button class="btn btn-sm btn-success" (click)="setSceneThumbnail()">
            Set Scene Thumbnail <i class="ms-1 fa-regular fa-camera"></i>
        </button>
    </div>

    <ng-container *ngIf="timeOfDay && scene.type=='Map'" [formGroup]="timeOfDay">
        <div class="mb-3">
            <label class="form-label">Time of Day</label>
            <select class="form-select" formControlName="value" (change)="updateSunPosition(timeOfDay)"
                style="cursor: pointer">
                <option *ngFor="let timeOfDay of skyboxOptions" [value]="timeOfDay.value">{{ timeOfDay.display }}
                </option>
            </select>
        </div>
    </ng-container>

    <!-- Standard and 360 Scenes -->
    <ng-container *ngIf="scene.type !='Map'">
        <div class="form-group">
            <!-- Scene File Property (360 Image scenes) -->
            <ng-container *ngIf="file && scene.type=='360 Image'" [formGroup]="file">
                <div class="mb-3">
                    <label class="form-label">Background image</label>
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span class="text-nowrap text-truncate me-auto" style="min-width:0;">
                                <span class="text-center"><i class="fas fa-file-image" style="width:20px"></i></span>
                                <span style="padding-left: 10px;">{{ fileReference.filename }}</span>
                            </span>
                            <a class="btn text-secondary px-1" title="Download"
                                (click)="downloadBackgroundFile(fileReference)">
                                <i class="fas fa-file-download"></i></a>
                            <label for="background" class="btn text-secondary px-1 mb-0" title="Replace"><i
                                    class="fas fa-file-upload"></i></label>
                            <input id="background" type="file" accept="image/*" style="display:none"
                                (change)="replaceBackgroundFile($event.target.files[0])">
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>

        <div class="d-grid gap-2 d-flex justify-content-end">
            <button class="btn btn-sm btn-success" (click)="setStartingOrientation()">
                Set Scene Orientation
            </button>
            <button *ngIf="scene.type == '360 Image'" class="btn btn-sm btn-success" (click)="setNorthAngle()">
                Set Scene North
            </button>
        </div>
    </ng-container>
</form>