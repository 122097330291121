import * as Mapbox from 'mapbox-gl'
import { Subscription } from 'rxjs'

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { FeatureProperty } from '@classes/FeatureProperty'
import { CommandService } from '@services/command.service'
import { clipboardContents$, CoordinatesClipboard, CopyService } from '@services/copy.service'
import { FeatureService } from '@services/feature.service'
import { SceneService } from '@services/scene.service'

@Component({
  selector: 'shared-edit-coordinate-list',
  templateUrl: './edit-coordinate-list.component.html',
  styleUrls: ['./edit-coordinate-list.component.css']
})
export class EditCoordinateListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: 'line' | 'polygon'
  @Input() valueString: string = undefined
  @Output() edit: EventEmitter<any> = new EventEmitter()

  private subscriptions: Subscription[] = []
  public clipboardCoordinates: [number, number][] = []
  public coordinateFormArray: UntypedFormArray = new UntypedFormArray([])
  public coordinateList: Mapbox.LngLatLike[]
  public coordinates: [number, number][] = []
  public featureProperty: FeatureProperty

  get isValid() { return this.coordinateFormArray.controls.every(c => c.valid) }
  get isInvalid() { return this.coordinateFormArray.controls.some(c => !c.valid) }
  get isPolygon() { return this.type == 'polygon' }
  get isLine() { return this.type == 'line' }
  get numCoordinates() { return this.coordinateFormArray.length }
  get minCoordinates() {
    if (this.isLine) return 2
    else if (this.isPolygon) return 3
  }

  constructor(
    public commandService: CommandService,
    public copyService: CopyService,
    public featureService: FeatureService,
    public sceneService: SceneService
  ) {
    this.getCoordinates()
  }

  ngOnInit(): void {
    this.updateForms()

    this.subscriptions.push(
      clipboardContents$.subscribe((contents: CoordinatesClipboard) => {
        this.coordinates = contents?.coordinates as [number, number][] ?? []
      })
    )
  }

  ngOnChanges(): void {
    this.updateForms()
  }

  updateForms() {
    if (this.valueString === undefined) this.valueString = this.type == 'line' ? '[[],[]]' : '[[],[],[],[]]'
    this.coordinateList = JSON.parse(this.valueString)
    this.coordinateFormArray = new UntypedFormArray([])

    this.coordinateList.forEach((coordinate, i) => {
      if (i == this.coordinateList.length) return
      this.coordinateFormArray.push(new UntypedFormGroup({
        longitude: new UntypedFormControl(coordinate[0], [Validators.min(-180), Validators.max(180)]),
        latitude: new UntypedFormControl(coordinate[1], [Validators.min(-90), Validators.max(90)])
      })
      )
    })
  }

  coordinatesInputToString(): string {
    const getCoordinates = (control: AbstractControl) => {
      return [parseFloat(control.get('longitude').value), parseFloat(control.get('latitude').value)]
    }

    let string = []
    this.coordinateFormArray.controls.forEach(group => string.push(getCoordinates(group)))

    return JSON.stringify(string)
  }

  addCoordinatesToForm() {
    this.coordinateFormArray.controls.push(
      new UntypedFormGroup({
        longitude: new UntypedFormControl('', [Validators.required, Validators.min(-180), Validators.max(180)]),
        latitude: new UntypedFormControl('', [Validators.required, Validators.min(-90), Validators.max(90)])
      })
    )
  }

  onEdit() {
    if (this.isInvalid) {
      this.showRequiredFields()
      return
    }

    let coordinateString = this.coordinatesInputToString()
    this.edit.emit({ value: coordinateString })
  }

  copyPosition(coordinateGroup: UntypedFormGroup) {
    this.copyService.getCoordinates()
      .then(coordinates => {
        const longitude = coordinateGroup.value.longitude as number
        const latitude = coordinateGroup.value.latitude as number

        coordinates.push([longitude, latitude])

        const contents = { type: 'coordinates', coordinates } as CoordinatesClipboard
        this.copyService.copyContentsToClipboard(contents)
      })
  }

  pasteCoordinates() {
    this.coordinateFormArray.controls = this.coordinates.map(([longitude, latitude]) => new UntypedFormGroup({
      longitude: new UntypedFormControl(longitude),
      latitude: new UntypedFormControl(latitude)
    }))

    this.onEdit()
  }

  pasteCoordinate(coordinateGroup: UntypedFormGroup) {
    this.copyService.getCoordinates()
      .then(coordinates => {
        this.clipboardCoordinates = coordinates

        if (coordinateGroup && this.clipboardCoordinates?.length > 0) {
          const [longitude, latitude] = this.clipboardCoordinates[this.clipboardCoordinates.length - 1]

          coordinateGroup.get('longitude').setValue(longitude)
          coordinateGroup.get('latitude').setValue(latitude)

          this.onEdit()
        }
      })
  }

  removeCoordinatesFromForm(i: number) {
    if (this.numCoordinates > this.minCoordinates) {
      this.coordinateFormArray.controls.splice(i, 1)
      this.onEdit()
    }
  }

  getCoordinates() {
    this.copyService.getCoordinates()
      .then(coordinates => this.coordinates = coordinates)
  }

  showRequiredFields() {
    this.coordinateFormArray.controls.forEach(c => c.markAllAsTouched())
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}