import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import Stripe from 'stripe'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EndpointOptions, RequestService } from '@services/request.service'

// Define an interface that reflects the relevant Stripe fields you need
export interface StripePrice {
  id: string
  lookup_key: string | null
  unit_amount: number | null
  currency: string
  recurring?: {
    interval: string
  }
  product: {
    id: string
    name: string
    description?: string
    metadata?: Record<string, any>
  }
  metadata?: Record<string, any>
}

export interface CheckoutSession {
  url: string
}

export interface SubscriptionItem {
  id: string
  quantity: number
}

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  constructor(private requestService: RequestService) { }

  lookupPrices(lookupKeys: string[]): Observable<StripePrice[]> {
    return this.requestService.create<StripePrice[]>(`${environment.api}/stripe/prices`, { lookupKeys })
  }

  getAllPricesAndProducts(): Observable<Stripe.Price[]> {
    return this.requestService.get<Stripe.Price[]>(`${environment.api}/stripe/prices`)
  }

  previewInvoice(items: Stripe.SubscriptionUpdateParams.Item[]): Observable<Stripe.Invoice> {
    const url = `${environment.api}/stripe/preview-invoice`
    const options = { error: { operation: 'Preview Invoice', toast: true } } as EndpointOptions

    return this.requestService.create(url, items, options)
  }

  updateSubscriptionInterval(interval: Stripe.Price.Recurring.Interval): Observable<{
    error?: string,
    message: string,
    invoice?: Stripe.Invoice,
    subscription?: Stripe.Subscription
  }> {
    const url = `${environment.api}/stripe/interval`
    const options = { error: { operation: 'Update Subscription Interval', toast: true } } as EndpointOptions

    return this.requestService.update(url, { interval }, options)
  }

  createCheckoutSession(
    lineItems: { price: string, quantity: number }[],
    successUrl: string,
    cancelUrl: string,
    params: { customerId?: string, customerEmail?: string },
    metadata: any
  ): Observable<CheckoutSession> {
    return this.requestService.create<CheckoutSession>(`${environment.api}/stripe/checkout-session`, {
      lineItems,
      successUrl,
      cancelUrl,
      ...params,
      metadata
    })
  }
}