import { Organization, OrganizationMember as AuthOrganizationMember, Role } from 'auth0'
import { forkJoin, Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AppState, AuthService, RedirectLoginOptions, User } from '@auth0/auth0-angular'
import { EndpointOptions, RequestService } from '@services/request.service'

export type ModuleType = '' | 'assets' | 'billing' | 'builder' | 'projects' | 'model-editor' | 'models' | 'organization' | 'viewer'

export type OpaqueToken = { access_token: string, expires_in: number, scope: string, token_type: string }

export type AuthUser = User & {
  org_id?: string
}

export type OrganizationMember = AuthOrganizationMember & { roles: Role[] }

export const AuthRole = Object.freeze({
  /** Handles Financials, Billing, and Account Management */
  ACCOUNT_MANAGER: environment.auth.roles['account manager'],
  /** Can view and edit projects */
  EDITOR: environment.auth.roles['editor'],
  /** Can view projects */
  READ_ONLY: environment.auth.roles['read only'],
  /** Can do anything */
  SUPERUSER: environment.auth.roles['super user'],
  /** Can view any projects by organization members */
  SUPERVISOR: environment.auth.roles['supervisor'],
  /** Handles User Management & Roles */
  USER_MANAGER: environment.auth.roles['user manager'],
})

export const isAccountManager = (roleID: string): boolean => roleID == AuthRole.ACCOUNT_MANAGER
export const isAdminRole = (roleID: string): boolean => isAccountManager(roleID) || isSuperUser(roleID) || isUserManager(roleID) || isSupervisor(roleID) || isEditor(roleID)
export const isEditor = (roleID: string): boolean => roleID == AuthRole.EDITOR
export const isReadOnly = (roleID: string): boolean => roleID == AuthRole.READ_ONLY
export const isSuperUser = (roleID: string): boolean => roleID == AuthRole.SUPERUSER
export const isSupervisor = (roleID: string): boolean => roleID == AuthRole.SUPERVISOR
export const isUserManager = (roleID: string): boolean => roleID == AuthRole.USER_MANAGER

export const hasViewerAccess = (...roles: Role[]) => roles.some(role => isReadOnly(role.id) || hasBuilderAccess(role))
export const hasBuilderAccess = (...roles: Role[]) => roles.some(role => isEditor(role.id) || isSupervisor(role.id) || hasUserAdminAccess(role))
export const hasUserAdminAccess = (...roles: Role[]) => roles.some(role => isUserManager(role.id) || hasFinancialAccess(role))
export const hasFinancialAccess = (...roles: Role[]) => roles.some(role => isAccountManager(role.id) || isSuperUser(role.id))

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUser: AuthUser
  public fetchingResources: boolean = true
  public lastUpdated: Date
  public organizationMembers: OrganizationMember[] = []
  public organizationRoles: Role[] = []
  public projectPublicStatus: boolean = false
  public roles: Role[] = []

  get currentModule(): ModuleType {
    const url = this._router.url

    if (url.includes("billing")) {
      return "billing"
    } else if (url.includes("models/edit")) {
      return "model-editor"
    } else if (url.includes("models")) {
      return "models"
    } else if (url.includes("assets")) {
      return "assets"
    } else if (url.includes("organization")) {
      return "organization"
    } else if (url.includes("projects/edit")) {
      return "builder"
    } else if (/projects\/\d+/.test(url)) { // Check if the URL matches "projects/" followed by any
      return "viewer"
    } else if (url.includes("projects")) {
      return "projects"
    } else {
      return ""
    }
  }
  get id(): string {
    let pathParts = this._router.url.split("/")
    return pathParts.length == 1 ? pathParts[1] : pathParts[pathParts.length - 1]
  }
  get user$() { return this._authService.user$ as Observable<AuthUser> }
  get isAuthenticated$() { return this._authService.isAuthenticated$ }

  get hasOrganization() { return this.currentUser?.org_id != null }

  get hasBuilderAccess() { return this.hasOrganization ? hasBuilderAccess(...this.roles) : true }
  get hasFinancialAccess() { return this.hasOrganization ? hasFinancialAccess(...this.roles) : false }
  get hasUserAdminAccess() { return this.hasOrganization ? hasUserAdminAccess(...this.roles) : false }
  get isReadOnly() { return this.hasOrganization ? this.roles?.some(role => isReadOnly(role.id)) : true }
  get isSuperUser() { return this.hasOrganization ? this.roles?.some(role => isSuperUser(role.id)) : false }

  public loginRedirectURL: string

  constructor(
    private _authService: AuthService,
    private _http: HttpClient,
    private _requestService: RequestService,
    private _router: Router,
  ) {
    this.user$.subscribe(user => this.currentUser = user)
  }

  public createAccessToken() {
    const url = `${environment.api}/auth/access-token`
    const options = { error: { operation: 'Create Access Token' } } as EndpointOptions

    return this._requestService.get<string>(url, options)
  }

  // Don't want to require a token if in the viewer
  public getToken(): Observable<string> {
    if (this.currentModule == "viewer") {
      if (this.projectPublicStatus) { // Public project in Viewer
        return of(undefined)
      } else { // Project in builder or private project in viewer
        return this._authService.getAccessTokenSilently()
      }
    } else {
      return this._authService.getAccessTokenSilently()
    }
  }

  /** Token used to retrieve public project resources */
  public getPublicToken(projectID: number) {
    const url = `${environment.api}/viewer/token/${projectID}`

    return this._http.get<OpaqueToken>(url)
  }

  /** Token used to retrieve public project resources */
  public getProjectToken(projectID: number) {
    const url = `${environment.api}/project/token/${projectID}`

    return this._http.get<OpaqueToken>(url)
  }

  public getUserAccounts() {
    const url = `${environment.api}/user/accounts`

    return this._http.get<{ hasFreeTrial: boolean, organizations: Organization[] }>(url)
  }

  public loginWithRedirect(options?: RedirectLoginOptions<AppState>) {
    this._authService.loginWithRedirect(options)
  }

  public logout() {
    this._authService.logout({ logoutParams: { returnTo: environment.logoutRedirect } })
  }

  /** Fetches things like an Organization's Roles  */
  public getOrganizationResources() {
    this.fetchingResources = true

    return forkJoin([this.getRoles(), this.getFilteredOrganizationMembers()]).pipe(
      tap(([roles, members]) => {
        const userIsSuperUser = roles?.some(role => isSuperUser(role.id))

        if (!userIsSuperUser) { // Removes superuser role from list of roles if user is not a superuser
          roles.splice(roles.findIndex(role => isSuperUser(role.id)), 1)
        }

        // Sorts roles by how much they are authorized to do
        this.organizationRoles = roles.sort((a, b) => a.description.localeCompare(b.description))
        this.organizationMembers = members
        this.fetchingResources = false
        this.lastUpdated = new Date()
      })
    )
  }

  /** ORGANIZATION */

  public getOrganization() {
    const url = `${environment.api}/org`
    const options = { error: { operation: 'Get Organization', toast: true } } as EndpointOptions

    return this._requestService.get<Organization>(url, options).pipe(
      tap(organization => {
        if (organization.metadata == null) {
          organization.metadata = { branding: {} }
        }
      })
    )
  }

  public checkOrganizationName(name: string) {
    const url = `${environment.api}/org/check-name?name=${name}`
    const options = { error: { operation: 'Check Organization Name', toast: true } } as EndpointOptions

    return this._requestService.get<{ available: boolean, message: string }>(url, options)
  }

  public updateOrganization(organization: Organization) {
    const url = `${environment.api}/org`
    const options = {
      successToast: { title: `Organization Updated`, message: `Your changes have been saved.` },
      error: { operation: 'Update Organization', toast: true }
    } as EndpointOptions

    return this._requestService.update<Organization>(url, organization, options)
  }

  public getOrganizationMembers() {
    const url = `${environment.api}/org/members`
    const options = { error: { operation: "Get Organization's Members", toast: true } } as EndpointOptions

    return this._requestService.get<OrganizationMember[]>(url, options).pipe(
      tap(members => this.organizationMembers = members)
    )
  }

  /** Important Note: Filtered members exclude our special Kartorium Admins/Superusers */
  public getFilteredOrganizationMembers() {
    const url = `${environment.api}/org/members/filtered`
    const options = { error: { operation: "Get Organization's Members", toast: true } } as EndpointOptions

    return this._requestService.get<OrganizationMember[]>(url, options).pipe(
      tap(members => this.organizationMembers = members)
    )
  }

  /** MEMBERS */
  public addMemberToOrganization(name: string, email: string, roles: string[]) {
    const url = `${environment.api}/org/member`
    const options = {
      successToast: { title: `Member Added to Organization`, message: `Invitation has been sent to ${email}.` },
      error: { operation: 'Add Member to Organization', toast: true }
    } as EndpointOptions
    const member = { name, email, roles } as OrganizationMember

    return this._requestService.create<User>(url, member, options)
  }

  public removeMembersFromOrganization(memberIDs: string[]) {
    const url = `${environment.api}/org/members`
    const options = {
      successToast: { title: `Member Removed`, message: `Member has been successfully removed from your organization.` },
      error: { operation: 'Remove Members from Organization', toast: true },
      httpOptions: {
        body: memberIDs
      },
    } as EndpointOptions

    return this._requestService.delete<OrganizationMember[]>(url, options)
  }

  public getCurrentUsersRoles$() {
    const url = `${environment.api}/org/user/roles`
    const options = { error: { operation: 'Get Current Users Roles', retry: true, toast: true } } as EndpointOptions

    return this._requestService.get<Role[]>(url, options).pipe(
      tap(roles => this.roles = roles)
    )
  }

  /** ROLES */

  public getRoles() {
    const url = `${environment.api}/org/roles`
    const options = { error: { operation: 'Get Roles', toast: true } } as EndpointOptions

    return this._requestService.get<Role[]>(url, options)
  }

  public getMembersRoles(memberID: string) {
    const url = `${environment.api}/org/roles/${memberID}`
    const options = { error: { operation: "Get Member's Roles", toast: true } } as EndpointOptions

    return this._requestService.get<Role[]>(url, options)
  }

  public setMemberRole(memberID: string, roleID: string) {
    const url = `${environment.api}/org/member/role/${memberID}`
    const options = {
      successToast: { title: `Updated Member's Role`, message: `Member's role has been changed` },
      error: { operation: "Set Member's Role", toast: true, status: { message: "Member's role failed to update" } }
    } as EndpointOptions

    return this._requestService.update<any>(url, { roleID }, options)
  }

  public addMemberRoles(user: OrganizationMember, ...roleIDs: string[]) {
    const url = `${environment.api}/org/roles/${user.user_id}`
    const options = { error: { operation: "Add Member Roles", toast: true } } as EndpointOptions

    return this._requestService.create<Role[]>(url, roleIDs, options)
  }

  public removeMemberRoles(user: OrganizationMember, ...roleIDs: string[]) {
    const url = `${environment.api}/org/roles/${user.user_id}`
    const options = { error: { operation: "Remove Member Roles", toast: true } } as EndpointOptions

    return this._requestService.update<Role[]>(url, roleIDs, options)
  }

  public addMultipleMemberRoles(...members: { member: OrganizationMember, roleIDs: string[] }[]) {
    const url = `${environment.api}/org/members/roles`
    const options = { error: { operation: "Add Roles to Members", toast: true } } as EndpointOptions

    return this._requestService.create<OrganizationMember[]>(url, members, options)
  }

  public removeMultipleMemberRoles(...members: { member: OrganizationMember, roleIDs: string[] }[]) {
    const url = `${environment.api}/org/members/roles`
    const options = { error: { operation: "Remove Roles from Members", toast: true } } as EndpointOptions

    return this._requestService.update<OrganizationMember[]>(url, members, options)
  }
}