import * as THREE from 'three'

import { Feature } from '@classes/Feature'
import { icon } from '@fortawesome/fontawesome-svg-core'
import * as FasSvgIcons from '@fortawesome/pro-solid-svg-icons'
import { TagTypes } from '@services/spatial-annotation.service'
import { kebabToCamel } from '@utils/Objects'
import { createRoundedPlaneGeometry } from '@utils/ThreeJS'

/** Defines the options for creating a tag. */
export interface TagOptions {
    backgroundColor?: string,
    backgroundShape?: 'circle' | 'pill' | 'rounded' | 'square',
    color?: string,
    icon?: string,
    showOnTop?: boolean,
    text?: string,
    type?: TagTypes,
}

export function createTagTexture(feature: Feature) {
    const tagOptions: TagOptions = {
        backgroundColor: feature.backgroundColor.value,
        backgroundShape: feature.backgroundShape.value,
        color: feature.color.value,
        icon: feature.icon.value,
        text: feature.text.value,
    }

    if (feature.type == 'icon') {
        return createTextureFromSVG(tagOptions)
    } else if (feature.type == 'label') {
        return createTextureFromText(tagOptions)
    } else {
        throw new Error('Unsupported tag type')
    }
}

/** Creates a THREE.CanvasTexture given an SVG string */
export function createTextureFromSVG(tagOptions: TagOptions): Promise<THREE.CanvasTexture> {
    const styledSvg = createIconSVGString(tagOptions)

    return svgToCanvas(styledSvg).then(
        canvas => new THREE.CanvasTexture(canvas)
    )
}

export function createIconSVGString(options?: TagOptions) {
    const backgroundColor = options.backgroundColor ?? '#ffffff'
    const fontColor = options.color ?? '#000000'
    const backgroundShape = options.backgroundShape ?? 'rounded'
    const iconClass = kebabToCamel(options.icon.slice(4))
    const selectedIcon = FasSvgIcons[iconClass]
    const iconSVG = icon(selectedIcon).html[0]

    return createStyledSvg(iconSVG, { color: fontColor, backgroundColor, backgroundShape })
}

export function createStyledSvg(svgMarkup: string, style: { color: string, backgroundColor: string, backgroundShape: 'circle' | 'rounded' | 'square' | 'pill' | 'none' }) {
    const parser = new DOMParser()
    const svgDoc = parser.parseFromString(svgMarkup, "image/svg+xml")

    // Insert the rectangle at the beginning of the SVG
    const svgElement = svgDoc.querySelector('svg')

    // Extract the original viewBox dimensions
    const originalViewBox = svgElement.getAttribute('viewBox').split(' ').map(Number)

    let [x, y, width, height] = originalViewBox
    // Calculate the scale factor and new viewBox dimensions
    const squareDimension = Math.max(width, height)
    width = squareDimension
    height = squareDimension

    const scale = 2
    const newWidth = width * scale
    const newHeight = height * scale
    const xOffset = (newWidth - originalViewBox[2]) / 2
    const yOffset = (newHeight - originalViewBox[3]) / 2

    svgElement.setAttribute("viewBox", `0 0 ${newWidth} ${newHeight}`)

    const path = svgElement.querySelector('path')

    path.setAttribute("fill", style.color)

    // Center the path within the SVG
    path.setAttribute("transform", `translate(${xOffset}, ${yOffset})`)

    if (style.backgroundShape !== 'none') {
        const background = svgDoc.createElementNS("http://www.w3.org/2000/svg", "rect")
        background.setAttribute("width", newWidth.toString())
        background.setAttribute("height", newHeight.toString())
        background.setAttribute("fill", style.backgroundColor)

        switch (style.backgroundShape) {
            case 'circle':
                background.setAttribute("rx", (newWidth / 2).toString())
                background.setAttribute("ry", (newHeight / 2).toString())
                break;
            case 'rounded':
                background.setAttribute("rx", "100")
                background.setAttribute("ry", "100")
                break;
            case 'pill':
                // TODO: make this actually look good for all icons
                background.setAttribute("rx", (newWidth / 2).toString())
                background.setAttribute("ry", (newHeight / 3).toString())
                break;
        }

        svgElement.insertBefore(background, svgElement.firstChild)
    }

    const serializer = new XMLSerializer()
    return serializer.serializeToString(svgElement)
}

export function svgToCanvas(svgStr: string): Promise<HTMLCanvasElement> {
    return new Promise(resolve => {
        const img = new Image()
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        img.onload = () => {
            const svgWidth = img.naturalWidth
            const svgHeight = img.naturalHeight
            const aspectRatio = svgWidth / svgHeight

            const baseSize = 128

            if (svgWidth > svgHeight) {
                canvas.width = closestPowerOfTwo(baseSize)
                canvas.height = closestPowerOfTwo(baseSize / aspectRatio)
            } else {
                canvas.height = closestPowerOfTwo(baseSize)
                canvas.width = closestPowerOfTwo(baseSize * aspectRatio)
            }

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            resolve(canvas)
        }

        const blob = new Blob([svgStr], { type: 'image/svg+xml' })

        img.crossOrigin = 'anonymous'
        img.src = URL.createObjectURL(blob)
    })
}

function closestPowerOfTwo(number: number) {
    return Math.pow(2, Math.round(Math.log(number) / Math.log(2)))
}

/** Creates a THREE.CanvasTexture given a string */
export function createTextureFromText(tagOptions: TagOptions): Promise<THREE.CanvasTexture> {
    return textToCanvas(tagOptions).then(
        canvas => new THREE.CanvasTexture(canvas)
    )
}

export function textToCanvas(tagOptions: TagOptions): Promise<HTMLCanvasElement> {
    const backgroundColor = tagOptions.backgroundColor
    const baseDimension = 1024
    const fontColor = tagOptions.color
    const textContent = tagOptions.text

    return new Promise(resolve => {
        // Create a temporary canvas to measure text
        const tempCanvas = document.createElement('canvas')
        const tempCtx = tempCanvas.getContext('2d')
        let fontSize = 48

        tempCtx.font = `${fontSize}px Arial`

        let textWidth = tempCtx.measureText(textContent).width
        let textHeight = fontSize
        let aspectRatio = textWidth / textHeight
        let canvasWidth, canvasHeight

        if (aspectRatio >= 1) {
            canvasWidth = baseDimension
            canvasHeight = baseDimension / aspectRatio
        } else {
            canvasHeight = baseDimension
            canvasWidth = baseDimension * aspectRatio
        }

        const canvas = document.createElement('canvas')
        // The font would take up the full width if it was canvasHeight but its not so we adjust
        // If the the width is 80% then there is no padding so I add padding based off of the hight.
        canvas.width = (canvasWidth * 0.8) + (canvasHeight * 0.8)
        canvas.height = canvasHeight
        const ctx = canvas.getContext('2d')

        if (ctx) {
            ctx.fillStyle = backgroundColor
            ctx.fillRect(0, 0, canvas.width, canvas.height)

            fontSize = canvasHeight * 0.8

            ctx.font = `${fontSize}px Arial`
            ctx.fillStyle = fontColor
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'

            ctx.fillText(textContent, canvas.width / 2, canvas.height / 2)
        }

        resolve(canvas)
    })
}

export function createTagMaterial(texture: THREE.CanvasTexture, options?: { opacity?: number }) {
    return new THREE.MeshBasicMaterial({
        map: texture,
        opacity: options?.opacity ?? 1,
        side: THREE.DoubleSide,
        transparent: true,
    })
}

export function createTagGeometry(width: number, height: number, backgroundShape: 'circle' | 'pill' | 'rounded' | 'square') {
    if (backgroundShape == 'circle' || backgroundShape == 'pill') {
        return createRoundedPlaneGeometry(width, height, { smoothness: 20, radius: Math.min(width, height) / 2 })
    } else if (backgroundShape == 'rounded') {
        return createRoundedPlaneGeometry(width, height, { smoothness: 20, radius: Math.min(width, height) / 4 })
    } else if (backgroundShape == 'square') {
        return new THREE.PlaneGeometry(width, height)
    }
}