import { Subscription } from 'rxjs'

import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core'
import { Model } from '@classes/Model'
import { CreateModelComponent } from '@modal/create-model/create-model.component'
import { AuthenticationService } from '@services/authentication.service'
import { FeatureService } from '@services/feature.service'
import { LoadingService } from '@services/loading.service'
import { ModalService } from '@services/modal.service'
import { ModelService } from '@services/model.service'
import { ProjectService } from '@services/project.service'
import { SubscriptionService } from '@services/subscription.service'
import { ModelGridComponent } from '@shared/model-grid/model-grid.component'

@Component({
  selector: 'shared-model-picker',
  templateUrl: './model-picker.component.html',
  styleUrls: ['./model-picker.component.css'],
})
export class ModelPickerComponent implements OnDestroy {
  @Input() models: Model[]
  @Output() onSelect = new EventEmitter<{
    event: PointerEvent
    model: Model
  }>()
  @ViewChild('modelGrid') modelGrid: ModelGridComponent

  private _subscriptions: Subscription[] = []
  public showProjectsModels = false

  get atModelLimit(): boolean { return this._subscriptionService.atModelLimit }

  get projectsModels() {
    const features = Array.from(this._featureService.scenesFeatures.entries())
      .map(([sceneID, features]) => features)
      .reduce((acc, val) => acc.concat(val))
      .filter((feature) => feature.modelID != null)

    return this.models.filter((model) =>
      features.some((feature) => feature.modelID == model.id)
    )
  }

  get inProject() { return this._authenticationService.currentModule == 'builder' }
  get loaded(): boolean { return this._loadingService.loaded }
  get selectedModel() { return this.inProject ? this._featureService.selectedFeature.model : this._modelService.selectedModel }
  get searchValue(): string { return this._modelService.search }
  set searchValue(search: string) { this._modelService.search = search }

  constructor(
    private _authenticationService: AuthenticationService,
    private _featureService: FeatureService,
    private _loadingService: LoadingService,
    private _modalService: ModalService,
    private _modelService: ModelService,
    private _projectService: ProjectService,
    private _subscriptionService: SubscriptionService,
  ) { }

  pickModel(modelSelect: { event: PointerEvent, model: Model }) {
    this.onSelect.emit(modelSelect)
  }

  refresh() {
    if (this.loaded) {
      if (this.inProject) {
        const projectID = this._projectService.currentProject.id

        this._loadingService.await(this._modelService.getModels(projectID))
      } else {
        this._loadingService.await(this._modelService.getUsersModels())
      }
    }
  }
  openCreateModal() {
    this._modalService.showAsModal(CreateModelComponent)
  }
  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe())
  }
}
