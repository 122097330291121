import saveAs from 'file-saver'
import { from, Observable, of, Subject } from 'rxjs'
import { concatMap, filter, finalize, map, switchMap, takeLast, takeUntil, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EndpointOptions, RequestService } from '@services/request.service'
import { ToastService } from '@services/toast.service'

type UploadPart = {
  ETag: string
  PartNumber: number
  token: string
}

type UploadPurpose = 'process-e57' | 'process-geotiff' | 'process-model' | 'process-point-cloud' | 'submit-feedback'

type UploadResponse = {
  token: string
  url: string
}

type UploadRequestOptions = {
  purpose?: UploadPurpose
  token?: string
}

/** 100 MB */
const MAX_FILE_SIZE = 100_000_000
/** 10 MB */
const FILE_CHUNK_SIZE = 10_000_000

function getTruncatedFileName(fileName: string, maxLength: number): string {
  const extension = fileName.substring(fileName.lastIndexOf('.'))
  const baseName = fileName.substring(0, fileName.lastIndexOf('.'))

  if (baseName.length > maxLength) {
    const truncatedName = baseName.substring(0, maxLength - 3) // Adjust for '...'
    return `${truncatedName}..${extension}`
  }

  return fileName
}

@Injectable({
  providedIn: 'root'
})
export class AwsService {
  private readonly UPLOAD_HISTORY_SIZE = 4;
  private _recentUploadDurations: number[] = [];

  constructor(private _http: HttpClient, private _requestService: RequestService, private _toastService: ToastService) { }

  private _createDownloadUrl(folder: string, filename: string) {
    const path = environment.api + '/s3/download'

    return this._requestService.create<string>(path, { folder, filename })
  }

  private _createPartUploadUrl(partNumber: number, token: string) {
    const path = environment.api + '/s3/multipart-upload/part'

    return this._requestService.create<UploadResponse>(path, { partNumber, token })
  }

  private _createUploadUrl(filename: string, options: UploadRequestOptions = {}) {
    const path = environment.api + '/s3/upload'

    return this._requestService.create<UploadResponse>(path, { filename, ...options })
  }

  private _finishMultipartUpload(parts: UploadPart[], token: string) {
    const path = environment.api + '/s3/multipart-upload/finish'

    return this._requestService.create<{ message, token }>(path, { parts, token })
  }

  private _startMultipartUpload(filename: string, options: UploadRequestOptions = {}): Observable<string> {
    const path = environment.api + '/s3/multipart-upload/start'
    return this._requestService.create<string>(path, { filename, ...options })
  }

  private _startModelProcessing(token: string) {
    const path = `${environment.api}/job/process-model`

    return this._requestService.create<string>(path, { token })
  }

  private _uploadFile(file: File, uniqueKey: string, options: UploadRequestOptions = {}): Observable<{ token: string }> {
    if (file.size > MAX_FILE_SIZE) {
      return this._startMultipartUpload(file.name, options).pipe(
        switchMap(startToken => {
          const totalParts = Math.ceil(file.size / FILE_CHUNK_SIZE)

          // Sequentially upload each part, passing their tokens to the next part
          return Array.from({ length: totalParts }, (_, partIndex) => partIndex).reduce(
            (chain$, partIndex) =>
              chain$.pipe(
                switchMap((prev: { parts: UploadPart[], token: string }) =>
                  this._uploadFilePart(file, uniqueKey, partIndex, prev.token).pipe(
                    map(part => ({
                      parts: [...prev.parts, part], // Append the current part to the parts array
                      token: part.token, // Pass the latest token to the next part
                    }))
                  )
                )
              ),
            of({ parts: [], token: startToken }) // Initial observable with empty parts array and starting token
          ).pipe(
            map(({ parts }) => parts), // Extract only the parts array
            switchMap((parts: UploadPart[]) => {
              const finalPart = parts[parts.length - 1]
              const finalToken = finalPart.token

              parts.forEach(part => delete part.token) // Remove the used tokens from each part

              return this._finishMultipartUpload(parts, finalToken) // Pass all parts and the final token
            })
          )
        })
      )
    } else {
      return this._createUploadUrl(file.name, options).pipe(
        switchMap(
          ({ token, url }) => {
            const endpointOptions = {
              error: { operation: 'uploadFile', toast: true },
              progressBar: { title: `Uploading Model File: ${file.name}` },
              successToast: { title: `Uploaded Model File: ${file.name}` },
            } as EndpointOptions

            return this._requestService.update(url, file, endpointOptions).pipe(
              map(() => ({ token }))
            )
          }
        ),
      )
    }
  }

  private _updateUploadHistory(duration: number) {
    // Add new duration to the front
    this._recentUploadDurations.unshift(duration);
    // Keep only the last UPLOAD_HISTORY_SIZE entries
    if (this._recentUploadDurations.length > this.UPLOAD_HISTORY_SIZE) {
      this._recentUploadDurations.pop();
    }
  }
  
  private _getAverageUploadDuration(): number {
    if (this._recentUploadDurations.length === 0) {
      return 15000; // Default to 15 seconds in milliseconds
    }
    const sum = this._recentUploadDurations.reduce((acc, curr) => acc + curr, 0);
    return sum / this._recentUploadDurations.length;
  }

  private _uploadFilePart(file: File, uniqueKey: string, partIndex: number, token: string): Observable<UploadPart> {
    const start = partIndex * FILE_CHUNK_SIZE
    const end = Math.min((partIndex + 1) * FILE_CHUNK_SIZE, file.size)
    const fileChunk = file.slice(start, end, 'binary')
    const partNumber = partIndex + 1
    const totalParts = Math.ceil(file.size / FILE_CHUNK_SIZE)
    const fileName = getTruncatedFileName(file.name, 15)
    const startTime = Date.now()

    let abortSubject = this._toastService.abortMap.get(uniqueKey)

    return this._createPartUploadUrl(partNumber, token).pipe(
      switchMap(({ token, url }) => {
        const endpointOptions: EndpointOptions = {
          error: { operation: 'uploadFilePart', toast: true },
          multipart: true,
          showProgressBar: true,
          progressBar: {
            title: `Uploading ${fileName} - Part ${partNumber}/${totalParts}`,
            actionButton: {
              title: "Cancel",
              callback: () => { abortSubject.next() }
            },
            // Use average of recent upload durations for estimate
            estimatedTimeInSeconds: Math.ceil(this._getAverageUploadDuration() / 1000) * (totalParts - (partNumber - 1)) || 15 * (totalParts)
          },
        } as EndpointOptions

        return this._requestService.update<HttpEvent<any>>(url, fileChunk, endpointOptions).pipe(
          filter(event => event.type == HttpEventType.Response),
          map((response: HttpResponse<HttpEvent<HttpEventType.Response>>) => {
            // Update the upload history with the new duration
            const duration = Date.now() - startTime;
            this._updateUploadHistory(duration);
            return { ETag: response.headers.get('ETag'), PartNumber: partNumber, token };
          })
        )
      })
    )
  }

  public deleteS3File(filename: string, folder: string) {
    const path = `${environment.api}/s3/delete/${filename}/${encodeURIComponent(folder)}`

    return this._requestService.delete<string>(path)
  }

  public downloadS3File(folder: string, filename: string) {
    return this._createDownloadUrl(folder, filename).pipe(
      tap(url => saveAs(url, filename))
    )
  }

  public getS3File(folder: string, filename: string) {
    return this._createDownloadUrl(folder, filename)
  }

  public uploadFiles(files: File[], options: UploadRequestOptions = {}): Observable<{ token: string }> {
    let token: string | undefined = options.token

    const removeWarning = this._toastService.setupNavigationWarning()

    return from(files).pipe(
      concatMap(file => {
        this._toastService.date = Date.now()
        const uniqueKey = `${file.name}-${this._toastService.date}`
        this._toastService.abortMap.set(uniqueKey, new Subject<void>())
        let abortSubject = this._toastService.abortMap.get(uniqueKey)
        return this._uploadFile(file, uniqueKey, { purpose: options.purpose, token }).pipe(
          takeUntil(abortSubject),
          tap(response => token = response.token)
        )
      }),
      takeLast(1),
      finalize(() => removeWarning())
    )
  }

  public processModelInORI(files: File[]) {
    return this.uploadFiles(files, { purpose: 'process-e57' }).pipe(
      switchMap(({ token }) => this._startModelProcessing(token))
    )
  }
}