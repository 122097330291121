import { LoginComponent } from 'src/app/modules/standalone/login/login.component'
import { SignupComponent } from 'src/app/modules/standalone/signup/signup.component'

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { UpgradeTierComponent } from '@billing/upgrade-tier/upgrade-tier.component'
import {
  canAccessBillingGuard, CustomAuthGuard, hasNoOrganizationGuard, hasNoSubscriptionGuard, hasSubscriptionGuard, isFreeTierGuard,
  isNotLegacySubscription, isProTierGuard, isUserAdminGuard, projectIsValidGuard, signupGuard, subscriptionIsActiveGuard
} from '@classes/RouteGuards'
import { ErrorPageComponent } from '@shared/error-page/error-page.component'

import { SubmitFeedbackComponent } from './modules/standalone/submit-feedback/submit-feedback.component'

const routes: Routes = [
  {
    path: 'assets',
    loadChildren: () => import('@assets/asset-management.module').then(m => m.AssetManagementModule),
    canActivate: [CustomAuthGuard, hasSubscriptionGuard, subscriptionIsActiveGuard, isProTierGuard]
  },
  {
    path: 'billing',
    loadChildren: () => import('@billing/billing.module').then(m => m.BillingModule),
    canActivate: [CustomAuthGuard, hasSubscriptionGuard, canAccessBillingGuard, isNotLegacySubscription]
  },
  {
    path: 'projects/edit/:id',
    loadChildren: () => import('@builder/builder.module').then(m => m.BuilderModule),
    canActivate: [projectIsValidGuard, CustomAuthGuard, hasSubscriptionGuard, subscriptionIsActiveGuard]
  },
  {
    path: 'builder/:id',
    redirectTo: 'projects/edit/:id',
  },
  {
    path: 'projects',
    loadChildren: () => import('@dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [CustomAuthGuard, hasSubscriptionGuard, subscriptionIsActiveGuard]
  },
  {
    path: 'dashboard',
    redirectTo: 'projects',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'models',
    loadChildren: () => import('@models/model-manager.module').then(m => m.ModelManagerModule),
    canActivate: [CustomAuthGuard, hasSubscriptionGuard, subscriptionIsActiveGuard]
  },
  {
    path: 'model-manager',
    redirectTo: 'models',
  },
  {
    path: 'organization',
    loadChildren: () => import('@organization/organization.module').then(m => m.OrganizationModule),
    canActivate: [CustomAuthGuard, hasSubscriptionGuard, isProTierGuard, isUserAdminGuard]
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [signupGuard, hasNoOrganizationGuard, hasNoSubscriptionGuard]
  },
  {
    path: 'upgrade',
    component: UpgradeTierComponent,
    canActivate: [CustomAuthGuard, hasSubscriptionGuard, isFreeTierGuard]
  },
  {
    path: 'feedback',
    component: SubmitFeedbackComponent,
    canActivate: [CustomAuthGuard, hasSubscriptionGuard]
  },
  {
    path: 'projects/:id',
    loadChildren: () => import('@viewer/viewer.module').then(m => m.ViewerModule),
    canActivate: [projectIsValidGuard]
  },
  {
    path: 'viewer/:id',
    redirectTo: 'projects/:id',
  },
  {
    path: 'error/:code',
    component: ErrorPageComponent,
  },
  {
    path: '',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }