import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs'
import { catchError, delay, distinctUntilChanged, filter, map, retry, switchMap, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Connection } from '@classes/Connection'
import { Feature, FeatureFileResult } from '@classes/Feature'
import { Project } from '@classes/Project'
import { PermissionType, ProjectPermission } from '@classes/ProjectPermission'
import { Scene } from '@classes/Scene'
import { User } from '@classes/User'
import { clone } from '@utils/Objects'

import { ActionResult, featureFromResult, FeaturePropertyResult, FeatureResult, InteractionResult, ModelResult } from './feature.service'
import { EndpointOptions, RequestService } from './request.service'
import { FileReferenceResult, sceneFromResult, ScenePropertyResult, SceneResult } from './scene.service'
import { ToastService } from './toast.service'
import { userFromResult, UserResult, UserService } from './user.service'

// Result types
export type ProjectResult = {
  id: number,
  organization_id: string,
  title: string,
  description?: string,
  created_on: string,
  last_edited: string,
  thumbnail: string,
  shared_with_organization: boolean,
  permissions: ProjectPermissionResult[],
  back_button_enabled: boolean,
  show_left_panel_at_start: boolean,
  show_panel_toggles: boolean,
  show_nav_bar: boolean,
  public_status: boolean,
  show_editors: boolean,
  logo_url: string,
  website_url: string
}

export type ProjectPermissionResult = {
  user_id: string,
  project_id: number,
  permission: PermissionType,
  user: UserResult,
  last_edited: Date,
  last_accessed: Date
}

export type ScenesFeaturesResult = {
  scenes: SceneResult[],
  featuresMap: FeatureResult[][],
}

export interface BuilderProjectComponents extends ViewerProjectComponents {
  connections: Connection[],
}

export interface ViewerProjectComponents {
  actionResults: (ActionResult & InteractionResult)[],
  featureFileResults: FeatureFileResult[],
  featurePropertyResults: FeaturePropertyResult[],
  featureResults: FeatureResult[],
  fileReferenceResults: FileReferenceResult[],
  modelResults: ModelResult[],
  projectResult: ProjectResult
  scenePropertyResults: ScenePropertyResult[],
  sceneResults: SceneResult[],
}

export type ProjectComponents = {
  project: Project
  scenes: Scene[],
  features: Feature[],
  connections?: Connection[],
}

function projectEssentialsFromResult(result: ViewerProjectComponents) {
  const { actionResults, featureFileResults, featurePropertyResults, featureResults, fileReferenceResults, modelResults, scenePropertyResults, sceneResults } = result
  const featureFileResultMap = new Map<number, FeatureFileResult[]>()
  const featurePropertyResultMap = new Map<number, FeaturePropertyResult[]>()
  const fileReferenceResultMap = new Map<number, FileReferenceResult>()
  const interactionResultMap = new Map<number, InteractionResult[]>()
  const modelResultMap = new Map<number, ModelResult>()
  const scenePropertyResultMap = new Map<number, ScenePropertyResult[]>()

  featureFileResults.forEach(file => {
    const files = featureFileResultMap.get(+file.feature_id) || []

    files.push(file)

    featureFileResultMap.set(+file.feature_id, files)
  })

  // Populate modelResultMap and fileReferenceResultMap
  modelResults.forEach(model => modelResultMap.set(model.id, model))

  fileReferenceResults.forEach(file => {
    fileReferenceResultMap.set(file.id, file)
    if (file.model_id) {
      const model = modelResultMap.get(file.model_id)
      if (model) {
        (model.files ||= []).push(file)
      }
    }
  })

  // Populate feature properties and interaction maps
  featurePropertyResults.forEach(property => {
    const properties = featurePropertyResultMap.get(property.feature_id) || []

    if (property.file_reference_id) {
      property.file_reference = fileReferenceResultMap.get(property.file_reference_id)
    }

    properties.push(property)

    featurePropertyResultMap.set(property.feature_id, properties)
  })

  actionResults.forEach(action => {
    const interactions = interactionResultMap.get(action.feature_id) || []
    let interaction = interactions.find(inter => inter.id === action.interaction_id)
    if (!interaction) {
      interaction = {
        id: action.interaction_id,
        feature_id: action.feature_id,
        type: action.type,
        actions: []
      } as InteractionResult
      interactions.push(interaction)
    }

    interaction.actions.push(action)
    interactionResultMap.set(action.feature_id, interactions)
  })

  // Populate scene properties map
  scenePropertyResults.forEach(property => {
    if (property.file_reference_id) {
      property.file_reference = fileReferenceResultMap.get(property.file_reference_id)
    }

    const properties = scenePropertyResultMap.get(property.scene_id) || []

    properties.push(property)

    scenePropertyResultMap.set(property.scene_id, properties)
  })

  const features = featureResults.map(feature => {
    if (feature.model_id) {
      feature.model = modelResultMap.get(feature.model_id)
    }

    feature.interactions = interactionResultMap.get(feature.id)
    feature.properties = featurePropertyResultMap.get(feature.id)
    feature.files = featureFileResultMap.get(feature.id)

    return featureFromResult(feature)
  })

  const scenes = sceneResults.map(scene => {
    scene.properties = scenePropertyResultMap.get(scene.id)

    return sceneFromResult(scene)
  })

  return { features, scenes }
}

// Conversion functions
export function projectFromResult(result: ProjectResult) {
  return new Project(
    result.title,
    {
      id: result.id,
      organizationID: result.organization_id,
      description: result.description,
      createdOn: result.created_on,
      lastEdited: new Date(result.last_edited),
      thumbnail: result.thumbnail,
      sharedWithOrganization: result.shared_with_organization,
      permissions: result.permissions?.map(permission => projectPermissionFromResult(permission)) ?? [],
      backButtonEnabled: result.back_button_enabled,
      showLeftPanelAtStart: result.show_left_panel_at_start,
      showPanelToggles: result.show_panel_toggles,
      showNavBar: result.show_nav_bar,
      publicStatus: result.public_status,
      showEditors: result.show_editors,
      logoUrl: result.logo_url,
      websiteUrl: result.website_url
    }
  )
}

export function projectPermissionFromResult(result: ProjectPermissionResult) {
  return new ProjectPermission(
    result.user_id,
    result.project_id,
    result.permission,
    userFromResult(result.user),
    {
      lastEdited: result.last_edited,
      lastAccessed: result.last_accessed
    }
  )
}

const currentProjectSubject = new BehaviorSubject<Project>(undefined)
const organizationsProjectsSubject = new BehaviorSubject<Project[]>([])
const usersProjectsSubject = new BehaviorSubject<Project[]>([])
export const project$: Observable<Project> = currentProjectSubject.pipe(filter(p => p !== undefined))

export const usersProjects$: Observable<Project[]> = usersProjectsSubject.pipe()

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private _projectUrl = environment.api + '/project' // URL to the API projects endpoint
  private _httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }

  public organizationProjects$: Observable<Project[]> = organizationsProjectsSubject.pipe()
  public routerEvent

  get currentProject(): Project {
    const project = clone(currentProjectSubject.getValue())
    if (project) return new Project(project.title, project)
    else return undefined
  }
  get organizationProjects(): Project[] { return organizationsProjectsSubject.getValue() }
  get usersProjects(): Project[] { return usersProjectsSubject.getValue() }

  set currentProject(project: Project) { currentProjectSubject.next(project) }
  set organizationProjects(projects: Project[]) { organizationsProjectsSubject.next(projects) }
  set usersProjects(projects: Project[]) { usersProjectsSubject.next(projects) }

  public projectID$ = this._router.events.pipe(
    filter(route => route instanceof NavigationEnd),
    map((route: NavigationEnd) => {
      const paths = route?.url.split("/")
      let projectID

      if (paths[paths.length - 1].includes('?')) {
        let splitParams = paths[paths.length - 1].split("?")
        projectID = +splitParams[0]
      } else {
        projectID = +paths[paths.length - 1]
      }

      return projectID ?? EMPTY
    }),
    distinctUntilChanged()
  ) as Observable<number>

  constructor(
    private _http: HttpClient,
    private _requestService: RequestService,
    private _router: Router,
    private _toastService: ToastService,
    private _userService: UserService,
  ) { }

  openProject(module: string, id?: number) {
    if (id) {
      location.replace("/" + module + "/" + id.toString())
    } else {
      location.replace("/" + module + "/" + this.currentProject.id)
    }
  }

  // Updates the users projects stream locally. Used when necessary.
  updateUsersProjectsLocally = (newProj: Project) => {
    let projects = this.usersProjects
    const ind = projects.findIndex(project => project.id == newProj.id)

    if (ind == -1) return
    projects[ind] = newProj
    this.usersProjects = projects
  }

  updateOrganizationProjectsLocally = (newProj: Project) => {
    let projects = this.organizationProjects
    const ind = projects.findIndex(project => project.id == newProj.id)
    if (newProj.sharedWithOrganization) {
      if (ind == -1) projects = projects.concat(newProj)
      else projects[ind] = newProj
    } else if (!newProj.sharedWithOrganization && ind > -1) projects.splice(ind, 1) // if we just made a project not an organization project
    else return

    this.organizationProjects = projects
  }

  getViewersProjectComponents(projectID: number, isAuthenticated: boolean = false): Observable<ProjectComponents> {
    if (isAuthenticated) {
      var url = `${environment.api}/viewer/project/${projectID}`
    } else {
      var url = `${environment.api}/public/project/${projectID}`
    }

    return this._http.get<ViewerProjectComponents>(url).pipe(
      retry(1),
      catchError((err) => {
        this._router.navigateByUrl('') // Return to home page if unauthorized to view project

        return this.handleError<ViewerProjectComponents>("Failed to get Projects components for user")(err)
      }),
      map(result => {
        const { features, scenes } = projectEssentialsFromResult(result)
        const project = projectFromResult(result.projectResult)

        return { project, scenes, features }
      })
    )
  }

  getBuildersProjectComponents(projectID: number): Observable<ProjectComponents> {
    const url = `${environment.api}/builder/project/${projectID}`

    return this._http.get<BuilderProjectComponents>(url).pipe(
      retry(3),
      catchError(error => {
        sessionStorage.setItem('authRedirectOrigin', this._router.url)
        this._router.navigate(['login'])
        return this.handleError<BuilderProjectComponents>("Failed to get Projects components for user")(error)
      }),
      map(result => {
        const { features, scenes } = projectEssentialsFromResult(result)
        const project = projectFromResult(result.projectResult)
        const connections = result.connections

        return { project, scenes, features, connections }
      })
    )
  }

  getUsersProjects(): Observable<Project[]> {
    return this._http.get<ProjectResult[]>(`${this._projectUrl}s`).pipe(
      map(ps => ps.map(p => projectFromResult(p))),
      catchError(this.handleError<Project[]>("Failed to get projects for user. Reattempting...")),
      delay(500),
      retry(3)
    )
  }

  getOrganizationsProjects(): Observable<Project[]> {
    const url = `${environment.api}/projects/organization`

    return this._http.get<ProjectResult[]>(url).pipe(
      map(ps => ps.map(p => projectFromResult(p))),
      catchError(this.handleError<Project[]>("Failed to get the organization's projects. Reattempting...")),
      delay(500),
      retry(3)
    )
  }

  createProject(project: Project) {
    const url = `${environment.api}/project`
    const options: EndpointOptions = {
      successToast: { title: "Project Created" },
      error: { toast: true, operation: "Create Project" }
    }

    return this._requestService.create<Project>(url, project, options).pipe(
      tap(project => this.usersProjects = [project].concat(this.usersProjects))
    )
  }

  updateProject(project: Project): Observable<Project> {
    const url = `${this._projectUrl}/${project.id}`

    return this._http.put<Project>(url, project, this._httpOptions).pipe(
      catchError(this.handleError<Project>("Failed to update project.")),
      tap(() => {
        if (project.id == this.currentProject?.id) {
          project.lastEdited = new Date()
          project.permissions.forEach(p => {
            if (p.userID == this._userService.currentUser.id) p.lastEdited = project.lastEdited
          })
          this.currentProject = project
        }
        this.updateUsersProjectsLocally(project)
        this.updateOrganizationProjectsLocally(project)
        this._toastService.toast({ title: "Project Updated", color: "green" })
      })
    )
  }

  deleteProject(project: Project): Observable<Project> {
    const url = `${this._projectUrl}/${project.id}`
    const options = {
      successToast: { title: "Project Deleted", color: "green" },
      error: { toast: true, operation: "Delete Project" },
    } as EndpointOptions

    const deleteProjectToast = this._toastService.toast({
      title: "Deleting Project",
      message: "Your project and is being deleted.",
      color: "blue",
    })

    return this._requestService.delete<Project>(url, options).pipe(
      catchError(this.handleError<Project>("Failed to delete project.")),
      tap(() => {
        const projects = this.usersProjects
        const projectIndex = projects.findIndex(({ id }) => project.id == id)

        if (projectIndex != -1) {
          projects.splice(projectIndex, 1)
          this.usersProjects = projects
        }

        this._toastService.dispose(deleteProjectToast)
      })
    )
  }

  /**
  * PROJECT PERMISSIONS
  */

  /**
   * @internal This is never used anywhere.
   * @returns all User's Permissions for a given Project
   */
  getProjectPermissions(projectID: number): Observable<ProjectPermission[]> {
    const url = `${environment.api}/permissions/${projectID}`

    return this._http.get<ProjectPermissionResult[]>(url)
      .pipe(
        catchError(this.handleError<ProjectPermissionResult[]>("Failed to get project permissions.")),
        map(perms => perms.map(perm => projectPermissionFromResult(perm)))
      )
  }

  updateProjectPermission(project: Project, permission: ProjectPermission): Observable<ProjectPermission> {
    const replacePermission = (changedPermission, permissions) => {
      const ind = permissions.findIndex(perm => perm.userID == changedPermission.userID)
      if (ind == -1) return
      permissions[ind] = permission
    }

    const url = `${environment.api}/permission/${project.id}`
    const options: EndpointOptions = {
      successToast: {
        title: "Permission Updated",
        color: "green"
      },
      error: {
        toast: true,
        operation: "Update Project Permission",
        status: {
          message: "Failed To Update Project Permission"
        }
      }
    }

    return this._requestService.update<ProjectPermission>(url, permission, options).pipe(
      tap(() => {
        replacePermission(permission, project.permissions)
        this.updateProjectsLocally(project)
      }),
    )
  }

  createProjectPermissions(permissions: ProjectPermission[], project: Project) {
    const url = `${environment.api}/permissions/${project.id}`

    return this._http.post(url, { permissions }, this._httpOptions)
      .pipe(
        catchError(this.handleError("Failed to create permissions for project.")),
        tap(() => {
          project.permissions = project.permissions.concat(permissions)
          this.updateProjectsLocally(project)
          this._toastService.toast({ title: "Permission Created", color: "green" })
        })
      )
  }

  deleteProjectPermission(permission: ProjectPermission, project: Project) {
    const removePermission = (uid) => {
      let ind = project.permissions.findIndex(perm => perm.userID == uid)

      if (ind == -1) return // IMPORTANT NOTE: probably bad if this is ever true.

      project.permissions.splice(ind, 1)
      this.updateProjectsLocally(project)
    }

    const url = `${environment.api}/permission/${permission.userID}/${permission.projectID}`
    const options: EndpointOptions = {
      successToast: {
        title: "Permission Deleted",
        color: "green"
      },
      error: {
        toast: true,
        operation: "Delete Project Permission",
        status: {
          message: "Failed To Delete Project Permission"
        }
      }
    }

    return this._requestService.delete(url, options).pipe(
      tap(() => removePermission(permission.userID)),
      switchMap(() => of(this.currentProject))
    )
  }

  updateProjectsLocally(changedProject: Project) {
    if (changedProject.sharedWithOrganization) this.updateOrganizationProjectsLocally(changedProject)
    this.updateUsersProjectsLocally(changedProject)
    if (changedProject.id == this.currentProject?.id) this.currentProject = changedProject
  }

  public userCanEdit(user: User) {
    const canEdit = (permission: string) => permission == 'Owner' || permission == 'Editor'

    return this.currentProject.permissions.some(({ userID, permission }) => userID == user?.id && canEdit(permission))
  }

  /*
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'Operation did not complete.', result?: T) {
    return (error: any): Observable<T> => {
      this._toastService.toast({ title: "Error", message: operation, color: "red" })

      // TODO: send the project error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}