import * as Mapbox from 'mapbox-gl'
import { BehaviorSubject, EMPTY, Observable } from 'rxjs'
import { filter, skip } from 'rxjs/operators'
import * as SunCalc from 'suncalc'
import { getSunrise, getSunset } from 'sunrise-sunset-js'
import * as THREE from 'three'

import { Platform } from '@angular/cdk/platform'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { CustomTransformControls } from '@classes/CustomTransformControls'
import { Feature, isMapFeature, isThreeFeature } from '@classes/Feature'
import { MapManager } from '@classes/MapManager'
import { MapSpace, MapSpaceConfiguration } from '@classes/MapSpace'
import { MeasurementController } from '@classes/MeasurementController'
import { DEFAULT_BACKGROUND, ModelSpace, ModelSpaceConfiguration, Space } from '@classes/ModelSpace'
import { OrbitControls } from '@classes/OrbitControls'
import { drag$, intersectionPoint$ } from '@classes/Raycaster'
import { Scene } from '@classes/Scene'
import { SceneManager } from '@classes/SceneManager'
import { easeTo, fitMapToBounds, fitMapToBox, focus, focusOnBox, lookAt, lookAtBox, mapEaseTo, TransitionOptions } from '@classes/Transitions'
import { SpatialAnnotationService } from '@services/spatial-annotation.service'

import { AuthenticationService } from './authentication.service'
import { ConnectionService } from './connection.service'
import { FeatureService, orginalFeatures } from './feature.service'
import { FilterService } from './filter.service'
import { PointCloudService } from './point-cloud.service'
import { RightSidebarService } from './right-sidebar.service'
import { SceneService } from './scene.service'
import { TooltipService } from './tooltip.service'
import { PointerService } from './pointer.service'

export interface VisibilityOptions {
  default?: boolean
  features?: Feature[]
  updatePermanently?: boolean
  updateLocally?: boolean
  visible?: boolean
  byFiltering?: boolean
}


export type ScenePosition = {
  camera: number[]
  target?: number[]
}

export type MapScenePosition = {
  longitude: number
  latitude: number
  bearing: number
  pitch: number
  zoom: number
}

/** 
 * Given a normalized coordinate
 * @returns the angle in radians 
 */
export function getRadians(x: number, y: number) {
  if (y >= 0) return Math.acos(x)
  else if (x < 0 && y < 0) return Math.PI - Math.asin(y)
  else if (x > 0 && y < 0) return (2 * Math.PI) + Math.asin(y)
}

export enum Activity {
  Analyzing = 'analyzing',
  Editing = 'editing',
  MapDrawing = 'mapDrawing',
  Measuring = 'measuring',
  Tagging = 'tagging',
  Moving = 'moving'
}

export enum Environment {
  Editor = 'editor',
  VirtualTour = 'virtualTour',
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentManagerService {
  private _currentActivity: Activity = Activity.Editing
  private _leftPanelTab: 'models' | 'features' | 'interface' | 'permissions' | 'scenes' | 'settings' | 'connections' = "scenes"
  private _modelSpaceInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public initialLoad: boolean = true
  public measurementController: MeasurementController
  public space: Space
  public modelSpaceInitialized$: Observable<boolean> = this._modelSpaceInitialized.pipe(skip(1))
  public panelSelectedFeature: Feature
  public sceneStack: Array<{ sceneID: number, position: ScenePosition | MapScenePosition }> = []
  public previousScenePosition: ScenePosition | MapScenePosition
  public showLeftPanel: boolean = false
  public previousSceneAngle: { offsetAngle: number, y: number }
  public leftPanelExpanded: boolean = false

  get currentActivity() {
    if (this.isAnalyzing) {
      return Activity.Analyzing
    } else if (this.isMeasuring) {
      return Activity.Measuring
    } else if (this.isTagging) {
      return Activity.Tagging
    } else if (this.isMoving) {
      return Activity.Moving
    } else {
      return Activity.Editing
    }
  }
  set currentActivity(activity: Activity) {
    const canvas: HTMLCanvasElement = this.modelSpace.canvas

    if (this.isMeasuring) {
      this.measurementController.disable()
    } else if (this.isTagging) {
      this._annotationService.disable(canvas)
    }

    if (activity == Activity.Measuring) {
      if (this.scene.type == 'Map') {
        this.map.doubleClickZoom.disable()
      }

      this.measurementController.enable()
    } else if (activity == Activity.Tagging) {
      if (this.scene.type == 'Map') {
        this.map.doubleClickZoom.disable()
      }

      this._annotationService.enable(canvas, 'tagging')
    } else {
      if (this.scene.type == 'Map') {
        this.map.doubleClickZoom.enable()
      }
      this._pointerService.setPointerState('grab', canvas.style)
    }

    this._currentActivity = activity
  }

  get leftPanelTab() { return this._leftPanelTab }
  set leftPanelTab(tab: 'models' | 'features' | 'interface' | 'permissions' | 'scenes' | 'settings' | 'connections') {
    this._leftPanelTab = tab
    setTimeout(() => {
      this._tooltipService.intializeTooltips()
    })
  }

  get camera() { return this.modelSpace?.camera }
  get controls() { return this.modelSpace?.orbitControls }
  get isAnalyzing() { return this._currentActivity == Activity.Analyzing }
  get isMobile() { return this._platform.ANDROID || this._platform.IOS }
  get isEditing() { return this._currentActivity == Activity.Editing }
  get isMeasuring() { return this.measurementController?.enabled }
  get isTagging() { return this._annotationService.enabled }
  get isMoving() { return this._annotationService.enabled }
  get map(): Mapbox.Map { return this.mapSpace?.map }
  get mapSpace() { return this.space as MapSpace }
  get measurement$() {
    return intersectionPoint$.pipe(
      filter(intersection => {
        const featureID = intersection?.model?.userData?.featureID
        const feature = this._featureService.features.find(feature => feature.id == featureID)
        const notViewpoint = feature?.type != 'view'

        return this.isMeasuring && notViewpoint
      })
    )
  }
  get modelSpace() { return this.space as ModelSpace }
  get postProcessor() { return this.modelSpace.postProcessor }
  get scene() { return this._sceneService.selectedScene }
  get transformControls() { return this.sceneManager.transformControls }

  constructor(
    private _annotationService: SpatialAnnotationService,
    private _authenticationService: AuthenticationService,
    private _connectionService: ConnectionService,
    private _featureService: FeatureService,
    private _platform: Platform,
    private _pointCloudService: PointCloudService,
    private _pointerService: PointerService,
    private _rightSidebarService: RightSidebarService,
    private _sceneService: SceneService,
    private _tooltipService: TooltipService,
    private _router: Router,
    public filterService: FilterService,
    public mapManager: MapManager,
    public sceneManager: SceneManager,
  ) {
    this._pointCloudService.setPointBudget(this.isMobile)
  }

  initModelSpace(container: HTMLCanvasElement) {
    const scene = this._sceneService.selectedScene

    if (scene.type == 'Map') {
      const bearing = +scene.bearing?.value
      const latitude = +scene.latitude?.value
      const longitude = +scene.longitude?.value
      const pitch = +scene.pitch?.value
      const zoom = +scene.zoom?.value
      const bounds = scene.bounds?.value
      const center = [longitude, latitude] as Mapbox.LngLatLike
      const config = { bearing, bounds, center, pitch, zoom, potree: this._pointCloudService.potreeInstance, pointClouds: this._pointCloudService.pointClouds } as MapSpaceConfiguration
      var controlsSize = 0.5

      this.space = new MapSpace(container, this.sceneManager, this.mapManager, config)
      this.measurementController = new MeasurementController(this.sceneManager, this.scene, this.space, this._pointerService, this.mapManager)
    } else {
      if (scene.type == '360 Image') {
        const cameraPosition = new THREE.Vector3().fromArray(scene.cameraPosition?.value as number[] ?? [])
        const controlsLocked = true
        const config = { cameraPosition, controlsLocked, potree: this._pointCloudService.potreeInstance, pointClouds: this._pointCloudService.pointClouds } as ModelSpaceConfiguration

        this.space = new ModelSpace(container, this.sceneManager, config, this._router)
      } else {
        const background = new THREE.Color(DEFAULT_BACKGROUND)
        const cameraPosition = new THREE.Vector3().fromArray(scene.cameraPosition?.value as number[] ?? [10, 10, 10])
        const targetPosition = new THREE.Vector3().fromArray(scene.targetPosition?.value as number[] ?? [0, 0, 0])
        const config = { background, cameraPosition, targetPosition, potree: this._pointCloudService.potreeInstance, pointClouds: this._pointCloudService.pointClouds } as ModelSpaceConfiguration

        this.space = new ModelSpace(container, this.sceneManager, config, this._router)
      }

      this.measurementController = new MeasurementController(this.sceneManager, this.scene, this.space, this._pointerService)

      if (this.previousScenePosition) {
        const { camera, target } = this.previousScenePosition as ScenePosition

        this.modelSpace.camera.position.fromArray(camera)
        this.modelSpace.orbitControls.target.fromArray(target)

        this.previousScenePosition = null
      }
    }

    if (this.sceneManager.transformControls) {
      this.sceneManager.transformControls.dispose()
    }

    this.sceneManager.transformControls = new CustomTransformControls(this.camera, this.modelSpace.canvas, this.sceneManager, { map: this.map, size: controlsSize })
    this.sceneManager.canvas = this.modelSpace.canvas
    this.sceneManager.camera = this.modelSpace.camera
    const northProperty = this._sceneService.selectedScene.properties.find(p => p.key == 'northAngle')

    /** Take into account previous scene's camera position and apply the offset from north to the current camera */
    if (northProperty && this.previousSceneAngle) {
      const northAngle = +northProperty.value
      const x = Math.cos(this.previousSceneAngle.offsetAngle + northAngle)
      const y = this.previousSceneAngle.y
      const z = Math.sin(this.previousSceneAngle.offsetAngle + northAngle)

      this.sceneManager.camera.position.set(x, y, z)

      this.previousSceneAngle = undefined
    }

    this._modelSpaceInitialized.next(true)
    return this.modelSpace
  }

  createModelEditor(container: HTMLCanvasElement) {
    if (this.modelSpace && this.modelSpace.rendering) {
      this.modelSpace.dispose()
    }

    if (this.sceneManager.transformControls) {
      this.sceneManager.transformControls.dispose()
    }

    const config = { background: new THREE.Color(0xD2D2D2), potree: this._pointCloudService.potreeInstance, pointClouds: this._pointCloudService.pointClouds } as ModelSpaceConfiguration

    this.space = new ModelSpace(container, this.sceneManager, config, this._router)
    this.sceneManager.transformControls = new CustomTransformControls(this.modelSpace.camera, this.modelSpace.canvas, this.sceneManager)
    this.measurementController = new MeasurementController(this.sceneManager, this.scene, this.space, this._pointerService, this.mapManager)

    if (this.measurementController.enabled) this.currentActivity = Activity.Editing
    return this.modelSpace
  }

  resetCurrentActivity() {
    this.currentActivity = Activity.Editing
  }

  toggleLeftPanel(show?: boolean, tab?: "models" | "features" | "interface" | "permissions" | "scenes" | "settings" | "connections") {
    if (tab) this.leftPanelTab = tab
    if (typeof show == 'boolean') this.showLeftPanel = show
    else this.showLeftPanel = !this.showLeftPanel
  }

  expandLeftPanel() {
    this.leftPanelExpanded = !this.leftPanelExpanded
  }

  getScenePosition(): ScenePosition | MapScenePosition {
    if (this.scene.type == 'Map') {
      const { lng: longitude, lat: latitude } = this.map.getCenter()
      const bearing = this.map.getBearing()
      const pitch = this.map.getPitch()
      const zoom = this.map.getZoom()

      return { longitude, latitude, bearing, pitch, zoom }
    } else {
      const camera = this.camera.position.toArray()
      const target = this.modelSpace.orbitControls.target.toArray()

      return { camera, target }
    }
  }

  pushToSceneStack() {
    const position = this.getScenePosition()
    const sceneID = this._sceneService.selectedSceneID

    this.sceneStack.push({ sceneID, position })
  }

  /** 
   * Saves the angle of the camera compared to the Scene's north angle. 
   * Used when transitioning between 360 Scenes to determine which direction to face. 
   */
  saveCameraAngle() {
    if (this.scene.type != '360 Image') return

    const northProperty = this.scene.properties.find(p => p.key == 'northAngle')
    const northAngle = +northProperty.value
    const cameraPosition = this.camera.position.clone()
    const cameraAngle = getRadians(cameraPosition.x, cameraPosition.z)
    const offsetAngle = cameraAngle - northAngle
    const y = this.camera.position.y

    this.previousSceneAngle = { offsetAngle, y }
  }

  goToLastScene() {
    const { sceneID, position } = this.sceneStack.pop()

    this.previousScenePosition = position
    this._sceneService.selectedScene = this._sceneService.scenes.find(scene => scene.id == sceneID)
  }

  toggleFullscreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().finally(() => {
        if (this._sceneService.selectedScene.type == "Map") setTimeout(() => this.map.resize(), 600)
      });
    }
    else if (document.exitFullscreen) document.exitFullscreen();
  }

  positionToLatLngAlt(position: THREE.Vector3, scene: Scene) {
    let sceneOrigin = this.sceneManager.getOrigin(scene)
    let sceneAltitude: number = 0
    let sceneMercatorCoordinates = Mapbox.MercatorCoordinate.fromLngLat(
      sceneOrigin,
      sceneAltitude
    )
    const mercatorMeter = sceneMercatorCoordinates.meterInMercatorCoordinateUnits()

    // This may look wrong...don't touch it.
    let featureMercatorCoordinates = new Mapbox.MercatorCoordinate(
      mercatorMeter * position.x + sceneMercatorCoordinates.x,
      mercatorMeter * position.z + sceneMercatorCoordinates.y,
      mercatorMeter * position.y + sceneMercatorCoordinates.z
    )

    const latLng = featureMercatorCoordinates.toLngLat()
    return [latLng.lng, latLng.lat, featureMercatorCoordinates.toAltitude()] as [number, number, number]
  }

  /**
  * Handles highlight-like effects on Features when they are clicked or hovered
  * @param interaction What caused the highlight to occur
  * @param enlarge Whether or not to trigger the enlarge effect
  * @internal TODO: Do something on Enlarge in the Builder
  */
  highlight(feature: Feature, interaction: 'onClick' | 'onHover', enlarge = true) {
    if (feature?.type == 'rasterImage') return

    if (feature?.interactable && feature[interaction] != 'nothing') {

      if (isThreeFeature(feature)) {
        const model = this.sceneManager.getFeatureGroup(feature.id)

        if (model) {
          if (feature[interaction] == 'change color') {
            // TODO: Implement interaction effect
          } else if (feature[interaction] == 'change opacity') {
            // TODO: Implement interaction effect
          } else if (feature[interaction] == 'enlarge' && enlarge) {
            const scale = feature.scale.map(val => val * 1.1)

            model.scale.fromArray(scale)
          } else if (feature[interaction] == 'outline') {
            const event = interaction == 'onClick' ? 'click' : 'hover'

            this.postProcessor.highlight(event, model)
          }
        }
      } else if (isMapFeature(feature)) {
        this.mapManager.setInteracted(feature, true)
      } else console.warn(`Cannot handle ${interaction} for Feature of type ${feature.type}.`)
    }
  }

  /**
  * Handles removing highlighting from Features
  * @param event The type of action that initially caused the highlight to occur
  */
  dehighlight(feature: Feature, event: 'click' | 'hover' = 'click') {
    if (feature == null) return
    if (feature.type == 'rasterImage') return

    const effect = event == 'click' ? feature.onClick : feature.onHover

    if (isThreeFeature(feature)) {
      const model = this.sceneManager.getFeatureGroup(feature.id)

      if (!model) return

      // Prevents removing the enlarge highlight from a hover event if the highlight was placed there by a click event
      const clickEnlarges = feature.onClick == 'enlarge'
      const isClicked = feature.id == this._featureService.selectedFeatureID
      const isHovered = event == 'hover'
      const stayEnlarged = isClicked && clickEnlarges && isHovered

      if (effect == 'enlarge' && !stayEnlarged) {
        model.scale.fromArray(feature.scale)
      } else if (effect == 'outline') {
        this.postProcessor.dehighlight(event, model)
      }
    } else if (isMapFeature(feature)) {
      if (feature.id != this._featureService.selectedFeatureID) { // If Feature is selected, don't remove highlights
        if (effect == 'enlarge') {
          this.mapManager.setInteracted(feature, false)
        } else if (effect == 'change opacity') {
          this.mapManager.setInteracted(feature, false)
        } else this.mapManager.setInteracted(feature, false)
      }
    }
  }

  /** Handles removing all highlighting from all Features */
  dehighlightAll(event?: 'click' | 'hover') {
    if (event) this.postProcessor.dehighlightAll(event)
    else this.postProcessor.removeAllHighlights()

    const interaction = event == 'click' ? 'onClick' : 'onHover'
    const enlarged = this._featureService.features
      .filter(feature => feature[interaction] == 'enlarge')
      .filter(feature => isThreeFeature(feature))

    // TODO: Handle all InteractionEffects
    enlarged.forEach(feature => {
      const model = this.sceneManager.getFeatureGroup(feature.id)

      if (model) model.scale.fromArray(feature.scale)
    })

    if (this.scene.type == 'Map')
      this.mapManager.dehighlightAll()
  }

  deselectGroup(group: THREE.Group) {
    const feature = this._featureService.getFeature(group?.userData?.featureID)

    this.deselectFeatureFromPanel()

    if (group) this.dehighlight(feature, 'click')
    else this.dehighlightAll('click')

    this.sceneManager.transformControls.detach()
  }

  deselectFeatureFromPanel() {
    this._featureService.selectedFeature = undefined
    this._rightSidebarService.closePanel()
    this._rightSidebarService.setTab("Scene")
  }

  deselectConnection() {
    this._connectionService.selectedConnection = undefined
    this._rightSidebarService.closePanel()
    this._rightSidebarService.setTab("Scene")
  }

  /** @returns [number, number] where the first number is the azimuth and the second is the altitude */
  getSunPosition(timeOfDay: string, longitude: number, latitude: number): [number, number] {
    const sunrise = getSunrise(latitude, longitude)
    const sunset = getSunset(latitude, longitude)
    let time = new Date() // Default to local time for sun position

    if (timeOfDay == 'sunrise') time = sunrise // sunrise
    else if (timeOfDay == 'morning') time.setHours(sunrise.getHours() + 1) // morning = sunrise + 1hr 
    else if (timeOfDay == 'afternoon') time.setHours(sunrise.getHours() / 2 + sunset.getHours() / 2 + 1) // afternoon = between sunrise & sunset + 1hr
    else if (timeOfDay == 'evening') time.setHours(sunset.getHours() - 1) // evening = sunset - 1hr
    else if (timeOfDay == 'sunset') time.setHours(sunset.getHours()) // sunrise
    else if (timeOfDay == 'night') time.setHours(sunset.getHours() + 2) // night = sunrise + 2hr

    const sunPos = SunCalc.getPosition(time, latitude, longitude)
    const sunAzimuth = 180 + (sunPos.azimuth * 180) / Math.PI
    const sunAltitude = 90 - (sunPos.altitude * 180) / Math.PI

    return [sunAzimuth, sunAltitude]
  }

  /** Returns the selected scene to its starting orientation */
  resetSceneOrientation() {
    const scene = this._sceneService.selectedScene

    if (scene.type == 'Map' && this.map) {
      const bearing = +scene.bearing?.value
      const pitch = +scene.pitch?.value
      const zoom = +scene.zoom?.value
      const latitude = +scene.latitude?.value
      const longitude = +scene.longitude?.value
      const center = [longitude, latitude] as Mapbox.LngLatLike
      const options = { bearing, pitch, zoom, center }

      mapEaseTo(this.map, options)
    } else if (scene.type == '360 Image') {
      const position = new THREE.Vector3()
        .fromArray(scene.cameraPosition?.value as number[])
        .normalize()
        .negate()
      const distance = this.camera.position.distanceTo(position)
      const time = distance > .75 ? 800 : 400

      lookAt(this.camera, this.controls, position, true, { easeTime: time, interruptions$: [drag$] })
    } else if (scene.type == 'Standard' || scene.type == 'Virtual Tour') {
      const cameraVector = new THREE.Vector3().fromArray(scene.cameraPosition?.value as number[])
      const targetVector = new THREE.Vector3().fromArray(scene.targetPosition?.value as number[])

      this.easeTo(this.camera.position, cameraVector)
      this.easeTo(this.controls.target, targetVector)
    }
  }

  /** 
   * Moves the view so that the entire box is in view.
   * Either moving the map such that the box is visible,
   * Or moving the orbit controls to the box's center and the camera outwards,
   * Or orienting the camera to look at the box's center.
   */
  focusOnBox(box: THREE.Box3): Promise<boolean> | void {
    if (this.scene.type == 'Map') {
      fitMapToBox(this.map, box, this.mapSpace.getLngLat.bind(this.mapSpace))
    } else {
      const pointerDown$ = this.modelSpace.raycaster.pointerDown$
      const mouseWheel$ = this.modelSpace.raycaster.mouseWheel$
      const options = { interruptable: true, interruptions$: [pointerDown$, mouseWheel$] } as TransitionOptions

      if (this.scene.type == '360 Image') {
        return lookAtBox(this.camera, this.controls, box, true, options)
      } else {
        return focusOnBox(this.camera, this.controls, box, true, options)
      }
    }
  }

  /** 
   * Moves the view so that the all of the Features are in view.
   * Either moving the map such that the Features are visible,
   * Or moving the orbit controls to the Feature's center and the camera outwards,
   * Or orienting the camera to look at the center of all the Features's.
   */
  focusOnFeatures(...features: Feature[]) {
    const featuresToFit: Feature[] = []

    features.forEach(feature => {
      if (feature.type == 'group') {
        const children = this._featureService.getDescendants(feature)

        featuresToFit.push(...children)
      } else {
        featuresToFit.push(feature)
      }
    })

    if (this.scene.type == 'Map') {
      const bounds = new Mapbox.LngLatBounds()

      featuresToFit.forEach(feature => {
        if (isThreeFeature(feature) || feature.type == 'marker') {
          const [longitude, latitude] = feature.position

          if (longitude != null && latitude != null) {
            bounds.extend(new Mapbox.LngLat(longitude, latitude))
          }
        } else if (['line', 'rasterImage', 'polygon'].includes(feature.type)) {
          const coordinateString: Mapbox.PointLike[] = JSON.parse(feature.properties.find(p => p.key == 'coordinateString').value)

          coordinateString.forEach(c => bounds.extend(new Mapbox.LngLat(c[0], c[1])))
        }
      })

      fitMapToBounds(this.map, bounds)
    } else {
      const box = new THREE.Box3()

      featuresToFit.forEach(feature => {
        const model = this.sceneManager.getFeatureGroup(feature.id)

        if (model != null) {
          box.expandByObject(model)
        } else {
          box.expandByPoint(new THREE.Vector3().fromArray(feature.position))
        }
      })

      return this.focusOnBox(box)
    }
  }

  /** 
   * Moves the view so that the all of the Models are in view.
   * Either moving the map such that the Models are visible,
   * Or moving the orbit controls to the Model's center and the camera outwards,
   * Or orienting the camera to look at the center of all the Model's.
   */
  focusOnModels(...models: THREE.Group[]) {
    const box = new THREE.Box3()

    models.forEach(model => model != null && box.expandByObject(model))

    return this.focusOnBox(box)
  }

  /** 
   * Moves the OrbitControls target to the new position.
   * @internal Not for use in Map Scenes
   */
  focus(camera: THREE.PerspectiveCamera, controls: OrbitControls, position: THREE.Vector3, option: TransitionOptions = {}) {
    const sceneType = this._sceneService?.selectedScene?.type

    if (sceneType == 'Standard' || sceneType == 'Virtual Tour') {
      const pointerDown$ = this.modelSpace.raycaster.pointerDown$
      const mouseWheel$ = this.modelSpace.raycaster.mouseWheel$
      const options = { interruptable: true, interruptions$: [pointerDown$, mouseWheel$], easeTime: option?.easeTime } as TransitionOptions

      return focus(this.controls, position, true, options)
    } else if (sceneType == '360 Image') {
      return this.lookAt(camera, controls, position)
    } else {
      console.error('focus() is not supported for scene type of: ', sceneType)
    }
  }

  /** 
   * Moves the Camera around the OrbitControls such that 
   * it is oriented facing the new position 
   * @internal Not for use in Map Scenes
   */
  lookAt(camera: THREE.PerspectiveCamera, controls: OrbitControls, position: THREE.Vector3) {
    const pointerDown$ = this.modelSpace.raycaster.pointerDown$
    const mouseWheel$ = this.modelSpace.raycaster.mouseWheel$
    const options = { interruptable: true, interruptions$: [pointerDown$, mouseWheel$] } as TransitionOptions

    return lookAt(camera, controls, position, true, options)
  }

  /** 
   * Gradually transitions a vector's values to the new target vector's values 
   * @returns true if ease completed without interruption and false otherwise
   */
  easeTo(vector: THREE.Vector3, target: THREE.Vector3, options: TransitionOptions = {}) {
    const pointerDown$ = this.modelSpace.raycaster.pointerDown$
    const mouseWheel$ = this.modelSpace.raycaster.mouseWheel$

    options.interruptions$ = options.interruptions$ ?? []
    options.interruptable = true
    options.interruptions$.push(pointerDown$, mouseWheel$)

    return easeTo(vector, target, options)
  }

  toggleVisibility(feature: Feature, options: VisibilityOptions = {}) {
    if (options?.features == null) {
      options.features = []
    }

    if (!options.features.some(f => f.id == feature.id)) {
      options.features.push(feature)
    }

    let visible = options?.visible

    if (options?.byFiltering && feature.ignoresFilters) {
      return EMPTY
    }

    if (options?.default) {
      const defaultFeatures = orginalFeatures.get(this._sceneService.selectedSceneID)
      const defaultFeature = defaultFeatures.find(defaultFeature => defaultFeature.id == feature.id)

      visible = defaultFeature?.visible ?? false
    } else if (typeof visible != "boolean") {
      visible = !feature.visible
    }

    if (isMapFeature(feature)) {
      if (visible) this.mapManager.show(feature)
      else this.mapManager.hide(feature)
    } else if (feature.type == 'group') {
      const children = this._featureService.getChildren(feature)

      children.forEach(child => this.toggleVisibility(child, { ...options, updateLocally: null, updatePermanently: null, byFiltering: options?.byFiltering }))
    } else if (isThreeFeature(feature)) {
      const model = this.sceneManager.getFeatureGroup(feature.id)

      if (model) {
        model.visible = visible
      }

    }

    feature.visible = visible

    if (options?.updatePermanently) {
      if (options.features.length == 1 && options.features[0].type != 'group') {
        return this._featureService.updateFeature(feature)
      } else {
        return this._featureService.updateGroupVisibilities(feature, options.features)
      }
    } else if (options?.updateLocally) {
      this._featureService.updateVisibilityLocally(...options.features)
    }

    return EMPTY
  }
}