import { BehaviorSubject, EMPTY, forkJoin, Observable, of } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, skip, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ParamMap } from '@angular/router'
import { FeatureCustomField, FeatureCustomFieldResult } from '@classes/Feature'
import { FileReference } from '@classes/FileReference'
import { Project } from '@classes/Project'
import { Scene, SceneState } from '@classes/Scene'
import { SceneProperty } from '@classes/SceneProperty'
import { AuthenticationService } from '@services/authentication.service'
import { AwsService } from '@services/aws.service'
import { ConnectionService } from '@services/connection.service'
import { customFieldFromResult } from '@services/feature.service'
import { FileReferenceService } from '@services/file-reference.service'
import { EndpointOptions, RequestService } from '@services/request.service'
import { SubscriptionService } from '@services/subscription.service'
import { ToastService } from '@services/toast.service'
import { clone } from '@utils/Objects'

// Result types
export type SceneResult = {
  id: number,
  project_id: number,
  name: string,
  type: "Standard" | "Map" | "360 Image" | "Virtual Tour",
  prevscene_id?: number,
  nextscene_id?: number,
  properties?: ScenePropertyResult[]
  thumbnail?: string,
  scene_of_interest?: boolean,
  update_thumbnail?: boolean,
  state?: SceneState
}

export type ScenePropertyResult = {
  id: number,
  scene_id: number,
  file_reference_id: number,
  file_reference: FileReferenceResult,
  type: "decimal" | "integer" | "string" | "boolean" | "vector3",
  key: string,
  value: string
}

export type FileReferenceResult = {
  id: number
  model_id: number
  filename: string
  hash: string
  extension: '.bin' | '.drc' | '.fbx' | '.gltf' | '.jpg' | '.json' | '.js' | '.obj' | '.ogg' | '.pcd' | '.pdb' | '.pmd' | '.png' | '.prwm' | '.svg' | '.tga' | '.3dm' | '.txt'
  label: string
  size: number
}

// Conversion functions
export function sceneFromResult(result: SceneResult) {
  return new Scene(
    result.project_id,
    result.name,
    result.type,
    {
      id: result.id,
      previousID: result.prevscene_id,
      nextID: result.nextscene_id,
      properties: result?.properties?.map(p => scenePropertyFromResult(p)) ?? [],
      thumbnail: result.thumbnail,
      sceneOfInterest: result.scene_of_interest,
      updateThumbnail: result.update_thumbnail,
      state: result.state
    }
  )
}

export function scenePropertyFromResult(result: ScenePropertyResult) {
  return new SceneProperty(
    result.scene_id,
    result.type,
    result.key,
    result.value,
    {
      id: result.id,
      fileReferenceID: result.file_reference_id,
      fileReference: result.file_reference ? fileReferenceFromResult(result.file_reference) : null
    }
  )
}

export function fileReferenceFromResult(result: FileReferenceResult) {
  return new FileReference(
    result.id,
    result.model_id,
    undefined,
    {
      filename: result.filename,
      hash: result.hash,
      extension: result.extension,
      label: result.label,
      size: result.size
    }
  )
}

const scenesSubject: BehaviorSubject<Scene[]> = new BehaviorSubject<Scene[]>(undefined)
const selectedSceneSubject: BehaviorSubject<Scene> = new BehaviorSubject<Scene>(undefined)

export const scenes$: Observable<Scene[]> = scenesSubject.pipe(skip(1), distinctUntilChanged())
export const selectedScene$: Observable<Scene> = selectedSceneSubject.pipe(filter(s => s !== undefined))

@Injectable({
  providedIn: 'root'
})
export class SceneService {
  private _sceneUrl = environment.api + '/scene'
  private _httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
  public scenes$: Observable<Scene[]> = scenesSubject.pipe(skip(1), distinctUntilChanged())
  public selectedScene$: Observable<Scene> = selectedSceneSubject.pipe(filter(s => s !== undefined))

  get scenes(): Scene[] { return scenesSubject.getValue() }
  set scenes(scenes: Scene[]) {
    const firstScene: Scene = scenes.find(scene => scene.previousID == null)
    const sortedScenes: Scene[] = [firstScene]
    const sceneMap = new Map<number, Scene>()

    scenes.forEach(scene => sceneMap.set(scene.id, scene))

    let currentScene: Scene = firstScene

    while (currentScene.nextID != null) {
      currentScene = sceneMap.get(currentScene.nextID)
      sortedScenes.push(currentScene)
    }

    scenesSubject.next(sortedScenes)

    if (this.selectedScene == null) {
      this.selectedScene = sortedScenes.find(scene => scene.state == 'ready') ?? firstScene
    } else {
      this.selectedScene = sceneMap.get(this.selectedSceneID)
    }
  }

  get selectedScene(): Scene {
    const scene = clone(selectedSceneSubject.getValue())
    if (scene) return new Scene(scene.projectID, scene.name, scene.type, scene)
    else return undefined
  }

  set selectedScene(scene: Scene) {
    if (this.scenes.includes(scene)) selectedSceneSubject.next(scene)
  }

  get selectedSceneID(): number {
    return selectedSceneSubject.getValue()?.id
  }

  public startingSceneParams: ParamMap

  constructor(
    private _authenticationService: AuthenticationService,
    private _awsService: AwsService,
    private _fileReferenceService: FileReferenceService,
    private _http: HttpClient,
    private _requestService: RequestService,
    private _subscriptionService: SubscriptionService,
    private _toastService: ToastService,
    private connectionService: ConnectionService
  ) {
    let pathParts = window.location.href.split('/')
    let idString = pathParts.slice(4).join()

    // First project emission. Only works in the builder
    let paramsString = ""
    if (idString.length > 0) paramsString = idString.split('?')[1]
    var params = new URLSearchParams(paramsString)

    // window.location -> find scene parameter
    // get a selected scene from url paramaters or the first scene
    scenes$.pipe(take(1)).subscribe(scenes => {
      if (params.has('scene') && (scenes.find(s => s.id == +params.get('scene')) !== undefined)) {
        this.selectedScene = scenes.find(s => s.id == +params.get('scene'))
      }
    })
    // Keep selected scene updated based on the main list
    scenes$.pipe(distinctUntilChanged(), withLatestFrom(selectedScene$)
    ).subscribe(
      ([scenes, selectedScene]) => {
        const sceneFromUpdatedList: Scene = scenes.find(scene => scene.id == selectedScene.id)

        if (!sceneFromUpdatedList) { // Selected Scene Deleted
          const prevScene: Scene = scenes.find(scene => scene.id == selectedScene.previousID)
          prevScene ? this.selectedScene = prevScene : this.selectedScene = scenes[0]
        } else if (selectedScene !== sceneFromUpdatedList) { // Selected Scene Updated
          this.selectedScene = sceneFromUpdatedList
        }
      }
    )
  }

  /** 
  * Updates the state of the local list of scenes and the selectedScene 
  * after every call to create, delete, or update an Scene.
  */
  public editSceneLocally(scene: Scene, type: 'create' | 'delete' | 'update') {
    const scenes = this.scenes ?? []
    const sceneIndex = scenes.findIndex(a => a.id == scene.id)
    const previousScene = scenes.find(s => s.id == scene.previousID)
    const nextScene = scenes.find(s => s.id == scene.nextID)

    if (type == 'create') {
      if (previousScene) {
        const prevIndex = scenes.findIndex(s => previousScene.id == s.id)
        previousScene.nextID = scene?.id
        scenes[prevIndex] = previousScene
      }

      if (nextScene) {
        const nextIndex = scenes.findIndex(s => nextScene.id == s.id)
        nextScene.previousID = scene?.id
        scenes[nextIndex] = nextScene
      }

      scenes.push(scene)
    } else if (type == 'delete') {
      if (sceneIndex != -1) {
        scenes.splice(sceneIndex, 1)

        if (previousScene) {
          const prevIndex = scenes.findIndex(scene => previousScene.id == scene.id)
          previousScene.nextID = nextScene?.id
          scenes[prevIndex] = previousScene
        }

        if (nextScene) {
          const nextIndex = scenes.findIndex(scene => nextScene.id == scene.id)
          nextScene.previousID = previousScene?.id
          scenes[nextIndex] = nextScene
        }

        if (scene?.id == this?.selectedSceneID) this.selectedScene = scenes[0]
      } else console.warn('Tried to remove unknown scene.')
    } else if (type == 'update') {
      if (sceneIndex != -1) {
        scenes[sceneIndex] = scene
        if (scene?.id == this?.selectedSceneID) this.selectedScene = scene
      } else console.warn('Tried to update unknown scene.')
    }

    this.scenes = scenes
  }

  // Updates the scenes locally. Only happens if they are mostly equal sets. Used only when necessary.
  updateScenesLocally(scenes: Scene[]): boolean {
    const scenesIds: number[] = this.scenes.map(p => { return p.id })

    if (scenes.length == this.scenes.length && scenes.every(scene => scenesIds.includes(scene.id))) {
      this.scenes = scenes
      return true
    }
    return false
  }

  updateSceneLocally(updatedScene: Scene): boolean {
    const scenes = this.scenes
    const sceneIndex = scenes.findIndex(s => s.id == updatedScene.id)

    if (sceneIndex == -1) return false

    scenes[sceneIndex] = updatedScene
    if (!updatedScene.thumbnail) updatedScene.thumbnail = scenes[sceneIndex].thumbnail

    if (this.selectedSceneID == updatedScene.id) this.selectedScene = updatedScene
    return this.updateScenesLocally(scenes)
  }

  setScene(id: number) {
    if (id != null) {
      this.selectedScene = this.scenes?.find(scene => scene.id == id)
    }
  }

  getScene(id: number): Observable<Scene> {
    const url = `${this._sceneUrl}/${id}`;
    return this._http.get<SceneResult>(url).pipe(
      map(s => sceneFromResult(s)),
      catchError(this.handleError<Scene>("Failed to get scene."))
    );
  }

  getScenes(projectId: number): Observable<Scene[]> {
    const url = `${environment.api}/viewer/scenes/${projectId}`

    return this._http.get<SceneResult[]>(url).pipe(
      catchError(this.handleError<SceneResult[]>("Failed to get scenes.")),
      map(s => s.map(s => sceneFromResult(s))),
    )
  }

  create360Scene$(scene: Scene, image: File) {
    const url = `${environment.api}/scene/360-image/${scene.projectID}`
    const options = {
      error: { operation: 'Create Map Scene', toast: true },
      successToast: { title: "Scene Created", color: "green" },
    } as EndpointOptions
    const body: FormData | Scene = new FormData()

    body.append('scene', JSON.stringify(scene))
    body.append('file', image, image.name)

    return this._requestService.create<SceneResult>(url, body, options).pipe(
      map(sceneResult => sceneFromResult(sceneResult)),
      tap(scene => this.editSceneLocally(scene, 'create'))
    )
  }

  createMapScene$(scene: Scene, ops?) {
    const url = `${environment.api}/scene/map/${scene.projectID}`
    const options = {
      error: { operation: 'Create Map Scene', toast: true },
      successToast: { title: "Scene Created", color: "green" },
    } as EndpointOptions

    return this._requestService.create<SceneResult>(url, scene, options).pipe(
      map(sceneResult => sceneFromResult(sceneResult)),
      tap(scene => this.editSceneLocally(scene, 'create'))
    )
  }

  createStandardScene$(scene: Scene) {
    const url = `${environment.api}/scene/standard/${scene.projectID}`
    const options = {
      error: { operation: 'Create Standard Scene', toast: true },
      successToast: { title: "Scene Created", color: "green" },
    } as EndpointOptions

    return this._requestService.create<SceneResult>(url, scene, options).pipe(
      map(sceneResult => sceneFromResult(sceneResult)),
      tap(scene => this.editSceneLocally(scene, 'create'))
    )
  }

  createVirtualTour$(project: Project, name: string, e57: File) {
    const url = `${environment.api}/scene/virtual-tour/${project.id ?? ""}`
    const endpointOptions = {
      error: { operation: 'Create Virtual Tour', toast: true },
      successToast: { title: "Virtual Tour Processing", color: "green", message: "Your file is uploaded and processing. You'll be notified by email when it's complete." },
    } as EndpointOptions

    return this._awsService.uploadFiles([e57], { purpose: 'process-point-cloud' }).pipe(
      switchMap(({ token }) => this._requestService.create<{ response: string }>(url, { name, project, token }, endpointOptions))
    )
  }

  updateScene(scene: Scene, toast = true): Observable<Scene> {
    const url = `${this._sceneUrl}/${scene.id}`

    return this._http.put(url, scene, this._httpOptions).pipe(
      catchError(this.handleError<Scene>("Failed to update scene.")),
      map(() => {
        this.editSceneLocally(scene, 'update')
        if (toast) this._toastService.toast({ title: "Scene Updated", color: "green" })
        return scene
      })
    )
  }

  deleteScene(scene: Scene): Observable<Scene> {
    if (this.scenes.length == 1) {
      this._toastService.toast({ title: "Delete Scene Failed", message: "Project must have at least one scene.", color: "red", autohide: true })
      return EMPTY
    } else {
      const url = `${this._sceneUrl}/${scene.id}`

      return this._http.delete(url).pipe(
        catchError(this.handleError("Failed to delete scene.")),
        map(() => {
          this._toastService.toast({ title: "Scene Deleted", color: "green" })
          this.editSceneLocally(scene, 'delete')
          return scene
        })
      )
    }
  }

  createSceneProperty(property: SceneProperty) {
    const url = `${environment.api}/scene/${property.sceneID}/property`

    return this._http.post<{ id: number }>(url, property).pipe(
      catchError(this.handleError("Failed to create scene property: ", property)),
      map(({ id }) => {
        const scene = this.scenes.find(f => f.id == property.sceneID)
        const { sceneID, type, key, value } = property

        property = new SceneProperty(sceneID, type, key, value as string, { id: id, ...property })

        if (scene.properties) scene.properties.push(property)
        else scene.properties = [property]

        this.editSceneLocally(scene, 'update')
        this._toastService.toast({ title: "Scene Property Created", color: "green" })

        return property
      })
    )
  }

  updateSceneProperty(property: SceneProperty): Observable<SceneProperty> {
    const url = `${this._sceneUrl}/property/${property.id}`

    return this._http.put(url, property, this._httpOptions).pipe(
      catchError(this.handleError("Failed to update scene property: ", property)),
      map(() => {
        const scene = this.scenes.find(scene => scene.id == property.sceneID)
        const propIndex = scene.properties.findIndex(prop => prop.id == property.id)

        scene.properties[propIndex] = property

        this.editSceneLocally(scene, 'update')
        this._toastService.toast({ title: "Scene Updated", color: "green" })
        return property
      })
    )
  }

  updateSceneProperties(sceneID: number, ...properties: SceneProperty[]): Observable<SceneProperty[]> {
    const url = `${this._sceneUrl}/property/`
    const updateProperty = (property: SceneProperty) => this._http.put(url + property.id, property, this._httpOptions)

    return forkJoin(properties.map(property => updateProperty(property))).pipe(
      catchError(this.handleError("Failed to update scene properties: ", properties)),
      map(() => {
        const scene = this.scenes.find(scene => scene.id == sceneID)

        properties.forEach(prop => {
          const propIndex = scene.properties.findIndex(p => p.id == prop.id)
          scene.properties[propIndex] = prop
        })

        this.editSceneLocally(scene, 'update')
        this._toastService.toast({ title: "Scene Updated", color: "green" })
        return properties
      })
    )
  }

  updateScenePosition(oldIndex: number, newIndex: number): Observable<Scene> {
    const array_move = (arr, old_index, new_index): Scene[] => {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr
    }

    let scenes = array_move(this.scenes, oldIndex, newIndex)

    scenes[0].previousID = null
    scenes[0].nextID = scenes[1].id

    for (let i = 1; i < scenes.length - 1; i++) {
      scenes[i].previousID = scenes[i - 1].id
      scenes[i].nextID = scenes[i + 1].id
    }

    scenes[scenes.length - 1].previousID = scenes[scenes.length - 2].id
    scenes[scenes.length - 1].nextID = null

    let scene: Scene = scenes[newIndex]

    const url = `${this._sceneUrl}/position/${scene.id}/${scene.previousID}/${scene.nextID}`
    const options = {
      error: { operation: 'Update Scene Position', toast: true },
      successToast: { title: "Scene Position Updated", color: "green" },
    } as EndpointOptions

    return this._requestService.update(url, null, options).pipe(
      map(() => {
        this.updateScenesLocally(scenes)
        return scene
      })
    )
  }

  replaceSceneImage(fileReferenceID: number, file: File) {
    if (!file) return

    const scene = this.selectedScene
    const imageProperty = scene.properties.find(property => property.fileReferenceID == fileReferenceID)
    const fileReference = imageProperty?.fileReference

    fileReference.file = file

    return this._fileReferenceService.updateFileReference(fileReference).pipe(
      catchError(this.handleError<FileReference>("Failed to replace scene image.")),
      map(fileReference => {
        imageProperty.fileReference = fileReference
        this.updateSceneLocally(scene)

        this._toastService.toast({ title: "Scene Background Updated", color: "green" })

        return scene
      })
    )
  }

  public getScenesCustomFields(scene: Scene, takeUntil?: Observable<any>): Observable<FeatureCustomField[]> {
    const sceneID = scene.id
    const endpointOptions = {
      error: { operation: 'Get Scenes Custom Fields', toast: true }, takeUntil
    } as EndpointOptions

    return this._authenticationService.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          var url = `${environment.api}/scene/custom-field/${sceneID}`
        } else {
          var url = `${environment.api}/public/scene/custom-field/${sceneID}`
        }

        return this._requestService.get<FeatureCustomFieldResult[]>(url, endpointOptions).pipe(
          map(customFieldsResults => customFieldsResults.map(cfr => customFieldFromResult(cfr)))
        )
      })
    )
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'Operation did not complete.', result?: T) {
    return (error: any): Observable<T> => {

      this._toastService.toast({ title: "Error", message: operation, color: "red" })

      // TODO: send the scene error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}