<shared-loading-screen [showTips]='currentModule == "builder"'
    *ngIf="currentModule == 'builder' || currentModule == 'viewer'"></shared-loading-screen>

<!-- User Profile Modal -->
<div class="modal fade" id="staticBackdrop" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div *ngIf="bothUsers$ | async as user" class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header bg-light border-bottom">
                <h2 class="modal-title text-dark" id="staticBackdropLabel">User Profile</h2>
                <button class="btn btn-close text-dark" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                <div class="row align-items-center mb-4">
                    <!-- Profile Picture -->
                    <div class="col-md-3 text-center">
                        <img [src]="user.picture" alt="User's profile picture"
                            class="rounded-circle img-fluid profile-picture shadow" />
                    </div>

                    <!-- User Information -->
                    <div class="col-md-9">
                        <div class="text-center text-md-left">
                            <h4 class="mb-1">{{ user.name }}</h4>
                            <p class="text-muted mb-0"><i class="fas fa-envelope me-2"></i>{{ user.email }}</p>
                        </div>
                    </div>
                </div>

                <!-- Edit and Reset Buttons -->
                <div class="d-flex justify-content-between mt-4">
                    <div>
                        <button *ngIf="!editing" class="btn btn-outline-primary btn-sm" (click)="editing = !editing">
                            <i class="fas fa-edit"></i> Edit Profile
                        </button>
                        <button *ngIf="editing" class="btn btn-outline-secondary btn-sm"
                            (click)="editing = !editing">Cancel</button>
                    </div>
                    <button (click)="resetPassword()" class="btn btn-primary btn-sm">Reset Password</button>
                </div>

                <!-- Edit Mode -->
                <div *ngIf="editing" class="mt-4">
                    <div class="mb-3">
                        <label for="editName" class="form-label">Name</label>
                        <input id="editName" type="text" class="form-control" [(ngModel)]="user.name"
                            (change)="updateUser(user)" />
                    </div>
                    <div class="mb-3">
                        <label for="editPicture" class="form-label">Profile Picture URL</label>
                        <input id="editPicture" type="text" class="form-control" [(ngModel)]="user.picture"
                            (change)="updateUser(user)" />
                    </div>
                </div>

                <!-- Organization Picker -->
                <div class="mt-4 p-3 border rounded shadow-sm bg-light">
                    <h5 class="mb-1">Organizations</h5>
                    <shared-organization-picker></shared-organization-picker>
                </div>
            </div>

            <!-- Feedback Section -->
            <div class="d-flex align-items-center justify-content-between px-3 py-3 border-top bg-light">
                <small class="text-muted">Have feedback?</small>
                <a class="text-primary" style="cursor: pointer; text-decoration: underline" data-bs-dismiss="modal"
                    (click)="navigateToFeedback()">Submit feedback here</a>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer d-flex justify-content-between">
                <button (click)="logout()" class="btn btn-outline-danger btn-sm">Sign Out</button>
                <button class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div (pointerdown)="closeContextMenu()" style="position: absolute; height: 100dvh; width:100dvw; overflow: hidden;">
    <router-outlet></router-outlet>
</div>

<ng-template contextMenu></ng-template>