import { Component } from '@angular/core'
import { Feature, FeatureOptions } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { Model } from '@classes/Model'
import { AuthenticationService } from '@services/authentication.service'
import { ContextMenuService } from '@services/context-menu.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { ModelService } from '@services/model.service'
import { SceneService } from '@services/scene.service'

@Component({
  selector: 'shared-model-menu',
  templateUrl: './model-menu.component.html',
  styleUrls: ['./model-menu.component.css']
})
export class ModelMenuComponent {
  public model: Model

  get canEditModel() {
    return this.model.permissions.some(
      ({ permission, userID }) => userID == this.currentUser.sub && permission == 'Owner'
    )
  }
  get currentUser() { return this.authenticationService.currentUser }

  constructor(
    private _featureService: FeatureService,
    private _modelService: ModelService,
    public sceneService: SceneService,
    public contextMenuService: ContextMenuService,
    public envManager: EnvironmentManagerService,
    private authenticationService: AuthenticationService
  ) { }

  addModelToScene() {
    const feature = this._createFeatureFromModel()
    this._featureService.createFeature(feature, { toast: true, updateLocally: true }).subscribe()
    this.contextMenuService.closeContext()
  }

  _createFeatureFromModel() {
    const featureOptions = {
      parentID: undefined,
      modelID: this.model.id,
      model: this.model,
      description: "",
      position: this._getPositionForFeature(),
      rotation: this.model.rotation,
      scale: [1, 1, 1],
      objectOfInterest: false,
      visible: true,
      opacity: 1,
      filterable: true,
      interactable: true,
      onClick: 'outline',
      onHover: 'outline',
      lastChanged: "",
      properties: [
        new FeatureProperty("boolean", "loadOnStart", "true"),
        new FeatureProperty("boolean", "showOnTop", "false"),
        new FeatureProperty("integer", "renderSide", JSON.stringify(0))
      ],
      interactions: [],
      children: [],
      unloaded: false
    } as Partial<FeatureOptions>

    return new Feature(this.sceneService.selectedScene.id, this.model.name, "3D", featureOptions)
  }

  _getPositionForFeature() {
    if (this.sceneService.selectedScene.type == "Map") return this._getPositionForMapScene()
    else if (this.sceneService.selectedScene.type == "360 Image") return this._getPositionFor360Scene()
    else return this._getPositionForStandardScene()
  }

  _getPositionForMapScene(): [number, number, number] {
    const center = this.envManager.map.getCenter()
    let altitude = 0

    try {
      altitude = this.envManager.map.queryTerrainElevation(center) ?? 0
    } catch { }

    return [center.lng, center.lat, altitude]
  }

  _getPositionFor360Scene() {
    const controls = this.envManager.modelSpace.orbitControls
    const camera = this.envManager.modelSpace.camera
    const MAXSIZE = 25
    const FITRATIO = 1.5
    const fitHeightDistance = MAXSIZE / (2 * Math.atan((Math.PI * camera.fov) / 360))
    const fitWidthDistance = fitHeightDistance / camera.aspect
    const distance = FITRATIO * Math.max(fitHeightDistance, fitWidthDistance)
    const direction = controls.target
      .clone()
      .sub(camera.position)
      .normalize()

    return direction.multiplyScalar(distance).toArray()
  }

  _getPositionForStandardScene() {
    return this.envManager.modelSpace.orbitControls.target.clone().toArray()
  }

  openModelEditor() {
    this._modelService.openModelEditor(this.model)
    this.contextMenuService.closeContext()
  }
}