import { Component } from '@angular/core'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { SceneService } from '@services/scene.service'

@Component({
  selector: 'shared-latlng-helper',
  templateUrl: './latlng-helper.component.html',
  styleUrls: ['./latlng-helper.component.css']
})
export class LatlngHelperComponent {

  // Track whether we’re showing full precision or not
  showFullPrecision = false

  get mapSpace() {
    return this.envManager.mapSpace
  }

  get latlngString() {
    // Pull in lat/lng from sceneService or mapSpace
    let lng = this.sceneService.selectedScene?.longitude?.value as number
    let lat = this.sceneService.selectedScene?.latitude?.value as number

    if (this.mapSpace?.mouseLngLat) {
      lng = this.mapSpace.mouseLngLat.lng
      lat = this.mapSpace.mouseLngLat.lat
    }

    // Decide how many decimals to show based on showFullPrecision
    const decimals = this.showFullPrecision ? 6 : 2

    // Safely handle null/undefined values
    if (typeof lng !== 'number' || typeof lat !== 'number') {
      return ''
    }

    // Use toFixed to limit decimal places
    return `Lng: ${lng.toFixed(decimals)}° Lat: ${lat.toFixed(decimals)}°`
  }

  constructor(
    public envManager: EnvironmentManagerService,
    public sceneService: SceneService
  ) { }

  // Toggle the precision on each click
  togglePrecision() {
    this.showFullPrecision = !this.showFullPrecision
  }
}