import { Organization } from 'auth0'
import { Offcanvas } from 'bootstrap'
import { forkJoin } from 'rxjs'
import { environment } from 'src/environments/environment'
import Stripe from 'stripe'

import { animate, state, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BillingModule } from '@billing/billing.module'
import { CreateOrganizationComponent } from '@billing/create-organization/create-organization.component'
import { SelectTierComponent } from '@billing/select-tier/select-tier.component'
import { AuthenticationService } from '@services/authentication.service'
import { SignUpProOptions, SubscriptionService } from '@services/subscription.service'
import { UserService } from '@services/user.service'
import { SharedModule } from '@shared/shared.module'
import { loadStripe, Stripe as StripeJS } from '@stripe/stripe-js'

@Component({
  animations: [
    trigger('unfoldVertical', [
      state('hidden', style({ height: '0', overflow: 'hidden', opacity: 0 })),
      state('visible', style({ height: '*', opacity: 1 })),
      transition('hidden <=> visible', [animate('0.5s ease')]),
    ])
  ],
  imports: [BillingModule, CommonModule, SharedModule],
  selector: 'signup',
  standalone: true,
  styleUrls: ['./signup.component.css'],
  templateUrl: './signup.component.html',
})
export class SignupComponent implements AfterViewInit, OnDestroy {
  @ViewChild('createOrg') createOrg: CreateOrganizationComponent
  @ViewChild('selectTier') selectTier: SelectTierComponent
  @ViewChild('submitButton') submitButton: ElementRef<HTMLButtonElement>

  public checkoutSessionID: any
  public error: string
  public freeProductPackage: Stripe.Price[] = []
  public invoice: Stripe.Invoice
  public organization: Organization
  public proProductPackage: Stripe.Price[] = []
  public state: "collectInfo" | "failed" | "submitting" | "success" = "collectInfo"
  public stripeJS: StripeJS
  public selectedTier: 'free' | 'pro' = 'pro'

  get interval() { return this.selectTier?.interval }
  get priceMultiplier() { return this.selectTier?.interval == 'year' ? 1 : .1 }
  get rightPanel() { return new Offcanvas(document.getElementById('right-panel')) }
  get selectedProducts() { return this.selectedTier == 'free' ? this.freeProductPackage : this.proProductPackage }
  get totalAmount() {
    return this.proProductPackage.reduce((total, price) =>
      total + (price.unit_amount * (price['quantity'] ?? 0) * this.priceMultiplier / 100), 0
    )
  }

  get currentUser$() { return this._authenticationService.user$ }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _subscriptionService: SubscriptionService,
    private _userService: UserService
  ) {
    this._authenticationService.isAuthenticated$.subscribe(isAuthenticated => {
      if (!isAuthenticated) {
        this._authenticationService.loginWithRedirect({
          appState: { target: 'signup' },
          authorizationParams: { screen_hint: 'signup' }
        })
      }
    })

    forkJoin([
      this._subscriptionService.getProductPackage('free', 'year'),
      this._subscriptionService.getProductPackage('pro', 'year')
    ]).subscribe(([freeProductPackage, proProductPackage]) => {
      this.proProductPackage = proProductPackage
      this.freeProductPackage = freeProductPackage
    })
  }

  ngAfterViewInit() {
    this._activatedRoute.queryParams.subscribe(queryParams => {
      const orgID = queryParams['org_id']
      const interval = queryParams['interval']
      const orgName = queryParams['org_name']
      const sessionId = queryParams['session_id']

      this.createOrg.orgID.setValue(orgID ?? '')
      this.createOrg.orgName.setValue(orgName ?? '')
      if (interval) this.selectTier.interval = interval

      if (sessionId) {
        this.checkoutSessionID = sessionId
        this.selectTier.chooseTier({ lookup_key: 'pro' } as any)

        this.openCheckoutPanel()
      }
    })
  }

  chooseTier(tier: 'free' | 'pro') {
    this.selectedTier = tier
  }

  proceedToPayment() {
    if (this.isValid()) {
      if (this.selectedTier == 'pro' && this.checkoutSessionID == null) {
        this.redirectToPaymentInfo()
      } else {
        this.openCheckoutPanel()
      }
    }
  }

  /** Checks if submission is valid and responds accordingly */
  isValid() {
    this.submitButton.nativeElement.disabled = false

    if (this.selectedTier == 'pro') {
      if (this.checkoutSessionID == null) {
        this.redirectToPaymentInfo()

        return false
      } else if (this.createOrg.organizationForm.invalid) {
        this.createOrg.organizationForm.markAllAsTouched()

        return false
      }
    }

    return true
  }

  /** Redirects the user to the payment checkout session page. */
  redirectToPaymentInfo() {
    const params = {
      cancel_route: 'signup',
      success_route: 'signup',
      success_params: [
        { 'interval': this.interval },
        { 'org_id': this.createOrg.orgID.value },
        { 'org_name': this.createOrg.orgName.value }
      ]
    }

    if (this.selectedTier == 'pro' && this.checkoutSessionID == null) {
      return Promise.all([
        loadStripe(environment.stripePublishableKey),
        this._subscriptionService.createCheckoutSession(params).toPromise()
      ]).then(([stripeJS, session]) => {
        this.stripeJS = stripeJS
        this.stripeJS.redirectToCheckout({ sessionId: session.id })
      })
    } else {
      this.openCheckoutPanel()
    }
  }

  /** Opens the right panel to show what upgrade items you are purchasing */
  openCheckoutPanel() {
    this.rightPanel.show()
  }

  /** Validates the organization's information and then opens the checkout panel. */
  finishSignup() {
    if (this.isValid()) {
      this.submitButton.nativeElement.disabled = true

      this.state = "submitting"
      const proOptions: SignUpProOptions = {
        checkoutSessionID: this.checkoutSessionID,
        interval: this.interval,
        orgID: this.createOrg.orgID.value,
        orgName: this.createOrg.orgName.value,
      }

      return this._subscriptionService.signUp(this.selectedTier, proOptions).subscribe(
        ({ latest_invoice, organization, message }) => {
          this.invoice = latest_invoice
          this.organization = organization
          this.state = 'success'
        },
        ({ error }) => {
          this.error = error
          this.state = "failed"
        }
      )
    }
  }

  goToDashboard() {
    if (this.organization) {
      sessionStorage.setItem('redirectDashboard', 'true')
      this._authenticationService.loginWithRedirect({ authorizationParams: { organization: this.organization?.id } })
    } else {
      this._userService.getCurrentUser().subscribe(() => this._router.navigate(['projects']))
    }
  }

  ngOnDestroy(): void {
    this.goToDashboard()
  }
}