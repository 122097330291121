<!-- Single-row Interval Selector, right-aligned -->
<div class="d-flex align-items-center mb-3">
    <label class="mb-0 font-weight-bold me-2" style="white-space: nowrap;">
        Billing Interval:
    </label>
    <div class="btn-group" role="group">
        <button type="button" class="btn btn-sm"
            [ngClass]="interval === 'month' ? 'btn-primary' : 'btn-outline-secondary'" [disabled]="isUpdatingInterval"
            (click)="interval !== 'month' && initiateIntervalChange('month')">
            Monthly
        </button>
        <button type="button" class="btn btn-sm"
            [ngClass]="interval === 'year' ? 'btn-primary' : 'btn-outline-secondary'" [disabled]="isUpdatingInterval"
            (click)="interval !== 'year' && initiateIntervalChange('year')">
            Yearly
        </button>
    </div>
</div>

<!-- Confirmation Prompt -->
<div *ngIf="pendingIntervalChange"
    class="alert alert-warning alert-dismissible fade show p-2 d-flex align-items-center justify-content-between"
    role="alert" style="max-width: 500px;">
    <div class="mr-2">
        <strong>Change interval to {{ pendingIntervalChange }}?</strong><br />
        <small>
            This may affect your next billing date or total cost. Please confirm.
        </small>
    </div>
    <div class="d-flex align-items-center">
        <button type="button" class="btn btn-success btn-sm mr-2" (click)="confirmIntervalChange()"
            [disabled]="isUpdatingInterval">
            Confirm
        </button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="cancelIntervalChange()"
            [disabled]="isUpdatingInterval">
            Cancel
        </button>
    </div>
</div>

<!-- Filter Input -->
<div class="mb-3">
    <input type="search" class="form-control" placeholder="Search products..." (input)="updateMerged()"
        [(ngModel)]="filterText">
</div>

<!-- Items Table (all items are shown; inactive ones are muted/disabled) -->
<div class="table-responsive border border-2 custom-scrollbar"
    [ngStyle]="{'max-height': selectedItem ? '35vh' : '100vh'}">
    <table class="table table-hover">
        <thead>
            <tr>
                <th (click)="sortBy('name')" style="cursor: pointer;">
                    Product
                    <i class="fa-solid"
                        [ngClass]="{'fa-arrow-up': sortField==='name' && sortDirection==='asc', 'fa-arrow-down': sortField==='name' && sortDirection==='desc'}"></i>
                </th>
                <th (click)="sortBy('price')" style="cursor: pointer;">
                    Unit Price
                    <i class="fa-solid"
                        [ngClass]="{'fa-arrow-up': sortField==='price' && sortDirection==='asc', 'fa-arrow-down': sortField==='price' && sortDirection==='desc'}"></i>
                </th>
                <th (click)="sortBy('quantity')" style="cursor: pointer;">
                    Quantity
                    <i class="fa-solid"
                        [ngClass]="{'fa-arrow-up': sortField==='quantity' && sortDirection==='asc', 'fa-arrow-down': sortField==='quantity' && sortDirection==='desc'}"></i>
                </th>
                <th class="text-center">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of availableItems"
                [ngClass]="{'selected': selectedItem && selectedItem.id === item.id, 'inactive': !isActive(item)}"
                (click)="isActive(item) && selectItem(item)">
                <td data-label="Product">
                    <span class="product-name">{{ item.price.product.name }}</span>
                </td>
                <td data-label="Unit Price">
                    {{ item.price.unit_amount / 100 | currency }}/{{item.price.recurring.interval}}
                </td>
                <td data-label="Quantity">{{ item.quantity }}</td>
                <td class="text-center">
                    <ng-container *ngIf="isActive(item); else inactiveTemplate">
                        <button class="btn btn-sm btn-outline-primary border-1 edit-btn"
                            (click)="selectItem(item); $event.stopPropagation();">
                            <i class="fas fa-pencil"></i>
                        </button>
                    </ng-container>
                    <ng-template #inactiveTemplate>
                        <button class="btn btn-sm btn-outline-secondary edit-btn" disabled>
                            <i class="fas fa-lock"></i>
                        </button>
                    </ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Edit Panel for Selected Item -->
<div *ngIf="selectedItem" class="edit-panel card m-3 shadow-lg position-relative">
    <!-- Close X in the top right corner -->
    <button type="button" class="close-x" (click)="cancelEdit()">
        <i class="fas fa-times"></i>
    </button>
    <div class="card-body">
        <h4 class="card-title product-title">{{ selectedItem.price.product.name }}</h4>
        <!-- Toggleable Details: Shown by default -->
        <button class="btn btn-link toggle-details-btn p-0 mb-2" type="button" (click)="toggleDetails()">
            <i class="fas" [ngClass]="{'fa-chevron-up': showDetails, 'fa-chevron-down': !showDetails}"></i>
            <span class="toggle-text">{{ showDetails ? 'Hide Details' : 'Show Details' }}</span>
        </button>
        <div *ngIf="showDetails" class="details mb-3">
            <p class="card-text description"><strong>Description:</strong>
                {{ selectedItem.price.product.description }}
            </p>
            <div *ngIf="selectedItem.price.product.marketing_features && selectedItem.price.product.marketing_features.length"
                class="features">
                <strong>Features:</strong>
                <ul>
                    <li *ngFor="let feature of selectedItem.price.product.marketing_features">{{ feature.name }}</li>
                </ul>
            </div>
            <hr class="divider">
        </div>

        <!-- Quantity Editor -->
        <div class="form-group">
            <label for="quantityInput">Quantity</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <button class="btn btn-outline-secondary stepper-btn" type="button" (click)="decrementQuantity()"
                        [disabled]="selectedItem.quantity <= getMinQuantity(selectedItem)"
                        [title]="selectedItem.quantity <= getMinQuantity(selectedItem) ? 'Minimum quantity reached' : 'Decrease quantity'">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
                <input id="quantityInput" type="number" class="form-control quantity-input text-center"
                    [(ngModel)]="selectedItem.quantity" [min]="getMinQuantity(selectedItem)"
                    [max]="getMaxQuantity(selectedItem)" (ngModelChange)="validateQuantity()">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary stepper-btn" type="button" (click)="incrementQuantity()"
                        [disabled]="getMaxQuantity(selectedItem) !== null && selectedItem.quantity >= getMaxQuantity(selectedItem)"
                        [title]="getMaxQuantity(selectedItem) !== null && selectedItem.quantity >= getMaxQuantity(selectedItem) ? 'Maximum quantity reached' : 'Increase quantity'">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>
            </div>
            <div *ngIf="quantityError" class="text-danger mt-1">
                {{ quantityError }}
            </div>
        </div>

        <div class="d-flex justify-content-between mt-3 button-container">
            <!-- Price Impact Display -->
            <div class="price-impact">
                <strong class="new-total">New Total:</strong>
                {{ (selectedItem.quantity * selectedItem.price.unit_amount) / 100 | currency }}
            </div>
            <button class="btn btn-primary update-btn" (click)="submitEdit()" [disabled]="quantityError || isUpdating">
                <span *ngIf="isUpdating"><i class="fas fa-spinner fa-spin"></i> Processing...</span>
                <span *ngIf="!isUpdating">Update Subscription</span>
            </button>
        </div>
    </div>
</div>

<!-- Invoice Message Display -->
<div *ngIf="invoice" class="invoice-message alert alert-success mt-3">
    <span>
        Subscription updated successfully.
        <a *ngIf="invoice.hosted_invoice_url" [href]="invoice.hosted_invoice_url" target="_blank" class="invoice-link">
            View Invoice
        </a>
    </span>
    <button type="button" class="close-invoice" (click)="invoice = null">
        <i class="fas fa-times"></i>
    </button>
</div>

<!-- Payment Error Display with Checkout Link -->
<div *ngIf="paymentError" class="payment-error alert alert-danger mt-3">
    {{ paymentError }} <a [href]="paymentSetupLink" target="_blank" class="alert-link">Update Payment Method</a>
</div>