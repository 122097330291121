import { FileReference as FileReference } from './FileReference'
import { ModelPermission } from './ModelPermission'

export type ModelOptions = {
    id?: number,
    description?: string,
    position?: [number, number, number],
    rotation?: [number, number, number],
    scale?: [number, number, number],
    thumbnail?: string,
    color?: string,
    overrideColor?: boolean,
    opacity?: number,
    lastChanged?: string,
    sharedWithOrganization?: boolean,
    organizationID?: string,
    permissions?: ModelPermission[],
    state?: string
}

export class Model {
    public id: number
    public name: string
    public description: string
    public position: [number, number, number]
    public rotation: [number, number, number]
    public scale: [number, number, number]
    public thumbnail: string
    public color: string
    public overrideColor: boolean
    public opacity: number
    public lastChanged: string
    public files: FileReference[]
    public sharedWithOrganization: boolean
    public organizationID: string
    public permissions: ModelPermission[]
    public updateThumbnail: boolean = false
    public state: string

    constructor(name: string, files: FileReference[], options?: ModelOptions) {
        this.id = options && options.id ? +options.id : null
        this.name = name
        this.description = options ? options.description : ""
        this.position = options && options.position ? options.position : [0, 0, 0]
        this.rotation = options && options.rotation ? options.rotation : [0, 0, 0]
        this.scale = options && options.scale ? options.scale : [1, 1, 1]
        this.thumbnail = options && options.thumbnail ? options.thumbnail : ""
        this.color = options && options.color ? options.color : '#000000'
        this.opacity = options && options.opacity ? options.opacity : 1.0
        this.overrideColor = options && options.overrideColor !== undefined ? options.overrideColor : false
        this.lastChanged = options && options.lastChanged ? options.lastChanged : ""
        this.files = files ? files.map(f => new FileReference(
            f.id,
            f.modelID,
            f.file,
            { filename: f.filename, hash: f.hash, extension: f.extension, label: f.label, size: f.size }
        )) : []
        this.sharedWithOrganization = options?.sharedWithOrganization ?? false
        this.organizationID = options?.organizationID
        this.permissions = options && options.permissions ? options.permissions.map(p => new ModelPermission(
            p.userID,
            p.modelID,
            p.permission,
            { user: p.user }
        )) : []
        this.state = options?.state
    }
}

export function equal(model1: Model, model2: Model): boolean {
    return physicallyEqual(model1, model2)
        && filesEqual(model1, model2)
        && model1.name == model2.name
        && model1.description == model2.description
        && model1.thumbnail == model2.thumbnail
        && model1.sharedWithOrganization == model2.sharedWithOrganization
        && model1.organizationID == model2.organizationID
        && model1.permissions.length == model2.permissions.length
        && model1.permissions.every(perm => model2.permissions.some(p => perm.equals(p)))
}

export function physicallyEqual(model1: Model, model2: Model): boolean {
    return model1.id == model2.id
        && model1.position.toString() == model2.position.toString()
        && model1.rotation.toString() == model2.rotation.toString()
        && model1.scale.toString() == model2.scale.toString()
        && model1.color == model2.color
        && model1.overrideColor == model2.overrideColor
        && model1.opacity == model2.opacity
}

export function filesEqual(model1: Model, model2: Model): boolean {
    return model1.files.length == model2.files.length
        && model1.files.every(file => model2.files.find(f => f.hash == file.hash))
}

export interface ModelFile {
    modelID: number
    s3ObjectReferenceID: number
    bucket: string
    key: string
    label: string
    type: "file" | "folder"
}

export interface ModelFileResult {
    model_id: string
    s3_object_reference_id: string
    bucket: string
    key: string
    label: string
    type: "file" | "folder"
}