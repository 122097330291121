<div class="d-grid gap-2">
    <ng-container *ngIf="geojsonFiles && geojsonFiles.length">
        <label class="form-label">GeoJSON Files</label>

        <div class="card bg-light mb-2 p-3 d-grid gap-3">
            <ng-container *ngFor="let fileWrapper of geojsonFiles; index as i">
                <!-- Outer container: allows horizontal scrolling if contents are too wide -->
                <div class="bg-white rounded p-2" style="max-width: 100%; overflow-x: auto;">
                    <!-- Inner row: no wrapping (flex-nowrap) keeps everything on one line -->
                    <div class="d-flex align-items-center flex-nowrap w-100">

                        <!-- "Replace file" button -->
                        <label class="text-center btn btn-primary me-2" for="replace-file-{{ i }}">
                            <i class="fas fa-file-search"></i>
                            <input [hidden]="true" id="replace-file-{{ i }}" type="file" accept=".geojson, .shp"
                                (change)="replaceFile($event.target.files[0], i)" />
                        </label>

                        <!-- Truncated file name -->
                        <input class="form-control me-2 text-truncate"
                            style="flex: 1 1 auto; min-width: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                            [disabled]="true" [ngModel]="fileWrapper.file.name" type="text" />

                        <!-- ng-select at a fixed 40% width -->
                        <div class="me-2" style="flex: 0 0 40%; min-width: 0;">
                            <ng-select [items]="fileWrapper.propertyOptions" bindLabel="label" bindValue="key"
                                [(ngModel)]="fileWrapper.nameKey" [appendTo]="'body'" placeholder="Select property">
                            </ng-select>
                        </div>

                        <!-- Remove file button -->
                        <a class="btn text-secondary border" (click)="removeFile(i)">
                            <i class="fas fa-trash"></i>
                        </a>
                    </div>
                </div>

            </ng-container>
        </div>
    </ng-container>

    <div class="d-flex align-items-center">
        <div class="me-3 d-flex" style="width:100px">
            <label tabindex="0" class="form-control btn btn-success btn-sm">+ Add Files
                <input type="file" class="invisible" id="featureInputGroupImage" style="width: 1px"
                    accept=".geojson, .shp" [required]="true" (change)="onFileChange($event.target.files)" multiple>
            </label>
        </div>
    </div>
    <small *ngIf="!isValid && touched" class="form-text text-danger">
        Must provide GeoJSON file
    </small>
</div>