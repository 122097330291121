import { fromEvent, Subscription } from 'rxjs'
import * as THREE from 'three'
import { ClearPass } from 'three/examples/jsm/postprocessing/ClearPass'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'

import { OutlinePass, OutlinePassParameters } from './OutlinePass'

export class PostProcessor {
    private _subscription: Subscription
    private _effectFXAA: ShaderPass
    private _hoverPass: OutlinePass
    private _selectPass: OutlinePass
    public composer: EffectComposer
    public renderer: THREE.WebGLRenderer

    get width(): number { return this.container.clientWidth }
    get height(): number { return this.container.clientHeight }
    get resolution(): THREE.Vector2 { return new THREE.Vector2(this.width, this.height) }
    get container(): HTMLElement { return this._parentCanvas.parentElement.parentElement }
    get canvas(): HTMLCanvasElement { return this.renderer.domElement }

    constructor(
        private _scene: THREE.Scene,
        private _camera: THREE.PerspectiveCamera,
        private _parentCanvas: HTMLCanvasElement,
    ) {
        this.renderer = new THREE.WebGLRenderer({ alpha: true })
        this.renderer.setSize(this.width, this.height)
        const canvasStyle = {
            position: "absolute", left: "0", top: "0", zIndex: "1",
            width: "100%", height: "100%", pointerEvents: "none"
        }
        for (const key in canvasStyle) this.canvas.style[key] = canvasStyle[key]

        this._parentCanvas.parentElement.appendChild(this.canvas)

        this._subscription = fromEvent(window, 'resize').subscribe(() => {
            this.handleResize()
            setTimeout(() => this.handleResize(), 200) // Handles issues with camera 'throw'
        })

        this._hoverPass = this._createOutlinePass({ visibleEdgeColor: new THREE.Color(0x6C757D) })
        this._selectPass = this._createOutlinePass({ pulsePeriod: 4 })
        this._effectFXAA = new ShaderPass(FXAAShader)

        const composeParams = { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat, stencilBuffer: true };
        this.composer = new EffectComposer(this.renderer,
            new THREE.WebGLRenderTarget(this.width, this.height, composeParams))
        this._effectFXAA.material.transparent = true

        this.handleResize()

        /** IMPORTANT NOTE: When adding passes, order matters. */
        this.composer.addPass(new ClearPass())
        this.composer.addPass(this._hoverPass)
        this.composer.addPass(this._selectPass)
        this.composer.addPass(this._effectFXAA)
    }

    private _createOutlinePass(params?: OutlinePassParameters) {
        const options = {
            visibleEdgeColor: new THREE.Color(0xFFFFFF),
            hiddenEdgeColor: new THREE.Color(0xFFFFFF),
            edgeThickness: 1,
            edgeStrength: 10,
        }

        for (const key in params) options[key] = params[key]

        return new OutlinePass(this.resolution, this._scene, this._camera, options)
    }

    public render() {
        this.composer.render()
    }

    public highlight(event: 'click' | 'hover', ...models: THREE.Group[]) {
        const outlinePass = event == 'click' ? this._selectPass : this._hoverPass

        outlinePass.selectedObjects = []

        models.forEach(model =>
            model.traverseVisible((mesh: THREE.Mesh | THREE.Line) => {
                if (mesh instanceof THREE.Mesh || mesh instanceof THREE.Line)
                    outlinePass.selectedObjects.push(mesh)
            })
        )
    }

    public dehighlight(event: 'click' | 'hover', ...models: THREE.Group[]) {
        const meshesToDehighlight: THREE.Object3D[] = []
        const outlinePass = event == 'click' ? this._selectPass : this._hoverPass
        const highlightedMeshes = outlinePass.selectedObjects

        models.forEach(model =>
            model.traverseVisible((mesh: THREE.Mesh | THREE.Line) => {
                if (mesh instanceof THREE.Mesh || mesh instanceof THREE.Line)
                    meshesToDehighlight.push(mesh)
            })
        )

        outlinePass.selectedObjects = highlightedMeshes.filter(highlight =>
            !meshesToDehighlight.some(dehighlight => dehighlight.id == highlight.id)
        )
    }

    public dehighlightAll(event: 'click' | 'hover') {
        if (event == 'click') this._selectPass.selectedObjects = []
        else if (event == 'hover') this._hoverPass.selectedObjects = []
    }

    public removeAllHighlights() {
        this._hoverPass.selectedObjects = []
        this._selectPass.selectedObjects = []
    }

    /** Makes sure that the renderer & composer are correctly sized */
    public handleResize() {
        this.renderer.setSize(this.width, this.height)
        this.composer.setSize(this.width, this.height)
        this.composer.setPixelRatio(window.devicePixelRatio)

        this._effectFXAA.uniforms['resolution'].value.set(
            1 / this.resolution.x * this.renderer.getPixelRatio(),
            1 / this.resolution.y * this.renderer.getPixelRatio()
        )
    }

    public dispose() {
        this._subscription.unsubscribe()

        this.renderer.forceContextLoss()
        this.renderer.domElement.remove()
        this.renderer.domElement = null
        this.renderer.renderLists.dispose()
        this.renderer.dispose()
        this.renderer = null

        this.composer.renderTarget1.dispose()
        this.composer.renderTarget2.dispose()
    }
}