import saveAs from 'file-saver'
import { concat, from, Observable } from 'rxjs'
import { concatMap, filter, finalize, last, map, switchMap, tap, toArray } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EndpointOptions, RequestService } from '@services/request.service'
import { ToastService } from '@services/toast.service'

type UploadPart = {
  ETag: string
  PartNumber: number
}

type UploadPurpose = 'process-e57' | 'process-model'

type UploadResponse = {
  token: string
  url: string
}

type UploadRequestOptions = {
  purpose?: UploadPurpose
  token?: string
}

/** 100 MB */
const MAX_FILE_SIZE = 100_000_000
/** 10 MB */
const FILE_CHUNK_SIZE = 10_000_000

function getTruncatedFileName(fileName: string, maxLength: number): string {
  const extension = fileName.substring(fileName.lastIndexOf('.'))
  const baseName = fileName.substring(0, fileName.lastIndexOf('.'))

  if (baseName.length > maxLength) {
    const truncatedName = baseName.substring(0, maxLength - 3) // Adjust for '...'
    return `${truncatedName}..${extension}`
  }

  return fileName
}

@Injectable({
  providedIn: 'root'
})
export class AwsService {

  constructor(private _http: HttpClient, private _requestService: RequestService, private _toastService: ToastService) { }

  private _createDownloadUrl(folder: string, filename: string) {
    const path = environment.api + '/s3/download'

    return this._requestService.create<string>(path, { folder, filename })
  }

  private _createPartUploadUrl(partNumber: number, token: string) {
    const path = environment.api + '/s3/multipart-upload/part'

    return this._requestService.create<UploadResponse>(path, { partNumber, token })
  }

  private _createUploadUrl(filename: string, options: UploadRequestOptions = {}) {
    const path = environment.api + '/s3/upload'

    return this._requestService.create<UploadResponse>(path, { filename, ...options })
  }

  private _finishMultipartUpload(parts: UploadPart[], token: string) {
    const path = environment.api + '/s3/multipart-upload/finish'

    return this._requestService.create<{ message, token }>(path, { parts, token })
  }

  private _startMultipartUpload(filename: string, options: UploadRequestOptions = {}): Observable<string> {
    const path = environment.api + '/s3/multipart-upload/start'

    return this._requestService.create<string>(path, { filename, ...options })
  }

  private _startModelProcessing(token: string) {
    const path = `${environment.api}/job/process-model`

    return this._requestService.create<string>(path, { token })
  }

  private _uploadFile(file: File, options: UploadRequestOptions = {}): Observable<{ token: string }> {
    if (file.size > MAX_FILE_SIZE) {
      return this._startMultipartUpload(file.name, options).pipe(
        switchMap(
          (token) => {
            const totalParts = Math.ceil(file.size / FILE_CHUNK_SIZE)
            const partsUploading: Observable<UploadPart>[] = []

            for (let partIndex = 0; partIndex < totalParts; partIndex++) {
              partsUploading.push(
                this._uploadFilePart(file, partIndex, token)
              )
            }

            return concat(...partsUploading).pipe(
              toArray(),
              switchMap(parts => this._finishMultipartUpload(parts, token)),
            )
          }
        )
      )
    } else {
      return this._createUploadUrl(file.name, options).pipe(
        switchMap(
          ({ token, url }) => {
            const endpointOptions = {
              error: { operation: 'uploadFile', toast: true },
              progressBar: { title: `Uploading Model File: ${file.name}` },
              successToast: { title: `Uploaded Model File: ${file.name}` },
            } as EndpointOptions

            return this._requestService.update(url, file, endpointOptions).pipe(
              map(() => ({ token }))
            )
          }
        ),
      )
    }
  }

  private _uploadFilePart(file: File, partIndex: number, token: string): Observable<UploadPart> {
    const start = partIndex * FILE_CHUNK_SIZE
    const end = Math.min((partIndex + 1) * FILE_CHUNK_SIZE, file.size)
    const fileChunk = file.slice(start, end, 'binary')
    const partNumber = partIndex + 1
    const totalParts = Math.ceil(file.size / FILE_CHUNK_SIZE)
    const fileName = getTruncatedFileName(file.name, 15)

    return this._createPartUploadUrl(partNumber, token).pipe(
      switchMap(({ token, url }) => {
        const endpointOptions: EndpointOptions = {
          error: { operation: 'uploadFilePart', toast: true },
          multipart: true,
          progressBar: { title: `Uploading ${fileName} - Part ${partNumber}/${totalParts}` },
        } as EndpointOptions

        return this._requestService.update<HttpEvent<any>>(url, fileChunk, endpointOptions).pipe(
          filter(event => event.type == HttpEventType.Response),
          map((response: HttpResponse<HttpEvent<HttpEventType.Response>>) =>
            ({ ETag: response.headers.get('ETag'), PartNumber: partNumber, token })
          )
        )
      })
    )
  }

  public deleteS3File(filename: string, folder: string) {
    const path = `${environment.api}/s3/delete/${filename}/${encodeURIComponent(folder)}`

    return this._requestService.delete<string>(path)
  }

  public downloadS3File(folder: string, filename: string) {
    return this._createDownloadUrl(folder, filename).pipe(
      tap(url => saveAs(url, filename))
    )
  }

  public uploadFiles(files: File[], options: UploadRequestOptions = {}): Observable<{ token: string }> {
    let token: string | undefined = options.token

    const removeWarning = this._toastService.setupNavigationWarning()

    return from(files).pipe(
      concatMap(file =>
        this._uploadFile(file, { purpose: options.purpose, token }).pipe(
          tap(response => token = response.token)
        )
      ),
      last(),
      finalize(() => removeWarning())
    )
  }

  public processModelInORI(files: File[]) {
    return this.uploadFiles(files, { purpose: 'process-e57' }).pipe(
      switchMap(({ token }) => this._startModelProcessing(token))
    )
  }
}