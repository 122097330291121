import { SceneProperty } from './SceneProperty'

export type SceneState = "ready" | "processing" | "failed"
export type SceneType = "Standard" | "Map" | "360 Image" | "Virtual Tour"

export type SceneOptions = {
    id?: number,
    previousID?: number,
    nextID?: number,
    properties?: SceneProperty[]
    thumbnail?: string,
    sceneOfInterest?: boolean,
    updateThumbnail?: boolean,
    state?: SceneState
}

export class Scene {
    public id: number
    public projectID: number
    public previousID: number
    public nextID: number
    public name: string
    public type: SceneType
    public properties: SceneProperty[]
    public thumbnail: string
    public sceneOfInterest: boolean
    public updateThumbnail: boolean
    public state: SceneState

    get bearing() { return this.properties?.find(p => p.key == 'bearing') }
    get targetPosition() { return this.properties?.find(p => p.key == 'targetPosition') }
    get cameraPosition() { return this.properties?.find(p => p.key == 'cameraPosition') }
    get fullscreenBtn() { return this.properties?.find(p => p.key == 'fullscreenBtn') }
    get latitude() { return this.properties?.find(p => p.key == 'latitude') }
    get longitude() { return this.properties?.find(p => p.key == 'longitude') }
    get overlap() { return this.properties?.find(p => p.key == 'overlap') }
    get pitch() { return this.properties?.find(p => p.key == 'pitch') }
    get resetBtn() { return this.properties?.find(p => p.key == 'resetBtn') }
    get shareBtn() { return this.properties?.find(p => p.key == 'shareBtn') }
    get style() { return this.properties?.find(p => p.key == 'style') }
    get terrain() { return this.properties?.find(p => p.key == 'terrain') }
    get timeOfDay() { return this.properties?.find(p => p.key == 'timeOfDay') }
    // VECKTA Mode
    get veckta() { return this.properties?.find(p => p.key == 'veckta') }
    get zoom() { return this.properties?.find(p => p.key == 'zoom') }
    get bounds() { return this.properties?.find(p => p.key == 'bounds') }
    get exportType() { return this.properties.find(({ key }) => key == 'exportType')?.value as 'leica' | 'matterport' | 'navvis' | 'faro' | 'spherical' | 'pinhole'}

    constructor(projectID: number, name: string, type: SceneType, options?: SceneOptions) {
        this.id = options && options.id ? +options.id : null
        this.projectID = +projectID
        this.name = name
        this.type = type
        this.previousID = options && options.previousID ? +options.previousID : null
        this.nextID = options && options.nextID ? +options.nextID : null
        this.properties = options && options.properties ? options.properties : []
        this.thumbnail = options ? options.thumbnail : null
        this.sceneOfInterest = options && options.sceneOfInterest !== undefined ? options.sceneOfInterest : true
        this.updateThumbnail = options && options.updateThumbnail !== undefined ? options.updateThumbnail : true
        this.state = options?.state ?? 'ready'
    }
}