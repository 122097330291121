export type FileExtension = '.3dm' | '.bin' | '.dae' | '.drc' | '.fbx' | '.glb' | '.gltf' | '.jpg' | '.js' | '.json' | '.mtl' | '.obj' | '.ogg' | '.pcd' | '.pdb' | '.pmd' | '.png' | '.prwm' | '.svg' | '.tga' | '.txt' | '.pdf' | '.e57' | '.las' | '.laz'

export type FileReferenceOptions = {
    filename?: string,
    hash?: string,
    extension?: FileExtension
    label?: string
    size?: number
}

export class FileReference {
    public id: number
    public modelID: number
    public filename: string
    public hash: string
    public file: File
    public extension: FileExtension
    public label: string
    public size: number

    constructor(id: number, modelID: number, file: File, options?: FileReferenceOptions) {
        this.id = id ? +id : null
        this.modelID = modelID ? +modelID : null
        this.filename = options ? options.filename : null
        this.hash = options ? options.hash : null
        this.file = file
        this.extension = options ? options.extension : null
        this.label = options ? options.label : null
        this.size = options ? options.size : null
    }

    equals(file: FileReference): boolean {
        return this.id == file?.id
            && this.modelID == file?.modelID
            && this.filename == file?.filename
            && this.hash == file?.hash
            && this.file == file?.file
            && this.extension == file?.extension
            && this.label == file?.label
            && this.size == file?.size
    }
}