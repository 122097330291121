import { Component, Input } from '@angular/core'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { ModalService } from '@services/modal.service'
import { EditProjectPermissionsComponent } from '@shared/edit-project-permissions/edit-project-permissions.component'

@Component({
  selector: 'shared-left-menu-button',
  templateUrl: './left-menu-button.component.html',
  styleUrls: ['./left-menu-button.component.css']
})
export class LeftMenuButtonComponent {
  @Input() buttonText: string
  @Input() iconClasses: string
  @Input() show: "models" | "features" | "interface" | "permissions" | "scenes" | "settings" | "connections"
  @Input() top: boolean = true

  get isSelected() { return this.envManager.leftPanelTab == this.show }
  get inBeta() { return this.show == 'connections' }

  constructor(
    public envManager: EnvironmentManagerService,
    public modalService: ModalService
  ) { }

  clicked() {
    if (this.show == 'permissions') {
      this.modalService.insertIntoModal(EditProjectPermissionsComponent, { title: "Edit Permissions" })
    } else if (this.isSelected && this.envManager.showLeftPanel) {
      this.envManager.toggleLeftPanel(false)
    } else {
      this.envManager.toggleLeftPanel(true, this.show)
    }
  }
}