import { BehaviorSubject, EMPTY, Observable } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { FileReference } from '@classes/FileReference'
import { Model } from '@classes/Model'
import { ModelPermission } from '@classes/ModelPermission'

import { AwsService } from './aws.service'
import { modelFromResult, ModelResult } from './feature.service'
import { FileReferenceService } from './file-reference.service'
import { EndpointOptions, ErrorOptions, RequestService } from './request.service'
import { ToastOptions, ToastService } from './toast.service'
import { UserService } from './user.service'

const modelsSubject = new BehaviorSubject<Model[]>([])
export const models$: Observable<Model[]> = modelsSubject

const selectedModelSubject = new BehaviorSubject<Model>(undefined)
export const selectedModel$: Observable<Model> = selectedModelSubject

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  public editableModels$ = models$.pipe(map(models => this.filterOnlyEditable(models)))
  public modelsLayout: 'grid' | 'list' = 'grid'
  public projectModels: Model[] = []
  public search = ''
  public previousModel: Model

  /** @returns Models which the user has 'Owner' permissions to */
  get editableModels(): Model[] { return this.filterOnlyEditable(this.models) }

  /** @returns Any Models which the user has any permissions to or are used in the current project */
  set models(models: Model[]) {
    if (this.selectedModel) this.previousModel = new Model(this.selectedModel.name, this.selectedModel.files, this.selectedModel)
    modelsSubject.next(models)
  }
  get models(): Model[] {
    return modelsSubject.getValue()?.map(model => new Model(model.name, model.files, { ...model }))
  }
  set selectedModel(model: Model) {
    if (this.selectedModel) this.previousModel = new Model(this.selectedModel.name, this.selectedModel.files, this.selectedModel)
    selectedModelSubject.next(model)
  }
  get selectedModel(): Model { return selectedModelSubject.getValue() }
  get modelIsSelected() { return selectedModelSubject.getValue() !== undefined }
  get user() { return this._userService.currentUser }

  filterOnlyEditable(models: Model[]) {
    return models.filter(model =>
      model.permissions.some(permission =>
        permission.userID == this.user.id && permission.permission == 'Owner'
      )
    )
  }

  public modelID$ = this._router.events.pipe(
    filter(route => route instanceof NavigationEnd),
    map((route: NavigationEnd) => {
      const paths = route?.url.split("/")
      let modelID

      if (paths[paths.length - 1].includes('?')) {
        let splitParams = paths[paths.length - 1].split("?")
        modelID = +splitParams[0]
      } else {
        modelID = +paths[paths.length - 1]
      }

      return modelID ?? EMPTY
    }),
    distinctUntilChanged()
  ) as Observable<number>

  constructor(
    private _awsService: AwsService,
    private _fileReferenceService: FileReferenceService,
    private _http: HttpClient,
    private _requestService: RequestService,
    private _router: Router,
    private _toastService: ToastService,
    private _userService: UserService,
  ) { }

  openModelEditor(model: Model) {
    const url = window.location.protocol + "/models/edit/" + model.id

    window.open(url, "_blank")
  }

  closeModelEditor() {
    if (this.selectedModel) {
      this.selectedModel = undefined
    }

    this.search = ''
    this._router.navigate(["/models"])
  }

  /**
  * Updates the state of the local list of models and the selectedModel
  * after every call to create, delete, or update a Model.
  */
  public editModelLocally(model: Model, type: 'create' | 'delete' | 'update') {
    const models = this.models
    const modelIndex = models.findIndex(m => m.id == model.id)

    if (type == 'create') {
      models.push(model)
      models.sort((a, b) => {
        return new Date(a.lastChanged) < new Date(b.lastChanged) ? 1 : -1
      })
    } else if (type == 'delete') {
      if (modelIndex != -1) {
        models.splice(modelIndex, 1)
        if (model?.id == this?.selectedModel?.id) this.selectedModel = undefined
      } else console.warn('Tried to remove unknown model.')
    } else if (type == 'update') {
      if (modelIndex != -1) {
        if (!model.thumbnail) model.thumbnail = models.find(a => a.id == model.id)?.thumbnail // Sometimes missing thumbnail
        models[modelIndex] = model
        if (model?.id == this?.selectedModel?.id) this.selectedModel = model
      } else console.warn('Tried to update unknown model.')
    }

    this.models = models
  }

  public getModelByID(modelID: number) {
    const url = `${environment.api}/model/${modelID}`
    const options = { error: { operation: "Get Model by ID" } } as EndpointOptions

    return this._requestService.get<ModelResult>(url, options).pipe(
      map(modelResult => {
        const model = modelFromResult(modelResult)

        this.selectedModel = model

        return model
      })
    )
  }

  getPointCloudFiles(modelID: number) {
    const url = `${environment.api}/model/point-cloud/${modelID}`
    const options = { error: { operation: 'Get Models Point Cloud Files', toast: true } } as EndpointOptions

    return this._requestService.get<{ metadataURL: string, octreeURL: string, hierarchyURL: string }>(url, options)
  }

  /**
   * Should fail in the backend if a feature references the model deleted.
   * @param model
   * @returns
   */
  deleteModel(model: Model) {
    const url = `${environment.api}/model/${model.id}`

    return this._http.delete<{ response: string }>(url).pipe(
      catchError(() => {
        this._toastService.toast({ title: "Cannot delete a Model currently used in a Feature", color: "red" })
        return EMPTY
      }),
      map(() => {
        if (this.selectedModel) {
          this._router.navigate(["/models"])
        }

        this.editModelLocally(model, 'delete')
        this._toastService.toast({ title: "Model Deleted", color: "green" })

        return model
      })
    )
  }

  updateModel(model: Model, params: { updateLocally?: boolean, toast?: boolean } = {}) {
    const url = `${environment.api}/model/${model.id}`
    const options = { error: { operation: "Update Model", toast: true } } as EndpointOptions

    if (params.toast) {
      options.successToast = { title: "Model Updated" }
    }

    return this._requestService.update<{ response: string }>(url, model, options).pipe(
      map(() => {
        if (params.updateLocally) {
          this.editModelLocally(model, 'update')
        }

        return model
      })
    )
  }

  /** Gets the models which the user has permissions to and are utilized in the project. */
  getModels(projectID: number): Observable<Model[]> {
    const url = `${environment.api}/models/project/${projectID}`
    const options = { error: { operation: "Get Models" } } as EndpointOptions

    return this._requestService.get<ModelResult[]>(url, options).pipe(
      map(modelResults => {
        const models = modelResults.map(modelResult => modelFromResult(modelResult))

        this.models = models

        if (this.selectedModel) {
          this.selectedModel = models.find(model => model.id == this.selectedModel.id)
        }

        return models
      })
    )
  }

  /** Gets models which the user has permissions to. */
  getUsersModels(): Observable<Model[]> {
    const url = `${environment.api}/models`
    const options = { error: { operation: "Get Users Models" } } as EndpointOptions

    return this._requestService.get<ModelResult[]>(url, options).pipe(
      map(modelResults => {
        const models = modelResults.map(modelResult => modelFromResult(modelResult))

        this.models = models

        if (this.selectedModel) {
          this.selectedModel = models.find(model => model.id == this.selectedModel.id)
        }

        return models
      })
    )
  }

  createModel(model: Model, ...files: File[]): Observable<Model> {
    const formData: FormData = new FormData()
    const url = `${environment.api}/model`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Create Model', toast: true } as ErrorOptions,
      progressBar: { title: "Creating your Model", message: "Uploading Model's File(s).", } as ToastOptions,
      successToast: {
        title: "Model Created",
        message: "Your Model is now available.",
        actionButton: {
          title: 'View Model',
        }
      } as ToastOptions
    }

    formData.append('name', model.name)
    formData.append('description', model.description)
    files.forEach(file => formData.append('file', file, file.name))

    return this._requestService.create<ModelResult>(url, formData, endpointOptions).pipe(
      map(modelResult => {
        const model = modelFromResult(modelResult)

        this.editModelLocally(model, 'create')

        endpointOptions.successToast.actionButton.callback = () => this.openModelEditor(model)

        return model
      })
    )
  }

  createPointCloud(file: File, params: { name: string, description: string, normalizePosition?: boolean, sceneId?: number }) {
    const url = `${environment.api}/model/point-cloud/`
    const endpointOptions = {
      error: { operation: 'Create Point Cloud', toast: true, retry: true },
      progressBar: { title: "Uploading your Point Cloud", message: "Uploading Model's File(s).", } as ToastOptions,
      successToast: { title: "Point Cloud File Uploaded", color: "green" },
    } as EndpointOptions

    return this._awsService.uploadFiles([file], { purpose: 'process-point-cloud' }).pipe(
      switchMap(({ token }) => this._requestService.create(url, { ...params, token }, endpointOptions)),
    )
  }

  /** Model Files */

  /**
   * Updates the state of the selectedModel after changes to a Model File
  */
  private _editModelFileLocally(type: 'create' | 'delete' | 'update', ...files: FileReference[]) {
    const model = this.models.find(model => model.id == files[0]?.modelID)

    if (!model) {
      console.warn(`Tried to ${type} File(s) of unknown Model.`)
      return
    }

    files.forEach(file => {
      const fileIndex = model.files.findIndex(f => f.id == file.id)

      if (type == 'create') model.files.push(file)
      else if (type == 'delete') {
        if (fileIndex != -1) model.files.splice(fileIndex, 1)
        else console.warn('Tried to remove unknown File.')
      } else if (type == 'update') {
        if (fileIndex != -1) model.files[fileIndex] = file
        else console.warn('Tried to update unknown File.')
      }
    })

    this.editModelLocally(model, 'update')
  }

  createModelFiles(modelID: number, ...files: File[]) {
    const title = `Uploading Model ${files.length > 1 ? 'Files' : 'File'}`

    return this._fileReferenceService.createFileReferences(files, { modelID, title }).pipe(
      tap((files: FileReference[]) => this._editModelFileLocally('create', ...files))
    )
  }

  updateModelFile(modelFile: FileReference) {
    return this._fileReferenceService.updateFileReference(modelFile).pipe(
      tap(_ => this._editModelFileLocally('update', modelFile))
    )
  }

  deleteModelFile(fileReference: FileReference) {
    const url = `${environment.api}/model/file/${fileReference.id}`
    const options = {
      error: { operation: "Delete Model File", toast: true },
      successToast: { title: "Model File Deleted" }
    } as EndpointOptions

    return this._requestService.delete<{ response: string }>(url, options).pipe(
      tap(() => this._editModelFileLocally('delete', fileReference))
    )
  }

  iconClasses(filename: string) {
    const regex = /(?:\.([^.]+))?$/
    const extension = regex.exec(filename)[1].toLowerCase()
    const imageFileTypes: string[] = ["png", "jpg", "jpeg", "mtl"]
    const modelFileTypes: string[] = ["obj", "fbx", "gltf"]
    const textFileTypes: string[] = ["txt"]

    if (imageFileTypes.includes(extension)) return "fas fa-file-image"
    if (modelFileTypes.includes(extension)) return "fas fa-cube"
    if (textFileTypes.includes(extension)) return "fas fa-file-alt"
    return "fas fa-file"
  }

  /** Model Permissions */

  /**
  * Updates the state of the selectedModel after changes to a Model Permission
  */
  private _editModelPermissionLocally(type: 'create' | 'delete' | 'update', ...permissions: ModelPermission[]) {
    const model = this.models.find(model => model.id == permissions[0].modelID)

    if (!model) {
      console.warn(`Tried to ${type} Permission(s) of unknown Model.`)
      return
    }

    permissions.forEach(permission => {
      const permissionIndex = model.permissions.findIndex(p => p.modelID == permission.modelID && p.userID == permission.userID)

      if (type == 'create') model.permissions.push(permission)
      else if (type == 'delete') {
        if (permissionIndex != -1) model.permissions.splice(permissionIndex, 1)
        else console.warn('Tried to remove unknown Permission.')
      } else if (type == 'update') {
        if (permissionIndex != -1) model.permissions[permissionIndex] = permission
        else console.warn('Tried to update unknown Permission.')
      }
    })

    this.editModelLocally(model, 'update')
  }

  updateModelPermission(permission: ModelPermission) {
    const url = `${environment.api}/model/permission/${permission.modelID}`
    const options: EndpointOptions = {
      error: { operation: "Update Model Permission", toast: true },
      successToast: { title: "Model Permissions Updated" }
    }

    return this._requestService.update<{ response: string }>(url, permission, options).pipe(
      tap(() => this._editModelPermissionLocally('update', permission))
    )
  }

  createModelPermissions(...permissions: ModelPermission[]) {
    const [{ modelID }] = permissions
    const url = `${environment.api}/model/permissions/${modelID}`
    const options: EndpointOptions = {
      error: { operation: 'Create Model Permissions', toast: true },
      successToast: { title: "Model Permissions Added" }
    }

    return this._requestService.create<{ response: string }>(url, { permissions }, options).pipe(
      tap(() => this._editModelPermissionLocally('create', ...permissions))
    )
  }

  deleteModelPermission(permission: ModelPermission) {
    const { modelID, userID } = permission
    const url = `${environment.api}/model/permission/${modelID}/${userID}`
    const options = {
      error: { operation: "Delete Model Permission", toast: true },
      successToast: { title: "Model Permission Removed" }
    } as EndpointOptions

    return this._requestService.delete<{ response: string }>(url, options).pipe(
      tap(() => this._editModelPermissionLocally('delete', permission))
    )
  }
}