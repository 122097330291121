import { Organization } from 'auth0'
import { BehaviorSubject, forkJoin } from 'rxjs'
import { distinctUntilChanged, map, skip, switchMap, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { Stripe } from 'stripe'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { features$ } from './feature.service'
import { models$ } from './model.service'
import { usersProjects$ } from './project.service'
import { EndpointOptions, RequestService } from './request.service'
import { scenes$ } from './scene.service'

export type SubscriptionTiers = 'free' | 'individual' | 'pro'

type SubscriptionDetails = {
  interval: Stripe.Price.Recurring.Interval
  isLegacy: boolean
  period_end: number
  period_start: number
  status: Stripe.Subscription.Status
  tier: SubscriptionTiers
  trialEnded: boolean
}

export type SignUpProOptions = {
  checkoutSessionID: string,
  interval: Stripe.Price.Recurring.Interval,
  orgName: string,
  orgID: string
}

export type SignUpResonse = {
  message: string
  latest_invoice: Stripe.Invoice
  organization: Organization
}

export type TierPrices = {
  free_tier: { yearly_price: Stripe.Price, monthly_price: Stripe.Price },
  individual_tier: { yearly_price: Stripe.Price, monthly_price: Stripe.Price },
  pro_tier: { yearly_price: Stripe.Price, monthly_price: Stripe.Price }
}

type UpdateSubscriptionItemsResponse = {
  invoice: Stripe.Invoice
  message: string
  organization: string
  status: Stripe.Invoice.Status
}

type UsageAndLimits = {
  resources: {
    documents: { usage: number, limit: number },
    models: { usage: number, limit: number },
    "360_images": { usage: number, limit: number }
  },
  seats: {
    admin: { usage: number, limit: number },
    editor: { usage: number, limit: number },
    read_only: { usage: number, limit: number }
  }
}

const detailsSource = new BehaviorSubject<SubscriptionDetails>(null)
export const details$ = detailsSource.pipe(skip(1))

const itemsSource = new BehaviorSubject<Stripe.SubscriptionItem[]>(null)
export const items$ = itemsSource.pipe(skip(1))

const usageSource = new BehaviorSubject<UsageAndLimits>(null)
export const usage$ = usageSource.pipe(skip(1))

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  get at360Limit() { return this.resources?.['360_images']?.usage >= this.resources?.['360_images']?.limit }
  get atDocumentLimit() { return this.resources?.documents?.usage >= this.resources?.documents?.limit }
  get atModelLimit(): boolean { return this.resources?.models?.usage >= this.resources?.models?.limit }
  get details() { return detailsSource.getValue() }
  get inFreeTier() { return this.tier == 'free' }
  get inProTier() { return this.tier == 'pro' }
  get interval() { return this.details?.interval }
  get isLegacy() { return this.details?.isLegacy }
  get items() { return itemsSource.getValue() }
  get periodEnd() { return this.details?.period_end ? new Date(this.details?.period_end * 1000) : null }
  get periodStart() { return this.details?.period_start ? new Date(this.details?.period_start * 1000) : null }
  get resources() { return this.usageAndLimits?.resources }
  get seats() { return this.usageAndLimits?.seats }
  get status() { return this.details?.status }
  get tier() { return this.details?.tier }
  get trialEnded() { return this.details?.trialEnded }
  get usageAndLimits() { return usageSource.getValue() }

  constructor(
    private _http: HttpClient,
    private _requestService: RequestService
  ) {
    features$.pipe(
      skip(2),
      map(arr => arr.length),
      distinctUntilChanged(),
      switchMap(_ => this.getSubscriptionUsage())
    ).subscribe()

    models$.pipe(
      skip(2),
      map(arr => arr.length),
      distinctUntilChanged(),
      switchMap(_ => this.getSubscriptionUsage())
    ).subscribe()

    usersProjects$.pipe(
      skip(2),
      map(arr => arr.length),
      distinctUntilChanged(),
      switchMap(_ => this.getSubscriptionUsage())
    ).subscribe()

    scenes$.pipe(
      skip(2),
      map(arr => arr.length),
      distinctUntilChanged(),
      switchMap(_ => this.getSubscriptionUsage())
    ).subscribe()
  }

  public getStatusByProject(projectID: number) {
    const url = `${environment.api}/public/subscription/status/${projectID}`

    return this._http.get<Stripe.Subscription.Status>(url)
  }

  public getSubscriptionUsage() {
    const url = `${environment.api}/subscription/usage`
    const options = { error: { operation: 'Get Subscription Usage', toast: true } } as EndpointOptions

    return this._requestService.get<UsageAndLimits>(url, options).pipe(
      tap(usage => usageSource.next(usage))
    )
  }

  public openSubscriptionBillingPortal() {
    const url = `${environment.api}/subscription/billing-portal`
    const options = { error: { operation: 'Get Subscription Billing Portal', toast: true } } as EndpointOptions

    return this._requestService.get<Stripe.BillingPortal.Session>(url, options).pipe(
      tap(session => window.open(session.url, '_blank'))
    )
  }

  public getSubscriptionDetails() {
    const url = `${environment.api}/subscription/details`

    return this._requestService.get<SubscriptionDetails>(url).pipe(
      tap(details => detailsSource.next(details))
    )
  }

  public getSubscriptionItems() {
    const url = `${environment.api}/subscription/items`
    const options = { error: { operation: 'Get Subscription Items', toast: true } } as EndpointOptions

    return this._requestService.get<Stripe.SubscriptionItem[]>(url, options).pipe(
      tap(items => itemsSource.next(items))
    )
  }

  public updateSubscriptionItems(items: Stripe.SubscriptionItem[]) {
    const url = `${environment.api}/subscription/items`
    const options = { error: { operation: 'Update Subscription Items', toast: true } } as EndpointOptions

    return this._requestService.update<UpdateSubscriptionItemsResponse>(url, items, options)
  }

  public previewInvoice(items: Stripe.SubscriptionItem[]) {
    const url = `${environment.api}/subscription/preview-invoice`
    const options = { error: { operation: 'Preview Invoice', toast: true } } as EndpointOptions

    return this._requestService.create<{ amount_due: number }>(url, items, options)
  }

  public createCheckoutSession(params: { cancel_route?: string, success_route: string, success_params?: { [key: string]: string }[] }) {
    const url = `${environment.api}/subscription/checkout-session`
    const options = { error: { operation: 'Get Checkout Session', toast: true } } as EndpointOptions

    return this._requestService.create<Stripe.Checkout.Session>(url, params, options)
  }

  public getAddonPackages(interval: Stripe.Price.Recurring.Interval) {
    const url = `${environment.api}/subscription/addon-packages?interval=${interval}`
    const options = { error: { operation: 'Get Addon Packages', toast: true } } as EndpointOptions

    return this._requestService.get<Stripe.Price[]>(url, options)
  }

  public getTiers() {
    const url = `${environment.api}/subscription/tiers`
    const options = { error: { operation: 'Get Tiers', toast: true } } as EndpointOptions

    return this._requestService.get<TierPrices>(url, options)
  }

  public getProductPackage(tier: SubscriptionTiers, interval: Stripe.Price.Recurring.Interval) {
    const url = `${environment.api}/subscription/product-package?tier=${tier}&interval=${interval}`
    const options = { error: { operation: 'Get Product Package', toast: true } } as EndpointOptions

    return this._requestService.get<Stripe.Price[]>(url, options)
  }

  public upgradeToProTier(checkoutSessionID: string, interval: Stripe.Price.Recurring.Interval, organization?: { orgName: string, orgID: string }) {
    const url = `${environment.api}/subscription/upgrade`
    const options = { error: { operation: 'Upgrade To Pro Tier', toast: true } } as EndpointOptions

    return this._requestService.create<UpdateSubscriptionItemsResponse>(url, { checkoutSessionID, interval, organization }, options).pipe(
      tap(() => this.getSubscriptionDetails()), // TODO: Remove nested Obs
    )
  }

  public signUp(tier: SubscriptionTiers, proOptions?: SignUpProOptions) {
    const url = `${environment.api}/sign-up`
    const options = { error: { operation: 'Sign Up', toast: true } } as EndpointOptions

    return this._requestService.create<SignUpResonse>(url, { tier, proOptions }, options)
  }

  public resetPassword() {
    const url = `${environment.api}/reset-password`
    const options = { error: { operation: 'Reset Password', toast: true } } as EndpointOptions

    return this._requestService.create<{ response: string }>(url, options)
  }
}