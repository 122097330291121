import * as Mapbox from 'mapbox-gl'
import { forkJoin, Observable, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import { AfterContentInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { FileReference } from '@classes/FileReference'
import { styles } from '@classes/MapSpace'
import { Scene } from '@classes/Scene'
import { sceneLoadMap } from '@classes/SceneManager'
import { SceneProperty } from '@classes/SceneProperty'
import { CopyService } from '@services/copy.service'
import { EnvironmentManagerService, getRadians } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { FileReferenceService } from '@services/file-reference.service'
import { LoadingService } from '@services/loading.service'
import { ProjectService } from '@services/project.service'
import { SceneService } from '@services/scene.service'
import { ThumbnailService } from '@services/thumbnail.service'
import { ToastService } from '@services/toast.service'
import { TooltipService } from '@services/tooltip.service'

@Component({
  selector: 'shared-edit-scene',
  templateUrl: './edit-scene.component.html',
  styleUrls: ['./edit-scene.component.css']
})
export class EditSceneComponent implements AfterContentInit, OnChanges, OnDestroy {
  @Input() scene: Scene
  private _subscriptions: Subscription[] = []
  public mapStyles = styles
  public orderedPropertyKeys = ["longitude", "latitude", "zoom", "pitch", "bearing"]
  public skyboxOptions = [
    { display: 'Local Time', value: 'local' },
    { display: 'Sunrise', value: 'sunrise' },
    { display: 'Morning', value: 'morning' },
    { display: 'Afternoon', value: 'afternoon' },
    { display: 'Evening', value: 'evening' },
    { display: 'Sunset', value: 'sunset' },
    { display: 'Night', value: 'night' },
  ]

  public sceneForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(),
    type: new UntypedFormControl(),
    sceneOfInterest: new UntypedFormControl('', { updateOn: 'change' }),
    properties: new UntypedFormArray([])
  }, { updateOn: 'blur' })

  public showAdvanced = false

  get properties() { return this.sceneForm.get('properties') as UntypedFormArray }
  get bearing() { return this.getPropertyFormGroup('bearing') }
  get bounds() { return this.getPropertyFormGroup('bounds') }
  get file() { return this.getPropertyFormGroup('file') }
  get longitude() { return this.getPropertyFormGroup('longitude') }
  get latitude() { return this.getPropertyFormGroup('latitude') }
  get overlap() { return this.getPropertyFormGroup('overlap') }
  get pitch() { return this.getPropertyFormGroup('pitch') }
  get style() { return this.getPropertyFormGroup('style') }
  get terrain() { return this.getPropertyFormGroup('terrain') }
  get zoom() { return this.getPropertyFormGroup('zoom') }
  get timeOfDay() { return this.getPropertyFormGroup('timeOfDay') }

  get fileReference() { return this.scene.properties.find(p => p.key == 'file')?.fileReference }
  get sceneManager() { return this.envManager.sceneManager }
  get mapSpace() { return this.envManager.mapSpace }
  get map() { return this.mapSpace?.map }
  get camera() { return this.envManager.modelSpace.camera }
  get orbitControls() { return this.envManager.modelSpace.orbitControls }

  constructor(
    private _fileReferenceService: FileReferenceService,
    private _loadingService: LoadingService,
    private _projectService: ProjectService,
    private _toastService: ToastService,
    public copyService: CopyService,
    public envManager: EnvironmentManagerService,
    public featureService: FeatureService,
    public sceneService: SceneService,
    public thumbnailService: ThumbnailService,
    public tooltipService: TooltipService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // Recreate the forms
    this.sceneForm.patchValue({
      name: this.scene.name,
      type: this.scene.type,
      sceneOfInterest: this.scene.sceneOfInterest,
    }, { emitEvent: false })

    this.scene.properties.sort((a, b) => {
      if (a.key > b.key) return -1;
      if (b.key > a.key) return 1;
      return 0;
    })

    this.properties.controls = this.scene.properties.map(p => this.newPropertyForm(p))
    this.tooltipService.intializeTooltips()
  }

  newPropertyForm(property: SceneProperty): UntypedFormGroup {
    let value: AbstractControl

    if (property.type == 'vector3') {
      value = new UntypedFormArray([new UntypedFormControl(0), new UntypedFormControl(0), new UntypedFormControl(0)])
      value.patchValue(property.value)
    } else if (property.key == 'timeOfDay') {
      value = new UntypedFormControl(property.value, { updateOn: 'change' })
    } else if (property.type == 'boolean' || property.key == 'style') value = new UntypedFormControl(property.value, { updateOn: 'change' })
    else value = new UntypedFormControl(property.value)

    return new UntypedFormGroup({
      id: new UntypedFormControl(property.id),
      sceneID: new UntypedFormControl(property.sceneID),
      fileReferenceID: new UntypedFormControl(property.fileReferenceID),
      type: new UntypedFormControl(property.type),
      key: new UntypedFormControl(property.key),
      value: value
    }, { updateOn: 'blur' })
  }

  ngAfterContentInit(): void {
    // This subscription updates the scene when the Angular Forms are updated
    this._subscriptions.push(
      this.sceneForm.valueChanges.pipe(switchMap(scene => {
        const prevScene = this.scene

        for (const key in scene)
          if (key != 'properties') prevScene[key] = scene[key]

        return this.sceneService.updateScene(prevScene)
      })).subscribe())
  }

  getPropertyFormGroup(key: string) {
    return this.properties.controls.find(prop => prop.get("key").value == key) as UntypedFormGroup
  }

  downloadBackgroundFile(fileReference: FileReference) {
    this._fileReferenceService.downloadFile(fileReference, this._projectService.currentProject.id).subscribe()
  }

  replaceBackgroundFile(file: File) {
    if (file) {
      const scene = this.sceneService.selectedScene
      const fileReferenceID = scene.properties.find(p => p.type == "image").fileReferenceID
      const sceneLoad = sceneLoadMap.get(scene.id)
      sceneLoad.backgroundLoaded = false

      this.sceneService.replaceSceneImage(fileReferenceID, file).subscribe()
    }
  }

  setStartingOrientation() {
    const properties: SceneProperty[] = []
    const targetProperty = this.scene.targetPosition
    const cameraProperty = this.scene.cameraPosition
    const targetPosition: [number, number, number] = this.orbitControls.target.toArray()
    const cameraPosition: [number, number, number] = this.camera.position.toArray()

    if (targetProperty && JSON.stringify(targetProperty.value) != JSON.stringify(targetPosition)) {
      targetProperty.value = targetPosition
      properties.push(targetProperty)
    }

    if (JSON.stringify(cameraProperty.value) != JSON.stringify(cameraPosition)) {
      cameraProperty.value = cameraPosition
      properties.push(cameraProperty)
    }

    if (properties.length > 0) {
      this.sceneService.updateSceneProperties(this.scene.id, ...properties).subscribe(() => {
        this._toastService.toast({ title: 'Update', message: 'Updated Scene Orientation', color: 'green', autohide: true })
      })
    }
  }

  setMapsStartingOrientation() {
    const checkPropertyChanged = (propertyGroup: UntypedFormGroup, value: any) => {
      if (propertyGroup.get("value").value != value) {
        propertyGroup.get("value").setValue(value)
        properties.push(propertyGroup.value)
      }
    }
    const properties: SceneProperty[] = []
    const center = this.map.getCenter()

    const bounds = this.map.getBounds()
    const boundingBox = {
      northEast: bounds.getNorthEast().toArray(),
      southWest: bounds.getSouthWest().toArray(),
    }
    checkPropertyChanged(this.bounds, boundingBox)

    checkPropertyChanged(this.bearing, this.map.getBearing())
    checkPropertyChanged(this.longitude, center.lng)
    checkPropertyChanged(this.latitude, center.lat)
    checkPropertyChanged(this.pitch, this.map.getPitch())
    checkPropertyChanged(this.zoom, this.map.getZoom())

    if (properties.length > 0)
      this.sceneService.updateSceneProperties(this.scene.id, ...properties)
        .subscribe(() => {
          this._toastService.toast({ title: 'Update', message: 'Updated Scene Orientation', color: 'green', autohide: true })
        })
  }

  updateSunPosition(timeOfDayForm: UntypedFormGroup) {
    const timeOfDay: string = timeOfDayForm.value.value
    const latitude: number = this.latitude.value.value
    const longitude: number = this.longitude.value.value
    const sunPos = this.envManager.getSunPosition(timeOfDay, longitude, latitude)

    this.sceneService.updateSceneProperty(timeOfDayForm.value).subscribe(() =>
      this.envManager.map.setPaintProperty('sky', 'sky-atmosphere-sun', sunPos))
  }

  updateTerrain(terrainProperty: SceneProperty) {
    const featureOffsets = this.featureService.features.map(
      feature => { // Finds the difference between the Map's Altitude and the Feature's
        const coordinates = new Mapbox.LngLat(feature.position[0], feature.position[1])
        const mapAltitude = this.map.queryTerrainElevation(coordinates)
        const altitudeDifference = feature.position[2] - mapAltitude

        return { featureID: feature.id, altitudeDifference: altitudeDifference }
      }
    ) as { featureID: number, altitudeDifference: number }[]

    this._loadingService.setLoaded(false) // Loading screen on until all Feature positions have updated

    this.sceneService.updateSceneProperty(terrainProperty).pipe(
      switchMap(() => new Observable(obs => {
        this.map.once('idle', (load) => {
          obs.next(load)
          obs.complete()
        })
      })),
      switchMap(() => forkJoin(
        this.featureService.features.map(feature => {
          const coordinates = new Mapbox.LngLat(feature.position[0], feature.position[1])
          const mapAltitude = this.map.queryTerrainElevation(coordinates)
          const altitudeDifference = featureOffsets.find(fo => fo.featureID == feature.id)?.altitudeDifference
          const newAltitude = mapAltitude + altitudeDifference

          feature.position[2] = newAltitude

          return this.featureService.updateFeature(feature)
        })
      ))
    ).subscribe(() => this._loadingService.setLoaded(true))
  }

  updateOverlapProperty(overlapProperty: SceneProperty) {
    this.sceneService.updateSceneProperty(overlapProperty).subscribe(property => {
      const overlap = overlapProperty.value as boolean
      const layer = 'pointLayer'

      this.map.setLayoutProperty(layer, 'icon-allow-overlap', !overlap)
      this.map.setLayoutProperty(layer, 'text-allow-overlap', !overlap)
    })

  }
  updateSceneProperty(sceneProperty: SceneProperty) {
    this.sceneService.updateSceneProperty(sceneProperty).subscribe()
  }

  /** Updates the north angle with the current camera angle. */
  setNorthAngle() {
    if (this.scene.type != '360 Image') return

    const cameraPosition = this.camera.position.clone()
    const cameraAngle = getRadians(cameraPosition.x, cameraPosition.z)
    const northProperty = this.scene.properties.find(p => p.key == 'northAngle')

    northProperty.value = cameraAngle

    this.sceneService.updateSceneProperty(northProperty).subscribe()
  }

  setSceneThumbnail() {
    this.thumbnailService.updateSceneThumbnail()
  }

  toggleCollapse() {
    this.showAdvanced = !this.showAdvanced
  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}