import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, filter, map, skip, switchMap, take, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { User } from '@classes/User'

import { AuthenticationService } from './authentication.service'
import { EndpointOptions, RequestService } from './request.service'
import { ToastService } from './toast.service'

// Result types
export type UserResult = {
  id: string,
  name: string,
  username: string,
  email: string,
  company: string,
  organization_id: string,
  picture: string,
  last_login: string,
  created_on: string
}

// Conversion functions
export function userFromResult(result: UserResult) {
  return new User(
    result.id,
    result.name,
    result.username,
    result.email,
    result.company,
    result.organization_id,
    result.picture,
    result.last_login,
    result.created_on
  )
}

const organizationUsersSubject = new BehaviorSubject<User[]>([])

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _userUrl = environment.api + '/user'

  private _currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(undefined)
  public currentUser$: Observable<User> = this._currentUser.pipe(skip(1))
  get currentUser(): User { return this._currentUser.getValue() }
  set currentUser(user: User) { this._currentUser.next(user) }

  public organizationUsers$: Observable<User[]> = organizationUsersSubject.pipe(skip(1))
  get organizationUsers(): User[] { return organizationUsersSubject.getValue() }
  set organizationUsers(users: User[]) { organizationUsersSubject.next(users) }

  constructor(
    private _authService: AuthenticationService,
    private _http: HttpClient,
    private _requestService: RequestService,
    private _toastService: ToastService,
  ) {
    this.getCurrentUser().pipe(
      switchMap(user => {
        user.lastLogin = new Date().toLocaleString()
        return this.updateUser(user)
      }),
      switchMap(() =>
        this.getOrganizationsUsers().pipe(tap(users => this.organizationUsers = users))
      )
    ).subscribe()
  }

  getCurrentUser() {
    if (this.currentUser) {
      return of(this.currentUser)
    } else {
      return this._authService.user$.pipe(
        filter(user => user !== undefined && user !== null),
        take(1),
        switchMap(() => this.getUser()),
        catchError(this.handleError<User>("Failed to get current user.")),
        tap(user => this.currentUser = user)
      )
    }
  }

  getUser(): Observable<User> {
    const url = `${environment.api}/user`
    const options = { error: { operation: 'Get Current Database User', retry: true } } as EndpointOptions

    return this._requestService.get<UserResult>(url, options).pipe(
      map(userResult => userFromResult(userResult)),
    )
  }

  getOrganizationsUsers(): Observable<User[]> {
    const url = `${environment.api}/users/organization`

    return this._http.get<UserResult[]>(url).pipe(
      catchError(this.handleError<UserResult[]>("Failed to get users.")),
      map(us => us.map(u => userFromResult(u)))
    )
  }

  updateUser(user: User): Observable<User> {
    const url = `${environment.api}/user`
    const endpointOptions = { error: { operation: 'Update User' } } as EndpointOptions

    return this._requestService.update<User>(url, user, endpointOptions).pipe(
      tap(user => this.currentUser = user)
    )
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'Operation did not complete.', result?: T) {
    return (error: any): Observable<T> => {
      this._toastService.toast({ title: "Error", message: operation, color: "red" })

      // TODO: send the user error to remote logging infrastructure
      console.error(error) // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }
}
