<div class="main bg-light">
    <!-- Navigation Bar -->
    <billing-nav-bar></billing-nav-bar>

    <div class="container p-3 d-flex flex-column gap-3">
        <!-- Subscription Summary & Billing Actions -->
        <div class="d-flex justify-content-between align-items-center">
            <shared-upgrade-tier-banner *ngIf="inFreeTier"></shared-upgrade-tier-banner>
            <div *ngIf="!inFreeTier" class="card p-3 bg-light bg-gradient shadow-sm">
                <span> {{ details.tier | titlecase }} Plan - Next Invoice on {{ billingPeriodEnd | date }} </span>
            </div>

            <div *ngIf="hasFinancialAccess && !inFreeTier" class="d-flex gap-3">
                <button class="btn btn-primary d-flex gap-2 align-items-center p-2"
                    (click)="openEditSubscriptionPanel()">
                    <span>Manage Subscription</span>
                    <i class="fa fa-pen-to-square"></i>
                </button>
                <button class="btn btn-outline-secondary d-flex gap-2 align-items-center p-2"
                    (click)="openBillingPortal()">
                    <span *ngIf="isOpening" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    <span>Billing Portal</span>
                    <i class="fa fa-credit-card"></i>
                </button>
            </div>
        </div>

        <!-- Subscription Usage Overview -->
        <div class="row">
            <div class="col-6">
                <div class="card p-3 shadow-sm">
                    <h5 class="fw-bold">Usage Overview</h5>
                    <div *ngFor="let item of usageStats" class="mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="fw-semibold">{{ item.name | titlecase }}</span>
                            <span class="fw-bold" [ngClass]="{
                                  'text-muted': (item.used / item.limit) < 0.75,
                                  'text-warning': (item.used / item.limit) >= 0.75 && (item.used / item.limit) < 1,
                                  'text-danger': (item.used / item.limit) >= 1
                              }">
                                {{ item.used | number }} / {{ item.limit | number }}
                                ({{ (item.used / item.limit * 100) | number:'1.0-0' }}%)
                            </span>
                        </div>
                        <div class="progress mt-1">
                            <div class="progress-bar progress-bar-animated bg-opacity-75" role="progressbar" [ngClass]="{
                                  'bg-success': (item.used / item.limit) < 0.75,
                                  'bg-warning': (item.used / item.limit) >= 0.75 && (item.used / item.limit) < 1,
                                  'bg-danger': (item.used / item.limit) >= 1
                              }" [style.width]="(item.used / item.limit) * 100 + '%'">
                            </div>
                        </div>
                        <div *ngIf="(item.used / item.limit) >= 1" class="mt-1 small d-flex align-items-center">
                            <i class="fa fa-exclamation-triangle text-danger"></i>
                            <span class="ms-2 text-danger">
                                Limit Reached!
                            </span>
                            <button *ngIf="!inFreeTier" class="btn btn-sm btn-outline-primary ms-2"
                                (click)="openEditSubscriptionPanel()">
                                Upgrade {{ item.name | titlecase }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="inProTier" class="col-6">
                <div class="card p-3 shadow-sm">
                    <h5 class="fw-bold">Recent Invoices</h5>
                    <div *ngIf="false; else noInvoices">
                        <ul class="list-group list-group-flush">
                            <li *ngFor="let invoice of invoices" class="list-group-item d-flex justify-content-between">
                                <span>{{ invoice.date | date }} - {{ invoice.amount | currency }}</span>
                                <a [href]="invoice.url" target="_blank" class="btn btn-sm btn-outline-primary">View</a>
                            </li>
                        </ul>
                    </div>
                    <ng-template #noInvoices>
                        <p class="text-muted">No invoices available.</p>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <!-- Toasts -->
    <div class="toast-container d-flex flex-column m-3">
        <shared-toast-container></shared-toast-container>
    </div>
</div>

<billing-right-panel></billing-right-panel>