import { Modal } from 'bootstrap'
import { EMPTY } from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Project } from '@classes/Project'
import { ModalBody } from '@services/modal.service'
import { ProjectService } from '@services/project.service'
import { SubscriptionService } from '@services/subscription.service'
import { CreateSceneComponent, isVirtualTourComponent } from '@shared/create-scene/create-scene.component'

@Component({
  selector: 'shared-modal-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css'],
})
export class CreateProjectComponent implements ModalBody, OnChanges {
  @Input() events: EventEmitter<'close' | 'submit'>
  @Input() modal: Modal
  @ViewChild(CreateSceneComponent) createSceneComponent: CreateSceneComponent

  public form = new FormGroup({
    title: new FormControl<string>('New Project', [Validators.required, Validators.maxLength(50)]),
    description: new FormControl<string>(''),
  })
  public submitting: boolean = false

  get title() { return this.form.get('title') }
  get description() { return this.form.get('description') }

  constructor(private _projectService: ProjectService, private _subscriptionService: SubscriptionService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.events.subscribe(event => {
      if (event == 'submit') {
        this.submit()
      } else if (event == 'close') {
        this.modal.hide()
      }
    })
  }

  getProject() {
    return new Project(this.title.value, { description: this.description.value })
  }

  submit(): boolean {
    if (this.form.invalid || this.createSceneComponent.form.invalid) {
      this.form.markAllAsTouched()
      this.createSceneComponent.form.markAllAsTouched()

      return false
    } else if (!this.submitting) {
      this.submitting = true

      const project = this.getProject()

      if (isVirtualTourComponent(this.createSceneComponent.bodyComponent)) {
        this.createSceneComponent.bodyComponent.createScene$(project).subscribe(() => this.submitting = false)
      } else {
        this._projectService.createProject(project).pipe(
          switchMap(project => this.createSceneComponent.createScene$(project.id).pipe(
            catchError(() => {
              this._projectService.deleteProject(project)

              this.submitting = false

              return EMPTY
            }),
            tap(() => this._projectService.openProject("projects/edit", project.id))
          ))
        ).subscribe(() => this.submitting = false)
      }

      this.modal.hide()

      return true
    }
  }
}