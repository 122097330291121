import { Modal } from 'bootstrap'
import * as Mapbox from 'mapbox-gl'

import { Component } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { AssetField } from '@classes/AssetField'
import { AssetService } from '@services/asset.service'
import { ToastColor, ToastService } from '@services/toast.service'

function minLengthArray(min: number): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    if (c instanceof FormArray) {
      return c.controls.length >= min ? null : { 'minLengthArray': { valid: false } }
    } else {
      return null
    }
  }
}

@Component({
  selector: 'assets-create-asset-field',
  templateUrl: './create-asset-field.component.html',
  styleUrls: ['./create-asset-field.component.css']
})
export class CreateAssetFieldComponent {
  public cancelText = 'Cancel'
  public field: FormGroup
  public submittedField: AssetField
  public isSubmitting = false
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Create'
  public title = 'Create Asset Field'
  public onSubmit: CallableFunction

  get dropdownOptions() { return this.field.get('dropdownOptions') as FormArray }

  constructor(private _assetService: AssetService, private _toastService: ToastService) {
    this.field = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      description: new FormControl(''),
      type: new FormControl('text'),
      required: new FormControl(false),
      defaultValue: new FormControl<string | string[]>(''),
      dropdownOptions: new FormArray([new FormControl(''), new FormControl('')], minLengthArray(2)),
      latitude: new FormControl('', [Validators.min(-90), Validators.max(90)]),
      longitude: new FormControl('', [Validators.min(-180), Validators.max(180)]),
    })

    this.field.get('type').valueChanges.subscribe(type => {
      if (type === 'chooseMultiple') this.field.get('defaultValue').setValue([])
      else if (type === 'chooseOne') this.field.get('defaultValue').setValue('')
    })
  }

  addDropdownOption() {
    this.dropdownOptions.push(new FormControl(''))
  }

  removeDropdownOption(dropdownIndex: number) {
    this.dropdownOptions.removeAt(dropdownIndex)
  }

  submit() {
    if (this.field.invalid) {
      this.field.markAllAsTouched()

      this._toastService.toast({ title: "Error", message: "Invalid submission. Please double-check your asset details.", color: "red" })
    } else if (!this.isSubmitting) {
      this.isSubmitting = true

      this.submittedField = new AssetField(
        this.field.get('name').value,
        this.field.get('description').value,
        this.field.get('type').value,
        {
          assetTypeID: this._assetService.selectedAssetType.id,
          defaultValue: this._getDefaultValue(this.field),
          dropdownOptions: (this.field.get('dropdownOptions') as FormArray).controls.map(control => control.value),
          required: this.field.get('required').value
        }
      )

      this._assetService.createAssetField(this.submittedField).subscribe(() => {
        this.close()
        this._assetService.triggerChangesListener()
        if (this.onSubmit) {
          this.onSubmit()
        }
      }, (error) => this.isSubmitting = false)
    }
  }

  close() {
    this.modal?.hide()
  }

  private _getDefaultValue(control: FormGroup): string | string[] | number {
    if (control.get('type').value == 'location') {
      return [control.get('longitude').value, control.get('latitude').value]
    } else {
      return control.get('defaultValue').value
    }
  }

  toggleDefaultValue(event: Event, i: number) {
    const isChecked = (event.target as HTMLInputElement).checked

    if (isChecked) this.field.get('defaultValue').enable()
    else this.field.get('defaultValue').disable()
  }

  handleMapClick(camera: Mapbox.CameraOptions, field: FormGroup) {
    const { lng, lat } = camera.center as Mapbox.LngLat

    field.get('longitude').setValue(lng)
    field.get('latitude').setValue(lat)
  }
}