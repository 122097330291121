import { forkJoin, from, Observable, of } from 'rxjs'
import { catchError, concatMap, finalize, map, tap, toArray } from 'rxjs/operators'

import { Component } from '@angular/core'
import { Action } from '@classes/Action'
import { Feature, FeatureCustomField, FeatureTypes } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { Interaction } from '@classes/Interaction'
import { CreateGeoJsonOptions, FeatureService } from '@services/feature.service'
import { SceneService } from '@services/scene.service'
import { ToastService } from '@services/toast.service'

// Turf imports for simplification
import simplify from '@turf/simplify'
import { Feature as GeoJSONFeature, Polygon, LineString } from 'geojson'

interface PropertyOption {
  key: string
  label: string // e.g., "Name: Ex Pole 4"
}

interface GeojsonFileWrapper {
  file: File
  nameKey: string
  propertyOptions: PropertyOption[]
}

@Component({
  selector: 'shared-create-features-from-geojson',
  templateUrl: './create-features-from-geojson.component.html',
  styleUrls: ['./create-features-from-geojson.component.css']
})
export class CreateFeaturesFromGeojsonComponent {
  public geojsonFiles: GeojsonFileWrapper[] = []
  public touched: boolean = false
  public showDetails: boolean = true

  get isValid() { return this.geojsonFiles.length > 0 }
  get selectedSceneID() { return this.sceneService.selectedSceneID }

  constructor(
    public featureService: FeatureService,
    public sceneService: SceneService,
    public toastService: ToastService
  ) { }

  onFileChange(files: FileList) {
    Array.from(files).forEach(file => {
      const wrapper: GeojsonFileWrapper = { file, nameKey: '', propertyOptions: [] }
      this.geojsonFiles.push(wrapper)
      this.extractPropertyKeys(file, wrapper)
    })
    this.touched = true
  }

  replaceFile(file: File, index: number) {
    this.geojsonFiles[index].file = file
    this.extractPropertyKeys(file, this.geojsonFiles[index])
    this.touched = true
  }

  removeFile(index: number) {
    this.geojsonFiles.splice(index, 1)
    this.touched = true
  }

  private extractPropertyKeys(file: File, wrapper: GeojsonFileWrapper) {
    const reader = new FileReader()
    reader.onload = () => {
      try {
        const content = reader.result as string
        const parsed = JSON.parse(content)

        let props: any = {}
        // For simplicity, just take the first feature if it's a FeatureCollection
        if (parsed.type === 'FeatureCollection' && parsed.features && parsed.features.length > 0) {
          props = parsed.features[0].properties
        } else if (parsed.type === 'Feature') {
          props = parsed.properties
        }

        if (props && typeof props === 'object') {
          // Build array of { key, label: "key: value" }
          wrapper.propertyOptions = Object.keys(props).map(key => {
            const value = props[key]
            return { key, label: `${key}: ${value}` }
          })
        }
      } catch (error) {
        console.error('Error extracting property keys from file:', file.name, error)
      }
    }
    reader.onerror = error => console.error('Error reading file', file.name, error)
    reader.readAsText(file)
  }

  showRequiredFields() {
    this.touched = true
  }

  // Main function to process files in sequence
  createFeaturesFromGeoJson$() {
    return forkJoin(
      this.geojsonFiles.map(wrapper =>
        this.readFileAsText(wrapper.file).pipe(
          map(result => ({ ...result, nameKey: wrapper.nameKey }))
        )
      )
    ).pipe(
      concatMap(fileResults => {
        return from(fileResults).pipe(
          concatMap(fileResult =>
            this.processSingleFile(fileResult, { nameKey: fileResult.nameKey })
          ),
          toArray(),
          map(fileArrays => fileArrays.reduce((acc, cur) => acc.concat(cur), []))
        )
      })
    )
  }

  // Read a single File into { fileName, content }
  private readFileAsText(file: File): Observable<{ fileName: string, content: string }> {
    return new Observable(subscriber => {
      const reader = new FileReader()
      reader.onload = () => {
        subscriber.next({ fileName: file.name, content: reader.result as string })
        subscriber.complete()
      }
      reader.onerror = error => subscriber.error(error)
      reader.readAsText(file)
    })
  }

  // Process a single file's contents
  private processSingleFile(
    fileResult: { fileName: string, content: string },
    options: CreateGeoJsonOptions
  ): Observable<Feature[]> {
    const { fileName, content } = fileResult
    if (!content) {
      return of([])
    }

    let parsed: any
    try {
      parsed = JSON.parse(content)
    } catch (e) {
      return of([])
    }

    // Helper function to batch features based on cumulative weight (JSON-stringified length)
    const batchByWeight = (features: Feature[], maxWeight: number): Feature[][] => {
      const batches: Feature[][] = []
      let currentBatch: Feature[] = []
      let currentWeight = 0
      for (const feature of features) {
        const weight = JSON.stringify(feature).length
        if (currentBatch.length > 0 && currentWeight + weight > maxWeight) {
          batches.push(currentBatch)
          currentBatch = [feature]
          currentWeight = weight
        } else {
          currentBatch.push(feature)
          currentWeight += weight
        }
      }
      if (currentBatch.length > 0) {
        batches.push(currentBatch)
      }
      return batches
    }

    if (parsed.type === 'FeatureCollection') {
      // Convert GeoJSON features to internal Features using the updated geoJsonToFeature
      const children = parsed.features.map((geoFeature: any, index: number) => {
        return this.geoJsonToFeature(geoFeature, options, (index + 1).toString())
      }) as Feature[]

      // (No filtering here since features will be simplified if needed.)
      const groupName = fileName + ' Group'

      return this.featureService.createFeatureGroup({ name: groupName, toast: true }).pipe(
        concatMap(group => {
          // Set parentID for all children
          const childrenWithParent = children.map(child => {
            child.parentID = group.id
            return child
          })

          // Batch features (adjust MAX_BATCH_WEIGHT as needed)
          const MAX_BATCH_WEIGHT = 750000
          const batches = batchByWeight(childrenWithParent, MAX_BATCH_WEIGHT)
          const totalBatches = batches.length
          let processedBatches = 0

          // Create a progress toast
          const progressToast = this.toastService.toast({
            title: 'Processing Features',
            message: `0 of ${totalBatches} completed.`,
            color: 'blue',
            autohide: false
          })

          return from(batches).pipe(
            concatMap(batch =>
              this.featureService.createFeatures(batch).pipe(
                tap(() => {
                  processedBatches++
                  progressToast.componentRef.instance.message = `${processedBatches} of ${totalBatches} completed.`
                })
              )
            ),
            toArray(),
            map(batchResults => {
              const createdChildren = batchResults.reduce((acc, curr) => acc.concat(curr), [] as Feature[])
              return [group, ...createdChildren]
            }),
            finalize(() => {
              this.toastService.dispose(progressToast)
              this.toastService.toast({
                title: 'Update Complete',
                color: 'green'
              })
            }),
            catchError(err => of([group]))
          )
        }),
        catchError(err => of([]))
      )
    } else if (parsed.type === 'Feature') {
      const feature = this.geoJsonToFeature(parsed, options)
      return this.featureService.createFeatures([feature]).pipe(
        catchError(err => of([]))
      )
    } else {
      const wrapped = {
        type: 'Feature',
        geometry: parsed,
        properties: {}
      }
      const feature = this.geoJsonToFeature(wrapped, options)
      return this.featureService.createFeatures([feature]).pipe(
        catchError(err => of([]))
      )
    }
  }

  // Convert GeoJSON feature to internal Feature (with simplification logic)
  private geoJsonToFeature(geoFeature: any, options: CreateGeoJsonOptions, nameSuffix: string = ''): Feature {
    const props = geoFeature.properties || {} as FeatureProperty[]
    const customFields = Object.entries(props).map(([key, value]) => {
      let checkedValue = value
      if (typeof value === 'string' && value.length > 255) {
        checkedValue = value.substring(0, 255)
        this.toastService.toast({
          title: 'Simplified Feature',
          message: 'Field ' + key + ' has been shortened to meet size requirements.',
          color: 'yellow'
        })
      }
      return ({ key, value: checkedValue })
    }) as FeatureCustomField[]

    let name = props[options.nameKey || 'name'] || props.name || ''

    const description = props.description || ''
    let type = '' as FeatureTypes
    let geometryData = geoFeature.geometry.coordinates
    let propertiesArray: FeatureProperty[] = []
    let position: number[] | null = null

    switch (geoFeature.geometry.type) {
      case 'Point':
        type = 'marker'
        if (!name && nameSuffix) {
          name = `Point ${nameSuffix}`
        }

        if (Array.isArray(geometryData) && geometryData.length === 2) {
          geometryData = [...geometryData, 0]
        }
        position = geometryData
        // For points, also add a coordinateString (using the position)
        propertiesArray = [
          { type: 'string', key: 'backgroundColor', value: '#adadad' } as FeatureProperty,
          { type: 'string', key: 'backgroundShape', value: 'none' } as FeatureProperty,
          { type: 'string', key: 'color', value: '#ffffff' } as FeatureProperty,
          { type: 'string', key: 'displayType', value: 'image' } as FeatureProperty,
          { type: 'string', key: 'icon', value: 'fas fa-location-dot' } as FeatureProperty,
          { type: 'integer', key: 'size', value: '0.07' } as FeatureProperty,
          { type: 'string', key: 'text', value: 'New Marker' } as FeatureProperty
        ]
        break
      case 'LineString':
        type = 'line'
        if (!name && nameSuffix) {
          name = `Line ${nameSuffix}`
        }

        // If the coordinates (as JSON) are too long, simplify using Turf
        if (JSON.stringify(geometryData).length > 10000) {
          let tolerance = 0.001
          const toleranceStep = 0.001
          const maxTolerance = 1
          let simplifiedFeature: GeoJSONFeature<LineString> = geoFeature
          let simplifiedCoords = geometryData
          while (JSON.stringify(simplifiedCoords).length > 10000 && tolerance < maxTolerance) {
            tolerance += toleranceStep
            simplifiedFeature = simplify(geoFeature, { tolerance, highQuality: false }) as GeoJSONFeature<LineString>
            simplifiedCoords = simplifiedFeature.geometry.coordinates
          }
          geometryData = simplifiedCoords
          this.toastService.toast({
            title: 'Simplified Feature',
            message: `A LineString feature's coordinates were simplified to reduce size.`,
            color: 'yellow'
          })
        }
        propertiesArray = [
          { type: 'string', key: 'color', value: '#ffff00' } as FeatureProperty,
          { type: 'string', key: 'coordinateString', value: JSON.stringify(geometryData) } as FeatureProperty,
          { type: 'boolean', key: 'dashed', value: 'false' } as FeatureProperty,
          { type: 'integer', key: 'width', value: '5' } as FeatureProperty,
          { type: 'integer', key: 'offset', value: '0' } as FeatureProperty
        ]
        break
      case 'Polygon':
        type = 'polygon'
        if (!name && nameSuffix) {
          name = `Polygon ${nameSuffix}`
        }

        if (JSON.stringify(geometryData).length > 10000) {
          let tolerance = 0.001
          const toleranceStep = 0.001
          const maxTolerance = 1
          let simplifiedFeature: GeoJSONFeature<Polygon> = geoFeature
          let simplifiedCoords = geometryData
          while (JSON.stringify(simplifiedCoords).length > 10000 && tolerance < maxTolerance) {
            tolerance += toleranceStep
            simplifiedFeature = simplify(geoFeature, { tolerance, highQuality: false }) as GeoJSONFeature<Polygon>
            simplifiedCoords = simplifiedFeature.geometry.coordinates
          }
          geometryData = simplifiedCoords
          this.toastService.toast({
            title: 'Simplified Feature',
            message: `A Polygon feature's coordinates were simplified to reduce size.`,
            color: 'yellow'
          })
        }
        // Flatten one level for storage
        let flatCoordinates = Array.isArray(geometryData) ? ([] as any[]).concat(...geometryData) : geometryData
        propertiesArray = [
          { type: 'string', key: 'coordinateString', value: JSON.stringify(flatCoordinates) } as FeatureProperty,
          { type: 'string', key: 'outlineColor', value: '#f4ebeb' } as FeatureProperty,
          { type: 'string', key: 'color', value: '#a65e5e' } as FeatureProperty,
          { type: 'boolean', key: 'outlineVisibility', value: 'true' } as FeatureProperty
        ]
        break
      default:
        throw new Error(`Unsupported geometry type: ${geoFeature.geometry.type}`)
    }

    const interactions = this.showDetails ? [{
      type: 'click',
      actions: [{
        key: 'showDetails',
        value: JSON.stringify({ string: 'modal', size: 'medium' })
      } as Action]
    }] as Interaction[] : [] as Interaction[]

    // Create and return the internal Feature instance
    const feature = new Feature(this.selectedSceneID, name, type, {
      description,
      customFields,
      interactions,
      properties: propertiesArray,
      position: position as [number, number, number]
    })

    return feature
  }
}