<div *ngIf="user$ | async as user" class="row align-items-center profile-header">
    <div class="col-md text-center text-md-left">
        <p class="lead text-muted">{{ user.company }}</p>
    </div>

    <div class="d-flex flex-column gap-2">
        <!-- Search bar -->
        <input *ngIf="organizations?.length > 3" class="form-control" type="search" [(ngModel)]="search"
            placeholder="Search">

        <ul class="list-group" [style.max-height]="'200px'" style="overflow-y: auto;">
            <!-- Current organization displayed separately -->
            <li *ngIf="userOrg"
                class="list-group-item list-group-item-action d-flex align-items-center d-grid gap-2 current-org"
                type="button" disabled>
                <img class="logo-sm" [src]="userOrg?.branding?.logo_url ?? defaultLogo" alt="Logo">
                <span class="fw-bold">{{userOrg.display_name}}</span>
                <span class="fw-bold">Current</span>
            </li>

            <!-- Filtered organizations based on search query -->
            <ng-container *ngFor="let org of organizations | searchSortByKeys: ['name', 'display_name'] : search">
                <li *ngIf="org.id !== user.org_id"
                    class="list-group-item list-group-item-action d-flex align-items-center d-grid gap-2" type="button"
                    (click)="accountLogin(org)">
                    <img class="logo-sm" [src]="org?.branding?.logo_url ?? defaultLogo" alt="Logo">
                    <span class="fw-bold">{{org.display_name}}</span>
                </li>
            </ng-container>
        </ul>

        <!-- Personal Account Section, separate from the list -->
        <div *ngIf="hasPersonalAccount" class="mt-2">
            <button class="btn btn-dark w-100 d-flex align-items-center" (click)="accountLogin()">
                <i class="fas fa-user me-2"></i>
                My Account
            </button>
        </div>
    </div>
</div>