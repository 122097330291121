import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

type CursorState = 'default' | 'clickable' | 'grab' | 'grabbing' | 'placing'

@Injectable({
  providedIn: 'root'
})
export class PointerService {

  constructor(
  ) {
    this.state$.subscribe((state) => {
      this.style.cursor = this.getCursorStyle()
    })
  }

  public state: CursorState = 'grab'
  private style: any = document.body.style

  private cursorMap: Record<CursorState, string> = {
    default: 'auto', // Arrow Head
    clickable: 'pointer', // Hand Pointer
    grab: 'grab', // Hand icon
    grabbing: 'grabbing', // Closed hand
    placing: 'crosshair', // Crosshairs
  }

  // Observable for the state
  private stateSubject = new BehaviorSubject<CursorState>(this.state)
  public readonly state$ = this.stateSubject.asObservable()

  // Set a new state
  setPointerState(newState: CursorState, style?: any): void {
    if (style) this.style = style
    else this.style = document.body.style
    
    this.state = newState
    this.stateSubject.next(newState)
  }

  // Get the cursor style based on the current state
  getCursorStyle(): string {
    return this.cursorMap[this.state]
  }
}
