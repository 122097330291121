<div class="d-grid gap-2">
    <div>
        <label class="form-label">
            Scene Type
        </label>

        <select class="form-select fontawesome-select" [(ngModel)]="type">
            <option selected>Standard</option>
            <option value="Map">Map (beta)</option>
            <option [disabled]="atFileLimit" value="360 Image">
                360° Image <a *ngIf="atFileLimit">🔒</a>
            </option>
            <option [disabled]="atFileLimit || atRealityAssetLimit" value="Virtual Tour">
                Virtual Tour <a *ngIf="atFileLimit || atRealityAssetLimit">🔒</a>
            </option>
        </select>
    </div>

    <shared-create-map-scene [hidden]="type != 'Map'"></shared-create-map-scene>

    <shared-create-standard-scene [hidden]="type != 'Standard'"></shared-create-standard-scene>

    <shared-create-three-sixty-scene [hidden]="type != '360 Image'"></shared-create-three-sixty-scene>

    <shared-create-virtual-tour [hidden]="type != 'Virtual Tour'"></shared-create-virtual-tour>
</div>