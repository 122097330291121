import { FeatureProperty } from './FeatureProperty'
import { Interaction } from './Interaction'
import { Model } from './Model'

export type FeatureTypes = "3D" | "cutOut" | "group" | 'icon' | "image" | "label" | "line" | "marker" | "polygon" | "rasterImage" | "view"
export type InteractionEffect = 'change color' | 'change opacity' | 'enlarge' | 'outline' | 'nothing'
export type MapFeatureType = Extract<FeatureTypes, 'line' | 'marker' | 'polygon' | 'rasterImage'>
export type ThreeFeatureType = Extract<FeatureTypes, '3D' | 'cutOut' | 'image' | 'icon' | 'label' | 'view'>

export const MapFeatureTypes: readonly MapFeatureType[] = ['line', 'marker', 'polygon', 'rasterImage']
export const ThreeFeatureTypes: readonly ThreeFeatureType[] = ['3D', 'cutOut', 'image', 'icon', 'label', 'view']

export function isMapFeature(feature: Feature) {
    return MapFeatureTypes.includes(feature?.type as MapFeatureType)
}

export function isThreeFeature(feature: Feature) {
    return ThreeFeatureTypes.includes(feature?.type as ThreeFeatureType)
}

export function hasFiles(feature: Feature) {
    return ['image', 'marker', 'rasterImage', 'view'].includes(feature?.type)
}

export type FeatureOptions = {
    id: number,
    parentID: number,
    modelID: number,
    model: Model,
    description: string,
    position: [number, number, number],
    rotation: [number, number, number],
    scale: [number, number, number],
    objectOfInterest: boolean,
    visible: boolean,
    opacity: number,
    filterable: boolean,
    interactable: boolean,
    onClick: InteractionEffect,
    onHover: InteractionEffect,
    lastChanged: string,
    properties: FeatureProperty[],
    customFields: FeatureCustomField[],
    interactions: Interaction[],
    files: (FeatureFileResult | FeatureFile)[],
    children: Feature[],
    unloaded: boolean,
    origin: 'internal' | 'external' | 'modified',
}

export class Feature {
    public id: number
    public sceneID: number
    public parentID: number
    public modelID: number

    public model: Model
    public name: string
    public type: FeatureTypes
    public description: string

    // Transformation class?
    public position: [number, number, number]
    public rotation: [number, number, number]
    public scale: [number, number, number]

    public visible: boolean
    public opacity: number
    public filterable: boolean
    public interactable: boolean

    public objectOfInterest: boolean
    public onClick: InteractionEffect
    public onHover: InteractionEffect
    public lastChanged: string

    public properties: FeatureProperty[]
    public interactions: Interaction[]
    public customFields: FeatureCustomField[]
    public files: FeatureFile[]
    public children: Feature[]
    public unloaded: boolean
    /** 
     * Interal: Regular Feature  
     * External: Feature from a Connection
     * Modified: Feature modified by a Connection
     **/
    public origin: 'internal' | 'external' | 'modified'

    get attachments() { return this.properties?.filter(p => p.key == "attachment") }
    get backgroundColor() { return this.properties?.find(p => p.key == "backgroundColor") }
    get backgroundShape() { return this.properties?.find(p => p.key == "backgroundShape") }
    get color() { return this.properties?.find(p => p.key == "color") }
    get correctSeam() { return this.properties?.find(p => p.key == 'correctSeam') }
    get embeddedImages() { return this.properties?.filter(p => p.key == 'embeddedImage') }
    get loadOnStart() { return this.properties?.find(p => p.key == 'loadOnStart') }
    get showOnTop() { return this.properties?.find(p => p.key == 'showOnTop') }
    get renderSide() { return this.properties?.find(p => p.key == 'renderSide') }
    get coordinateString() { return this.properties.find(p => p.key == 'coordinateString') }
    get display() { return this.properties?.find(p => p.key == "display") }
    get displayType() { return this.properties?.find(p => p.key == "displayType") }
    get icon() { return this.properties?.find(p => p.key == "icon") }
    get ignoresFilters() { return !this.filterable }
    get isBackdrop() { return this.properties?.some(({ key }) => key == "virtualTourBackdrop") }
    get image() { return this.properties?.find(p => p.type == "image") }
    get matrix() { return this.properties?.find(p => p.key == "matrix") }
    get placement() { return this.properties?.find(p => p.key == "placement") }
    get scaleWithCamera() { return this.properties?.find(p => p.key == "scaleWithCamera") }
    get size() { return this.properties?.find(p => p.key == "size") }
    get text() { return this.properties?.find(p => p.key == "text") }
    get toggleable() { return this.properties?.find(p => p.key == "toggleable") }
    get toggleIcon() { return this.properties?.find(p => p.key == "toggleIcon") }
    get toggleText() { return this.properties?.find(p => p.key == "toggleText") }
    get trackCamera() { return this.properties?.find(p => p.key == "trackCamera") }
    get virtualTourBackdrop() { return this.properties?.find(p => p.key == 'virtualTourBackdrop') }

    // [key: string]: boolean | number | string | number[] | Model | FeatureProperty[] | Interaction[] | Feature[]
    constructor(sceneID: number, name: string, type: FeatureTypes, options?: Partial<FeatureOptions>) {
        this.id = options?.id ? +options.id : undefined
        this.sceneID = sceneID ? +sceneID : undefined
        this.parentID = options?.parentID ? +options.parentID : undefined
        this.modelID = options?.modelID ? +options.modelID : undefined

        this.description = options?.description ?? ''
        this.name = name
        this.type = type

        this.position = options?.position ? options.position : [0, 0, 0]
        this.rotation = options?.rotation ? options.rotation : [0, 0, 0]
        this.scale = options?.scale ? options.scale : [1, 1, 1]

        this.onClick = options?.onClick ?? 'nothing'
        this.onHover = options?.onHover ?? 'nothing'
        this.filterable = options?.filterable ?? true
        this.interactable = options?.interactable !== undefined ? options.interactable : true
        this.lastChanged = options?.lastChanged ? options.lastChanged : ''
        this.objectOfInterest = options?.objectOfInterest !== undefined ? options.objectOfInterest : true
        this.visible = options?.visible !== undefined ? options.visible : false
        this.opacity = options?.opacity ?? 1.0

        this.model = options?.model ? new Model(options.model.name, options.model.files, options.model) : undefined
        this.children = options?.children ? options.children : []
        this.interactions = options?.interactions ? options.interactions
            .map(int => new Interaction(int.type, int.actions, int)) : []
        this.properties = options?.properties ? options.properties
            .map(prop => new FeatureProperty(prop.type, prop.key, prop.value, prop)) : []
        this.customFields = options?.customFields
        this.files = options?.files?.map((file: FeatureFileResult) => ({ ...file, featureID: +file.feature_id, s3ObjectReferenceID: +file.s3_object_reference_id }))

        const unloaded = () => {
            if (options?.unloaded !== undefined) {
                return options.unloaded
            } else if (this.loadOnStart) {
                return !this.loadOnStart.value
            } else {
                return false
            }
        }
        this.unloaded = unloaded()
        this.origin = options?.origin ?? 'internal'
    }

    public equals(feature: Feature) {
        if (feature === undefined) return false
        if (this.id != feature.id ||
            this.sceneID != feature.sceneID ||
            this.parentID != feature.parentID ||
            this.modelID != feature.modelID ||
            this.name != feature.name ||
            this.type != feature.type ||
            this.description != feature.description ||

            this.position.toString() !== feature.position.toString() ||
            this.rotation.toString() !== feature.rotation.toString() ||
            this.scale.toString() !== feature.scale.toString() ||

            this.visible != feature.visible ||
            this.opacity != feature.opacity ||
            this.filterable != feature.filterable ||
            this.interactable != feature.interactable ||
            this.objectOfInterest != feature.objectOfInterest ||
            this.onHover != feature.onHover ||
            this.lastChanged != feature.lastChanged) return false

        if (this.properties.length != feature.properties.length) return false
        if (this.interactions.length != feature.interactions.length) return false

        for (const interaction of this.interactions) {
            if (!interaction.equals(feature.interactions.find(i => i.id == interaction.id))) return false
        }

        for (const property of this.properties) {
            if (!property.equals(feature.properties.find(i => i.id == property.id))) return false
        }

        return true
    }
}

export function filesEqualInFeature(previous: Feature, current: Feature): boolean {
    const previousImageProperty = previous?.properties.find(property => property.key == 'file' && property.type == 'image')
    const currentImageProperty = current?.properties.find(property => property.key == 'file' && property.type == 'image')

    return previousImageProperty?.fileReference?.hash == currentImageProperty?.fileReference?.hash
}

class GroupFeature extends Feature {
    // get children(): Feature[] { return }

    constructor(group: Feature) {
        super(group.id, group.name, group.type, group)
    }
}

class ImageFeature extends Feature {
    get scaleWithCamera() { return this.properties?.find(p => p.key == "scaleWithCamera") }
    get trackCamera() { return this.properties?.find(p => p.key == "trackCamera") }
    get image() { return this.properties?.find(p => p.type == "image") }

    constructor(image: Feature) {
        super(image.id, image.name, image.type, image)
    }
}

class LineFeature extends Feature {
    get color() { return this.properties?.find(p => p.key == "color") }
    get coordinates() { return this.properties?.find(p => p.key == "coordinateString") }
    get dashed() { return this.properties?.find(p => p.key == "dashed") }
    get width() { return this.properties?.find(p => p.key == "width") }
    get offset() { return this.properties?.find(p => p.key == "offset") }

    constructor(line: Feature) {
        super(line.id, line.name, line.type, line)
    }
}

class ModelFeature extends Feature {
    get renderSide() { return this.properties?.find(p => p.key == 'renderSide') }
    // get group() { return  }

    constructor(model: Feature) {
        super(model.id, model.name, model.type, model)
    }
}

class PolygonFeature extends Feature {
    get color() { return this.properties?.find(p => p.key == "color") }
    get coordinates() { return this.properties?.find(p => p.key == "coordinateString") }
    get outlineColor() { return this.properties?.find(p => p.key == "outlineColor") }
    get outlineVisibility() { return this.properties?.find(p => p.key == "outlineVisibility") }

    constructor(polygon: Feature) {
        super(polygon.id, polygon.name, polygon.type, polygon)
    }
}

class MarkerFeature extends Feature {
    get image() { return this.properties?.find(p => p.type == "image") }
    get size() { return this.properties?.find(p => p.key == "size") }

    constructor(marker: Feature) {
        super(marker.id, marker.name, marker.type, marker)
    }
}

class TextFeature extends Feature {
    get text() { return this.properties?.find(p => p.key == "text") }

    constructor(text: Feature) {
        super(text.id, text.name, text.type, text)
    }
}

class ViewPointFeature extends Feature {
    get correctSeam() { return this.properties?.find(p => p.key == 'correctSeam') }
    get repeat() { return -0.9993 }
    get offset() { return 0.9995 }

    constructor(viewPoint: Feature) {
        super(viewPoint.id, viewPoint.name, viewPoint.type, viewPoint)
    }
}

export interface FeatureCustomFieldResult {
    id?: number
    feature_id: number
    key?: string
    value?: any
}

export interface FeatureCustomField {
    id?: number
    featureID: number
    key?: string
    value?: any
}

export interface FeatureFile {
    featureID: number
    s3ObjectReferenceID: number
    bucket: string
    key: string
    label: string
    type: "file" | "folder"
}

export interface FeatureFileResult {
    feature_id: string
    s3_object_reference_id: string
    bucket: string
    key: string
    label: string
    type: "file" | "folder"
}