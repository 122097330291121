<div class="main">
    <div *ngIf="state == 'collectInfo'" class="d-flex justify-content-end p-4">
        <img *ngIf="currentUser$ | async" [src]="(currentUser$ | async)?.picture"
            class="border rounded-circle thumbnail" role="button" data-bs-toggle="modal"
            data-bs-target="#staticBackdrop" width="50px">
    </div>
    <div class="body d-flex flex-column align-items-center p-4 gap-4">
        <ng-container *ngIf="state == 'collectInfo'">
            <billing-select-tier #selectTier [selectable]="['free', 'pro']" [selected]="'pro'"
                (selectTier)="chooseTier($event)"></billing-select-tier>

            <billing-create-organization #createOrg [@unfoldVertical]="selectedTier == 'pro' ? 'visible' : 'hidden'"
                class="px-5">
            </billing-create-organization>

            <div>
                <button class="btn text-white scheme-bg-dark rounded-1 py-2 w-100" [disabled]="state == 'submitting'"
                    (click)="proceedToPayment()">
                    {{selectedTier == 'free' ? 'Get Started for Free' : 'Proceed to Payment'}}
                </button>
            </div>
        </ng-container>

        <!-- Submitting -->
        <h1 *ngIf="state == 'submitting' && selectedTier == 'free'" class="fw-bold text-secondary fs-1">
            Submitting your Information...
        </h1>
        <h1 *ngIf="state == 'submitting' && selectedTier != 'free'" class="fw-bold text-secondary fs-1">
            Submitting Payment Information...
        </h1>

        <!-- Success -->
        <ng-container *ngIf="state == 'success'">
            <billing-view-invoice [invoice]="invoice" [title]="'Registration Successful'"
                [subtitle]="'Welcome to Kartorium!'"></billing-view-invoice>

            <div>
                <button class="btn text-white scheme-bg-dark rounded-1 py-2 w-100" (click)="goToDashboard()">
                    Go to Dashboard
                </button>
            </div>
        </ng-container>

        <!-- Failed -->
        <div *ngIf="state == 'failed'"
            class="d-flex flex-column align-items-center bg-light rounded-1 shadow gap-3 p-5">
            <span class="text-danger">
                {{error}}
            </span>
            <span class="text-dark">
                There was a problem during signup. If this issue keeps happening, please contact us at
                <a href="mailto:support@kartorium.com">{{'support@kartorium.com'}}</a>
            </span>
        </div>
    </div>
</div>

<!-- Toasts -->
<div class="toast-container d-flex flex-column m-3">
    <shared-toast-container></shared-toast-container>
</div>

<div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="right-panel"
    aria-labelledby="right-panel-label">
    <div class="offcanvas-header">
        <h1 class="offcanvas-title" id="right-panel-label">Review & Confirm</h1>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>

    <div *ngIf="prices" class="offcanvas-body d-flex flex-column gap-4">
        <div class="plan-summary p-3 bg-light rounded">
            <h4 class="mb-2">{{ selectedProduct.name }}</h4>
            <p class="mb-0 text-muted">
                {{selectedProduct.description}}
            </p>
        </div>

        <div class="cart-item d-flex justify-content-between align-items-center p-3 border rounded">
            <div class="d-flex flex-column">
                <strong>{{ selectedProduct.name }}</strong>
                <small class="text-muted">Quantity: x1</small>
            </div>
            <span class="fw-bold">{{ this.totalAmount | currency }}</span>
        </div>

        <div class="total-cost p-3 rounded bg-light d-flex justify-content-between align-items-center">
            <span class="fw-semibold">Total Cost</span>
            <h4 class="m-0 text-primary">
                {{ this.totalAmount | currency }}
            </h4>
        </div>

        <div class="d-flex justify-content-center mt-auto">
            <button #submitButton [disabled]="!isValid" (click)="finishSignup()" class="btn btn-success btn-lg px-4"
                type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                Finish Signup
            </button>
        </div>
    </div>
</div>