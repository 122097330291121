import { Modal } from 'bootstrap'
import { merge, Subscription } from 'rxjs'
import { filter, startWith } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core'
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Connection, ConnectionProperty } from '@classes/Connection'
import { newRuleGroup } from '@classes/Rules'
import { ConnectionService } from '@services/connection.service'
import { features$, FeatureService } from '@services/feature.service'
import { ModalBody } from '@services/modal.service'
import { RightSidebarService } from '@services/right-sidebar.service'
import { SceneService } from '@services/scene.service'
import { ToastService } from '@services/toast.service'

@Component({
  selector: 'shared-modal-create-connection',
  templateUrl: './create-connection.component.html',
  styleUrls: ['./create-connection.component.css'],
})
export class CreateConnectionComponent implements ModalBody, OnChanges, OnDestroy {
  @Input() events: EventEmitter<'close' | 'submit'>
  @Input() modal: Modal

  private _subscriptions: Subscription[] = []
  public connectionForm: UntypedFormGroup
  public features$ = features$.pipe(
    startWith(this._featureService.features)
  )
  public urlStatus: 'good' | 'bad' | 'loading' = 'bad'

  get createFeatures(): UntypedFormControl { return this.connectionForm.get('createFeatures') as UntypedFormControl }
  get featureID(): UntypedFormControl { return this.connectionForm.get('featureID') as UntypedFormControl }
  get headers(): UntypedFormArray { return this.connectionForm.get('headers') as UntypedFormArray }
  get name(): UntypedFormControl { return this.connectionForm.get('name') as UntypedFormControl }
  get parameters(): UntypedFormArray { return this.connectionForm.get('parameters') as UntypedFormArray }
  get type(): UntypedFormControl { return this.connectionForm.get('type') as UntypedFormControl }
  get url(): UntypedFormControl { return this.connectionForm.get('url') as UntypedFormControl }

  get isMap(): boolean { return this._sceneService.selectedScene.type == 'Map' }
  get selectedSceneID() { return this._sceneService.selectedSceneID }

  constructor(
    private _connectionService: ConnectionService,
    private _featureService: FeatureService,
    private _rightSidebarService: RightSidebarService,
    private _sceneService: SceneService,
    private _toastService: ToastService,
  ) {
    this.connectionForm = new UntypedFormGroup({
      sceneID: new UntypedFormControl(this.selectedSceneID),
      url: new UntypedFormControl('', { updateOn: 'blur' }),
      headers: new UntypedFormArray([]),
      parameters: new UntypedFormArray([]),
      name: new UntypedFormControl('New Connection', [Validators.required]),
      createFeatures: new UntypedFormControl(this.isMap),
      featureID: new UntypedFormControl(''),
      type: new UntypedFormControl(this.isMap ? 'marker' : 'customFields')
    })

    this._subscriptions.push(
      merge(
        this.url.valueChanges, this.headers.valueChanges, this.parameters.valueChanges
      ).subscribe(() => this.validateURL(this.url.value))
    )
  }

  ngOnChanges() {
    this._subscriptions.push(
      this.events.pipe(filter(event => event == 'submit')).subscribe(() => this.submit())
    )
  }

  addHeader(key = '', value = '') {
    this.headers.push(new UntypedFormGroup({
      key: new UntypedFormControl(key, { updateOn: 'blur' }),
      value: new UntypedFormControl(value, { updateOn: 'blur' })
    }))
  }

  addParameter(key = '', value = '') {
    this.parameters.push(new UntypedFormGroup({
      key: new UntypedFormControl(key, { updateOn: 'blur' }),
      value: new UntypedFormControl(value, { updateOn: 'blur' }),
      dynamic: new UntypedFormControl(false, { updateOn: 'change' }),
      type: new UntypedFormControl('time', { updateOn: 'change' })
    }), { emitEvent: false })
  }

  removeFormGroup(index: number, formArray: UntypedFormArray) {
    formArray.removeAt(index)
  }

  updateCreateFeatures(value: 'true' | 'false') {
    if (value == 'true') {
      this.type.setValue('marker')
      this.featureID.setValue('')
    } else {
      this.type.setValue('customFields')
      this.featureID.setValue(this._featureService.features[0].id)
    }
  }

  updateDynamic(parameter: UntypedFormGroup, isDynamic: boolean) {
    if (!isDynamic) {
      parameter.get('value').setValue('')
    } else {
      parameter.get('value').setValue('today')
    }
  }

  public extractParameters() {
    if (this.parameters == null) {
      this.parameters.setValue([], { emitEvent: false })
    }

    const { baseURL, params } = this._connectionService.extractParams(this.url.value)

    params.forEach(([key, value]) => this.addParameter(key, value))

    this.url.setValue(baseURL, { emitEvent: false })
  }

  public validateURL(url: string) {
    if (url.includes('?')) {
      this.extractParameters()
    }

    this.urlStatus = 'loading'

    const headers = this.headers.value
    const parameters = this.parameters.value

    if (url != '') {
      this._connectionService.tryConnection(url, headers, parameters).subscribe(
        (value) => this.urlStatus = 'good',
        (error) => this.urlStatus = 'bad'
      )
    } else {
      this.urlStatus = 'bad'
    }
  }

  submit() {
    if (this.connectionForm.invalid || this.urlStatus == 'bad') {
      this._toastService.toast({ title: "Error", message: "Invalid input in the connection creation", color: "red" })
      return false
    }

    const stringifiedURL = JSON.stringify({
      url: this.url.value,
      headers: this.headers.value,
      parameters: this.parameters.value,
      dataPath: null,
      idAttribute: '',
      externalID: ''
    })

    const connection = new Connection(this.selectedSceneID, {
      ...this.connectionForm.value,
      url: stringifiedURL,
    })

    if (connection.createFeatures) {
      this.createFeatures.setValue(true, { emitEvent: false })
      const value = JSON.stringify({ longitude: null, latitude: null })

      connection.properties.push(
        new ConnectionProperty("integer", "size", "1"),
        new ConnectionProperty("string", "backgroundColor", "#ffffff"),
        new ConnectionProperty("string", "backgroundShape", "rounded"),
        new ConnectionProperty("string", "color", "#000000"),
        new ConnectionProperty("string", "coordinateKey", value, {}),
        new ConnectionProperty("string", "displayType", "image"),
        new ConnectionProperty("string", "icon", "fas fa-location-dot"),
        new ConnectionProperty("string", "text", "New Marker"),
      )
    }
    
    connection.properties.push(
      new ConnectionProperty("string", "filter", JSON.stringify(newRuleGroup(true)))
    )

    this._connectionService.createConnection(connection).subscribe(connection => {
      this._rightSidebarService.setTab('Connection')
      this._rightSidebarService.openPanel()
      this._connectionService.selectedConnection = connection
    })

    return true
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}