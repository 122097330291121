import { BehaviorSubject, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { Project } from '@classes/Project'
import { PermissionType, ProjectPermission } from '@classes/ProjectPermission'
import { AuthenticationService } from '@services/authentication.service'
import { DashboardService } from '@services/dashboard.service'
import { project$, ProjectService } from '@services/project.service'
import { SubscriptionService } from '@services/subscription.service'
import { TooltipService } from '@services/tooltip.service'
import { UserService } from '@services/user.service'

enum EditPermissionState {
  UPDATE = 'update',
  DELETE = 'delete',
  ONE_USER_WARNING = 'oneUserWarning',
  ADD = 'add'
}

interface PermissionChanges {
  permission: ProjectPermission
  element?: HTMLSelectElement
}

const messages = {
  update: (permission: ProjectPermission) => `Change permissions for ${permission.user.name}? This will take effect immediately.`,
  delete: (permission: ProjectPermission) => `Delete permissions for ${permission.user.name}?`,
  oneUserWarning: () => `Projects need at least one owner.`,
  add: () => undefined
}

@Component({
  selector: 'shared-edit-project-permissions',
  templateUrl: './edit-project-permissions.component.html',
  styleUrls: ['./edit-project-permissions.component.css']
})
export class EditProjectPermissionsComponent implements OnDestroy, OnInit, AfterViewInit {
  @Input() showEmail: boolean = true
  private _editPermissionsState$ = new BehaviorSubject<EditPermissionState>(undefined)
  private _subscriptions: Subscription[] = []
  public editPermissionsState$ = this._editPermissionsState$.asObservable()
  public message: string = undefined
  public permissionChanges: PermissionChanges = undefined
  public permissions: ProjectPermission[] = []

  get currentUser() { return this.userService.currentUser }
  get currentUserPermission(): string {
    const user = this._authenticationService.currentUser
    const permission: ProjectPermission = this.permissions.find((perm: ProjectPermission) => perm.userID == user?.sub)
    if (permission) return permission.permission
    else return ''
  }
  get editPermissionsState() { return this._editPermissionsState$.getValue() }
  set editPermissionsState(state: EditPermissionState) {
    this._editPermissionsState$.next(state)
    if (state) this.message = messages[state](this.permissionChanges?.permission)
  }
  get inFreeTier() { return this._subscriptionService.inFreeTier }
  get project() { return this._dashboardService.selectedProjectForDetails ? this._dashboardService.selectedProjectForDetails : this._projectService.currentProject }

  constructor(
    private _authenticationService: AuthenticationService,
    private _dashboardService: DashboardService,
    private _projectService: ProjectService,
    private _router: Router,
    private _subscriptionService: SubscriptionService,
    private _tooltipService: TooltipService,
    public userService: UserService,
  ) { }

  ngOnInit() {
    if (this._authenticationService.currentModule == 'builder') {
      var observableProject$ = project$
    } else {
      var observableProject$ = this._dashboardService.selectedProjectForDetails$
    }

    this._subscriptions.push(
      observableProject$
        .pipe(filter(project => project !== undefined))
        .subscribe((project: Project) => this.permissions = project.permissions)
    )
  }

  ngAfterViewInit(): void {
    setTimeout(() => this._tooltipService.intializeTooltips())
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  showUpdateConfirmation(permission: ProjectPermission, element: HTMLSelectElement) {
    if (this.changesPresentForDifferentUser(permission)) this.resetChangedElementToOriginalValue()
    if (!this.canChangePermission(permission)) {
      element.value = permission.permission
      this.editPermissionsState = EditPermissionState.ONE_USER_WARNING
      return
    }
    if (element.value == permission.permission) {
      this.cancel()
      return
    }
    this.permissionChanges = { permission, element }
    this.editPermissionsState = EditPermissionState.UPDATE
  }

  showDeleteConfirmation(permission: ProjectPermission) {
    this.resetChangedElementToOriginalValue()
    if (!this.canChangePermission(permission)) {
      this.editPermissionsState = EditPermissionState.ONE_USER_WARNING
      return
    }
    this.permissionChanges = { permission }
    this.editPermissionsState = EditPermissionState.DELETE
  }

  /** Checks if the permission change would leave the project without an owner.
   * @param permissionToChange original permission before change is applied
   * @returns `true` if valid change
  */
  canChangePermission(permissionToChange: ProjectPermission) {
    const numberOfOriginalOwners = this.permissions.filter(perm => perm.permission === 'Owner').length
    const permissionToChangeIsNotOwner = permissionToChange.permission != 'Owner'
    return numberOfOriginalOwners >= 2 || permissionToChangeIsNotOwner
  }

  changesPresentForDifferentUser(attemptedChangePermission: ProjectPermission) {
    return this.permissionChanges && this.permissionChanges.permission.userID != attemptedChangePermission.userID
  }

  confirmUpdate() {
    this.permissionChanges.permission.permission = this.permissionChanges.element.value as PermissionType
    this._projectService.updateProjectPermission(this.project, this.permissionChanges.permission).subscribe(() => this.cancel())
  }

  confirmDelete() {
    const userID = this.permissionChanges.permission.userID
    this._projectService.deleteProjectPermission(this.permissionChanges.permission, this.project).subscribe(() => {
      this.cancel()
      if (userID === this._authenticationService.currentUser.sub) this.close()
    })
  }

  cancel() {
    if (this.permissionChanges?.element) this.resetChangedElementToOriginalValue()
    this.clearPermissionChanges()
    this._editPermissionsState$.next(undefined)
  }

  close() {
    if(this._authenticationService.currentModule == "builder") this._router.navigate(['projects'])
    else this._dashboardService.detailsModal?.hide()
  }

  resetChangedElementToOriginalValue() {
    if (!this.permissionChanges?.element) return
    let originalPermissionValue = this.permissions.find(perm => perm.userID == this.permissionChanges.permission.userID).permission
    this.permissionChanges.element.value = originalPermissionValue
  }

  clearPermissionChanges() {
    this.permissionChanges = undefined
  }

  addPermission() {
    this.editPermissionsState = EditPermissionState.ADD
  }

  permissionAdded() {
    this.editPermissionsState = undefined
  }
}