<div class="d-grid gap-2">
    <div *ngIf="files.value.length > 0">
        <label class="form-label">GeoTIFF Files</label>

        <div class="list-group">
            <div *ngFor="let file of files.value; index as i" class="list-group-item d-flex align-items-center gap-2">
                <label class="btn btn-primary btn-sm" [for]="'replace-file-' + i">
                    <i class="fas fa-file-search"></i>
                    <input [hidden]="true" [id]="'replace-file-' + i" type="file" accept=".tif, .tiff"
                        (change)="replaceFile($event.target.files[0], i)">
                </label>

                <input class="form-control" type="text" [placeholder]="file.name" disabled>

                <div class="btn btn-outline-danger btn-sm" (click)="removeFile(i)">
                    <i class="fas fa-trash"></i>
                </div>
            </div>
        </div>
    </div>

    <label class="form-control btn btn-success btn-sm w-25">
        + Add Files
        <input type="file" [hidden]="true" accept=".tif, .tiff" multiple (change)="addFiles($event.target.files)">
    </label>

    <small *ngIf="files.invalid && files.touched" class="form-text text-danger">
        Must provide .tif files
    </small>
</div>