import { Offcanvas } from 'bootstrap'

import { EventEmitter, Injectable } from '@angular/core'

export type PanelOptions = {
  destroyOnClose?: boolean
  title: string
  width?: string
}

export type SidePanel = {
  component: any
  componentRef: any
  destroyOnClose: boolean
  isOpen: boolean
  isResponsive: boolean
  onClose: EventEmitter<void>
  panel: Offcanvas
  title: string
  width: string
  zIndex: string
}

@Injectable({
  providedIn: 'root'
})
export class UiService {
  public rightPanel: SidePanel = {
    component: null,
    componentRef: null,
    destroyOnClose: false,
    isOpen: false,
    isResponsive: false,
    onClose: new EventEmitter(),
    panel: null,
    title: '',
    width: '50%',
    zIndex: 'var(--right-sidepanel-index)'
  }

  constructor() { }

  closeRightPanel() {
    this.rightPanel.isOpen = false
    this.rightPanel.panel.hide()
    this.rightPanel.onClose.emit()
    this.rightPanel.zIndex = 'var(--right-sidepanel-index)'
  }

  openRightPanel(component: any, options?: PanelOptions) {
    if (this.rightPanel.componentRef) {
      setTimeout(() => {
        this.rightPanel.componentRef.destroy()
        this.rightPanel.componentRef = undefined
      })
    }
    this.rightPanel.component = component
    this.rightPanel.isOpen = true
    this.rightPanel.panel.show()
    this.rightPanel.title = options?.title
    this.rightPanel.destroyOnClose = options?.destroyOnClose

    if (options?.width) {
      this.rightPanel.width = options.width
    }
  }

  toggleRightPanel() {
    if (this.rightPanel.isOpen) {
      this.closeRightPanel()
    } else {
      this.openRightPanel(this.rightPanel.component)
    }
  }
}