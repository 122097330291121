<div *ngIf="selectedAsset" class="p-2 d-flex flex-column align-items-center">
    <!-- Row 1: Selected Asset label and Remove button -->
    <div class="d-flex gap-4 align-items-center w-100 justify-content-between mb-3">
        <label class="fs-5 fw-bold">Selected Asset</label>
        <button class="btn btn-sm btn-danger" (click)="removeSelectedAsset()">Remove</button>
    </div>

    <!-- Grid: Labels and Values in two synchronized rows -->
    <div class="d-flex flex-column overflow-auto w-100">
        <div class="d-flex gap-1 flex-nowrap">
            <div class="column">Asset ID:</div>
            <div class="column">{{selectedAsset.id}}</div>
            <ng-container *ngFor="let datum of compiledAssetData">
                <div class="column">
                    <ng-container [ngSwitch]="datum.type">
                        <ng-container *ngSwitchCase="'trueFalse'">
                            <span>{{datum.value == 0 ? 'True' : 'False'}}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'location'">
                            <span>{{datum.value[1]}}, {{datum.value[0]}}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'chooseMultiple'">
                            <span>{{ isArray(datum.value) ? datum.value.join(', ') : datum.value }}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'images'">
                            <span *ngIf="!datum.value || datum.value == ''">No Files Selected</span>
                            <span *ngIf="datum.value?.length > 0">{{datum.value}}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'files'">
                            <span *ngIf="datum.value == ''">No Files Selected</span>
                            <span *ngIf="datum.value.length > 0">{{datum.value}}</span>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span>{{datum.value}}</span>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="mt-1 mb-1 me-1">
    <div class="input-group input-group-sm mt-1">
        <span class="input-group-text">Asset Types</span>
        <div class="flex-grow-1">
            <ng-select class="w-100" [items]="assetTypes" bindLabel="name" [(ngModel)]="selectedAssetType"
                (change)="selectAssetType($event)" [searchable]="true" placeholder="Search Asset Types">
            </ng-select>
        </div>
    </div>
</div>

<div class="d-flex flex-column gap-2" style="max-height:500px">
    <div style="max-height:400px;">
        <div class="d-flex border-start flex-grow-1 w-100" style="overflow-x: auto; overflow-y: auto">
            <div class="d-flex justify-content-center align-items-center flex-grow-1">
                <span *ngIf="!(selectedAssetType$ | async)" class="fs-3 p-2">No Asset Type Selected</span>
                <div *ngIf='selectedAssetType$ | async' class='h-100 w-100' [ngClass]="{'bg-light bg-gradient': true}"
                    (scroll)="onTableScroll($event)">
                    <table class="table table-striped table-hover w-100">
                        <thead>
                            <tr>
                                <th style="height:50px">
                                    <div class="text-nowrap">
                                        <span>Asset ID</span>
                                        <i [ngClass]="{'ms-1 fa': true, 'fa-caret-down': sortOrder == 'ASC' && !sortByFieldID, 'fa-caret-up': (sortOrder == 'DESC' && !sortByFieldID) || sortByFieldID}"
                                            style="cursor: pointer;">
                                        </i>
                                    </div>
                                </th>
                                <th *ngFor="let field of assetFields; index as i" style="height:50px">
                                    <ng-container [ngSwitch]="field.type">
                                        <ng-container *ngSwitchCase="'images'">
                                            <div class="d-flex align-items-center">
                                                <span>{{field.name}}</span>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'files'">
                                            <div class="d-flex align-items-center">
                                                <span>{{field.name}}</span>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <div
                                                class="d-flex align-items-center input-group input-group-sm flex-nowrap">
                                                <div class="input-group-text">
                                                    <span>{{field.name}}</span>
                                                    <i [ngClass]="{
                                            'ms-1 fa': true, 
                                            'fa-caret-down': sortByFieldID == field.id && sortOrder == 'ASC', 
                                            'fa-caret-up': sortByFieldID != field.id || (sortByFieldID == field.id && sortOrder == 'DESC')
                                        }" (click)="sortByField(field)" style="cursor: pointer;">
                                                    </i>
                                                </div>
                                                <input type="search" class='form-control flex-grow-1'
                                                    style="flex-shrink:0; min-width: 100px;"
                                                    [value]="filterMap.get(field.id) ? filterMap.get(field.id) : ''"
                                                    (focusout)="filter(field, $event.target.value)"
                                                    placeholder="Filter">
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let row of assetTableRows" [style.cursor]='"pointer"'
                                [ngClass]="{'table-primary': selectedAsset?.id == row.assetID}"
                                (click)="selectAsset(row.assetID)">
                                <td>
                                    <div class="d-flex gap-1 align-items-center">
                                        <span>{{row.assetID}}</span>
                                    </div>
                                </td>
                                <td *ngFor="let value of row.values; index as i">
                                    <div class="d-flex align-items-center gap-1">
                                        <ng-container [ngSwitch]="getValueType(i)">
                                            <ng-container *ngSwitchCase="'trueFalse'">
                                                <span>{{value == 0 ? 'True' : 'False'}}</span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'location'">
                                                <span class="text-truncate">
                                                    {{value[1]}}, {{value[0]}}
                                                </span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'chooseMultiple'">
                                                <div class="d-flex flex-column">
                                                    <span class="text-truncate">
                                                        {{ isArray(value) ? value.join(', ') : value }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'images'">
                                                <span *ngIf="!value || value == ''">No Files Selected</span>
                                                <ng-container *ngIf="value?.length > 0">
                                                    <span>{{value}}</span>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'files'">
                                                <span *ngIf="value == ''">No Files Selected</span>
                                                <ng-container *ngIf="value.length > 0">
                                                    <span>{{value}}</span>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <span>{{value}}</span>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </td>

                            </tr>

                            <div *ngIf="assetTableRows.length == 0 && loading" class="p-3 fs-5 text-muted">
                                Loading...
                            </div>
                            <div *ngIf="assetTableRows.length == 0 && !loading" class="p-3 fs-5 text-muted">
                                No Entries
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>