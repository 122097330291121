import { Modal } from 'bootstrap'
import { Observable, Subscription } from 'rxjs'
import { filter, switchMap, tap } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core'
import { Project } from '@classes/Project'
import { Scene, SceneType } from '@classes/Scene'
import { LoadingService } from '@services/loading.service'
import { ModalBody } from '@services/modal.service'
import { ModelService } from '@services/model.service'
import { ProjectService } from '@services/project.service'
import { RightSidebarService } from '@services/right-sidebar.service'
import { SceneService } from '@services/scene.service'
import { SubscriptionService } from '@services/subscription.service'
import { CreateMapSceneComponent } from '@shared/create-map-scene/create-map-scene.component'
import { CreateStandardSceneComponent } from '@shared/create-standard-scene/create-standard-scene.component'
import { CreateThreeSixtySceneComponent } from '@shared/create-three-sixty-scene/create-three-sixty-scene.component'
import { CreateVirtualTourComponent } from '@shared/create-virtual-tour/create-virtual-tour.component'

export interface CreateSceneBody {
  createScene$(projectID: number): Observable<Scene> | any
  form: any
}

export function isVirtualTourComponent(component: any): component is CreateVirtualTourComponent {
  return component instanceof CreateVirtualTourComponent
}

@Component({
  selector: 'shared-modal-create-scene',
  templateUrl: './create-scene.component.html',
  styleUrls: ['./create-scene.component.css'],
})
export class CreateSceneComponent implements ModalBody, OnChanges, OnDestroy {
  @Input() events: EventEmitter<'close' | 'submit'>
  @Input() modal: Modal
  @ViewChild(CreateMapSceneComponent) mapComponent: CreateMapSceneComponent
  @ViewChild(CreateStandardSceneComponent) standardComponent: CreateStandardSceneComponent
  @ViewChild(CreateThreeSixtySceneComponent) threeSixtyComponent: CreateThreeSixtySceneComponent
  @ViewChild(CreateVirtualTourComponent) virtualTourComponent: CreateVirtualTourComponent

  private _subscriptions: Subscription[] = []
  public submitting: boolean = false
  public type: SceneType = 'Map'

  get at360Limit() { return this._subscriptionService.at360Limit }
  get atModelLimit() { return this._subscriptionService.atModelLimit }
  get bodyComponent() {
    if (this.type == '360 Image') {
      return this.threeSixtyComponent
    } else if (this.type == 'Map') {
      return this.mapComponent
    } else if (this.type == 'Standard') {
      return this.standardComponent
    } else if (this.type == 'Virtual Tour') {
      return this.virtualTourComponent
    }
  }
  get form() { return this.bodyComponent.form }

  constructor(
    private _loadingService: LoadingService,
    private _modelService: ModelService,
    private _projectService: ProjectService,
    private _rightSidebarService: RightSidebarService,
    private _sceneService: SceneService,
    private _subscriptionService: SubscriptionService,
  ) {
    this._loadingService.await(
      this._modelService.getUsersModels()
    )
  }

  ngOnChanges() {
    this._subscriptions.push(
      this.events.pipe(filter(event => event == 'submit')).subscribe(() => this.submit())
    )
  }

  submit(): boolean {
    if (this.form.valid && !this.submitting) {
      this.submitting = true

      const projectID = this._projectService.currentProject.id

      if (isVirtualTourComponent(this.bodyComponent)) {
        this.bodyComponent.createScene$({ id: projectID } as Project).pipe(
          switchMap(() => this._subscriptionService.getSubscriptionUsage())
        ).subscribe(() => this.submitting = false)
      } else {
        this._loadingService.await(
          this.bodyComponent.createScene$(projectID).pipe(
            switchMap(scene => {
              this._sceneService.selectedScene = scene
              this._rightSidebarService.setTab("Scene")

              return this._subscriptionService.getSubscriptionUsage()
            }),
            tap(() => this.submitting = false)
          )
        )
      }

      this.modal.hide()

      return true
    } else {
      this.form.markAllAsTouched()

      return false
    }
  }

  createScene$(projectID: number) {
    if (isVirtualTourComponent(this.bodyComponent)) {
      return this.bodyComponent.createScene$({ id: projectID } as Project).pipe(tap(() => this.type = "Map"))
    } else {
      return this.bodyComponent.createScene$(projectID).pipe(tap(() => this.type = "Map"))
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}