import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Project } from '@classes/Project'
import { SceneService } from '@services/scene.service'
import { CreateSceneBody } from '@shared/create-scene/create-scene.component'
import { validateFileExtensions } from '@validators/Files'

@Component({
  selector: 'shared-create-virtual-tour',
  templateUrl: './create-virtual-tour.component.html',
  styleUrls: ['./create-virtual-tour.component.css']
})
export class CreateVirtualTourComponent implements CreateSceneBody {
  public form = new FormGroup({
    file: new FormControl<File>(null, [Validators.required, validateFileExtensions(['e57'])]),
    name: new FormControl<string>('New Virtual Tour Scene', [Validators.required]),
  })

  get file() { return this.form.get('file') as FormControl<File> }
  get name() { return this.form.get('name') as FormControl<string> }

  constructor(private _sceneService: SceneService) { }

  setFile(file: File) {
    this.file.setValue(file)
    this.file.markAsTouched()
  }

  createScene$(project: Project | any) {
    const { file, name } = this.form.value

    return this._sceneService.createVirtualTour$(project, name, file) as any
  }
}